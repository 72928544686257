import { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'

export default function SuccessfulNoti({ show, setShow, message }) {

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="tw-pointer-events-none tw-fixed tw-inset-0 tw-flex tw-items-start tw-px-4 tw-py-6 sm:tw-items-start sm:tw-p-6 tw-z-50"
      >
        <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-space-y-4 sm:tw-items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="tw-pointer-events-auto tw-w-full tw-max-w-sm tw-overflow-hidden tw-rounded-lg tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5">
              <div className="tw-p-4">
                <div className="tw-flex tw-items-start">
                  <div className="tw-flex-shrink-0">
                    <CheckCircleIcon className="tw-h-6 tw-w-6 tw-text-green-400" aria-hidden="true" />
                  </div>
                  <div className="tw-ml-3 tw-w-0 tw-flex-1 tw-pt-0.5">
                    <p className="tw-text-sm tw-font-medium tw-text-gray-900">{message}!</p>
                    {/* <p className="tw-mt-1 tw-text-sm tw-text-gray-500">Anyone with a link can now view this file.</p> */}
                  </div>
                  <div className="tw-ml-4 tw-flex tw-flex-shrink-0">
                    <button
                      type="button"
                      className="tw-inline-flex tw-rounded-md tw-bg-white tw-text-gray-400 tw-hover:text-gray-500 tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-indigo-500 tw-focus:ring-offset-2"
                      onClick={() => {
                        setShow(false)
                      }}
                    >
                      <span className="tw-sr-only">Close</span>
                      <XMarkIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}