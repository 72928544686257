import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import MultiComboBoxInput from '../MultiComboBoxInput'

export default function CompetitionFilterPopup({
    open, skillItems, skillSingleSelect, setOpen,
    setFilters, handleFilterChange,filters,
    teamTypeItems, teamTypeSingleSelect, clearEventFilters
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="tw-relative tw-z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
          <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="tw-relative tw-transform tw-overflow-auto tw-rounded-lg tw-bg-white 
              tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all tw-leading-[15rem]
              sm:tw-my-8 sm:tw-w-full sm:tw-max-w-sm sm:tw-p-6">
          
              <div className="tw-pt-5">
                <label htmlFor="teamTypes" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                  Filter by Competition Sector
                </label>
                {filters && filters.teamTypes && 
                  <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                    Selected: {Array.isArray(filters.teamTypes) ? filters.teamTypes.join(', ') : filters.teamTypes}
                  </div>
                }
                <MultiComboBoxInput
                  items={teamTypeItems}
                  label="teamTypes"
                  altLabel="Competition Sector"
                  singleSelect={teamTypeSingleSelect}
                  selectedValues={filters.teamTypes}
                  setFilters={setFilters}
                  handleFilterChange={handleFilterChange}
                />
              </div>

              
              <div className="tw-pt-5">
                <label htmlFor="memberTypes" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                  Filter by Skill
                </label>
                {filters && filters.memberTypes && 
                  <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                    Selected: {Array.isArray(filters.memberTypes) ? filters.memberTypes.join(', ') : filters.memberTypes}
                  </div>
                }
                <MultiComboBoxInput
                  items={skillItems}
                  label="memberTypes"
                  altLabel="Skill"
                  singleSelect={skillSingleSelect}
                  selectedValues={filters.memberTypes}
                  setFilters={setFilters}
                  handleFilterChange={handleFilterChange}
                />
              </div>
              <div className="tw-flex tw-justify-between tw-mt-40 tw-mb-2">
                <button
                  type="button"
                  className="tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-red-600 tw-text-base tw-font-medium tw-text-white hover:tw-bg-red-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-red-500 tw-w-1/2 tw-mr-2"
                  onClick={clearEventFilters}
                >
                  Clear Filters
                </button>
                <button
                  type="button"
                  className="tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-indigo-600 tw-text-base tw-font-medium tw-text-white hover:tw-bg-indigo-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500 tw-w-1/2 tw-ml-2"
                  onClick={() => setOpen(false)}
                >
                  Go Back
                </button>
              </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
