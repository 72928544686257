import React, { useState, useEffect, useRef } from 'react';
import { useUser } from '../../../contexts/UserContext';
import ChaiFilterPopup from './ChaiFilterPopup';
import { handleFilterChange } from '../../../utils/helperFunctions';
import { industryValues } from '../../../utils/helperValues';

export default function ChaiFilters({ users }) {
  const { 
    chaiPageSearchTerm, 
    setChaiPageSearchTerm,
    chaiFilters: filters, 
    setChaiFilters: setFilters, 
    userInfo,
    clearChaiFilters: clearFilters
  } = useUser();
  const [filterOpen, setFilterOpen] = useState(false);
  const handleFilterOpen = () => setFilterOpen(!filterOpen);

  const sortedTags = userInfo && userInfo.chaiTagsArray ? userInfo.chaiTagsArray.sort((a, b) => a.value.localeCompare(b.value)) : [];

  return (
    <>
                <div className="tw-grid tw-grid-cols-4 tw-gap-4 tw-w-full">
                  <input
                    id="search-field"
                    className="tw-block tw-w-full tw-h-9 tw-border tw-border-gray-300 tw-rounded-md  md:tw-pt-0 tw-pb-0 
                    tw-pl-0 tw-pr-0 tw-text-gray-900 tw-placeholder:text-gray-400 
                    sm:tw-text-sm dark:tw-text-white dark:tw-bg-black tw-col-span-3 "
                    
                    placeholder="Search by name, notes, email" 
                    value={chaiPageSearchTerm}
                    onChange={e => {
                        setChaiPageSearchTerm(e.target.value)
                    }}  
                    type="search"
                    name="search"
                  />
                   <div className="tw-col-span-1 tw-flex tw-justify-center tw-items-center">
                    <button
                      type="button"
                      className="tw-rounded-full tw-bg-indigo-600 tw-px-4 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
                      onClick={handleFilterOpen}
                    >
                      Filter
                    </button>
                  </div>
                </div>
            {userInfo && userInfo.chaiTagsArray && (
              <ChaiFilterPopup
                open={filterOpen}
                filters={filters}
                clearFilters={clearFilters}
                tagItems={sortedTags}
                tagSingleSelect={false}
                setOpen={setFilterOpen}
                setFilters={setFilters}
                handleFilterChange={handleFilterChange}
                industryItems={industryValues} 
                industrySingleSelect={false}
                />
            )}
      </>
  );
}

