import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import MultiComboBoxInput from '../../filters/MultiComboBoxInput'


export default function EditJob({ 
    handleChange,
    formState,
    popUpOpen, setPopUpOpen,
    handleSubmit, loading,
    industryValues,roleValues,
    jobFilters, setJobFilters, 
    handleMultiComboChangeHelper, handleFilterChange, jobStatuses=[]
      }) {
  return (
    <Transition.Root show={popUpOpen} as={Fragment}>
      <Dialog as="div" className="tw-relative tw-z-10" onClose={setPopUpOpen}>
        <Transition.Child
          as={Fragment}
          enter="tw-ease-out tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in tw-duration-200"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
        </Transition.Child>

        <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
          <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
            <Transition.Child
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
              enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            >
              <Dialog.Panel className="tw-relative tw-transform tw-overflow-auto tw-rounded-lg 
              tw-bg-white tw-px-4 tw-pb-4 tw-pt-10 tw-text-left tw-shadow-xl tw-transition-all sm:tw-mb-8 sm:tw-w-full 
              sm:tw-max-w-sm sm:tw-p-6 tw-mt-20">
                <form>
                    <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="company">
                          Company <span className="required-star">*</span>
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="company" type="text" 
                          value={formState.company || ''}
                          placeholder="Company" onChange={handleChange} required />
                      </div>
                      {jobStatuses.length > 0 && (
                        <div className="tw-mb-4">
                          <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="jobStatus">
                            Job Status <span className="required-star">*</span>
                          </label>
                          <select 
                            className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                            name="jobStatus" 
                            value={formState.jobStatus || ''}
                            onChange={handleChange}
                            required
                          >
                            {jobStatuses.map((status, index) => (
                              <option key={index} value={status}>
                                {status}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="companyWebsite">
                          Company Website <span className="required-star">*</span>
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="companyWebsite" type="url" 
                          value={formState.companyWebsite || ''}
                          placeholder="www.bestcompany.com" onChange={handleChange} required />
                        <p className="tw-mt-1 tw-text-xs tw-text-gray-600">Enter the main website of the company, not the recruiting page.</p>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="role">
                          Role Name <span className="required-star">*</span>
                        </label>
                        <textarea 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="role" 
                          value={formState.role || ''}
                          placeholder="Role" 
                          onChange={handleChange} 
                          required 
                        />
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="roleType">
                          Role Type
                        </label>
                        <select 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                          name="roleType" onChange={handleChange} required>
                          <option value="">Select Role Type</option>
                          <option value="Full-time" selected={formState.roleType === "Full-time"}>Full-time</option>
                          <option value="Internship" selected={formState.roleType === "Internship"}>Internship</option>
                        </select>
                      </div>
                      <div className="tw-mb-4">
                      {jobFilters && jobFilters.industries && 
                        <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                          Selected: {Array.isArray(jobFilters.industries) ? jobFilters.industries.join(', ') : jobFilters.industries}
                        </div>
                      }
                      <MultiComboBoxInput 
                      items={industryValues}
                      label="industries"
                      singleSelect={false}
                      setFilters={setJobFilters}
                      handleFilterChange={handleFilterChange}
                      selectedValues={jobFilters.industries || []}
                      />
                    </div>
                    <div className="tw-mb-4">
                      {jobFilters && jobFilters.roles && 
                        <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                          Selected: {Array.isArray(jobFilters.roles) ? jobFilters.roles.join(', ') : jobFilters.roles}
                        </div>
                      }
                      <MultiComboBoxInput 
                      items={roleValues}
                      label="roles"
                      singleSelect={false}
                      setFilters={setJobFilters}
                      handleFilterChange={handleFilterChange}
                      selectedValues={jobFilters.roles || []}
                      />
                    </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="jobLink">
                          Job Link <span className="required-star">*</span>
                        </label>
                        <textarea 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="jobLink" 
                          value={formState.jobLink || ''}
                          placeholder="www.jobs.com/id/123456" 
                          onChange={handleChange} 
                          required 
                        />
                        <p className="tw-mt-1 tw-text-xs tw-text-gray-600">Enter the direct link to the job posting</p>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="isSponsor">
                          Sponsors Internationals?
                        </label>
                        <select 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                          name="isSponsor" onChange={handleChange} required>
                          <option value="">Select</option>
                          <option value="Yes" selected={formState.isSponsor === "Yes"}>Yes</option>
                          <option value="No" selected={formState.isSponsor === "No"}>No</option>
                        </select>
                      </div>
                     <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="datePosted">
                          Date Posted
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="datePosted" type="date" 
                          value={formState.datePosted || ''}
                          onChange={handleChange} required />
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="deadline">
                          Deadline
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="deadline" type="date" 
                          value={formState.deadline || ''}
                          onChange={handleChange} required />
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="location">
                          Location(s)
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="location" type="text" 
                          value={formState.location || ''}
                          placeholder="Location" onChange={handleChange} required />
                          <p className="tw-mt-1 tw-text-xs tw-text-gray-600">For Multiple Locations, Add a / between each location</p>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="isAcademic">
                          Academic Internship?
                        </label>
                        <select 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                          name="isAcademic" onChange={handleChange} required>
                          <option value="">Select</option>
                          <option value="Yes" selected={formState.isAcademic === "Yes"}>Yes</option>
                          <option value="No" selected={formState.isAcademic === "No"}>No</option>
                        </select>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="workSetting">
                          Work Setting
                        </label>
                        <select 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                          name="workSetting" onChange={handleChange} required>
                          <option value="">Select Work Setting</option>
                          <option value="In-office" selected={formState.workSetting === "In-office"}>In-office</option>
                          <option value="Remote" selected={formState.workSetting === "Remote"}>Remote</option>
                          <option value="Hybrid" selected={formState.workSetting === "Hybrid"}>Hybrid</option>
                        </select>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="startDate">
                          Start Date
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="startDate" type="date" 
                          value={formState.startDate || ''}
                          onChange={handleChange} required />
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="endDate">
                          End Date
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="endDate" type="date" 
                          value={formState.endDate || ''}
                          onChange={handleChange} required />
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="isCoverLetterRequired">
                          Cover Letter Required?
                        </label>
                        <select 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                          name="isCoverLetterRequired" onChange={handleChange} required>
                          <option value="">Select</option>
                          <option value="yes" selected={formState.isCoverLetterRequired === "yes"}>Yes</option>
                          <option value="no" selected={formState.isCoverLetterRequired === "no"}>No</option>
                        </select>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="additionalNotes">
                          Additional Notes
                        </label>
                        <textarea 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="additionalNotes" 
                          value={formState.additionalNotes || ''}
                          placeholder="Additional Notes" onChange={handleChange} />
                      </div>
                  <div className="tw-mb-4">
                    <button 
                      className="tw-inline-flex tw-w-full tw-justify-center tw-bg-blue-500 tw-hover:bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
                      disabled={loading} 
                      onClick={handleSubmit}>
                      Submit
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}









