

import React, { useState, useEffect, useRef } from 'react';
import { useUser } from '../../contexts/UserContext';
import { useParams, useNavigate } from 'react-router-dom';
import FilterPopup from '../../components/FilterPopup';
import { useNavigateToDirectory } from '../../utils/helperFunctions';
import Loading from '../../components/Loading';

export default function ContactDetail() {
    const { user } = useUser();
    const navigate = useNavigate();
    // const [eventData, setEventData] = useState(null);
    // const [activeTab, setActiveTab] = useState('attending');
    // let { id } = useParams();
    // const navigate = useNavigate();
  
    // useEffect(() => {
    //   if (events) {
    //     const event = events.find(event => event.id === id);
    //     setEventData(event);
    //   }
    // }, [events, id]);
  
    if (!user) {
      return <Loading />;
    }
  
    const downloadToCalendar = () => {
      // Placeholder for download to calendar logic
    };
  
    // const handleJoinEvent = () => {
    //   joinEvent(user.uid, id);
    // };
  
    // const {
    //   eventName: Name,
    //   image: eventImage,
    //   neighborhood: Neighborhood,
    //   address: Address,
    //   eventType,
    //   eventLink,
    //   eventDescription,
    //   startTime,
    //   startDate,
    //   organizers,
    //   attendees
    // } = eventData;

    const profileImageUrl = "https://via.placeholder.com/150";
    const name = "John Doe";
    const email = "johndoe@example.com";
    const phone = "+1234567890";
    const company = "Acme Corporation";
    const xProfileUrl = "https://www.example.com/xprofile";
    const xProfile = "XProfile Name";
    const linkedInProfileUrl = "https://www.linkedin.com/in/johndoe";
    const linkedIn = "John Doe LinkedIn";
    const notes = "Met at the annual tech conference. Interested in collaborative projects in software development.";

    const interactionHistory = [
        { id: 1, summary: "Met at tech conference", date: "2022-05-15" },
        { id: 2, summary: "Follow-up call about project", date: "2022-05-20" }
    ];

    const futureInteractions = [
        { id: 1, summary: "Scheduled meeting for project discussion", date: "2022-06-10" }
    ];

    const firstMet = "Annual Tech Conference 2022";

    const tasks = [
        { id: 1, description: "Send project proposal draft", dueDate: "2022-06-15" },
        { id: 2, description: "Arrange follow-up meeting", dueDate: "2022-06-20" }
    ];

    const tags = ["Tech", "Collaboration", "Software Development"];
    
  
    return (
        <div className="contact-detail">
        <div className="contact-detail__header">
            <button className="contact-detail__back-button" onClick={() => navigate(-1)}>Back</button>
            {/* <div className="contact-detail__header-info"> */}
                <img src={profileImageUrl} alt="Profile" className="contact-detail__image"/>
                <h1 className="contact-detail__name">{name}</h1>
            {/* </div> */}
        </div>
        <div className="contact-detail__info">
            <div className="contact-detail__section contact-detail__tags">
                <h2>Tags</h2>
                {/* <input type="text" className="add-input" placeholder="Add a new tag..."/>
                <button className="btn">Add Tag</button> */}
                <ul>
                    {tags.map(tag => (
                        <li key={tag} className="contact-detail__tag">
                            {tag}
                            {/* <i className="fa fa-times tag-remove-icon" onClick={() => removeTag(tag)}></i> */}
                            <i className="fa fa-times contact-detail__tag__remove-icon"></i>
                            </li>
                    ))}
                </ul>
            </div>
            <div className="contact-detail__info-grid">
                <div className="contact-detail__section contact-detail__contact-info">
                    <h2>Contact Information</h2>
                    <p><strong>Email:</strong> <a href={`mailto:${email}`}>{email}</a></p>
                    <p><strong>Phone:</strong> <a href={`tel:${phone}`}>{phone}</a></p>
                    <p><strong>Company/Organization:</strong> {company}</p>
                    <p><strong>X Profile:</strong> <a href={xProfileUrl}>{xProfile}</a></p>
                    <p><strong>LinkedIn Profile:</strong> <a href={linkedInProfileUrl}>{linkedIn}</a></p>
                </div>
                <div className="contact-detail__section contact-detail__notes">
                    <h2>Contact History</h2>
                    <p><strong>First Met: </strong>{firstMet}</p>
                    <p><strong>Notes: </strong>{notes}</p>
                    {/* <textarea className="editable-text">{notes}</textarea> */}
                    {/* <button className="btn">Save Notes</button> */}
                </div>
            </div>
            <div className="contact-detail__history-grid">
                <div className="contact-detail__section contact-detail__tasks">
                        <h2>Tasks</h2>
                        <input type="text" className="add-input" placeholder="Add a new task..."/>
                        <button className="btn">Add Task</button>
                        <ul>
                            {tasks.map(task => (
                                <li key={task.id}>{task.description} - {task.dueDate}</li>
                            ))}
                        </ul>
                </div>
                <div className="contact-detail__interactions-future">
                    <div className="contact-detail__section contact-detail__interaction-history">
                        <h2>Scheduled Future Interactions</h2>
                        <ul>
                            {futureInteractions.map(interaction => (
                                <li key={interaction.id}>{interaction.summary} - {interaction.date}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="contact-detail__section contact-detail__future-interactions">
                        <h2>Interaction History</h2>
                        <input type="text" className="add-input" placeholder="Add a new interaction..."/>
                        <button className="btn">Add Interaction</button>
                        <ul>
                            {interactionHistory.map(interaction => (
                                <li key={interaction.id}>{interaction.summary} - {interaction.date}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    );
}
