import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import MultiComboBoxInput from '../../filters/MultiComboBoxInput'


export default function EditSharedCompany({ 
    handleChange,
    formState, 
    popUpOpen, setPopUpOpen,
    handleSubmit, loading,
    companyFilters, setCompanyFilters,
    handleFilterChange, 
    industryValues,roleValues
}) {
  return (
    <Transition.Root show={popUpOpen} as={Fragment}>
      <Dialog as="div" className="tw-relative tw-z-10" onClose={setPopUpOpen}>
        <Transition.Child
          as={Fragment}
          enter="tw-ease-out tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in tw-duration-200"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
        </Transition.Child>

        <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
          <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
            <Transition.Child
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
              enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            >
              <Dialog.Panel className="tw-relative tw-transform tw-overflow-auto tw-rounded-lg 
              tw-bg-white tw-px-4 tw-pb-4 tw-pt-10 tw-text-left tw-shadow-xl tw-transition-all sm:tw-mb-8 sm:tw-w-full 
              sm:tw-max-w-sm sm:tw-p-6 tw-mt-20">
                <form>
                    <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="companyName">
                          Company Name <span className="required-star">*</span>
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="companyName" type="text" 
                          value={formState.companyName || ''}
                          placeholder="Company" onChange={handleChange} required />
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="companyId">
                          Company Id (used to match contacts to the company )<span className="required-star">*</span>
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="companyId" type="text" 
                          value={formState.companyId || ''}
                          placeholder="companyId" onChange={handleChange} required />
                          <p className="tw-mt-1 tw-text-xs tw-text-gray-600">Also known as a slug i.e. disney or morganstanley</p>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="companyWebsite">
                          Company Website <span className="required-star">*</span>
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="companyWebsite" type="url" 
                          value={formState.companyWebsite || ''}
                          placeholder="www.bestcompany.com" onChange={handleChange} required />
                        <p className="tw-mt-1 tw-text-xs tw-text-gray-600">Enter the main website of the company, not the recruiting page.</p>
                      </div>
                      <div className="tw-mb-4">
                      {companyFilters && companyFilters.industries && 
                        <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                          Selected: {Array.isArray(companyFilters.industries) ? companyFilters.industries.join(', ') : companyFilters.industries}
                        </div>
                      }
                      <MultiComboBoxInput 
                      items={industryValues}
                      label="industries"
                      singleSelect={false}
                      setFilters={setCompanyFilters}
                      handleFilterChange={handleFilterChange}
                      selectedValues={companyFilters.industries || []}
                      />
                    </div>
                    <div className="tw-mb-4">
                      {companyFilters && companyFilters.roles && 
                        <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                          Selected: {Array.isArray(companyFilters.roles) ? companyFilters.roles.join(', ') : companyFilters.roles}
                        </div>
                      }
                      <MultiComboBoxInput 
                      items={roleValues}
                      label="roles"
                      singleSelect={false}
                      setFilters={setCompanyFilters}
                      handleFilterChange={handleFilterChange}
                      selectedValues={companyFilters.roles || []}
                      />
                    </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="sponsorsInternationals">
                          Sponsors Internationals 
                        </label>
                        <select 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="sponsorsInternationals" 
                          value={formState.sponsorsInternationals || ''}
                          onChange={handleChange} required>
                          <option value={true} defaultValue>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="officeLocations">
                          Office Locations 
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="officeLocations" type="text" 
                          value={formState.officeLocations || ''}
                          placeholder="Office Locations" onChange={handleChange} required />
                          <p className="tw-mt-1 tw-text-xs tw-text-gray-600">Seperate multiple locations with a / between each location</p>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="organizationRelationshipToCompany">
                          Organization Relationship to Company 
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="organizationRelationshipToCompany" type="text" 
                          value={formState.organizationRelationshipToCompany || ''}
                          placeholder="Organization Relationship to Company" onChange={handleChange} required />
                          <p className="tw-mt-1 tw-text-xs tw-text-gray-600">Alumni work here, recruiting partner, etc...</p>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="networkingOpportunities">
                          Networking Opportunities 
                          {/* <span className="required-star">*</span> */}
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="networkingOpportunities" type="text" 
                          value={formState.networkingOpportunities || ''}
                          placeholder="Networking Opportunities" onChange={handleChange} required />
                          <p className="tw-mt-1 tw-text-xs tw-text-gray-600">Where can members interact with this company?</p>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="orgId">
                          Org Id 
                          {/* <span className="required-star">*</span> */}
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="orgId" type="text" 
                          value={formState.orgId || ''}
                          placeholder="orgId" onChange={handleChange} required />
                          <p className="tw-mt-1 tw-text-xs tw-text-gray-600">Update owner</p>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="companyNotes">
                          Company Notes 
                          {/* <span className="required-star">*</span> */}
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="companyNotes" type="text" 
                          value={formState.companyNotes || ''}
                          placeholder="Company Notes" onChange={handleChange} required />
                          <p className="tw-mt-1 tw-text-xs tw-text-gray-600">Any other company information</p>
                      </div>
                  <div className="tw-mb-4">
                    <button 
                      className="tw-inline-flex tw-w-full tw-justify-center tw-bg-blue-500 tw-hover:bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
                      disabled={loading} 
                      onClick={handleSubmit}>
                      Submit
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}









