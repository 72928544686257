export const studies = [
    {
      id: Math.random().toString(36).substring(2, 15),
      studyName: "Telehealth Cognitive Study",
      description: "Improving Cognition Through Telehealth Aerobic Exercise and Cognitive Training After a First Schizophrenia Episode",
      clinicalName: "Clinical 1",
      visits: {
        total: 3,
        details: {
          1: "one hour study",
          2: "two hour study",
          3: "two hour study"
        }
      },
      type: "Cancer",
      amountPerVisit: 100,
      criteriaToJoin: [
        "A first episode of a psychotic illness that began within the past two years",
        "A diagnosis by DSM-5 of schizophrenia, schizoaffective disorder, or schizophreniform disorder",
        "Age 18 to 45 years of age",
        "Sufficient acculturation and fluency in the English language to avoid invalidating research measures",
        "Residence likely to be within commuting distance of the UCLA Aftercare Research Program"
      ],
      contactInfo: "8557316040",
      studyLink: "https://www.uclahealth.org/clinical-trials/improving-cognition-through-telehealth-aerobic-exercise-and",
      active: true
    },
    {
      id: Math.random().toString(36).substring(2, 15),
      studyName: "Effect of Metformin",
      description: "This is a description for Study 2",
      clinicalName: "Effect of Metformin, a Drug Used to Treat Diabetes, vs Placebo",
      visits: {
        total: 2,
        details: {
          1: "one hour study",
          2: "two hour study"
        }
      },
      type: "Diabetes",
      amountPerVisit: 0,
      criteriaToJoin: [
        "Male or female, age ≥ 18 years at the time of signing informed consent.",
        "Body Mass Index (BMI) ≥ 27 kg/m2",
        "Waist circumference >40\" for men, and >35\" for women"
      ],
      contactInfo: "8557316040",
      studyLink: "https://www.uclahealth.org/clinical-trials/effect-metformin-drug-used-treat-diabetes-vs-placebo",
      active: true
    },
    {
      id: Math.random().toString(36).substring(2, 15),
      studyName: "UCLA Research Study",
      description: "Focus group interviews for UCLA students, staff, and faculty are being held by the Healthy Climate Committee as part of a research study about the campus climate, including topics such as belonging. The researchers would like to find out about your experience within the campus community and working and/or studying at UCLA. Participation in the focus group is voluntary. There will be other UCLA community members participating in the small group discussion (about 8 total). Focus groups will be held separately for each of the following groups: undergraduate students, graduate students, staff, and faculty. The focus group will take approximately 1.5 hours, and there are both in-person and virtual opportunities to participate. You will receive a $40 gift card for your participation, and a meal will be provided for in-person focus groups.",
      clinicalName: "N/A",
      visits: {
        total: 1,
        details: {
          1: "1.5 hour study",
        }
      },
      type: "Mental Health",
      amountPerVisit: 40,
      criteriaToJoin: [
        "Be a current UCLA student, staff, or faculty member",
        "Have worked or studied at UCLA for at least 1 year",
        "Are at least 18 years of age or older",
        "Are comfortable having a conversation in English"
      ],
      contactInfo: "",
      studyLink: "https://ucla.us5.list-manage.com/track/click?u=383c8f247acb23a1b05163ea4&id=9bcc17ff74&e=d93efb331d",
      active: true
    },
    {
      id: Math.random().toString(36).substring(2, 15),
      studyName: "Study 4",
      description: "This is a description for Study 4",
      clinicalName: "Clinical 4",
      visits: {
        total: 3,
        details: {
          1: "one hour study",
          2: "two hour study",
          3: "two hour study"
        }
      },
      type: "Pediatric Cancer",
      amountPerVisit: 400,
      criteriaToJoin: ["Criteria 7", "Criteria 8"],
      contactInfo: "8557316040",
      studyLink: "",
      active: true
    }
  ];