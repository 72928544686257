
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

export const usePageTracking = () => {
    const location = useLocation() || {};
    const isRouterContext = location.pathname !== undefined;

    useEffect(() => {
      if (isRouterContext) {
        const handleRouteChange = () => {
          const pageLoadTime = window.performance.now(); // Get the current time
          ReactGA.send({
            hitType: "pageview",
            page: location.pathname + location.search,
            // Custom parameter for page load time
            nonInteraction: true, // This is a non-interaction event
            value: Math.round(pageLoadTime) // Round to nearest integer
          });
        };
  
        handleRouteChange(); // Call on initial load
  
        // Optional: If you want to track page load time on route changes, you can do so here
        // Note: This example assumes ReactGA.send can accept these parameters directly. Adjust according to your analytics setup.
      }
    }, [location, isRouterContext]);
  };