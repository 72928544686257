import { useState, useEffect } from 'react';
import { app, realTimeDB } from '../../firebase/firebase';
import { getFirestore, collection, where, getDocs, doc, getDoc, updateDoc, deleteField } from 'firebase/firestore';
import { ref, get, getDatabase, child, query, onValue, update, remove } from "firebase/database";
import { getAuth, onAuthStateChanged} from 'firebase/auth';
export const useEarnPage = () => {
  const [clinicalStudies, setClinicalStudies] = useState([]);
  const [activeStudies, setActiveStudies] = useState([]);
  const [ignoredStudies, setIgnoredStudies] = useState([]);
  const [earnPageFilters, setEarnPageFilters] = useState({
    "visits": {
        "min": 0,
        "max": Infinity
      },
      "amountPerVisit": {
        "min": 0,
        "max": Infinity
      },
    "type": [],
  });
  const [earnPageSearchTexts, setEarnPageSearchTexts] = useState({
    'type': ''
  });
  const [earnPageSearchTerm, setEarnPageSearchTerm] = useState('');
  const [initialEarnLoaded, setInitialEarnLoaded] = useState(false);


  // const ignoreStudy = async (userId, studyId) => {
  //   try {
  //     // Get the study from Firestore
  //     const studyRef = doc(fireStoreDB, 'clinical_studies', studyId);
  //     const studyDoc = await getDoc(studyRef);
  
  //     if (!studyDoc.exists()) {
  //       throw new Error('Study not found');
  //     }
  
  //     const studyData = studyDoc.data();
  //     let updateData = {};

  //     // Check if the user has already ignored the study
  //     if (studyData.ignoredBy && studyData.ignoredBy[userId]) {
  //       // Remove them from the ignored list
  //       updateData = { [`ignoredBy.${userId}`]: deleteField() };
  //     } else {
  //       // Add them to the ignored list
  //       updateData = { [`ignoredBy.${userId}`]: true };
  //     }

  //     await updateDoc(studyRef, updateData);

  //     const updatedClinicalStudies = clinicalStudies.map(study => {
  //       if (study.id === studyId) {
  //         const updatedStudy = { ...study };
  //         updatedStudy.ignoredBy = updatedStudy.ignoredBy || {};
  //         if (updatedStudy.ignoredBy[userId]) {
  //           delete updatedStudy.ignoredBy[userId]; // Unignore
  //         } else {
  //           updatedStudy.ignoredBy[userId] = true; // Ignore
  //         }
  //         return updatedStudy;
  //       }
  //       return study;
  //     });


  //     setClinicalStudies(updatedClinicalStudies);

  //   } catch (error) {
  //     console.error('Error ignoring study:', error);
  //   }
  // };


  // const studyViewed = async (userId, studyId) => {
  //   try {
  //     // Get the study from Firestore
  //     const studyRef = doc(fireStoreDB, 'clinical_studies', studyId);
  //     const studyDoc = await getDoc(studyRef);
  
  //     if (!studyDoc.exists()) {
  //       throw new Error('Study not found');
  //     }
  
  //     const studyData = studyDoc.data();
  //     let updateData = {};

  //     // Check if viewedBy exists
  //     if (studyData.viewedBy) {
  //       // If the userId isn't in it, add the userId to the object
  //       if (!studyData.viewedBy[userId]) {
  //         updateData = { [`viewedBy.${userId}`]: true };
  //       }
  //       // Update viewCount to match the size of viewedBy
  //       updateData.viewCount = Object.keys(studyData.viewedBy).length;
  //     } else {
  //       // If not, create a new viewedBy object with the userId
  //       updateData = { viewedBy: { [userId]: true }, viewCount: 1 };
  //     }

  //     // Update the doc
  //     if (updateData.viewedBy) {
  //       await updateDoc(studyRef, updateData);

  //       const updatedClinicalStudies = clinicalStudies.map(study => {
  //         if (study.id === studyId) {
  //           const updatedStudy = { ...study };
  //           updatedStudy.viewedBy = updatedStudy.viewedBy || {};
  //           if (!updatedStudy.viewedBy[userId]) {
  //             updatedStudy.viewedBy[userId] = true; // Mark as viewed
  //             updatedStudy.viewCount = Object.keys(updatedStudy.viewedBy).length; // Update viewCount
  //           }
  //           return updatedStudy;
  //         }
  //         return study;
  //       });

  //       setClinicalStudies(updatedClinicalStudies);
  //     }

  //   } catch (error) {
  //     console.error('Error ignoring study:', error);
  //   }
  // };
  

  //SNAPSHOT Version
  // const getClinicalStudiesFromRealTimeDB = () => {
  //   const clinicalStudiesRef = ref(realTimeDB, '/clinical_studies/losangeles');
  //   onValue(clinicalStudiesRef, (snapshot) => {
  //     const data = snapshot.val();
  //     setClinicalStudies(data);
  //   });
  // };
  // const getClinicalStudiesFS = async () => {
  //   const db = getFirestore();
  //   const city = "losangeles";
  //   const clinicalStudiesRef = collection(db, 'clinical_studies');
    
  //   // Create a query against the collection, filtering by `metroId`
  //   const q = query(clinicalStudiesRef, where("metroId", "==", city));
  
  //   try {
  //     const startTime = Date.now(); // Start the timer
  //     const snapshot = await getDocs(q);
  //     const endTime = Date.now(); // End the timer
  
  //     if (!snapshot.empty) {
  //       const data = snapshot.docs.map(doc => doc.data());
  //       // setClinicalStudies(data); // Assuming this is a state setter function from your framework
  //       const returnTime = Date.now();
  //       return data;
  //     } else {
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, async (incomingUser) => {
      if (incomingUser) { // Fetch data only if user is logged in
        try {
          const userId = incomingUser.uid;
          const db = getDatabase();
          const city = "losangeles"
          const clinicalStudiesRef = ref(db, `/clinical_studies/${city}`);
          const snapshot = await get(clinicalStudiesRef);
          if (snapshot.exists()) {
            const data = snapshot.val();
            const studiesArray = Object.keys(data).map(key => data[key]);
            setClinicalStudies(studiesArray);
            const { active, ignored } = studiesArray.reduce((acc, study) => {
              if (userId && study.ignoredBy?.[userId]) {
                acc.ignored.push(study);
              } else {
                acc.active.push(study);
              }
              return acc;
            }, { active: [], ignored: [] });
            setActiveStudies(active);
            setIgnoredStudies(ignored);
            return studiesArray;
          } 
        } catch (error) {
          console.error(error);
        }
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);
  

  const getClinicalStudiesRTD = async () => {
    const db = getDatabase();
    const city = "losangeles"
    const clinicalStudiesRef = ref(db, `/clinical_studies/${city}`);
    try {
      // const startTime = Date.now(); // Start the timer
      const snapshot = await get(clinicalStudiesRef);
      const idPlacerholder = "asdf234-sdefa2"
      // const endTime = Date.now(); // End the timer
      if (snapshot.exists()) {
        
        const data = snapshot.val();
        const studiesArray = Object.keys(data).map(key => data[key]);
        setClinicalStudies(studiesArray);
        const { active, ignored } = studiesArray.reduce((acc, study) => {
          if (idPlacerholder && study.ignoredBy?.[idPlacerholder]) {
            acc.ignored.push(study);
          } else {
            acc.active.push(study);
          }
          return acc;
        }, { active: [], ignored: [] });
        
        setActiveStudies(active);
        setIgnoredStudies(ignored);
        // const returnTime = Date.now();
        return studiesArray;
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const ignoreStudyRTD = async (userId, studyId) => {
    const city = "losangeles"
    try {
       // Reference to the ignoredBy field for the specific study
    const ignoredByRef = ref(realTimeDB, `clinical_studies/${city}/${studyId}/ignoredBy`);

    // Check the current state of ignoredBy for the user
    const ignoredBySnapshot = await get(child(ignoredByRef, userId));

    let updateData = {};
    // Toggle the ignored state
    if (ignoredBySnapshot.exists()) {
      // User already ignored the study, remove their ignore status
      updateData[userId] = null; // Setting a value to null deletes the key
    } else {
      // User has not ignored the study, set their ignore status to true
      updateData[userId] = true;
    }

    // Update only the ignoredBy field for the study
    await update(ignoredByRef, updateData);

      const updatedClinicalStudies = clinicalStudies.map(study => {
        if (study.id === studyId) {
          const updatedStudy = { ...study };
          updatedStudy.ignoredBy = updatedStudy.ignoredBy || {};
          if (updatedStudy.ignoredBy[userId]) {
            delete updatedStudy.ignoredBy[userId];
          } else {
            updatedStudy.ignoredBy[userId] = true;
          }
          return updatedStudy;
        }
        return study;
      });
      
      const { active, ignored } = updatedClinicalStudies.reduce((acc, study) => {
        if (study.ignoredBy && study.ignoredBy[userId]) {
          acc.ignored.push(study);
        } else {
          acc.active.push(study);
        }
        return acc;
      }, { active: [], ignored: [] });
      
      setActiveStudies(active);
      setIgnoredStudies(ignored);

      setClinicalStudies(updatedClinicalStudies);
    } catch (error) {
      console.error('Error ignoring study:', error);
    }
  };

  const studyViewedRTD = async (userId, studyId) => {
    const city = "losangeles"
    try {
      const studyRef = ref(realTimeDB, `clinical_studies/${city}/${studyId}`);
    const snapshot = await get(studyRef);

    if (!snapshot.exists()) {
      throw new Error('Study not found');
    }

    const studyData = snapshot.val();
    let updateData = {};

    // Update the viewedBy field
    if (studyData.viewedBy && !studyData.viewedBy[userId]) {
      studyData.viewedBy[userId] = true;
    } else {
      studyData.viewedBy = { [userId]: true };
    }
    updateData.viewedBy = studyData.viewedBy;

    // Update the viewCount field
    updateData.viewCount = Object.keys(updateData.viewedBy).length;

    await update(studyRef, updateData);

      const updatedClinicalStudies = clinicalStudies.map(study => {
        if (study.id === studyId) {
          const updatedStudy = { ...study };
          updatedStudy.viewedBy = updatedStudy.viewedBy || {};
          if (!updatedStudy.viewedBy[userId]) {
            updatedStudy.viewedBy[userId] = true;
            updatedStudy.viewCount = Object.keys(updatedStudy.viewedBy).length;
          }
          return updatedStudy;
        }
        return study;
      });

      setClinicalStudies(updatedClinicalStudies);
    } catch (error) {
      console.error('Error viewing study:', error);
    }
  };


  // const ignoreStudy = async (userId, studyId) => {
  //   const auth = getAuth(app);
  //   try {
  //     const idToken = await auth.currentUser.getIdToken(true);
  //     const response = await axios.put(`${API_BASE_URL}/ignore-study`, {
  //       userId: userId,
  //       studyId: studyId
  //     }, {
  //       headers: { 
  //         'Authorization': `Bearer ${idToken}`,
  //         'Content-Type': 'application/json'
  //       }
  //     });
  //     const { data } = response.data;
  //     // Update the clinicalStudies state to reflect the ignored study
  //     setClinicalStudies(prevStudies => {
  //       return prevStudies.map(study => {
  //         if (study.id === studyId) {
  //           // If the study doesn't have ignoredBy field, initialize it as an object with userId
  //           if (!study.ignoredBy) {
  //             study.ignoredBy = { [userId]: true };
  //           } else {
  //             // If it does have ignoredBy field, check if userId is already there
  //             // If userId is there, delete it. If not, add it.
  //             if (study.ignoredBy[userId]) {
  //               delete study.ignoredBy[userId];
  //             } else {
  //               study.ignoredBy[userId] = true;
  //             }
  //           }
  //         }
  //         return study;
  //       });
  //     });
  //   } catch (error) {
  //     console.error('Error ignoring study:', error);
  //   }
  // };

  const ignoreStudy = () => {
    // Placeholder function
  };

  const studyViewed = () => {
    // Placeholder function
  };

  return {
    clinicalStudies,
    setClinicalStudies,
    earnPageFilters,
    setEarnPageFilters,
    earnPageSearchTexts,
    setEarnPageSearchTexts,
    earnPageSearchTerm,
    setEarnPageSearchTerm,
    initialEarnLoaded, 
    setInitialEarnLoaded,
    activeStudies, 
    setActiveStudies,
    ignoredStudies, 
    setIgnoredStudies,
    ignoreStudy,
    studyViewed,
    // getClinicalStudiesFS,
    getClinicalStudiesRTD,
    ignoreStudyRTD,
    studyViewedRTD
  };
};