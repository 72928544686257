import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { PaperClipIcon, EnvelopeIcon, PlusIcon } from '@heroicons/react/20/solid'
import { useUser } from '../../contexts/UserContext';
import Loading from '../../components/Loading';
import { app } from '../../firebase/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import EditSharedCompany from '../../components/formPopups/organizations/EditSharedCompany';
import { colorArray, handleFilterChange } from '../../utils/helperFunctions';
import { industryValues, roleValues } from '../../utils/helperValues';
import AddContact from '../../components/formPopups/organizations/AddContact';

export default function BrewCompany() {
  const { brewCompanies, joinBrewCompany, user, sharedCompanies, setMyChaiTab,
    clubs, userInfo, getContacts, updateCompany, updateSharedContact, userCompanies, createUserCompany } = useUser();
  const [brewCompanyData, setBrewCompanyData] = useState(null);
  const [sharedCompaniesSearched, setSharedCompaniesSearched] = useState(false);
  const [contactsSearched, setContactsSearched] = useState(false);
  const [editCompanyOpen, setEditCompanyOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  let { id, orgId } = useParams();
  const navigate = useNavigate();
  const [companyFilters, setCompanyFilters] = useState({ 
    industries: [],
    roles: [], });
  

  const initialFormState = {
    companyId: "",
    companyName: "",
    companyNotes: "",
    companyWebsite: "",
    id: "",
    networkingOpportunities: "",
    officeLocations: "",
    orgId: "",
    organizationRelationshipToCompany: "",
    sponsorsInternationals: "",
  };

  const initialContactState = {
    contactName: "",
    location: "",
    email: "",
    linkedIn: "",
    // introducedBy: "",
    bSchool: "",
    focusArea: "",
    otherNotes: "",
    dateLastContacted: "",
  };

  const [contactFilters, setContactFilters] = useState({ 
    industries: [],
    roles: [], });

  const [formState, setFormState] = useState(initialFormState);

  const [contactState, setContactState] = useState(initialContactState);

  useEffect(() => {
    if (!sharedCompaniesSearched && clubs.length > 0) {
      const auth = getAuth(app);
      const unsubscribe = onAuthStateChanged(auth, async (incomingUser) => {
        if (incomingUser) {
          try {
            const clubIds = clubs.reduce((acc, club) => {
              const isLeader = club.leadership && club.leadership[incomingUser.uid];
              const isMember = club.members && Array.isArray(club.members) && club.members.some(member => member.id === incomingUser.uid);
              if (isLeader || isMember) {
                acc.push(club.id);
              }
              return acc;
            }, []);

            if (clubIds.length > 0) {
              await getContacts(clubIds);
              setSharedCompaniesSearched(true);
            }
          } catch (error) {
            console.error("Error fetching shared companies data:", error);
          }
        }
      });
      return () => unsubscribe();
    } else if(!contactsSearched) {
      (async () => {
        await getContacts([orgId]);
        setContactsSearched(true);
      })();
    }
  }, [clubs, sharedCompaniesSearched, getContacts]);
  
  useEffect(() => {
    let brewCompany;
    if (brewCompanies && brewCompanies.length > 0) {
      brewCompany = brewCompanies.find(brewCompany => brewCompany.id === id);
    }
    if (sharedCompanies && sharedCompanies.length > 0) {
        brewCompany = sharedCompanies.find(company => company.id === id);
    }
    setBrewCompanyData(brewCompany);
    if (brewCompany) {
      setFormState({
        companyName: brewCompany.companyName || "",
        companyNotes: brewCompany.companyNotes || "",
        companyWebsite: brewCompany.companyWebsite || "",
        companyId: brewCompany.companyId || "",
        orgId: brewCompany.orgId || "",
        id: brewCompany.id || "",
        networkingOpportunities: brewCompany.networkingOpportunities || "",
        officeLocations: brewCompany.officeLocations || "",
        orgId: brewCompany.orgId || "",
        organizationRelationshipToCompany: brewCompany.organizationRelationshipToCompany || "",
        sponsorsInternationals: brewCompany.sponsorsInternationals || "",
      });
      setCompanyFilters({
        roles: brewCompany.roles || [],
        industries: brewCompany.industries || [],
      })
    }
  }, [brewCompanies, sharedCompanies, id]);

  if (!brewCompanyData || !user) {
    return <Loading />;
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState(prevState => {
        return { ...prevState, [name]: value };
    });
};

const handleSubmit = async (event) => {
  event.preventDefault();
  setIsSubmitting(true);
  const formData = new FormData();
  Object.keys(formState).forEach(key => {
    formData.append(key, formState[key]);
  });
  Object.keys(companyFilters).forEach(key => {
    formData.append(key, companyFilters[key]);
  });
  if (userInfo) {
    formData.append("updatedByName", userInfo.name);
    formData.append("updatedById", userInfo.id);
  } 
  await updateCompany(formData);
  setEditCompanyOpen(false);
  setIsSubmitting(false);
}

const handleContactSubmit = async (event) => {
  event.preventDefault();
  setIsSubmitting(true);
  const formData = new FormData();
  Object.keys(contactState).forEach(key => {
    formData.append(key, formState[key]);
  });
  Object.keys(contactFilters).forEach(key => {
    formData.append(key, companyFilters[key]);
  });
  if (brewCompanyData.orgId) {
    formData.append("orgId", brewCompanyData.orgId);
    formData.append("companySlug", brewCompanyData.companyId || "");
    formData.append("companyId", brewCompanyData.id || "");
  }
  if (userInfo) {
    formData.append("updatedByName", userInfo.name);
    formData.append("updatedById", userInfo.id);
  } 
  await updateSharedContact(formData);
  setContactModalOpen(false);
  setIsSubmitting(false);
}

const addCompanyToChai = async () => {
  setMyChaiTab("Companies");
  setIsSubmitting(true);

  const formState = {
    companyName: brewCompanyData.companyName || "",
    notes: "",
    sharedNotes: brewCompanyData.companyNotes ? [brewCompanyData.companyNotes] : [],
    website: brewCompanyData.companyWebsite || "",
    sharedCompanyId: id,
    sponsorsInternationals: brewCompanyData.sponsorsInternationals || "",
    sharedOrgData: [{
      orgId: brewCompanyData.orgId || "",
      sharedCompanyId: brewCompanyData.id || ""
    }],
    industries: companyFilters && Array.isArray(companyFilters.industries) ? companyFilters.industries : [],
    roles: companyFilters && Array.isArray(companyFilters.roles) ? companyFilters.roles : [],
  }
  
  // const contactsArray = brewCompanyData.contactLeads || brewCompanyData.contacts || []; - TODO figure this part out 
  const formData = new FormData();
  Object.keys(formState).forEach(key => {
    if (typeof formState[key] === 'object' && formState[key] !== null && !(formState[key] instanceof File)) {
      // Stringify object fields
      formData.append(key, JSON.stringify(formState[key]));
    } else {
        // Append non-object fields as is
      formData.append(key, formState[key]);
    }
  });
  
  
  await createUserCompany(formData);
  setIsSubmitting(false);
};

  let isLeader = false;
  if(brewCompanyData.orgId) {
    let selectedClub = clubs.find(club => club.id === brewCompanyData.orgId);
    if (selectedClub && Array.isArray(selectedClub.leadership)) {
      isLeader = selectedClub.leadership.some(leader => leader.id === userInfo.id);
    } else {
      isLeader = false;
    }
  }

  let chaiCompanyId, isChaiCompany, chaiCompany;
  if (userCompanies) {
    chaiCompany = userCompanies ? userCompanies.find(company => company.companyName === brewCompanyData.companyName) : null;
    isChaiCompany = Boolean(chaiCompany);
    if (isChaiCompany) {
      chaiCompanyId = chaiCompany.id;
    }
  }

  const goToChai = () => navigate(`/chai/company/${chaiCompanyId}`);

  const industriesData = brewCompanyData.industries || brewCompanyData.industry;
  const sortedIndustries = brewCompanyData && Array.isArray(industriesData) ? industriesData.sort((a, b) => a.trim().localeCompare(b.trim())) : industriesData ? [industriesData] : [];
  const sortedRoles = brewCompanyData && Array.isArray(brewCompanyData.roles) ? brewCompanyData.roles.sort((a, b) => a.trim().localeCompare(b.trim())) : [];
  const contactsArray = brewCompanyData.contactLeads || brewCompanyData.contacts || [];
  return (
    <>
      <AddContact 
      handleChange={handleChange}
      formState={formState} 
      popUpOpen={contactModalOpen} 
      setPopUpOpen={setContactModalOpen}
      handleSubmit={handleContactSubmit}
      loading={isSubmitting}
      contactFilters={contactFilters}
      setContactFilters={setContactFilters}
      handleFilterChange={handleFilterChange}
      industryValues={industryValues}
      roleValues={roleValues}
      />
          <EditSharedCompany
        handleChange={handleChange}
        formState={formState} 
        popUpOpen={editCompanyOpen} 
        setPopUpOpen={setEditCompanyOpen}
        handleSubmit={handleSubmit}
        loading={isSubmitting}
        companyFilters={companyFilters}
        setCompanyFilters={setCompanyFilters}
        handleFilterChange={handleFilterChange}
        industryValues={industryValues}
        roleValues={roleValues}
      />
          <div className="tw-flex tw-justify-between tw-pb-5">
          <button className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => navigate(-1)}>Back</button>
          {/* {isLeader && <button className="tw-bg-yellow-500 hover:tw-bg-yellow-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => { setEditCompanyOpen(true); }}>Edit Company</button>} */}
          {<button className="tw-bg-yellow-500 hover:tw-bg-yellow-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => { setEditCompanyOpen(true); }}>Edit Company</button>}
        </div>
        <div>
          <div className="tw-px-4 sm:tw-px-0 tw-flex tw-items-center">
            <img
              src={`https://logo.clearbit.com/${brewCompanyData.companyWebsite}`}
              alt={brewCompanyData.companyName}
              className="tw-h-12 tw-w-12 tw-flex-none tw-rounded-lg tw-bg-white tw-object-cover tw-ring-1 tw-ring-gray-900/10"
            />
            <div className="tw-pl-4">
              <h3 className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">{brewCompanyData.companyName}</h3>
              <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-6 tw-text-gray-500">{brewCompanyData.schoolName}</p>
            </div>
          </div>
          <div className="tw-grid tw-grid-cols-5  tw-mb-6 sm:tw-mb-0 tw-gap-3 tw-mt-5">
            {!isChaiCompany ? (
              <button
                type="button"
                className="tw-col-span-2 tw-inline-flex tw-w-full tw-flex-1 tw-items-center tw-justify-center tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50"
                onClick={addCompanyToChai}
                disabled={isSubmitting}
              >
                Add to Chai
              </button>
            ) : (
              <button
                type="button"
                className="tw-col-span-2 tw-inline-flex tw-w-full tw-flex-1 tw-items-center tw-justify-center tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50"
                onClick={goToChai}
              >
                View Personal Company Info
              </button>
            )}
          </div>
          <div className="tw-mt-6 tw-border-t tw-border-gray-100">
            <dl className="tw-divide-y tw-divide-gray-100">
              <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Relationship</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{brewCompanyData.relationship || brewCompanyData.organizationRelationshipToCompany}</dd>
              </div>
            {brewCompanyData.companyWebsite && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Company Website</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
                  <a href={`http://${brewCompanyData.companyWebsite}`} target="_blank" rel="noopener noreferrer" className="tw-text-blue-500 hover:tw-text-blue-800">
                    {brewCompanyData.companyWebsite}
                  </a>
                </dd>
              </div>}
              {sortedIndustries.length > 0 && sortedIndustries[0] !== "" && (
        <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
          <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Industry Background</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
          {sortedIndustries.map((industry,index) => {
                if (!industry) return null;
                const selectedColor = colorArray[index % 7 + 1]
                return (
                <div key={`${industry}-${index}`} className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2 tw-relative`}>
                  {industry}
                  {/* <XIcon className="tw-ml-2 tw-h-4 tw-w-4 tw-text-${selectedColor}-600 hover:tw-text-${selectedColor}-800 tw-cursor-pointer" onClick={() => handleRemoveTag(tag)} aria-hidden="true" /> */}
                </div>
              )})}
          </dd>
        </div>
      )}
      {sortedRoles.length > 0 && sortedRoles[0] !== "" && (
        <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
          <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Roles</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
          {sortedRoles.map((role, index) => {
                if (!role) return null;
                const selectedColor = colorArray[index % 7 + 5];
                return (
                <div key={`${role}-${index}`} className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2 tw-relative`}>
                  {role}
                  {/* <XIcon className="tw-ml-2 tw-h-4 tw-w-4 tw-text-${selectedColor}-600 hover:tw-text-${selectedColor}-800 tw-cursor-pointer" onClick={() => handleRemoveTag(role)} aria-hidden="true" /> */}
                </div>
              );
          })}
          </dd>
        </div>
      )}
            {brewCompanyData.officeLocations &&  <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Office Locations</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{brewCompanyData.officeLocations}</dd>
              </div>}
              {brewCompanyData.networkingOpportunities && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Networking Opportuntiies</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
                  {Array.isArray(brewCompanyData.networkingOpportunities) ? (
                    <ul>
                      {brewCompanyData.networkingOpportunities.map((opportunity, index) => (
                        <li key={index}>{opportunity}</li>
                      ))}
                    </ul>
                  ) : (
                    <span>{brewCompanyData.networkingOpportunities}</span>
                  )}
                </dd>
              </div>}
              <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Shared Notes</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{brewCompanyData.notes || brewCompanyData.companyNotes}</dd>
              </div>
              {brewCompanyData.contactLeads && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Email address</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
                  <ul>
                    {brewCompanyData.contactLeads?.map((contact, index) => (
                      <li key={index}><a href={`mailto:${contact}`} className="tw-text-blue-500 hover:tw-text-blue-800">{contact}</a></li>
                    ))}
                  </ul>
                </dd>
              </div>}
              <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Company Contacts</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
                {/* <button className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded">Add Contact</button> */}
                <button
                  type="button"
                  onClick={() => setContactModalOpen(!contactModalOpen)}
                  className="tw-inline-flex tw-items-center tw-gap-x-1.5 tw-rounded-full tw-bg-white tw-px-3 tw-py-1.5 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-hover:bg-gray-50"
                >
                  <PlusIcon className="tw--ml-1 tw--mr-0.5 tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                  Add Contact
                </button>
                <div className="">
                    <div className="tw-mt-8 tw-flow-root">
                      <div className="tw--mx-4 tw--my-2 tw-overflow-x-auto sm:tw--mx-6 lg:tw--mx-8">
                        <div className="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle">
                          <table className="tw-min-w-full tw-divide-y tw-divide-gray-300">
                            <thead>
                              <tr>
                                <th scope="col" className="tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-0">Name</th>
                                <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">City</th>
                                <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">Email</th>
                                <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">LinkedIn</th>
                              </tr>
                            </thead>
                            {contactsArray.length > 0 &&  <tbody className="tw-divide-y tw-divide-gray-200">
                            {contactsArray.map((contact, index) => (
                                <tr key={index} onClick={() => navigate(`/brew/contact/${contact.contactId}`)} className="hover:tw-cursor-pointer">
                                  <td className="tw-whitespace-nowrap tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-pl-0">{contact.name || contact.contactName}</td>
                                  <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{contact.city || contact.locationId}</td>
                                  <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                                    {contact.email && <a href={`mailto:${contact.email}`} className="tw-text-grey-600 hover:tw-text-blue-500">
                                      <EnvelopeIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-grey-600 hover:tw-text-indigo-600" aria-hidden="true" />
                                    </a>}
                                  </td>
                                  <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                                   {(contact.linkedIn || contact.linkedin) && <a href={contact.linkedIn || contact.linkedin} target="_blank" rel="noopener noreferrer" className="tw-text-grey-600 hover:tw-text-blue-500">
                                      <PaperClipIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-grey-600 hover:tw-text-blue-500" aria-hidden="true" />
                                    </a>}
                                  </td>
                                </tr>
                              ))}
                            </tbody>}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </dd>
              </div>
              {/* <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">About</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
                  Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                  qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                  pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                </dd>
              </div> */}
              {/* <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Attachments</dt>
                <dd className="tw-mt-2 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                  <ul role="list" className="tw-divide-y tw-divide-gray-100 tw-rounded-md tw-border tw-border-gray-200">
                    <li className="tw-flex tw-items-center tw-justify-between tw-py-4 tw-pl-4 tw-pr-5 tw-text-sm tw-leading-6">
                      <div className="tw-flex tw-w-0 tw-flex-1 tw-items-center">
                        <PaperClipIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-400" aria-hidden="true" />
                        <div className="tw-ml-4 tw-flex tw-min-w-0 tw-flex-1 tw-gap-2">
                          <span className="tw-truncate tw-font-medium">resume_back_end_developer.pdf</span>
                          <span className="tw-flex-shrink-0 tw-text-gray-400">2.4mb</span>
                        </div>
                      </div>
                      <div className="tw-ml-4 tw-flex-shrink-0">
                        <a href="#" className="tw-font-medium tw-text-indigo-600 tw-hover:text-indigo-500">
                          Download
                        </a>
                      </div>
                    </li>
                    <li className="tw-flex tw-items-center tw-justify-between tw-py-4 tw-pl-4 tw-pr-5 tw-text-sm tw-leading-6">
                      <div className="tw-flex tw-w-0 tw-flex-1 tw-items-center">
                        <PaperClipIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-400" aria-hidden="true" />
                        <div className="tw-ml-4 tw-flex tw-min-w-0 tw-flex-1 tw-gap-2">
                          <span className="tw-truncate tw-font-medium">coverletter_back_end_developer.pdf</span>
                          <span className="tw-flex-shrink-0 tw-text-gray-400">4.5mb</span>
                        </div>
                      </div>
                      <div className="tw-ml-4 tw-flex-shrink-0">
                        <a href="#" className="tw-font-medium tw-text-indigo-600 tw-hover:text-indigo-500">
                          Download
                        </a>
                      </div>
                    </li>
                  </ul>


                  
                </dd>
              </div> */}
            </dl>
          </div>
        </div>
        </>
      )
}