import React, { useEffect, useState } from 'react';
import { useUser } from '../../contexts/UserContext';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router-dom';
import { classNames, handleFilterChange } from '../../utils/helperFunctions';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import AddJobs from '../../components/formPopups/recruiting/AddJobs';
import { BookmarkIcon, XMarkIcon as XIcon } from '@heroicons/react/20/solid'
import AddContacts from '../../components/formPopups/organizations/AddContacts';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../../firebase/firebase';
import { industryValues, roleValues } from '../../utils/helperValues';
import { DateTime } from "luxon";
import StandardMenu from '../../components/menus/StandardMenu';
import AddRecruitingTeam from '../../components/formPopups/recruiting/AddRecruitingTeam';
import { jobStatuses } from '../../components/formPopups/recruiting/RecruitingConsts';

export default function BrewWorks() {
    const { brewCompanies, brewWorksPageSearchTerm, brewCompanyFilters, 
      brewWorksLoading, addUserToRTD, addJobs, sharedCompanies, jobStatus, setJobStatus,
      brewWorksTab, setBrewWorksTab, openJobs, userInfo, savedUserJobs, getOpenJobs, getContacts,
      brewWorksFilters: filters, setBrewWorksFilters: setFilters, getClubRecruitingTeams, clubRecruitingTeams,
      saveUserJob, ignoreJob, clubs, addContacts, addRecruitingTeam, addRecruitingTeamMember } = useUser();
    const navigate = useNavigate();
    const [jobPopupOpen, setJobPopupOpen] = useState(false);
    const [recruitingTeamPopupOpen, setRecruitingTeamPopupOpen] = useState(false);
    const [isJobFormSubmitting, setIsJobFormSubmitting] = useState(false);
    const [isRecruitingTeamFormSubmitting, setIsRecruitingTeamFormSubmitting] = useState(false);
    const [addJobType, setAddJobType] = useState('Single-Short');
    const [addRecruitingType, setAddRecruitingType] = useState('Fulltime');
    const [addRecruitingTeamState, setAddRecruitingTeamState] = useState('Team');
    const [shareJobWith, setShareJobWith] = useState('myself');
    const [jobType, setJobType] = useState('available');
    const [companyType, setCompanyType] = useState('new');
    const [existingCompany, setExistingCompany] = useState(null);

    //Contacts
    const [contactPopupOpen, setContactPopupOpen] = useState(false);
    const [isContactFormSubmitting, setIsContactFormSubmitting] = useState(false);
    const [addContactType, setAddContactType] = useState('Add-Company');
    const [shareContactWith, setShareContactWith] = useState('myself');
    const [jobFilters, setJobFilters] = useState({ 
      industries: [],
      roles: [], });
    
    const [recruitingType, setRecruitingType] = useState('Fulltime');
    const [recruitingClub, setRecruitingClub] = useState('');
    //, 'Rejected', 'Accepted', 'Archived'

    const initialJobFormState = {
        company: '',
        companyWebsite: '',
        role: '',
        roleType: '', // "Full-time" or "Internship"
        jobLink: '',
        datePosted: null,
        deadline: null,
        location: '',
        isAcademic: '', // "yes" or "no", explain if it's an academic internship
        isSponsor: '',
        workSetting: '', // "in-office", "remote", or "hybrid"
        startDate: null,
        endDate: null,
        isCoverLetterRequired: '', // "yes" or "no"
        additionalMaterials: '',
        additionalNotes: ''
    };

  const initialContactFormState = {
    companyName: '',
    companyId: '',
    companyWebsite: '',
    sponsorsInternationals: '',
    officeLocations: '',
    organizationRelationshipToCompany: '',
    networkingOpportunities: '',
    companyNotes: '',
  };

  const initialRecruitingFormState = {
    teamName: '',
    recruitingType: "Fulltime",
    addType: 'Team',
    teamMemberName: '',
    teamMemberId: '',
  };
  const teams = [
    {
      name: 'Edinburgh',
      people: [
        { name: 'Lindsay Walton', coffeeChats: 5, applied: 3, interviews: 2, offers: 1 },
        { name: 'Courtney Henry', coffeeChats: 4, applied: 2, interviews: 1, offers: 1 },
      ],
    },
    {
      name: 'Alpha',
      people: [
        { name: 'James Smith', coffeeChats: 3, applied: 3, interviews: 2, offers: 0 },
        { name: 'Maria Garcia', coffeeChats: 6, applied: 5, interviews: 3, offers: 2 },
      ],
    },
    {
      name: 'Omega',
      people: [
        { name: 'Robert Johnson', coffeeChats: 2, applied: 1, interviews: 1, offers: 0 },
        { name: 'Patricia Williams', coffeeChats: 7, applied: 4, interviews: 3, offers: 2 },
      ],
    }
  ]

  const [jobFormState, setJobFormState] = useState(initialJobFormState);
  const [recruitingFormState, setRecruitingFormState] = useState(initialRecruitingFormState);

    useEffect(() => {
      const auth = getAuth(app);
      const unsubscribe = onAuthStateChanged(auth, async (incomingUser) => {
          try {
            if(clubs.length > 0) {
              const clubIds = [];
              const leaderIds = [];
              clubs.forEach(club => {
                  if (club.leaderIds && (Array.isArray(club.leaderIds) && club.leaderIds.includes(incomingUser.uid)
                  || club.leadership[incomingUser.uid] === true)
                  ) {
                      if (!clubIds.includes(club.id)) {
                          clubIds.push(club.id);
                      } 
                      if (!leaderIds.includes(club.id)) {
                        leaderIds.push(club.id);
                      }
                  } else if (club.memberIds && Array.isArray(club.memberIds) && club.memberIds.includes(incomingUser.uid)) {
                      if (!clubIds.includes(club.id)) {
                          clubIds.push(club.id);
                      }
                  }
              });
              getOpenJobs(clubIds);
              getContacts(clubIds);
              getClubRecruitingTeams(leaderIds);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
      });
      return () => {
        unsubscribe();
      };
    }, [clubs]);

    const handleJobFormChange = (e) => {
        const { name, value } = e.target;
        setJobFormState(prevState => {
            return { ...prevState, [name]: value };
        });
    };

    const handleRecruitTeamChange = (e) => {
      const { name, value } = e.target;
      setRecruitingFormState(prevState => {
        return { ...prevState, [name]: value };
      });
    }

    const handleJobFormDateChange = (name, date) => {
        setJobFormState(prevState => ({
            ...prevState,
            [name]: date
        }));
    };

    const handleJobFormRadioChange = (e) => {
        const { id } = e.target;
        setCompanyType(id);
    };

    const handleShareJobWithChange = (e) => {
        setShareJobWith(e.target.value);
        setCompanyType("new");
    };

    const handleCompanyDropdownChange = (e) => {
        const selectedCompanyId = e.target.value;
        setExistingCompany(selectedCompanyId);
        // const existingCompany = sharedCompanies.find(company => company.id === selectedCompanyId);
        // if (existingCompany) {
        //     setJobFormState(prevState => ({
        //         ...prevState,
        //         companyId: existingCompany.id,
        //         companyName: existingCompany.companyName,
        //         companyWebsite: existingCompany.companyWebsite
        //     }));
        // }
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      setIsJobFormSubmitting(true);
      const formData = new FormData();
      Object.keys(jobFormState).forEach(key => {
        formData.append(key, jobFormState[key]);
      });
      Object.keys(jobFilters).forEach(key => {
        formData.append(key, jobFilters[key]);
      });
      if (companyType === 'existing') {
        const foundCompany = sharedCompanies.find(company => company.id === existingCompany);
        if (foundCompany) {
          formData.set("company", foundCompany.companyName);
          formData.set("companyWebsite", foundCompany.companyWebsite);
          formData.set("companyId", foundCompany.id);
        }
      }
      if (userInfo) {
        formData.append("addedByName", userInfo.name);
        formData.append("addedById", userInfo.id);
      } 
      if (shareJobWith !== "myself") {
        formData.append("shareJobWith", shareJobWith)
        formData.append("clubId", shareJobWith)
      }
      await addJobs(formData);
      resetJobForm()
      setIsJobFormSubmitting(false);
      setJobPopupOpen(false);
    }

    const handleSelectChange = (event, key) => {
        const value = event.target.value;
        setRecruitingFormState(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleMemberChange = (event) => {
        const [memberId, memberName] = event.target.value.split(',');
        setRecruitingFormState(prevState => ({
            ...prevState,
            teamMemberId: memberId,
            teamMemberName: memberName,
            teamMemberFull: event.target.value
        }));
    };

    const resetJobForm = () => {
        setJobFormState(initialJobFormState);
    };

    const handleFileChange = async (event) => {
      const file = event.target.files[0];
  
      if (file.type === 'text/csv') {
        setJobFormState(prevState => ({
          ...prevState,
          'Open Jobs': file
        }));
      } else {
        alert('Please upload a file in CSV format.');
      }
    };

// Contact Methods

const [contactFormState, setContactFormState] = useState(initialContactFormState);
const handleContactFormChange = (e) => {
  const { name, value } = e.target;
  setContactFormState(prevState => {
      return { ...prevState, [name]: value };
  });
};


const handleContactFormDateChange = (name, date) => {
  setContactFormState(prevState => ({
      ...prevState,
      [name]: date
  }));
};

const handleContactSubmit = async (event) => {
event.preventDefault();
setIsContactFormSubmitting(true);
const formData = new FormData();
Object.keys(contactFormState).forEach(key => {
  formData.append(key, contactFormState[key]);
});
if (userInfo) {
  formData.append("addedByName", userInfo.name);
  formData.append("addedById", userInfo.id);
  formData.append("orgId", shareContactWith)
}
await addContacts(formData);
setIsContactFormSubmitting(false);
setContactPopupOpen(false);
}

const updateRecruitingClub = (clubName) => {
  setRecruitingClub(clubName);
  const club = clubs.find(c => c.shortName === clubName);
  if (club) {
    setShareContactWith(club.id);
  } else {
    console.error("Club not found with the name:", clubName);
  }
}

const updateRecruitingType = (type) => {
  setRecruitingType(type);
  setAddRecruitingType(type);
}

const handleRecruitingTeamSubmit = async (event) => {
  event.preventDefault();
  setIsRecruitingTeamFormSubmitting(true);
  console.log(recruitingFormState)
  if(addRecruitingTeamState === "Team") {
   await addRecruitingTeam(
      shareContactWith,
     "2024-2025",
     recruitingFormState.recruitingType,
     recruitingFormState.teamName,
     recruitingClub
    ) 
  } else {
    await addRecruitingTeamMember(
      shareContactWith,
      "2024-2025",
      recruitingFormState.teamSelection,
      recruitingFormState.teamMemberName,
      recruitingFormState.teamMemberId,
      addRecruitingType.toLowerCase()
    )
    // recruitingFormState.teamMemberName,
    // recruitingFormState.teamMemberId
  }
  setIsRecruitingTeamFormSubmitting(false);
  setRecruitingTeamPopupOpen(false);
}

const resetContactForm = () => {
  setContactFormState(initialContactFormState);
};

const handleContactFileChange = async (event) => {
const file = event.target.files[0];

if (file.type === 'text/csv') {
  setContactFormState(prevState => ({
    ...prevState,
    'New Contacts': file
  }));
} else {
  alert('Please upload a file in CSV format.');
}
};

const jobShortner = (company,role, maxLength) => {
  if (`${company} - ${role}`.length > maxLength) {
    let trimmedString = `${company} - ${role}`.substring(0, maxLength);
    if (trimmedString.lastIndexOf(' ') > 0) {
      return trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))) + '...';
    }
    return trimmedString + '...';
  }
  return `${company} - ${role}`;
}

const locationShortner = (string, maxLength) => {
  if (string && string.includes('/')) {
    return "Multiple";
  }
  if (string && string.length > maxLength) {
    let trimmedString = string.substring(0, maxLength);
    if (trimmedString.lastIndexOf(' ') > 0) {
      return trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))) + '...';
    }
    return trimmedString + '...';
  }
  return string;
}


// General Methods

    
    const filteredBrewCompanies = React.useMemo(() => {
        return brewCompanies.filter(company => {
            const searchTermLower = brewWorksPageSearchTerm.toLowerCase();
            const searchableFields = [
              "companyName",
              "sponsorsInternationals",
              "relationship",
              "networkingOpportunities",
              "notes",
              "contactLeads",
              "companyContacts"
            ];

            const fieldMatches = searchableFields.some(field => {

                if (Array.isArray(company[field])) {
                    return company[field].some(item => {
                        if (typeof item === 'string') {
                            return item.toLowerCase().includes(searchTermLower);
                        }
                        return false;
                    });
                } else if (typeof company[field] === 'string') {
                    return company[field].toLowerCase().includes(searchTermLower);
                } else if (field === "sponsorsInternationals") {
                  if (/spon.*/.test(searchTermLower)) {
                        return company[field] === true;
                    }
                }
                return false;
            });

            // const filtersMatch = Object.keys(brewCompanyFilters).every(key => {
            //     const filterValues = brewCompanyFilters[key];
            //     if (!filterValues || filterValues.length === 0 || filterValues[0] === '') return true;
            //     if (Array.isArray(filterValues)) {
            //         return filterValues.some(filterValue => company[key] && company[key].includes(filterValue));
            //     }
            //     return company[key] === brewCompanyFilters[key];
            // });

            // return fieldMatches && filtersMatch;
            // brewCompanyFilters
            return fieldMatches;
        });
    }, [brewCompanies, brewWorksPageSearchTerm]);

    const filteredSharedCompanies = React.useMemo(() => {
      if (!sharedCompanies || !sharedCompanies.length > 0) return [];
      return sharedCompanies.filter(company => {
          const searchTermLower = brewWorksPageSearchTerm.toLowerCase();
          const searchableFields = [
            "companyName",
            "sponsorsInternationals",
            "relationship",
            "networkingOpportunities",
            "notes",
            "contactLeads",
            "companyContacts"
          ];

          const fieldMatches = searchableFields.some(field => {

              if (Array.isArray(company[field])) {
                  return company[field].some(item => {
                      if (typeof item === 'string') {
                          return item.toLowerCase().includes(searchTermLower);
                      }
                      return false;
                  });
              } else if (typeof company[field] === 'string') {
                  return company[field].toLowerCase().includes(searchTermLower);
              } else if (field === "sponsorsInternationals") {
                if (/spon.*/.test(searchTermLower)) {
                      return company[field] === true;
                  }
              }
              return false;
          });

          const filtersMatch = Object.keys(filters).every(key => {
            const filterValues = filters[key];
            // console.log(filterValues,filters)
            if (!filterValues || filterValues.length === 0 || filterValues[0] === '') return true;
            if (key === 'roles') {
              return company['roles'] && filterValues.some(role => company['roles'].includes(role));
            } 
            if (key === 'industries') {
              return company['industries'] && filterValues.some(filterValue => 
                company['industries'].some(industry => 
                  industry.trim().toLowerCase() === filterValue.trim().toLowerCase()
                )
              );
            }
            else if (key === 'clubs') {
              return filterValues.some(club => club.id === "" ? !company['orgId'] : company['orgId'] === club.id);
            }
            else if (Array.isArray(filterValues)) {
              return filterValues.some(filterValue => company[key] && company[key].includes(filterValue));
            }
            return company[key] === filters[key];
          });

          // Only return the job if it matches both the search term, the filters, and is not already saved
          return fieldMatches && filtersMatch;
      });
  }, [sharedCompanies, brewWorksPageSearchTerm, filters]);

        
    const filteredOpenJobs = React.useMemo(() => {
      return openJobs.filter(job => {
          const searchTermLower = brewWorksPageSearchTerm.toLowerCase();
          const searchableFields = [
            "company",
            "location",
            "role",
            "additionalNotes",
            "workSetting",
          ];

          const isJobSaved = savedUserJobs.some(savedJob => savedJob.id === job.id);
          if (isJobSaved) return false;

          const fieldMatches = searchableFields.some(field => {
              if (Array.isArray(job[field])) {
                  return job[field].some(item => {
                      if (typeof item === 'string') {
                          return item.toLowerCase().includes(searchTermLower);
                      }
                      return false;
                  });
              } else if (typeof job[field] === 'string') {
                  return job[field].toLowerCase().includes(searchTermLower);
              } else if (field === "sponsorsInternationals") {
                if (/spon.*/.test(searchTermLower)) {
                      return job[field] === true;
                  }
              }
              return false;
          });

          const filtersMatch = Object.keys(filters).every(key => {
            const filterValues = filters[key];
            if (!filterValues || filterValues.length === 0 || filterValues[0] === '') return true;
            if (key === 'roles') {
              return job['roles'] && filterValues.some(role => job['roles'].includes(role));
            } else if (key === 'industries') {
              return job['industries'] && filterValues.some(industry => job['industries'].includes(industry));
            } else if (key === 'clubs') {
              return filterValues.some(club => club.id === "" ? !job['clubId'] : job['clubId'] === club.id);
            }
            else if (Array.isArray(filterValues)) {
              return filterValues.some(filterValue => job[key] && job[key].includes(filterValue));
            }
            return job[key] === filters[key];
          });

          // Only return the job if it matches both the search term, the filters, and is not already saved
          return fieldMatches && filtersMatch;
      });
  }, [openJobs, brewWorksPageSearchTerm, filters]);

          
  const filteredUserJobs = React.useMemo(() => {
    return savedUserJobs.filter(job => {
        const searchTermLower = brewWorksPageSearchTerm.toLowerCase();
        const searchableFields = [
          "company",
          "location",
          "role",
          "additionalNotes",
          "workSetting",
        ];

        const fieldMatches = searchableFields.some(field => {

            if (Array.isArray(job[field])) {
                return job[field].some(item => {
                    if (typeof item === 'string') {
                        return item.toLowerCase().includes(searchTermLower);
                    }
                    return false;
                });
            } else if (typeof job[field] === 'string') {
                return job[field].toLowerCase().includes(searchTermLower);
            } else if (field === "sponsorsInternationals") {
              if (/spon.*/.test(searchTermLower)) {
                    return job[field] === true;
                }
            }
            return false;
        });

        const filtersMatch = Object.keys(filters).every(key => {
          const filterValues = filters[key];
          if (!filterValues || filterValues.length === 0 || filterValues[0] === '') return true;
          if (key === 'roles') {
            return job['roles'] && filterValues.some(role => job['roles'].includes(role));
          } else if (key === 'industries') {
            return job['industries'] && filterValues.some(industry => job['industries'].includes(industry));
          }else if (Array.isArray(filterValues)) {
            return filterValues.some(filterValue => job[key] && job[key].includes(filterValue));
          }
          return job[key] === filters[key];
        });

  
        // Only return the job if it matches both the search term and the filters
        return fieldMatches && filtersMatch;
    });
}, [savedUserJobs, brewWorksPageSearchTerm, filters]);

    if (brewWorksLoading ) {
        return <Loading />;
    }
    if (!brewCompanies.length > 0) {
      return  <h1 className="tw-text-2xl tw-font-semibold tw-mb-4">Coming Soon: Streamline Your Job Hunt with Targeted Company Tracking</h1>
    }
    const statuses = {
        true: 'tw-text-green-700 tw-bg-green-50 tw-ring-green-600/20',
        // Withdraw: 'tw-text-gray-600 tw-bg-gray-50 tw-ring-gray-500/10',
        false: 'tw-text-red-700 tw-bg-red-50 tw-ring-red-600/10',
      }

    const handleCompanyClick = (orgId, id) => {
        navigate(`/brew/org/${orgId}/company/${id}`);
    }

    const handleJobCompanyClick = (id, type) => {
      if(type === 'User') {
        navigate(`/brew/job/${id}/user`);
      } else {
        navigate(`/brew/job/${id}`);
      }
    }
    const downloadTemplate = () => {
        const link = document.createElement('a');
        link.href = process.env.PUBLIC_URL + '/csv/jobstemplate.csv';
        link.download = 'jobstemplate.csv';
        link.click();
    }

    const downloadContactTemplate = () => {
      const link = document.createElement('a');
      link.href = process.env.PUBLIC_URL + '/csv/clubcontactstemplate.csv';
      link.download = 'clubcontactstemplate.csv';
      link.click();
  }

    const handleIgnoreJob = (id, ownerId) => {
      //Add Owner Id
      ignoreJob(id, ownerId);
    }

    const handleSaveJob = (id, ownerId) => {
      saveUserJob(id, ownerId)
    }

    const sortedOpenJobs = 
    filteredOpenJobs.filter(job => job.companyWebsite !== undefined && job.companyWebsite !== '').sort((a, b) => a.company.localeCompare(b.company))
    .concat(filteredOpenJobs.filter(job => job.companyWebsite === undefined || job.companyWebsite === '').sort((a, b) => a.company.localeCompare(b.company)))

    const sortedUserJobs = 
    filteredUserJobs.filter(job => job.companyWebsite !== undefined && job.companyWebsite !== '').sort((a, b) => a.company.localeCompare(b.company))
    .concat(filteredUserJobs.filter(job => job.companyWebsite === undefined || job.companyWebsite === '').sort((a, b) => a.company.localeCompare(b.company)))

    const userId = userInfo?.id;
    const yesterday = DateTime.now().minus({ days: 1 }).startOf('day');

    const ignoredJobs = sortedOpenJobs.filter(job => job.ignoredBy && job.ignoredBy[userId] === true);
    const nonIgnoredJobs = sortedOpenJobs.filter(job => (!job.ignoredBy || job.ignoredBy[userId] !== true) && (!job.deadline || job.deadline === 'null' || DateTime.fromISO(job.deadline) > yesterday));
    const expiredJobs = sortedOpenJobs.filter(job => job.deadline && job.deadline !== 'null' && DateTime.fromISO(job.deadline) <= yesterday);
    const activeUserJobs = sortedUserJobs.filter(job => 
      (!job.deadline || job.deadline === 'null' || DateTime.fromISO(job.deadline) > yesterday) &&
      (job.jobStatus === jobStatus || (job.jobStatus === undefined && jobStatus === 'Saved'))
    )
    const isDate = (potentialDate) => {
      return !isNaN(Date.parse(potentialDate));
    }

    const jobMapper = (jobType) => {
      let jobList;
      let showCommands = true;
      if (jobType === 'ignored') {
        showCommands = false;
        jobList = ignoredJobs;
      } else if (jobType === 'expired') {
        showCommands = false;
        jobList = expiredJobs;
      } else if (jobType === 'saved')  { 
        showCommands = false;
        jobList = activeUserJobs;
      }else {
        jobList = nonIgnoredJobs;
      }
      return jobList.map((job, index) => (
        <li 
          key={`${job.company}-${index}`} 
          className="tw-overflow-hidden tw-rounded-xl tw-border tw-border-gray-200"
        >
          <div 
            className="tw-flex tw-items-center tw-gap-x-4 tw-border-b tw-border-gray-900/5 tw-bg-gray-50 tw-p-6"
            onClick={() => handleJobCompanyClick(job.id)}
          >
            {job.companyWebsite && job.companyWebsite !== undefined ? (
              <img
                src={`https://logo.clearbit.com/${job.companyWebsite}`}
                alt={job.company}
                className="tw-h-12 tw-w-12 tw-flex-none tw-rounded-lg tw-bg-white tw-object-cover tw-ring-1 tw-ring-gray-900/10"
              />
            ) : (
              <div className="tw-h-12 tw-w-12 tw-flex-none tw-rounded-lg tw-bg-white tw-ring-1 tw-ring-gray-900/10"></div>
            )}
            <div 
              className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
            >{jobShortner(job.company, job.role,40)}</div>
          </div> 
          <dl 
            className="tw--my-3 tw-divide-y tw-divide-gray-100 tw-px-6 tw-py-4 tw-text-sm tw-leading-6"
            onClick={() => handleJobCompanyClick(job.id)}
          >
            <div className="tw-flex tw-justify-between tw-gap-x-4 tw-py-3">
              <dt className="tw-text-gray-500">Application Due:</dt>
              <dd className="tw-text-gray-700">
                {isDate(job.deadline) && (<time dateTime={job.deadline}>{job.deadline}</time>)}
              </dd>
            </div>
            <div className="tw-flex tw-justify-between tw-gap-x-4 tw-py-3">
              <dt className="tw-text-gray-500">Location:</dt>
              <dd className="tw-text-gray-700">
                {locationShortner(job.location,30)}
              </dd>
            </div>
          </dl>
          {showCommands && (
            <div>
              <div className="tw--mt-px tw-flex tw-border-t tw-border-gray-900/5 tw-divide-x tw-divide-gray-200">
                <div className="tw-flex tw-w-0 tw-flex-1">
                  <a
                    onClick={() => job.clubId ? handleIgnoreJob(job.id, job.clubId) : handleIgnoreJob(job.id)}
                    className="tw-relative tw--mr-px tw-inline-flex tw-w-0 tw-flex-1 tw-items-center tw-justify-center tw-gap-x-3 tw-rounded-bl-lg tw-border tw-border-transparent tw-py-4 tw-text-sm tw-font-semibold tw-text-gray-700 hover:tw-text-red-500 hover:tw-cursor-pointer"
                  >
                    Ignore
                  </a>
                </div>
                <div className="tw--ml-px tw-flex tw-w-0 tw-flex-1">
                  <a
                    onClick={() => job.clubId ? handleSaveJob(job.id, job.clubId) : handleSaveJob(job.id)}
                    className="tw-relative tw-inline-flex tw-w-0 tw-flex-1 tw-items-center tw-justify-center tw-gap-x-3 tw-rounded-br-lg tw-border tw-border-transparent tw-py-4 tw-text-sm tw-font-semibold tw-text-green-900 hover:tw-text-green-500 hover:tw-cursor-pointer"
                  >
                    Save Job
                  </a>
                </div>
              </div>
            </div>
          )}
          {jobType === 'ignored' && (
            <div>
              <div className="tw--mt-px tw-flex tw-border-t tw-border-gray-900/5 tw-divide-x tw-divide-gray-200">
                <div className="tw--ml-px tw-flex tw-w-0 tw-flex-1">
                  <a
                    onClick={() => job.clubId ? handleSaveJob(job.id, job.clubId) : handleSaveJob(job.id)}
                    className="tw-relative tw-inline-flex tw-w-0 tw-flex-1 tw-items-center tw-justify-center tw-gap-x-3 tw-rounded-br-lg tw-border tw-border-transparent tw-py-4 tw-text-sm tw-font-semibold tw-text-green-900 hover:tw-text-green-500 hover:tw-cursor-pointer"
                  >
                    Save Job
                  </a>
                </div>
              </div>
            </div>
          )}
          {jobType === 'saved' && (
            <div>
              <div className="tw--mt-px tw-flex tw-border-t tw-border-gray-900/5 tw-divide-x tw-divide-gray-200">
                <div className="tw--ml-px tw-flex tw-w-0 tw-flex-1">
                  <a
                    onClick={() => job.clubId ? handleIgnoreJob(job.id, job.clubId) : handleIgnoreJob(job.id)}
                    className="tw-relative tw-inline-flex tw-w-0 tw-flex-1 tw-items-center tw-justify-center tw-gap-x-3 tw-rounded-br-lg tw-border tw-border-transparent tw-py-4 tw-text-sm tw-font-semibold tw-text-green-900 hover:tw-text-red-500 hover:tw-cursor-pointer"
                  >
                    Ignore Job
                  </a>
                </div>
              </div>
            </div>
          )}
        </li>
      ));
    }
    const leaderInClubs = clubs && clubs.length > 0 ? clubs.filter(club => club.leadership && club.leadership.some(leader => leader.id === userId))
    .map(club => ({
      shortName: club.shortName,
      id: club.id
    }))
    .sort((a, b) => a.shortName.localeCompare(b.shortName)) : [];
    let enhancedSharedCompanies = [];
    if (sharedCompanies && sharedCompanies.length > 0) {
      // console.log(filteredSharedCompanies,'FSC')
      filteredSharedCompanies.forEach(clubObject => {
        const orgId = clubObject.orgId;
        const companyDetails = clubObject;
        
        const club = clubs.find(club => club.id === orgId);
        enhancedSharedCompanies.push({
          ...clubObject,
          clubName: club ? club.shortName || "" : "",
        });
      });
    } else {
      // console.log('Shared companies data is not available.');
    }
    // console.log(clubs)
    let clubMembers = [];
    if (leaderInClubs.length > 0) { 
      clubMembers = leaderInClubs.map(club => {
          const foundClub = clubs.find(c => c.id === club.id);
          if (!foundClub) {
            return {};
          }
          const members = foundClub.members || [];
          return {
            clubName: club.shortName,
            clubId: club.id,
            members: members.map(member => ({
              id: member.id,
              name: member.name,
            }))
          };
      });
    }

    // console.log(enhancedSharedCompanies,'ESC')
    enhancedSharedCompanies.sort((a, b) => a.companyName.localeCompare(b.companyName));

    // const stats = [
    //   { name: 'Total Subscribers', stat: '71,897', previousStat: '70,946', change: '12%', changeType: 'increase' },
    //   { name: 'Avg. Open Rate', stat: '58.16%', previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
    //   { name: 'Avg. Click Rate', stat: '24.57%', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
    // ]

    const totalStats = teams.reduce((acc, team) => {
      team.people.forEach(person => {
        acc.totalCoffeeChats += person.coffeeChats;
        acc.totalInterviews += person.interviews;
        acc.totalOffers += person.offers;
      });
      return acc;
    }, { totalCoffeeChats: 0, totalInterviews: 0, totalOffers: 0 });

    const stats = [
      { name: 'Total Coffee Chats', stat: totalStats.totalCoffeeChats.toString() },
      { name: 'Total Interviews', stat: totalStats.totalInterviews.toString() },
      { name: 'Total Offers', stat: totalStats.totalOffers.toString() },
    ];

    const recruitingTypes = [
      { name: 'Fulltime', value: 'Fulltime' },
      { name: 'Internship', value: 'Internship' },
    ];

    const brewWorksTabs = ['My Applications', 'Club Job Board', 'Shared Company Info'];
    if (leaderInClubs.length > 0) {
      brewWorksTabs.push('Club Recruiting Hub');
    }

    const leaderInClubsMenuItems = (leaderInClubs || []).map(club => ({
      name: club.shortName,
      value: club.shortName
    }));

    let activeClubTeams = [];
    let activeClubAllTeams = [];
    // console.log(clubRecruitingTeams,'CRT',recruitingClub)

    if (recruitingClub) {
      let type = addRecruitingType.toLowerCase()
      let selectedClub = clubRecruitingTeams.find(team => team.clubName === recruitingClub)
      if (selectedClub) {
        activeClubAllTeams = [...selectedClub["fulltime"], ...selectedClub["internships"]]
      } 
      activeClubTeams = selectedClub?.[type]?.map(team => ({
        teamId: team.teamId,
        name: team.teamName,
        people: team.members
      })) || [];
    }
    // console.log(activeClubTeams,'ACT')

    const clubTeamStats = activeClubTeams.reduce((acc, team) => {
      team.people.forEach(person => {
        acc.totalCoffeeChats += person.coffeeChats;
        acc.totalInterviews += person.interviews;
        acc.totalOffers += person.offers;
      });
      return acc;
    }, { totalCoffeeChats: 0, totalInterviews: 0, totalOffers: 0 });

    const clubStats = [
      { name: 'Total Coffee Chats', stat: clubTeamStats.totalCoffeeChats.toString() },
      { name: 'Total Interviews', stat: clubTeamStats.totalInterviews.toString() },
      { name: 'Total Offers', stat: clubTeamStats.totalOffers.toString() },
    ];

    // if (clubRecruitingDocument) {
    //   // Assuming the structure of clubRecruitingDocument is suitable for direct use or needs minimal transformation
    //   // Mapping or transformation logic can be added here if necessary
    // }

    // console.log(leaderInClubs)
    // console.log(recruitingClub,'RC')

    // console.log(enhancedSharedCompanies,'ESC')

    return (
      <>
      <h1 className="tw-text-2xl tw-font-semibold tw-mb-4">BrewWorks: Streamline Your Job Hunt with Targeted Company Tracking</h1>
      {/* <button 
        className="tw-mt-4 tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
        onClick={() => addJobs()}
      >
        Add Job
      </button> */}
      <AddJobs
       addJobType={addJobType} 
       setAddJobType={setAddJobType}
       shareJobWith={shareJobWith} 
       handleShareJob={handleShareJobWithChange}
       sharedCompanies={sharedCompanies}
       handleChange={handleJobFormChange}
       handleDataChange={handleJobFormDateChange}
       handleFileChange={handleFileChange}
       formState={jobFormState} 
       setFormState={setJobFormState}
       popUpOpen={jobPopupOpen} 
       setPopUpOpen={setJobPopupOpen}
       handleSubmit={handleSubmit} 
       loading={isJobFormSubmitting}
       leaderInClubs={leaderInClubs}
       jobFilters={jobFilters}
       setJobFilters={setJobFilters}
       handleFilterChange={handleFilterChange}
       industryValues={industryValues}
       roleValues={roleValues}
       handleJobFormRadioChange={handleJobFormRadioChange}
       handleCompanyDropdownChange={handleCompanyDropdownChange}
       companyType={companyType}
      />

      <AddContacts
        handleChange={handleContactFormChange}
        handleFileChange={handleContactFileChange}
        formState={contactFormState}
        popUpOpen={contactPopupOpen} 
        setPopUpOpen={setContactPopupOpen}
        handleSubmit={handleContactSubmit} 
        loading={isContactFormSubmitting}
        // handleMultiComboChangeHelper,
        addContactType={addContactType} 
        setContactJobType={setAddContactType}
        setShareContactWith={setShareContactWith}
        leaderInClubs={leaderInClubs}
      />

      <AddRecruitingTeam
        handleChange={handleRecruitTeamChange} 
        formState={recruitingFormState} 
        recruitingTeams={[]}
        popUpOpen={recruitingTeamPopupOpen}
        setPopUpOpen={setRecruitingTeamPopupOpen}
        handleSubmit={handleRecruitingTeamSubmit} 
        loading={isRecruitingTeamFormSubmitting}
        addRecruitingType={addRecruitingType} 
        setRecruitingType={setAddRecruitingType}
        addRecruitingTeam={addRecruitingTeamState} 
        setAddRecruitingTeam={setAddRecruitingTeamState}
        shareContactWith={shareContactWith}
        setShareContactWith={setShareContactWith}
        leaderInClubs={leaderInClubs}
        clubMembers={clubMembers}
        handleSelectChange={handleSelectChange}
        activeClubTeams={activeClubTeams}
        handleMemberChange={handleMemberChange}
        activeClubAllTeams={activeClubAllTeams}
      />
      

      <div className="tw-block  tw-pb-5 lg:tw-pb-10">
        <div className="tw-border-b tw-border-gray-200">
          <nav className="tw-mb-px tw-flex tw-justify-between" aria-label="Tabs">
            <div className="tw-flex tw-space-x-8">
              {brewWorksTabs.map((tabName) => (
                <div
                  key={tabName}
                  onClick={(event) => {
                    event.preventDefault();
                    setBrewWorksTab(tabName);
                  }}
                  className={classNames(
                      brewWorksTab === tabName
                      ? 'tw-border-indigo-500 tw-text-indigo-600 tw-cursor-pointer'
                      : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700 tw-cursor-pointer',
                    'tw-whitespace-nowrap tw-border-b-2 tw-py-4 tw-px-1 tw-text-sm tw-font-medium'
                  )}
                  aria-current={brewWorksTab === tabName ? 'page' : undefined}
                >
                  {tabName}
                </div>
              ))}
            </div>
            {/* <div>
              <button 
                className="tw-py-2 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-indigo-600 hover:tw-bg-indigo-700"
                onClick={() => setPopUpOpen(true)}
              >
                {buttonText}
                </button>
            </div> */}
          </nav>
        </div>
      </div>
      {brewWorksTab === "Club Job Board" &&       
      <div className="tw-grid tw-grid-cols-3 tw-gap-4 tw-mb-4">
        <div className="tw-col-span-2">
          <p>To upload multiple jobs, download the template and fill it out with the job information.</p>
        </div>
        <div className="tw-col-span-1">
        </div>
        <div className="tw-col-span-1">
        <button 
            className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
            onClick={downloadTemplate}
          >
            Download Template
          </button>
        </div>
        <div className="tw-col-span-1">
        </div>
        <div className="tw-col-span-1 tw-text-right">
        <button 
            className="tw-ml-4 tw-bg-green-500 hover:tw-bg-green-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
            onClick={() => setJobPopupOpen(true)}
          >
            Add Jobs
          </button>
        </div>
      </div>
          }
      {brewWorksTab === "Shared Company Info" &&  
      <div className="tw-grid tw-grid-cols-3 tw-gap-4 tw-mb-4">
            {/* <h3 className="tw-text-xl tw-mb-2"><strong>Note:</strong>Only McKinsey is set up now as a demo</h3> */}
        <div className="tw-col-span-2">
          <p>To upload multiple contacts, download the template and fill it out with the company and contact information.</p>
        </div>
        <div className="tw-col-span-1">
        </div>
        <div className="tw-col-span-1">
        <button 
            className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
            onClick={downloadContactTemplate}
          >
            Download Template
          </button>
        </div>
        <div className="tw-col-span-1">
        </div>
        <div className="tw-col-span-1 tw-text-right">
        <button 
            className="tw-ml-4 tw-bg-green-500 hover:tw-bg-green-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
            onClick={() => setContactPopupOpen(true)}
          >
            Add Contacts
          </button>
        </div>
      </div>}
      {brewWorksTab === "My Applications" && (
        <>
        <div className="tw-text-right tw-mb-4">
              <StandardMenu 
                buttonText={"Status"}
                items={jobStatuses}
                onSelect={setJobStatus}
                className="tw-mr-4"
                selectedText={jobStatus}
              />
        </div>
        <ul role="list" className="tw-grid tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 lg:tw-grid-cols-3 xl:tw-grid-cols-4 xl:tw-gap-x-8">
        {activeUserJobs.map((job, index) => ( 
        <li 
          key={`${job.company}-${index}`} 
          className="tw-overflow-hidden tw-rounded-xl tw-border tw-border-gray-200"
        >
          <div 
            className="tw-flex tw-items-center tw-gap-x-4 tw-border-b tw-border-gray-900/5 tw-bg-gray-50 tw-p-6"
            onClick={() => handleJobCompanyClick(job.id, 'User')}
          >
            {job.companyWebsite ? (
              <img
                src={`https://logo.clearbit.com/${job.companyWebsite}`}
                alt={job.company}
                className="tw-h-12 tw-w-12 tw-flex-none tw-rounded-lg tw-bg-white tw-object-cover tw-ring-1 tw-ring-gray-900/10"
              />
            ) : (
              <div className="tw-h-12 tw-w-12 tw-flex-none tw-rounded-lg tw-bg-white tw-ring-1 tw-ring-gray-900/10"></div>
            )}
            <div 
              className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
            >{jobShortner(job.company, job.role, 30)}</div>
          </div> 
          <dl 
            className="tw--my-3 tw-divide-y tw-divide-gray-100 tw-px-6 tw-py-4 tw-text-sm tw-leading-6"
            onClick={() => handleJobCompanyClick(job.id)}
          >
            <div className="tw-flex tw-justify-between tw-gap-x-4 tw-py-3">
              <dt className="tw-text-gray-500">Application Due:</dt>
              <dd className="tw-text-gray-700">
                {isDate(job.deadline) ? (<time dateTime={job.deadline}>{job.deadline}</time>) : ""}
              </dd>
            </div>
            <div className="tw-flex tw-justify-between tw-gap-x-4 tw-py-3">
              <dt className="tw-text-gray-500">Location:</dt>
              <dd className="tw-text-gray-700">
                {locationShortner(job.location, 30)}
              </dd>
            </div>
          </dl>
       
        </li>
        ))}
        </ul>
           {/* {jobType === 'saved' && (
            <div>
              <div className="tw--mt-px tw-flex tw-border-t tw-border-gray-900/5 tw-divide-x tw-divide-gray-200">
                <div className="tw--ml-px tw-flex tw-w-0 tw-flex-1">
                  <a
                    onClick={() => job.clubId ? handleIgnoreJob(job.id, job.clubId) : handleIgnoreJob(job.id)}
                    className="tw-relative tw-inline-flex tw-w-0 tw-flex-1 tw-items-center tw-justify-center tw-gap-x-3 tw-rounded-br-lg tw-border tw-border-transparent tw-py-4 tw-text-sm tw-font-semibold tw-text-green-900 hover:tw-text-red-500 hover:tw-cursor-pointer"
                  >
                    Ignore Job
                  </a>
                </div>
              </div>
            </div>
          )} */}
        </>
      )}
        {brewWorksTab === "Club Job Board" && (
          <div className="tw-mb-4">
            <label htmlFor="jobType" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-700">Select Job Type:</label>
            <select
              id="jobType"
              name="jobType"
              className="tw-mt-1 tw-block tw-w-full tw-pl-3 tw-pr-10 tw-py-2 tw-text-base tw-border tw-border-gray-300 tw-rounded-md tw-focus:tw-outline-none tw-focus:tw-ring-indigo-500 tw-focus:tw-border-indigo-500 sm:tw-text-sm"
              value={jobType}
              onChange={(e) => setJobType(e.target.value)}
            >
              {/* <option value="new">New</option> */}
              <option value="saved">Saved</option>
              <option value="available">Available</option>
              <option value="ignored">Ignored</option>
              <option value="expired">Expired</option>
              
            </select>
          </div>
        )}
        <ul role="list" className="tw-grid tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 lg:tw-grid-cols-3 xl:tw-grid-cols-4 xl:tw-gap-x-8">
        {brewWorksTab === "Club Job Board" && openJobs && jobMapper(jobType)
        
       
        
      }
      {/* <div className="tw-flex tw-justify-between tw-gap-x-4 tw-py-3">
        <dt className="tw-text-gray-500">Sponsors Internationals:</dt>
        <dd className="tw-flex tw-items-start tw-gap-x-2">
          {job.sponsorsInternationals !== undefined && (<div
            className={classNames(
              statuses[job.sponsorsInternationals],
              'tw-rounded-md tw-py-1 tw-px-2 tw-text-xs tw-font-medium tw-ring-1 tw-ring-inset'
            )}
          >
            {job.sponsorsInternationals ? "true" : "false"}
          </div>)}
        </dd>
      </div> */}
      {brewWorksTab === "Shared Company Info" &&  enhancedSharedCompanies.map((company, index) => (
        <li 
        key={`${company.companyName}-${index}`} 
        className="tw-overflow-hidden tw-rounded-xl tw-border tw-border-gray-200 hover:tw-cursor-pointer"
        onClick={() => handleCompanyClick(company.orgId, company.id)}
        >
          <div className="tw-flex tw-items-center tw-gap-x-4 tw-border-b tw-border-gray-900/5 tw-bg-gray-50 tw-p-6">
            {company.companyWebsite && (
              <img
                src={`https://logo.clearbit.com/${company.companyWebsite}`}
                alt={company.companyName}
                className="tw-h-12 tw-w-12 tw-flex-none tw-rounded-lg tw-bg-white tw-object-cover tw-ring-1 tw-ring-gray-900/10"
              />
            )}
            <div 
            className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
            // onClick={() => handleCompanyClick(company.id)}
            >{company.companyName}</div>
          </div> 
          <dl 
          className="tw--my-3 tw-divide-y tw-divide-gray-100 tw-px-6 tw-py-4 tw-text-sm tw-leading-6"
          // onClick={() => handleCompanyClick(company.id)}
          >
            {/* <div className="tw-flex tw-justify-between tw-gap-x-4 tw-py-3">
              <dt className="tw-text-gray-500">Application Due:</dt>
              <dd className="tw-text-gray-700">
                {company.lastInvoice && <time dateTime={company.lastInvoice.dateTime}>{company.lastInvoice.date}</time>}
              </dd>
            </div> */}
            <div className="tw-flex tw-justify-between tw-gap-x-4 tw-py-3">
              <dt className="tw-text-gray-500">Networking Opportunities:</dt>
              <dd className="tw-text-gray-700">
                {company.networkingOpportunities}
              </dd>
            </div>
            <div className="tw-flex tw-justify-between tw-gap-x-4 tw-py-3">
              <dt className="tw-text-gray-500">Club:</dt>
              <dd className="tw-flex tw-items-start tw-gap-x-2">
                {/* <div className="tw-font-medium tw-text-gray-900">{company.lastInvoice.amount}</div> */}
                <div
                  className={classNames(
                    // statuses[company.sponsorsInternationals],
                    'tw-rounded-md tw-py-1 tw-px-2 tw-text-xs tw-font-medium tw-ring-1 tw-ring-inset'
                  )}
                >
                  {/* {company.sponsorsInternationals ? "true" : "false"} */}
                  {company.clubName}
                </div>
              </dd>
            </div>
          </dl>
        </li>
      ))}
        {brewWorksTab === "Demo" &&  filteredBrewCompanies.map((company) => (
        <li 
        key={company.companyName} 
        className="tw-overflow-hidden tw-rounded-xl tw-border tw-border-gray-200 hover:tw-cursor-pointer"
        onClick={() => handleCompanyClick(company.id)}
        >
          <div className="tw-flex tw-items-center tw-gap-x-4 tw-border-b tw-border-gray-900/5 tw-bg-gray-50 tw-p-6">
            <img
              src={`https://logo.clearbit.com/${company.companyWebsite}`}
              alt={company.companyName}
              className="tw-h-12 tw-w-12 tw-flex-none tw-rounded-lg tw-bg-white tw-object-cover tw-ring-1 tw-ring-gray-900/10"
            />
            <div 
            className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
            // onClick={() => handleCompanyClick(company.id)}
            >{company.companyName}</div>
          </div> 
          <dl 
          className="tw--my-3 tw-divide-y tw-divide-gray-100 tw-px-6 tw-py-4 tw-text-sm tw-leading-6"
          // onClick={() => handleCompanyClick(company.id)}
          >
            {/* <div className="tw-flex tw-justify-between tw-gap-x-4 tw-py-3">
              <dt className="tw-text-gray-500">Application Due:</dt>
              <dd className="tw-text-gray-700">
                {company.lastInvoice && <time dateTime={company.lastInvoice.dateTime}>{company.lastInvoice.date}</time>}
              </dd>
            </div> */}
            <div className="tw-flex tw-justify-between tw-gap-x-4 tw-py-3">
              <dt className="tw-text-gray-500">Networking Opportunities:</dt>
              <dd className="tw-text-gray-700">
                {company.networkingOpportunities}
              </dd>
            </div>
            <div className="tw-flex tw-justify-between tw-gap-x-4 tw-py-3">
              <dt className="tw-text-gray-500">Sponsors:</dt>
              <dd className="tw-flex tw-items-start tw-gap-x-2">
                {/* <div className="tw-font-medium tw-text-gray-900">{company.lastInvoice.amount}</div> */}
                <div
                  className={classNames(
                    statuses[company.sponsorsInternationals],
                    'tw-rounded-md tw-py-1 tw-px-2 tw-text-xs tw-font-medium tw-ring-1 tw-ring-inset'
                  )}
                >
                  {company.sponsorsInternationals ? "true" : "false"}
                </div>
              </dd>
            </div>
          </dl>
        </li>
      ))}
    </ul>
      <>
      {brewWorksTab === "Club Recruiting Hub" && (
        <>
        
         <div>
            <div className="tw-flex tw-justify-between">
              <h3 className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
                {recruitingClub ? `${recruitingClub} - ${recruitingType} Recruiting` : 'Please Select a Club'}
                </h3>
              <div className="tw-flex tw-gap-x-4 tw-justify-end">
              <StandardMenu 
              buttonText={"Club"}
              items={leaderInClubsMenuItems}
              onSelect={updateRecruitingClub}
              className="tw-mr-4"
              />
              <StandardMenu 
              buttonText={"Recruiting Type"}
              items={recruitingTypes}
              onSelect={updateRecruitingType}
              
              />
              </div>
              
              {/* <Menu as="div" className="tw-relative tw-inline-block tw-text-left">
                <div>
                  <Menu.Button className="tw-inline-flex tw-w-full tw-justify-center tw-gap-x-1.5 tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50">
                    Class Year
                    <ChevronDownIcon className="tw-mr-1 tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="tw-transition tw-ease-out tw-duration-100"
                  enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                  enterTo="tw-transform tw-opacity-100 tw-scale-100"
                  leave="tw-transition tw-ease-in tw-duration-75"
                  leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                  leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                >
                  <Menu.Items className="tw-absolute tw-right-0 tw-z-10 tw-mt-2 tw-w-56 tw-origin-top-right tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-focus:outline-none">
                    <div className="tw-py-1">
                      {classYears.map((year) => (
                        <Menu.Item key={year.value}>
                          {({ active }) => (
                            <a
                              onClick={() => setClassYear(year.value)}
                              className={classNames(
                                active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700',
                                'tw-block tw-px-4 tw-py-2 tw-text-sm hover:tw-cursor-pointer'
                              )}
                            >
                              {year.value}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu> */}
            </div>
            {/* Clubs Stats - stats */}
            <dl className="tw-mt-5 tw-grid tw-grid-cols-1 tw-divide-y tw-divide-gray-200 tw-overflow-hidden tw-rounded-lg tw-bg-white tw-shadow md:tw-grid-cols-3 md:tw-divide-x md:tw-divide-y-0">
              {clubStats.map((item) => (
                <div key={item.name} className="tw-px-4 tw-py-5 sm:tw-p-6">
                  <dt className="tw-text-base tw-font-normal tw-text-gray-900">{item.name}</dt>
                  <dd className="tw-mt-1 tw-flex tw-items-baseline tw-justify-between md:tw-block lg:tw-flex">
                    <div className="tw-flex tw-items-baseline tw-text-2xl tw-font-semibold tw-text-indigo-600">
                      {item.stat}
                      {/* <span className="tw-ml-2 tw-text-sm tw-font-medium tw-text-gray-500">from {item.previousStat}</span> */}
                    </div>

                    {/* <div
                      className={classNames(
                        item.changeType === 'increase' ? 'tw-bg-green-100 tw-text-green-800' : 'tw-bg-red-100 tw-text-red-800',
                        'tw-inline-flex tw-items-baseline tw-rounded-full tw-px-2.5 tw-py-0.5 tw-text-sm tw-font-medium md:tw-mt-2 lg:tw-mt-0'
                      )}
                    >
                      {item.changeType === 'increase' ? (
                        <ArrowUpIcon
                          className="tw--ml-1 tw-mr-0.5 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-self-center tw-text-green-500"
                          aria-hidden="true"
                        />
                      ) : (
                        <ArrowDownIcon
                          className="tw--ml-1 tw-mr-0.5 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-self-center tw-text-red-500"
                          aria-hidden="true"
                        />
                      )}

                      <span className="tw-sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                      {item.change}
                    </div> */}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
         <div className="sm:tw-flex sm:tw-items-center tw-mt-4">
         <div className="sm:tw-flex-auto">
           {/* <h1 className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Recruitment Hub</h1>
           <p className="tw-mt-2 tw-text-sm tw-text-gray-700">
             Overview of the club's recruitment status
           </p> */}
         </div>
         <div className="tw-mt-4 sm:tw-ml-16 sm:tw-mt-0 sm:tw-flex-none">
           {recruitingClub && (
             <button
               type="button"
               className="tw-block tw-rounded-md tw-bg-indigo-600 tw-px-3 tw-py-2 tw-text-center tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
               onClick={() => setRecruitingTeamPopupOpen(true)}
             >
               Add Member / Team
             </button>
           )}
         </div>
       </div>
       {recruitingClub && (
        <div className="tw-flow-root tw-mt-4 tw-mb-16">
          <div className="tw-overflow-x-auto">
            <div className="tw-inline-block tw-min-w-full tw-align-middle">
              <table className="tw-min-w-full">
                <thead className="tw-bg-white">
                  <tr>
                    <th scope="col" className="tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-3">
                      Name
                    </th>
                    <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900">
                      Coffee<br/>Chats
                    </th>
                    <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900">
                      Applied
                    </th>
                    <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900">
                      Interviews
                    </th>
                    <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900">
                      Offers
                    </th>
                    {/* <th scope="col" className="tw-relative tw-py-3.5 tw-pl-3 tw-pr-4 sm:tw-pr-3">
                      <span className="tw-sr-only">Edit</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody className="tw-bg-white">
                  {/* teams for Demoing */}
                  {activeClubTeams.map((team) => (
                    <Fragment key={team.name}>
                      <tr className="tw-border-t tw-border-gray-200">
                        <th
                          colSpan={6}
                          scope="colgroup"
                          className="tw-bg-gray-50 tw-py-2 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-3"
                        >
                          {team.name}
                        </th>
                      </tr>
                      {team.people.length > 0 && team.people.map((person, personIdx) => (
                        <tr
                          key={person.name}
                          className={classNames(personIdx === 0 ? 'tw-border-gray-300' : 'tw-border-gray-200', 'tw-border-t')}
                        >
                          <td className="tw-whitespace-nowrap tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-pl-3">
                            {person.name}
                          </td>
                          <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500 tw-text-center">{person.coffeeChats}</td>
                          <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500 tw-text-center">{person.applied}</td>
                          <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500 tw-text-center">{person.interviews}</td>
                          <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500 tw-text-center">{person.offers}</td>
                          {/* <td className="tw-relative tw-whitespace-nowrap tw-py-4 tw-pl-3 tw-pr-4 tw-text-right tw-text-sm tw-font-medium sm:tw-pr-3">
                            <a href="#" className="tw-text-indigo-600 tw-hover:text-indigo-900">
                              Edit<span className="tw-sr-only">, {person.name}</span>
                            </a>
                          </td> */}
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>)}
        </>
      )}
      </>
    {brewWorksTab !== "Club Recruiting Hub" && (
      <a href="https://clearbit.com" className="tw-text-blue-500 tw-hover:text-blue-800">Logos provided by Clearbit</a>
    )}
    {/*             
            <Menu as="div" className="tw-relative tw-ml-auto">
              <Menu.Button className="tw--m-2.5 tw-block tw-p-2.5 tw-text-gray-400 tw-hover:text-gray-500">
                <span className="tw-sr-only">Open options</span>
                <EllipsisHorizontalIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="tw-absolute tw-right-0 tw-z-10 tw-mt-0.5 tw-w-32 tw-origin-top-right tw-rounded-md tw-bg-white tw-py-2 tw-shadow-lg tw-ring-1 tw-ring-gray-900/5 tw-focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? 'tw-bg-gray-50' : '',
                          'tw-block tw-px-3 tw-py-1 tw-text-sm tw-leading-6 tw-text-gray-900'
                        )}
                      >
                        View<span className="tw-sr-only">, {client.companyName}</span>
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? 'tw-bg-gray-50' : '',
                          'tw-block tw-px-3 tw-py-1 tw-text-sm tw-leading-6 tw-text-gray-900'
                        )}
                      >
                        Edit<span className="tw-sr-only">, {client.companyName}</span>
                      </a>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu> */}
    </>
    )
}