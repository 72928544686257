import { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../../firebase/firebase';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useChaiPage = () => {
  const [userCompanies, setUserCompanies] = useState([]);
  const [userContacts, setUserContacts] = useState([]);
  const [chaiLoaded, setChaiLoaded] = useState(false);
  const [myChaiTab, setMyChaiTab] = useState('Companies');
  const [chaiFormLoading, setChaiFormLoading] = useState(false);
  const [chaiPopUpOpen, setChaiPopUpOpen] = useState(false);
  const [chaiPageSearchTerm, setChaiPageSearchTerm] = useState('');
  const [chaiFilters, setChaiFilters] = useState({ 
    tags: [], industries: [] });

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, async (incomingUser) => {
      if (incomingUser) { // Fetch data only if user is logged in
        try {
          //yi xiaos
          const userId = incomingUser.uid;
          const idToken = await incomingUser.getIdToken(true);
          const [userContactsResponse, userCompaniesResponse] = await Promise.all([
            axios.get(`${API_BASE_URL}/user-contacts`, {
              params: { userId }, // Send userId as a URL query parameter
              headers: { 'Authorization': `Bearer ${idToken}` }
            }),
            axios.get(`${API_BASE_URL}/user-companies`, {
              params: { userId }, // Send userId as a URL query parameter
              headers: { 'Authorization': `Bearer ${idToken}` }
            })
          ]);
          const userContactsData = userContactsResponse.data;
          const userCompaniesData = userCompaniesResponse.data;
          setUserContacts(userContactsData);
          setUserCompanies(userCompaniesData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const createUserCompany = async (formData) => {
    try {
      const auth = getAuth(app);
      const currentUser = auth.currentUser;
      const [idToken, userId] = await Promise.all([currentUser.getIdToken(true), currentUser.uid]);
      formData.append('userId', userId);
      const response = await axios.post(`${API_BASE_URL}/create-user-company`, formData, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        'Content-Type': 'multipart/form-data'
      });
      const data = response.data;
      setUserCompanies(data);
    } catch (error) {
      console.error('Error creating company:', error);
    }
  };
  
  const updateUserCompany = async (formData, companyId) => {
    try {
      const auth = getAuth(app);
      const currentUser = auth.currentUser;
      const [idToken, userId] = await Promise.all([currentUser.getIdToken(true), currentUser.uid]);
      formData.append('userId', currentUser.uid);
      formData.append('companyId', companyId); // Appending companyId to formData
      const response = await axios.put(`${API_BASE_URL}/update-user-company`, formData, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        'Content-Type': 'multipart/form-data'
      });
      const data = response.data;
      setUserCompanies(Object.values(data.companyMap));
    } catch (error) {
      console.error('Error updating company:', error);
    }
  };
  
const fetchUserCompanies = async () => {
      const auth = getAuth(app);
      try {
        let currentUser;
        await onAuthStateChanged(auth, (user) => {
          if (user) {
            currentUser = user;
          }
        });
        const idToken = await currentUser.getIdToken(true);
        const userId = currentUser.uid;
    
        const response = await axios.get(`${API_BASE_URL}/user-companies`, {
          params: { userId }, // Send userId as a URL query parameter
          headers: { 
            'Authorization': `Bearer ${idToken}`
          }
        });
        const resultData = response.data;
        setUserCompanies(resultData);
      } catch (error) {
        console.error('Error fetching user companies:', error);
      }
};

const createUserContact = async (formData) => {
    const auth = getAuth(app);
    try {
      const currentUser = auth.currentUser;
      const idToken = await currentUser.getIdToken(true);
      const userId = currentUser.uid;
      formData.append('userId', userId);
      const response = await axios.post(`${API_BASE_URL}/create-user-contact`, formData, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        'Content-Type': 'multipart/form-data'
      });
      // setUserContacts(prevContacts => {
      //   const updatedContacts = [...prevContacts, data];
      //   return updatedContacts;
      // });
      const data = response.data;
      setUserContacts(data);
      fetchUserCompanies();
      fetchUserContacts();
      return 
    } catch (error) {
      console.error('Error creating contact:', error);
    }
};

const updateUserContact = async (formData, contactId) => {
  try {
    const auth = getAuth(app);
    const currentUser = auth.currentUser;
    const [idToken, userId] = await Promise.all([currentUser.getIdToken(true), currentUser.uid]);
    formData.append('userId', currentUser.uid);
    formData.append('contactId', contactId); // Appending contactId to formData
    const response = await axios.put(`${API_BASE_URL}/update-user-contact`, formData, {
      headers: { 'Authorization': `Bearer ${idToken}` },
      'Content-Type': 'multipart/form-data'
    });
    const data = response.data;
    setUserContacts(Object.values(data.contactMap));
    fetchUserCompanies();
    fetchUserContacts();
  } catch (error) {
    console.error('Error updating company:', error);
  }
};

const fetchUserContacts = async () => {
    const auth = getAuth(app);
    try {
      let currentUser;
      await onAuthStateChanged(auth, (user) => {
        if (user) {
          currentUser = user;
        }
      });
      // if (!currentUser) return;
      const idToken = await currentUser.getIdToken(true);
      const userId = currentUser.uid;
      const response = await axios.get(`${API_BASE_URL}/user-contacts`, {
        params: { userId }, // Send userId as a URL query parameter
        headers: { 'Authorization': `Bearer ${idToken}` }
      });
      const resultData = response.data;
      setUserContacts(resultData);
    } catch (error) {
      console.error('Error fetching user contacts:', error);
    }
};

const initialChaiFetch = async () => {
    const auth = getAuth(app);
    let currentUser;
    let idToken;
    let userId;
    try {
      currentUser = auth.currentUser;
      if (currentUser) {
        idToken = await currentUser.getIdToken(true);
        userId = currentUser.uid;
      }
      const [userContactsResponse, userCompaniesResponse] = await Promise.all([
        axios.get(`${API_BASE_URL}/user-contacts`, {
          params: { userId }, // Send userId as a URL query parameter
          headers: { 'Authorization': `Bearer ${idToken}` }
        }),
        axios.get(`${API_BASE_URL}/user-companies`, {
          params: { userId }, // Send userId as a URL query parameter
          headers: { 'Authorization': `Bearer ${idToken}` }
        })
      ]);
      const userContactsData = userContactsResponse.data;
      const userCompaniesData = userCompaniesResponse.data;
      setUserContacts(userContactsData);
      setUserCompanies(userCompaniesData);
    } catch (error) {
      console.error('Error fetching user contacts and companies:', error);
    }
};

const logContactAction = async (actionType, collectionType, id, actionDate, notes) => {
  try {
    const auth = getAuth(app);
    const currentUser = auth.currentUser;
    const userId = auth.currentUser.uid;
    const idToken = await currentUser.getIdToken(true); // Get auth token
    const response = await axios.put(`${API_BASE_URL}/log-contact-action`, null, {
      params: { actionType, collectionType, userId, docId: id, actionDate, notes }, // Send details as URL query parameters
      headers: { 'Authorization': `Bearer ${idToken}` }
    });
    if (collectionType === "user_companies") {
      setUserCompanies(prevCompanies => prevCompanies.map(company => company.id === id ? response.data : company));
    } else if (collectionType === "user_contacts") {
      setUserContacts(prevContacts => prevContacts.map(contact => contact.id === id ? response.data : contact));
    }
  } catch (error) {
    console.error('Error logging contact action:', error);
  }
};

const updateContactAction = async (actionState, collectionType, id) => {
  try {
    const auth = getAuth(app);
    const currentUser = auth.currentUser;
    const userId = auth.currentUser.uid;
    const idToken = await currentUser.getIdToken(true); // Get auth token
    actionState.docId = id;
    const response = await axios.put(`${API_BASE_URL}/update-contact-action`, null, {
      params: { actionState, collectionType, userId }, // Send details as URL query parameters
      headers: { 'Authorization': `Bearer ${idToken}` }
    });
    if (collectionType === "user_companies") {
      setUserCompanies(prevCompanies => prevCompanies.map(company => company.id === id ? response.data : company));
    } else if (collectionType === "user_contacts") {
      setUserContacts(prevContacts => prevContacts.map(contact => contact.id === id ? response.data : contact));
    }
  } catch (error) {
    console.error('Error updating contact action:', error);
  }
};

const addTagToContact = async (tagName, contactId) => {
  const auth = getAuth(app);
  if (auth && auth.currentUser) {
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const userId = auth.currentUser.uid;
      const response = await axios.put(`${API_BASE_URL}/add-tag-to-contact`, null, {
        params: {userId, contactId, tagName  },
        headers: { 'Authorization': `Bearer ${idToken}` }
      });
      if (response.data) {
      setUserContacts(prevContacts => prevContacts.map(contact => 
        contact.id === contactId ? { ...contact, ...response.data } : contact
      ));
      } else {
        console.log(response)
      }
    } catch (error) {
      console.error("Error adding tags to user:", error);
    }
  }
}

const addTagToCompany = async (tagName, companyId) => {
  const auth = getAuth(app);
  if (auth && auth.currentUser) {
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const userId = auth.currentUser.uid;
      const response = await axios.put(`${API_BASE_URL}/add-tag-to-company`, null, {
        params: {userId, companyId, tagName  },
        headers: { 'Authorization': `Bearer ${idToken}` }
      });
      if (response.data) {
      setUserCompanies(prevCompanies => prevCompanies.map(company => 
        company.id === companyId ? { ...company, ...response.data } : company
      ));
      } else {
        console.log(response)
      }
    } catch (error) {
      console.error("Error adding tags to user:", error);
    }
  }
}

const removeTagFromContact = async (tagName, contactId) => {
  const auth = getAuth(app);
  if (auth && auth.currentUser) {
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const userId = auth.currentUser.uid;
      const response = await axios.put(`${API_BASE_URL}/remove-contact-tag`, null, {
        params: {userId, contactId, tagName},
        headers: { 'Authorization': `Bearer ${idToken}` }
      });
      if (response.data) {
        setUserContacts(prevContacts => prevContacts.map(contact => 
          contact.id === contactId ? { ...contact, tags: contact.tags.filter(tag => tag !== tagName) } : contact
        ));
      } else {
        console.log(response)
      }
    } catch (error) {
      console.error("Error removing tag from contact:", error);
    }
  }
}

const removeTagFromCompany = async (tagName, companyId) => {
  const auth = getAuth(app);
  if (auth && auth.currentUser) {
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const userId = auth.currentUser.uid;
      const response = await axios.put(`${API_BASE_URL}/remove-company-tag`, null, {
        params: {userId, companyId, tagName},
        headers: { 'Authorization': `Bearer ${idToken}` }
      });
      if (response.data) {
        setUserCompanies(prevCompanies => prevCompanies.map(company => 
          company.id === companyId ? { ...company, tags: company.tags.filter(tag => tag !== tagName) } : company
        ));
      } else {
        console.log(response)
      }
    } catch (error) {
      console.error("Error removing tag from company:", error);
    }
  }
}


const clearChaiFilters = () => {
  console.log('clearing')
  setChaiFilters({
    tags: [], 
  });
}; 




  return {
    myChaiTab, 
    setMyChaiTab,
    chaiFormLoading, 
    setChaiFormLoading,
    chaiPopUpOpen, 
    setChaiPopUpOpen,
    initialChaiFetch,
    chaiPageSearchTerm,
    setChaiPageSearchTerm,
    userCompanies, 
    setUserCompanies,
    userContacts, 
    setUserContacts,
    chaiLoaded,
    setChaiLoaded,
    createUserCompany,
    updateUserCompany,
    fetchUserCompanies,
    createUserContact,
    updateUserContact,
    fetchUserContacts,
    logContactAction,
    updateContactAction,
    // --- filter functions
    chaiFilters, 
    setChaiFilters,
    clearChaiFilters,
    // --- tag functions
    addTagToContact,
    addTagToCompany,
    removeTagFromContact,
    removeTagFromCompany,
  };
};
