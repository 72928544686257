import { useNavigate } from 'react-router-dom';

export const useCompanyClickHandler = () => {
    const navigate = useNavigate();
  
    return (id) => {
      navigate(`/chai/company/${id}`);
    };
  };

  export const useContactClickHandler = () => {
    const navigate = useNavigate();
  
    return (id) => {
      navigate(`/chai/contact/${id}`);
    };
  };

  export const useClubClickHandler = () => {
      const navigate = useNavigate();
  
      return (id) => {
        navigate(`/school/club/${id}`);
      };
    };
  
    export const useCompetitionClickHandler = () => {
      const navigate = useNavigate();
  
      return (id) => {
        navigate(`/school/competition/${id}`);
      };
    };

  export const useOrganizationClickHandler = () => {
      const navigate = useNavigate();
  
      return (id) => {
        navigate(`/school/organization/${id}`);
      };
    };
