import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import Loading from '../../components/Loading';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../../firebase/firebase';
import { useCompanyClickHandler } from '../../utils/handleClicks';
import EditChaiContact from '../../components/formPopups/chai/EditChaiContact';
import { handleChangeHelper, handleMultiComboChangeHelper, handleWebsiteBlurHelper } from '../../utils/formHelperFunctions';
import { useChaiEditSubmit } from '../../utils/chaiHelperFunctions';
import AddAction from '../../components/formPopups/actions/AddAction';
import ActionsTimeline from '../../components/timeline/ActionTimeline';
import { colorArray, formatDate, handleFilterChange } from '../../utils/helperFunctions';
import { XMarkIcon as XIcon,PlusIcon, PencilIcon } from '@heroicons/react/20/solid';
import { industryValues, roleValues } from '../../utils/helperValues';
import { DateTime } from "luxon";
import AddContact from '../../components/formPopups/organizations/AddContact';

export default function SharedContact() {
  const { userCompanies, userContacts, user, fetchUserCompanies, fetchUserContacts, userInfo, createUserContact,
    myChaiTab, chaiFormLoading, chaiPopUpOpen, setChaiPopUpOpen, setMyChaiTab, logContactAction, updateContactAction,
    getOrgContact, orgContacts, clubs, updateSharedContact,
    } = useUser();
  const [contactData, setContactData] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [contactRequested, setContactRequested] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [companyFilters, setCompanyFilters] = useState({ 
    industries: [] });

    const [contactFilters, setContactFilters] = useState({ 
      industries: [],
      roles: [], });

  let { id } = useParams();
  let completeCompanyData;
  const navigate = useNavigate();
  const handleCompanyClick = useCompanyClickHandler();

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, async (incomingUser) => {
        if (!contactRequested) {
            // Get Shared Contacts
            await getOrgContact(id);
            setContactRequested(true);
        }
    });
    return () => {
        unsubscribe();
    };
}, []);

  useEffect(() => {
    if (orgContacts) {
      const orgContact = orgContacts.find(contact => contact.contactId === id);
      if (orgContact) {
        setContactData({ 
          ...orgContact, 
        });
      }
    }
  }, [orgContacts, id]);


  const [formState, setFormState] = useState({
    contactName: "",
    role: "",
    email: "",
    phone: "",
    notes: "",
    linkedIn: "",
    companyName: "",
    website: "",
    lastContactDate: DateTime.now().toISODate(),
  });

  useEffect(() => {
    if (contactData) {
      setFormState(prevState => ({
        contactName: contactData.contactName || '',
        orgId: contactData.clubId || '',
        contactId: contactData.contactId || '',
        companyId: contactData.companyId || '',
        role: contactData.role || '',
        email: contactData.email || '',
        phone: contactData.phone || '',
        linkedIn: contactData.linkedIn || '',
        notes: contactData.notes || '',
        website: contactData.website || '',
        location: contactData.location || '',
        industries: contactData.industries || [],
        focusArea: contactData.focusArea || '',
        interests: contactData.interests || [],
        bSchool: contactData.bSchool || '',
        // directoryId: contactData.directoryId || ''
      }));
      setCompanyFilters({
        industries: contactData.industries || []
      })
    }
  }, [contactData]);

  let isLeader = false;
  if(contactData && contactData.clubId && clubs.length > 0) {
    let selectedClub = clubs.find(club => club.id === contactData.clubId);
    if (Array.isArray(selectedClub.leadership)) {
      isLeader = selectedClub.leadership.some(leader => leader.id === userInfo.id);
    } else {
      isLeader = false;
    }
  }



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState(prevState => {
        return { ...prevState, [name]: value };
    });
};

  // const handleChaiSubmit = useChaiEditSubmit();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    console.log(formState)
    const formData = new FormData();
    Object.keys(formState).forEach(key => {
      formData.append(key, formState[key]);
    });
    Object.keys(contactFilters).forEach(key => {
      formData.set(key, contactFilters[key]);
    });
    if (userInfo) {
      formData.append("updatedByName", userInfo.name);
      formData.append("updatedById", userInfo.id);
    } 
    await updateSharedContact(formData);
    setContactModalOpen(false);
    setIsSubmitting(false);
  };

  const addToChai = async () => {
    setMyChaiTab("Contacts");
    setIsSubmitting(true);

    const formState = {
        contactName: contactData.contactName,
        email: contactData.email || '',
        phone: contactData.phone || '',
        linkedIn: contactData.linkedIn || contactData.linkedin || '',
        notes: contactData.notes || '',
        website: contactData.website || '',
        role: contactData.role || '',
        lastContactDate: contactData.lastContactDate || DateTime.now().toISODate(),
        industries: contactData.industries || [],
        sharedContactId: id,
    }
    const formData = new FormData();
    Object.keys(formState).forEach(key => {
      if (typeof formState[key] === 'object' && formState[key] !== null && !(formState[key] instanceof File)) {
        // Stringify object fields
        formData.append(key, JSON.stringify(formState[key]));
      } else {
          // Append non-object fields as is
        formData.append(key, formState[key]);
      }
    });
    
    await createUserContact(formData);
    setIsSubmitting(false);
  };


  const goToDirectory = (id) => {
    navigate(`/directory/${id}`);
  };

  let chaiContactId, isChaiContact, chaiContact;
  if (userContacts) {
    chaiContact = userContacts ? userContacts.find(contact => contact.sharedContactId === id) : null;
    isChaiContact = Boolean(chaiContact);
    if (isChaiContact) {
      chaiContactId = chaiContact.id;
    }
  }

  const goToChai = () => navigate(`/chai/contact/${chaiContactId}`);

  if (!contactData || !user || !userInfo) {
    return <Loading />;
  }
  const sortedContactHistory = contactData && contactData.contactHistoryArray ? contactData.contactHistoryArray.sort((a, b) => new Date(a.actionDate) - new Date(b.actionDate)) : [];
  const industriesData = contactData.industries || contactData.industry;
  const sortedIndustries = contactData && Array.isArray(industriesData) ? industriesData.sort((a, b) => a.trim().localeCompare(b.trim())) : industriesData ? [industriesData] : [];
  const sortedRoles = contactData && Array.isArray(contactData.roles) ? contactData.roles.sort((a, b) => a.trim().localeCompare(b.trim())) : [];
  return ( 
  <>

      <AddContact 
      handleChange={handleChange}
      formState={formState} 
      popUpOpen={contactModalOpen} 
      setPopUpOpen={setContactModalOpen}
      handleSubmit={handleSubmit}
      loading={isSubmitting}
      contactFilters={contactFilters}
      setContactFilters={setContactFilters}
      handleFilterChange={handleFilterChange}
      industryValues={industryValues}
      roleValues={roleValues}
      />

          <div className="tw-flex tw-justify-between tw-pb-5">
          <button className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => navigate(-1)}>Back</button>
          {<button className="tw-bg-yellow-500 hover:tw-bg-yellow-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => { setContactModalOpen(true); }}>Edit Contact</button>}
        </div>
    <div className="tw-overflow-hidden tw-bg-white tw-shadow sm:tw-rounded-lg">
      <div className="tw-px-4 tw-py-5 sm:tw-px-6">
        <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">{contactData.contactName}</h3>
        <p className="tw-mt-1 tw-mb-2 tw-max-w-2xl tw-text-sm tw-leading-5 tw-text-gray-500">
          {contactData.role} 
          {contactData.company && Object.keys(contactData.company).length > 0 && (
            <>
              {" - "}
              <span 
                className="tw-inline-flex tw-items-center tw-rounded-full tw-bg-indigo-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-indigo-600 tw-ring-1 tw-ring-inset tw-ring-indigo-500/10 tw-mr-2 tw-mb-2 tw-cursor-pointer"
                onClick={() => handleCompanyClick(contactData.company.id)}
              >
                {contactData.company.name}
              </span>
            </>
          )} 
        </p>
      </div>
      <div className="tw-grid tw-grid-cols-5  tw-mb-6 sm:tw-mb-0 tw-gap-3 tw-mt-5">
            {!isChaiContact ? (
              <button
                type="button"
                className="tw-col-span-2 tw-inline-flex tw-w-full tw-flex-1 tw-items-center tw-justify-center tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50"
                onClick={addToChai}
                disabled={isSubmitting}
              >
                Add to Chai
              </button>
            ) : (
              <button
                type="button"
                className="tw-col-span-2 tw-inline-flex tw-w-full tw-flex-1 tw-items-center tw-justify-center tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50"
                onClick={goToChai}
              >
                Go To Contact
              </button>
            )}
          </div>
      <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
        <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Email</dt>
        <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
          <a href={`mailto:${contactData.email}`} className="tw-text-blue-500 hover:tw-text-blue-800 visited:tw-text-purple-600">{contactData.email}</a>
        </dd>
      </div>
      <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
        <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Phone</dt>
        <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
          <a href={`tel:${contactData.phone}`} className="tw-text-blue-500 hover:tw-text-blue-800 visited:tw-text-purple-600">{contactData.phone}</a>
        </dd>
      </div>
      {(contactData.notes || contactData.interestNotes) && (
        <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
          <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Notes</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
            <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-5 tw-text-gray-500">{contactData.interestNotes || contactData.notes}</p>
          </dd>
        </div>
      )}
      {contactData.focusArea && (
        <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
          <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Focus Area</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
            <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-5 tw-text-gray-500">{contactData.focusArea}</p>
          </dd>
        </div>
      )}
      {contactData.interests && (
        <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
          <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Interests</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
            <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-5 tw-text-gray-500">{contactData.interests}</p>
          </dd>
        </div>
      )}
      {contactData.linkedIn && (
        <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
          <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">LinkedIn</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
            <a href={contactData.linkedIn} target="_blank" rel="noopener noreferrer" className="tw-text-blue-500 hover:tw-text-blue-800 visited:tw-text-purple-600">{contactData.linkedIn}</a>
          </dd>
        </div>
      )}
      {contactData.location && (
        <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
          <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Location</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
          <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-5 tw-text-gray-500">{contactData.location}</p>
          </dd>
        </div>
      )}

    {(contactData.industries || contactData.industry) && (
        <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
          <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Industry Background</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
          {sortedIndustries.map((industry,index) => {
                const selectedColor = colorArray[index % 7 + 4]
                return (
                <div key={`${industry}-${index}`} className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2 tw-relative`}>
                  {industry}
                </div>
              )})}
          </dd>
        </div>
      )}
          {sortedRoles.length > 0 && (
        <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
          <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Roles Held</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
          {sortedRoles.map((roles,index) => {
                const selectedColor = colorArray[index % 7 + 1]
                return (
                <div key={`${roles}-${index}`} className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2 tw-relative`}>
                  {roles}
                </div>
              )})}
          </dd>
        </div>
      )}
      {(contactData.lastContactDate) && (
        <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
        <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Last Contacted</dt>
        <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
          <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-5 tw-text-gray-500">{DateTime.fromISO(contactData.dateLastContacted).toLocaleString(DateTime.DATE_MED)}</p>
        </dd>
      </div>
      )}
            {(contactData.bSchool) && (
        <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
        <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Business School</dt>
        <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
          <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-5 tw-text-gray-500">{contactData.bSchool}</p>
        </dd>
      </div>
      )}

      {contactData.directoryId && (
        <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
          <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Directory Contact</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
            <span className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-indigo-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-indigo-600 tw-ring-1 tw-ring-inset tw-ring-indigo-500/10 tw-mr-2 tw-mb-2 tw-cursor-pointer`}
              onClick={() => goToDirectory(contactData.directoryId)}>
              Go to Directory Page</span>
          </dd>
        </div>
      )}
    </div>
  </>
  
  )}