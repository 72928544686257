import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import MultiComboBoxInput from '../../filters/MultiComboBoxInput'


export default function AttendEventForm({ 
    handleChange, formState,
    popUpOpen, setPopUpOpen,
    handleSubmit, loading, eventName  }) {
  return (
    <Transition.Root show={popUpOpen} as={Fragment}>
      <Dialog as="div" className="tw-relative tw-z-10" onClose={setPopUpOpen}>
        <Transition.Child
          as={Fragment}
          enter="tw-ease-out tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in tw-duration-200"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
        </Transition.Child>

        <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
          <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
            <Transition.Child
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
              enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            >
              <Dialog.Panel className="tw-relative tw-transform tw-overflow-auto tw-rounded-lg tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-max-w-sm sm:tw-p-6">
                  <form>
                    <h1 className="tw-text-center tw-text-2xl tw-font-bold tw-text-black-600 tw-mb-4">Attendee Information</h1>
                    <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="whyIAmAttending">
                      Why I Am Attending the {eventName ? eventName : 'Event'} <span className="required-star">*</span>
                      </label>
                      <textarea 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                      name="whyIAmAttending" 
                      placeholder="Why I Am Attending" 
                      value={formState.whyIAmAttending || ''}
                      onChange={handleChange}>
                      </textarea>
                    </div>
                    <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="notes">
                      Who I Want to Meet at the {eventName ? eventName : 'Event'} <span className="required-star">*</span> 
                      </label>
                      <textarea 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                      name="whoIWantToMeet" 
                      placeholder="Who I Want to Meet" 
                      value={formState.whoIWantToMeet || ''}
                      onChange={handleChange}>
                      </textarea>
                    </div>
                    <div className="tw-mb-4">
                      <button 
                      className="tw-inline-flex tw-w-full tw-justify-center tw-bg-blue-500 tw-hover:bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
                      disabled={loading} 
                      onClick={handleSubmit}>
                        Submit
                      </button>
                    </div>
                  </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
