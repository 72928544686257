import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, RecaptchaVerifier } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore'; 
import { app } from '../firebase/firebase';
import { getFirestore } from "firebase/firestore";
import { useNavigate, useLocation } from 'react-router-dom';
import SuccessfulNoti from '../components/notifications/SuccessfulNoti';

const db = getFirestore(app);

const Login = () => {
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [fullName, setFullName] = useState('');
  const [formState, setFormState] = useState({
    email: '',
    password: ''
  });
  const [isSignUp, setIsSignUp] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [error, setError] = useState(null);
  const [resetMessage, setResetMessage] = useState(null);
  const [showSuccessNoti, setShowSuccessNoti] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || '/';
  console.log(location)
  console.log(location.state)
  // const handleSendResetEmail = async () => {
  //   const reCAPTCHAVerifier = new RecaptchaVerifier('recaptcha-container', {
  //     size: 'compact',
  //     callback: (response) => {
  //       // reCAPTCHA is verified, proceed with sending the reset email
  //       sendPasswordResetEmail(email)
  //         .then(() => {
  //           setError(null);
  //           alert('Password reset email sent!');
  //         })
  //         .catch((error) => {
  //           setError('An error occurred during reset. Please try again.');
  //           console.error(error);
  //         });
  //     }
  //   });

  //   // Show reCAPTCHA widget and verify it
  //   reCAPTCHAVerifier.verify().then((result) => {
  //     // reCAPTCHA has been verified, use the result to authenticate
  //     // signInWithRedirect(auth, result, firebase.auth.EmailAuthProvider.credential(email));
  //   });
  // };

  const handleSignUp = async () => {
    try {
      const { user } = await createUserWithEmailAndPassword(auth, formState.email, formState.password);
      // await setDoc(doc(db, 'users', user.uid), {
      //   fullName,
      //   schoolProgram,
      // });
      setError(null);
      navigate(from, { replace: true });
    } catch (error) {
      setError('An error occurred during sign up. Please try again.');
      console.error(error);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, formState.email);
      setError(null);
      setResetMessage(true)
      setIsResetting(true);
      setShowSuccessNoti(true);
      setTimeout(() => {
        setResetMessage(false)
        setIsResetting(false);
        setShowSuccessNoti(false);
      }, 3000);
      return;
    } catch (error) {
      setError(error);
      console.error(error);
    }
  }
  const handleChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value
    });
  };

  const handleSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, formState.email, formState.password);
      setError(null);
      navigate(from, { replace: true });
      // navigate('/earn');
    } catch (error) {
      setError(error);
      console.error(error);
    }
  };

  const goToSignup = () => {
    navigate('/signup');
  };

  let errorText;
  if (error instanceof Error) {
    errorText = error.message;
  }
  if (typeof errorText === 'string') {
    if (errorText.includes('auth/user-not-found') || errorText.includes('auth/wrong-password') || errorText.includes('invalid-login-credentials')) {
      errorText = 'An error occurred during login. Please check your email and password.';
    } else if (errorText.includes('auth/invalid-email')) {
      errorText = 'The email address is badly formatted.';
    } else if (errorText.includes('auth/missing-email')) {
      errorText = 'Email is required.';
    } else {
      errorText = 'An error occurred with your email or passsword. Please try again';
    }
  } else {
    let page = isReset ? "reset" : "login"
    errorText = `An error occurred during ${page}. Please try again.`;
  }
  return (
    <>
    
    <div className="tw-relative tw--mt-[5.75rem] tw-overflow-hidden pt-[5.75rem] login-form">
    {showSuccessNoti && <SuccessfulNoti show={showSuccessNoti} setShow={setShowSuccessNoti} message={"Password Reset Message Sent"} />}
      <div className="tw-relative tw-mx-auto tw-w-full tw-max-w-container tw-sm:px-6 tw-lg:px-8 login-form__form-padding">
        <div className="tw-space-y-24 tw-pb-px">
          <div className="tw-grid tw-grid-cols-[1fr,auto] tw-items-center">
            <div className="tw-col-span-2 tw-row-start-2 tw-min-w-0">
              <div className="tw-flex tw-min-h-[840px] tw-flex-col tw-bg-white">
                <div className="tw-flex tw-min-h-full tw-flex-1 tw-flex-col tw-justify-center tw-px-6 tw-py-12 tw-lg:px-8">
            <div className="tw-sm:mx-auto tw-sm:w-full tw-sm:max-w-sm">
              {/* <img
                className="tw-mx-auto tw-h-10 tw-w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt="Your Company"
              /> */}
                           <img
                              // className="tw-h-8 tw-w-auto"
                              className="tw-mx-auto  tw-h-20 tw-w-auto" 
                              src="/on-white-teahouse-STACKED.svg"
                              alt="Your Company"
                              // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                            />
              {/* <h1 className="tw-text-center tw-text-4xl tw-font-extrabold">
                Chai
              </h1> */}
              <h2 className="tw-mt-2 tw-text-center tw-text-2xl tw-font-bold tw-leading-9 tw-tracking-tight tw-text-gray-900">
                Sign into Chai
              </h2>
            </div>

            <div className="tw-mt-10 tw-sm:mx-auto tw-sm:w-full tw-sm:max-w-sm">
              <form className="tw-space-y-6" >
                {/* onSubmit={handleSubmit} */}
                <div>
                  {isReset ? (
                    <>
                        {error && (
                        <div className="tw-mt-2 tw-text-red-500 tw-text-md tw-text-center">
                          {errorText}
                        </div>
                      )}
                      <label htmlFor="email" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                        Email address
                      </label>
                      <div className="tw-mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          value={formState.email}
                          onChange={handleChange}
                          className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-indigo-600 tw-sm:text-sm tw-sm:leading-6"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {error && (
                        <div className="tw-mt-2 tw-text-red-500 tw-text-md tw-text-center">
                          {errorText}
                        </div>
                      )}
                      <label htmlFor="email" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                        Email address
                      </label>
                      <div className="tw-mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          value={formState.email}
                          onChange={handleChange}
                          className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-indigo-600 tw-sm:text-sm tw-sm:leading-6"
                        />
                      </div>
                      <div className="tw-flex tw-items-center tw-justify-between">
                        <label htmlFor="password" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                          Password
                        </label>
                      </div>
                      <div className="tw-mt-2">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          value={formState.password}
                          onChange={handleChange}
                          className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-indigo-600 tw-sm:text-sm tw-sm:leading-6"
                        />
                      </div>
                    </>
                  )}
                </div>

                <div>
                  {isReset ? (
                    <button
                      type="button"
                      onClick={handlePasswordReset}
                      disabled={isResetting}
                      className="tw-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-indigo-600 tw-px-3 tw-py-1.5 tw-text-sm tw-font-semibold tw-leading-6 tw-text-white tw-shadow-sm tw-hover:bg-indigo-500 tw-focus-visible:outline tw-focus-visible:outline-2 tw-focus-visible:outline-offset-2 tw-focus-visible:outline-indigo-600"
                    >
                      Reset Password
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={handleSignIn}
                      className="tw-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-indigo-600 tw-px-3 tw-py-1.5 tw-text-sm tw-font-semibold tw-leading-6 tw-text-white tw-shadow-sm tw-hover:bg-indigo-500 tw-focus-visible:outline tw-focus-visible:outline-2 tw-focus-visible:outline-offset-2 tw-focus-visible:outline-indigo-600"
                    >
                      Sign In
                    </button>
                  )}
                </div>
              </form>

              <p className="tw-mt-10 tw-text-center tw-text-sm tw-text-gray-500">
                {isReset ? <a onClick={() => setIsReset(false)} className="tw-font-semibold tw-leading-6 tw-text-indigo-600 tw-hover:text-indigo-500">Sign In</a> :
                <a onClick={() => setIsReset(true)} className="tw-font-semibold tw-leading-6 tw-text-indigo-600 tw-hover:text-indigo-500">Forgot Password?</a>}
                <br/>
                <br/>
                <a onClick={goToSignup} className="tw-font-semibold tw-leading-6 tw-text-indigo-600 tw-hover:text-indigo-500">Sign Up</a>
              </p>
            </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    </>
  )

  // return (
  //   <div className="login-form__container-center">
  //     <form className="login-form__container">
  //       {resetMessage && <p className="login-form">Password Reset sent if an email exists at that address</p>}
  //       {error && <p className="login-form__error">{error}</p>}
  //       {isReset ? 
        
  //        (
  //         <>
  //           <input className="login-form__input" type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
  //           {/* <div id="recaptcha-container"></div> */}
  //         </>
  //       ): 
  //       (       
  //         <>
  //         <input className="login-form__input" type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
  //       <input className="login-form__input" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
  //       </>
  //       )
        
  //       }
      
  //       {/* {isSignUp (
  //         <>
  //           <input className="login-form__input" type="text" placeholder="Full Name" onChange={(e) => setFullName(e.target.value)} />
  //           <input className="login-form__input" type="text" placeholder="School Program" onChange={(e) => setSchoolProgram(e.target.value)} />
  //         </>
  //       )} */}

        
        
  //       {/* {isSignUp ? (
  //         // <button className="login-form__button" type="button" onClick={handleSignUp}>Sign Up</button>
  //         null
  //       ) : (
  //         <button className="login-form__button" type="button" onClick={handleSignIn}>Sign In</button>
  //       )} */}


  //       {isReset ? (
  //         <button className="login-form__button" type="button" onClick={handlePasswordReset}>Reset Password</button>
  //       ) : (
  //         <button className="login-form__button" type="button" onClick={handleSignIn}>Sign In</button>
  //       )}
  //       {/* <button className="login-form__button login-form__toggle" type="button" onClick={() => setIsSignUp(!isSignUp)}>
  //         {isSignUp ? 'Already have an account? Sign In' : 'Don\'t have an account? Sign Up'}
  //       </button> */}
  //       {isReset ? <a onClick={() => setIsReset(false)}>Sign In</a> :
  //       <a onClick={() => setIsReset(true)}>Forgot Password?</a>
  //       }
        
  //     </form>
  //   </div>
  // );
};

export default Login;
