import React, { useState, useEffect, useRef } from 'react';
import { useUser } from '../../contexts/UserContext';
import FilterPopup from '../FilterPopup';
import { getUniqueValues, uniqueFilterValues, useNavigateToDirectory, handleFilterChange } from '../../utils/helperFunctions';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import MultiComboBoxInput from './MultiComboBoxInput';
import DirectoryFilterPopup from './directory/DirectoryFilterPopup';

export default function DirectoryFilters({ users }) {
  const { 
    directory, 
    getUserDetailsById, 
    directoryFilters: filters, 
    setDirectoryFilters: setFilters, 
    directorySearchTexts: searchTexts,
    setDirectorySearchTexts: setSearchTexts,
    directorySearchTerm,
    setDirectorySearchTerm,
    directoryFilterTerm, 
    setDirectoryFilterTerm,
    clearDirectoryFilters,
    directorySearch,
    signUpData
  } = useUser();

  const dropdownRefs = useRef([]);
  const directorySlice = directory ? directory.slice(0,400) : [];
  const [filterOpen, setFilterOpen] = useState(false);

  const handleSearchTextChange = (filterKey, newSearchText) => {
    setSearchTexts(prevTexts => ({
      ...prevTexts,
      [filterKey]: newSearchText
    }));
  };

  const handleFilterOpen = () => setFilterOpen(!filterOpen);

  const filteredDirectory = React.useMemo(() => {
    return directorySlice.filter(user => {
        const searchTermLower = directoryFilterTerm.toLowerCase();
        // Define the fields you want to search through
        const searchableFields = ['name', 'howYouCanHelp', 'program', 'currentGoals','schoolFiveYearGoals', 'interestsHobbies','recruitingFor', 'country'];

         // Check if any of the fields include the search term
        const fieldMatches = searchableFields.some(field => {
          if (Array.isArray(user[field])) {
            return user[field].some(item => item.toLowerCase().includes(searchTermLower));
          } else if (typeof user[field] === 'string') {
            return user[field].toLowerCase().includes(searchTermLower);
          }
          return false;
        });

    //   // Check if the user's name includes the search term
    //   const nameMatches = user.name.toLowerCase().includes(searchTerm.toLowerCase());
  
      // Check if the user matches the filters
      const filtersMatch = Object.keys(filters).every(key => {
        const filterValues = filters[key];
        if (!filterValues || filterValues.length === 0 || filterValues[0] === '') return true;
        if (Array.isArray(filterValues)) {
          return filterValues.some(filterValue => user[key] && user[key].includes(filterValue));
        }
        if (key === 'recruitingFor' || key === 'interestsHobbies') {
          return user[key] && user[key].includes(filters[key]);
        }
        return user[key] === filters[key];
      });
  
      // Return true if the user matches both the search term and the filters
      return fieldMatches && filtersMatch;
    });
  }, [directorySlice, filters, directoryFilterTerm]); 

//   const uniqueFilterValues = (filterKey) => {
//     let values = directorySlice.flatMap((user, index) => user[filterKey] ? user[filterKey] : []);
//     values = [...new Set(values)]; // remove duplicates
//     if (searchTexts[filterKey] && searchTexts[filterKey].trim() !== '') {
//       values = values.filter(value => 
//           value && 
//           typeof value === 'string' && 
//           typeof searchTexts[filterKey] === 'string' && 
//           value.toLowerCase().includes(searchTexts[filterKey].toLowerCase())
//         ); // filter based on search text only if search text is not empty
//     }
//     values.sort((a, b) => { // sort alphabetically, considering emojis
//       const aEmojiRemoved = a.replace(/^[^\p{L}\p{N}]+/u, '');
//       const bEmojiRemoved = b.replace(/^[^\p{L}\p{N}]+/u, '');
//       return aEmojiRemoved.localeCompare(bEmojiRemoved);
//     });
//     return values;
//   };
const uniquePrograms = uniqueFilterValues(directorySlice, 'program')
const triggerDirectorySearch = async () => {
  await directorySearch();
  setFilterOpen(false)
};

const handleDirectorySearch = (value) => {
  setDirectorySearchTerm(value)
  setDirectoryFilterTerm(value)
}


// .map(program => {
//   // Regex to match patterns like '- A', '- B', '- C', etc.
//   const regex = /- (A|B|C|D|E)/g;

//   // Replace function to format matched groups
//   return program.replace(regex, (match, p1) => `- Section ${p1}`);
// });
  return (
    // <div className="directory__filter-container">
    <>
         {/* <label htmlFor="search-field" className="tw-sr-only">
                  Search
                </label> */}
                {/* <MagnifyingGlassIcon
                  className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-h-full tw-w-5 tw-text-gray-400"
                  aria-hidden="true"
                /> */}
              {/* <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-4 tw-w-full tw-pt-4"> */}
                <div className="tw-grid tw-grid-cols-4 tw-gap-4 tw-w-full">
                  {directory.length === 0 ? (
                    <div className="tw-h-full tw-col-span-3"></div>
                  ) : (
                    <input
                      id="search-field"
                      className="tw-block tw-w-full tw-h-full tw-border tw-border-gray-300 tw-rounded-md  md:tw-pt-0 tw-pb-0 
                      tw-pl-0 tw-pr-0 tw-text-gray-900 tw-placeholder:text-gray-400 
                      sm:tw-text-sm dark:tw-text-white dark:tw-bg-black tw-col-span-3"
                      placeholder="Filter by name, program, and country" 
                      value={directoryFilterTerm}
                      onChange={e => setDirectoryFilterTerm(e.target.value)}  
                      type="search"
                      name="search"
                    />
                  )}
                  <div className="tw-col-span-1 tw-flex tw-justify-center tw-items-center">
                    <button
                      type="button"
                      className="tw-rounded-full tw-bg-indigo-600 tw-px-4 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
                      onClick={handleFilterOpen}
                    >
                      Search
                    </button>
                  </div>
                </div>


                {/* tw-focus:ring-0 */}
                {signUpData && (
                  <DirectoryFilterPopup
                     open={filterOpen}
                     filters={filters}
                     clearFilters={clearDirectoryFilters}
                     directorySearchTerm={directorySearchTerm}
                     setDirectorySearchTerm={handleDirectorySearch}
                     programItems={signUpData.programs}
                     programSingleSelect={false}
                     interestItems={signUpData.interests}
                     interestSingleSelect={false}
                     countryItems={signUpData.countries}
                     countrySingleSelect={false}
                     industryItems={signUpData.industries}
                     industrySingleSelect={false}
                     setOpen={setFilterOpen}
                     setFilters={setFilters}
                     handleFilterChange={handleFilterChange}
                     directorySearch={triggerDirectorySearch}
                    />
                )}
      </>
  );
}
