import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { PaperClipIcon, EnvelopeIcon } from '@heroicons/react/20/solid'
import { useUser } from '../../contexts/UserContext';
import Loading from '../../components/Loading';
import AddJobs from '../../components/formPopups/recruiting/AddJobs';
import EditJob from '../../components/formPopups/recruiting/EditJob';
import { industryValues, roleValues } from '../../utils/helperValues';
import { capitalize, colorArray, handleFilterChange } from '../../utils/helperFunctions';
import { app } from '../../firebase/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import StandardMenu from '../../components/menus/StandardMenu';
import { jobStatuses } from '../../components/formPopups/recruiting/RecruitingConsts';

export default function UserJob() {
  const { user, openJobs, userInfo, updateJob, clubs, getOpenJobs, 
    savedUserJobs, userContacts, jobStatus, setJobStatus, updateUserJobStatus } = useUser();
  const [jobData, setJobData] = useState(null);
  const [editJobOpen, setEditJobOpen] = useState(false);
  const [isJobFormSubmitting, setIsJobFormSubmitting] = useState(false);
  const [jobFilters, setJobFilters] = useState({ 
    industries: [],
    roles: [], });

  let { id, userId } = useParams();
  const navigate = useNavigate();

  const initialJobFormState = {
    company: '',
    companyWebsite: '',
    role: '',
    roleType: '', // "Full-time" or "Internship"
    jobLink: '',
    datePosted: null,
    deadline: null,
    location: '',
    isAcademic: '', // "yes" or "no", explain if it's an academic internship
    isSponsor: '',
    workSetting: '', // "in-office", "remote", or "hybrid"
    startDate: null,
    endDate: null,
    isCoverLetterRequired: '', // "yes" or "no"
    additionalMaterials: '',
    additionalNotes: '',
    applicationStatus: 'Saved'
};

const [jobFormState, setJobFormState] = useState(initialJobFormState);

useEffect(() => {
  const auth = getAuth(app);
  const unsubscribe = onAuthStateChanged(auth, async (incomingUser) => {
      try {
        if(clubs.length > 0) {
        const clubIds = [];
        clubs.forEach(club => {
            if (club.leadership && (Array.isArray(club.leadership) && club.leadership.some(leader => leader.id === incomingUser.uid)
            || club.leadership[incomingUser.uid] === true)
            ) {
                if (!clubIds.includes(club.id)) {
                    clubIds.push(club.id);
                }
            } else if (club.members && Array.isArray(club.members) && club.members.some(member => member.id === incomingUser.uid)) {
                if (!clubIds.includes(club.id)) {
                    clubIds.push(club.id);
                }
            }
        });
        getOpenJobs(clubIds);
        //Maybe call get user jobs
      }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  });
  return () => {
    unsubscribe();
  };
}, [clubs]);

  useEffect(() => {
    if (Array.isArray(savedUserJobs) && savedUserJobs.length > 1) {
      const jobInfo = savedUserJobs.find(company => company.id === id);
      if(jobInfo) {

      setJobFormState(jobInfo);
      setJobData({
        company: jobInfo.company || '',
        companyWebsite: jobInfo.companyWebsite || '',
        companyId: jobInfo.companyId || '',
        role: jobInfo.role || '',
        roleType: jobInfo.roleType || '', // "Full-time" or "Internship"
        jobLink: jobInfo.jobLink || '',
        jobId: id,
        clubId: jobInfo.clubId || '',
        datePosted: jobInfo.datePosted || '',
        deadline: jobInfo.deadline || '',
        location: jobInfo.location || '',
        isAcademic: jobInfo.isAcademic || '', // "yes" or "no", explain if it's an academic internship
        isSponsor: jobInfo.isSponsor || '',
        workSetting: jobInfo.workSetting || '', // "in-office", "remote", or "hybrid"
        startDate: jobInfo.startDate || '',
        endDate: jobInfo.endDate || '',
        isCoverLetterRequired: jobInfo.isCoverLetterRequired || '', // "yes" or "no"
        additionalMaterials: jobInfo.additionalMaterials || '',
        additionalNotes: jobInfo.additionalNotes || '',
        jobStatus: jobInfo.jobStatus || 'Saved',
      });
      if(jobInfo.industries || jobInfo.roles){
        setJobFilters({
          industries: jobInfo.industries || [],
          roles: jobInfo.roles || [],
        })
      }
    }
    }
  }, [openJobs, id]);


  if (!jobData || !user) {
    return <Loading />;
  }

  let chaiContactId, isChaiContact, chaiContact;
  if (userContacts) {
    chaiContact = userContacts ? userContacts.find(contact => contact.sharedContactId === id) : null;
    isChaiContact = Boolean(chaiContact);
    if (isChaiContact) {
      chaiContactId = chaiContact.id;
    }
  }

  const goToChai = () => navigate(`/chai/contact/${chaiContactId}`);
  const goToSharedContact = () => navigate(`/brew/org/${jobData.orgId}/company/${jobData.companyId}`);

  const handleJobFormChange = (e) => {
    const { name, value } = e.target;
    setJobFormState(prevState => {
        return { ...prevState, [name]: value };
    });
};

const handleJobStatusChange = async (newStatus) => {
  if(isJobFormSubmitting) return
  setJobData(prevState => ({
    ...prevState,
    jobStatus: newStatus
  }));
  setJobStatus(newStatus)
  setIsJobFormSubmitting(true)
  await updateUserJobStatus(userInfo.id, id, newStatus)
  setIsJobFormSubmitting(false)

  // Placeholder for future function call with id and user's id
  // This function can be implemented to perform additional actions when job status changes
  // Example: updateJobStatusInDatabase(id, userInfo.id, newStatus);
};


const handleJobFormDateChange = (name, date) => {
    setJobFormState(prevState => ({
        ...prevState,
        [name]: DateTime.fromJSDate(date)
    }));
};

const handleSubmit = async (event) => {
  event.preventDefault();
  const formData = new FormData();
  Object.keys(jobFormState).forEach(key => {
    formData.append(key, jobFormState[key]);
  });
  Object.keys(jobFilters).forEach(key => {
    formData.append(key, jobFilters[key]);
  });
  if (userInfo) {
    formData.append("updatedByName", userInfo.name);
    formData.append("updatedById", userInfo.id);
  } 
  // await updateJob(formData);
  setEditJobOpen(false);
}
  const sortedIndustries = jobFilters && Array.isArray(jobFilters.industries) ? jobFilters.industries.sort((a, b) => a.localeCompare(b)) : [];
  const sortedRoles = jobFilters && Array.isArray(jobFilters.roles) ? jobFilters.roles.sort((a, b) => a.localeCompare(b)) : [];
  let isLeader = false;
  if(jobData.clubId) {
    let selectedClub = clubs.find(club => club.id === jobData.clubId);
    if (Array.isArray(selectedClub.leadership)) {
      isLeader = selectedClub.leadership.some(leader => leader.id === userInfo.id);
    } else {
      isLeader = false;
    }
  }

  let contactType;
  if (jobData.companyId && jobData.clubId) {
    contactType = "sharedCompanyContact";
  } else if (jobData.companyId && !jobData.clubId) {
    contactType = "chaiContact";
  } else {
    contactType = "unlinked";
  }


  return (
    <>
        <EditJob
       handleChange={handleJobFormChange}
       handleDataChange={handleJobFormDateChange}
       formState={jobFormState} 
       setFormState={setJobFormState}
       popUpOpen={editJobOpen} 
       setPopUpOpen={setEditJobOpen}
       handleSubmit={handleSubmit} 
       loading={isJobFormSubmitting}
       jobFilters={jobFilters}
       setJobFilters={setJobFilters}
       handleFilterChange={handleFilterChange}
       industryValues={industryValues}
       roleValues={roleValues}
       jobStatuses={jobStatuses}
      />
          <div className="tw-flex tw-justify-between tw-pb-5">
          <button className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => navigate('/brew')}>Back</button>
          <button className="tw-bg-yellow-500 hover:tw-bg-yellow-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => { setEditJobOpen(true); }}>Edit Job</button>
        </div>
        <div>
          <div className="tw-px-4 sm:tw-px-0 tw-flex tw-items-center">
            <img
              src={`https://logo.clearbit.com/${jobData.companyWebsite}`}
              alt={jobData.companyName}
              className="tw-h-12 tw-w-12 tw-flex-none tw-rounded-lg tw-bg-white tw-object-cover tw-ring-1 tw-ring-gray-900/10"
            />
            <div className="tw-pl-4">
              <h3 className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">{jobData.company}</h3>
              <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-6 tw-text-gray-500">{jobData.role}</p>
            </div>
          </div>
          <div className="tw-grid tw-grid-cols-5  tw-mb-6 sm:tw-mb-0 tw-gap-3 tw-mt-5">
            {contactType === "sharedCompanyContact" ? (
              <button
                type="button"
                className="tw-col-span-2 tw-inline-flex tw-w-full tw-flex-1 tw-items-center tw-justify-center tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50"
                onClick={goToSharedContact}
                disabled={isJobFormSubmitting}
              >
                View Shared Company Info
              </button>
            ) : contactType === "chaiContact" ? (
              <button
                type="button"
                className="tw-col-span-2 tw-inline-flex tw-w-full tw-flex-1 tw-items-center tw-justify-center tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50"
                onClick={goToChai}
                disabled={isJobFormSubmitting}
              >
                Go To Contact
              </button>
            ) : null}
          </div>
          
          <div className="tw-mt-6 tw-border-t tw-border-gray-100">
            <dl className="tw-divide-y tw-divide-gray-100">
        <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
          <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Job Status</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
            {/* <select 
              value={jobData.jobStatus} 
              onChange={() => {}} 
              className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
            >
              <option value="Saved">Saved</option>
              <option value="Applied">Applied</option>
              <option value="Interviewed">Interviewed</option>
              <option value="Offer">Offer</option>
              <option value="Rejected">Rejected</option>
            </select> */}
              <StandardMenu 
                buttonText={"Status"}
                items={jobStatuses}
                onSelect={handleJobStatusChange}
                className="tw-mr-4"
                selectedText={jobData.jobStatus}
                disabled={isJobFormSubmitting}
              />
          </dd>
        </div>
            {sortedIndustries.length > 0 && (
        <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
          <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Industry Focus</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
          {sortedIndustries.map((industry,index) => {
                const selectedColor = colorArray[index % 7 + 1]
                return (
                <div key={`${industry}-${index}`} className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2 tw-relative`}>
                  {industry}
                  {/* <XIcon className="tw-ml-2 tw-h-4 tw-w-4 tw-text-${selectedColor}-600 hover:tw-text-${selectedColor}-800 tw-cursor-pointer" onClick={() => handleRemoveTag(tag)} aria-hidden="true" /> */}
                </div>
              )})}
          </dd>
        </div>
      )}
      {sortedRoles.length > 0 && (
        <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
          <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Role Focus</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
          {sortedRoles.map((role,index) => {
                const selectedColor = colorArray[index % 7 + 5]
                return (
                <div key={`${role}-${index}`} className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2 tw-relative`}>
                  {role}
                  {/* <XIcon className="tw-ml-2 tw-h-4 tw-w-4 tw-text-${selectedColor}-600 hover:tw-text-${selectedColor}-800 tw-cursor-pointer" onClick={() => handleRemoveTag(tag)} aria-hidden="true" /> */}
                </div>
              )})}
          </dd>
        </div>
      )}
                {jobData.roleType && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Role Type</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{jobData.roleType}</dd>
              </div>}
                {jobData.datePosted && jobData.datePosted != 'null' && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Date Posted</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{DateTime.fromISO(jobData.datePosted).toLocaleString(DateTime.DATE_MED)}</dd>
              </div>}
              {typeof jobData.isActive !== 'undefined' && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Active Status</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{jobData.isActive ? 'Active' : 'Inactive'}</dd>
              </div>}
              {jobData.jobLink && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Job Link</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
                  <a href={jobData.jobLink} className="tw-text-blue-500 hover:tw-text-blue-800" target="_blank" rel="noopener noreferrer">{jobData.jobLink}</a>
                </dd>
              </div>}
              {jobData.location && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Location</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{capitalize(jobData.location)}</dd>
              </div>}
              {jobData.deadline && jobData.deadline != 'null' && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Application Deadline</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{DateTime.fromISO(jobData.deadline).toLocaleString(DateTime.DATE_MED)}</dd>
              </div>}
              {jobData.isAcademic && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Academic Internship?</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{capitalize(jobData.isAcademic)}</dd>
              </div>}
              {jobData.workSetting && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Work Setting</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{capitalize(jobData.workSetting)}</dd>
              </div>}
              {jobData.startDate && jobData.startDate != 'null' && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Start Date</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{DateTime.fromISO(jobData.startDate).toLocaleString(DateTime.DATE_MED)}</dd>
              </div>}
              {jobData.endDate && jobData.endDate != 'null' && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">End Date</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{DateTime.fromISO(jobData.endDate).toLocaleString(DateTime.DATE_MED)}</dd>
              </div>}
              {typeof jobData.isCoverLetterRequired !== 'undefined' && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Cover Letter Required</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{capitalize(jobData.isCoverLetterRequired ? 'Yes' : 'No')}</dd>
              </div>}
              {jobData.additionalNotes && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Additional Notes</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{jobData.additionalNotes}</dd>
              </div>}
              {jobData.companyContacts && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Company Contacts</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
                  <div className="">
                    <div className="tw-mt-8 tw-flow-root">
                      <div className="tw--mx-4 tw--my-2 tw-overflow-x-auto sm:tw--mx-6 lg:tw--mx-8">
                        <div className="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle">
                          <table className="tw-min-w-full tw-divide-y tw-divide-gray-300">
                            <thead>
                              <tr>
                                <th scope="col" className="tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-0">Name</th>
                                <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">City</th>
                                <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">Email</th>
                                <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">LinkedIn</th>
                              </tr>
                            </thead>
                            <tbody className="tw-divide-y tw-divide-gray-200">
                              {jobData.companyContacts?.map((contact, index) => (
                                <tr key={index} onClick={() => navigate(`/brew/contact/${contact.contactId}`)} className="hover:tw-cursor-pointer">
                                  <td className="tw-whitespace-nowrap tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-pl-0">{contact.name}</td>
                                  <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{contact.city || contact.locationId}</td>
                                  <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                                    <a href={`mailto:${contact.email}`} className="tw-text-grey-600 hover:tw-text-blue-500">
                                      <EnvelopeIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-grey-600 hover:tw-text-indigo-600" aria-hidden="true" />
                                    </a>
                                  </td>
                                  <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                                    <a href={contact.linkedin} target="_blank" rel="noopener noreferrer" className="tw-text-grey-600 hover:tw-text-blue-500">
                                      <PaperClipIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-grey-600 hover:tw-text-blue-500" aria-hidden="true" />
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </dd>
              </div>}
              {/* <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">About</dt>
                <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
                  Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                  qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                  pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                </dd>
              </div> */}
              {/* <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
                <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Attachments</dt>
                <dd className="tw-mt-2 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                  <ul role="list" className="tw-divide-y tw-divide-gray-100 tw-rounded-md tw-border tw-border-gray-200">
                    <li className="tw-flex tw-items-center tw-justify-between tw-py-4 tw-pl-4 tw-pr-5 tw-text-sm tw-leading-6">
                      <div className="tw-flex tw-w-0 tw-flex-1 tw-items-center">
                        <PaperClipIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-400" aria-hidden="true" />
                        <div className="tw-ml-4 tw-flex tw-min-w-0 tw-flex-1 tw-gap-2">
                          <span className="tw-truncate tw-font-medium">resume_back_end_developer.pdf</span>
                          <span className="tw-flex-shrink-0 tw-text-gray-400">2.4mb</span>
                        </div>
                      </div>
                      <div className="tw-ml-4 tw-flex-shrink-0">
                        <a href="#" className="tw-font-medium tw-text-indigo-600 tw-hover:text-indigo-500">
                          Download
                        </a>
                      </div>
                    </li>
                    <li className="tw-flex tw-items-center tw-justify-between tw-py-4 tw-pl-4 tw-pr-5 tw-text-sm tw-leading-6">
                      <div className="tw-flex tw-w-0 tw-flex-1 tw-items-center">
                        <PaperClipIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-400" aria-hidden="true" />
                        <div className="tw-ml-4 tw-flex tw-min-w-0 tw-flex-1 tw-gap-2">
                          <span className="tw-truncate tw-font-medium">coverletter_back_end_developer.pdf</span>
                          <span className="tw-flex-shrink-0 tw-text-gray-400">4.5mb</span>
                        </div>
                      </div>
                      <div className="tw-ml-4 tw-flex-shrink-0">
                        <a href="#" className="tw-font-medium tw-text-indigo-600 tw-hover:text-indigo-500">
                          Download
                        </a>
                      </div>
                    </li>
                  </ul>


                  
                </dd>
              </div> */}
            </dl>
          </div>
        </div>
        </>
      )
}