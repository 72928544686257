import { Fragment, useState, useEffect, useRef } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react'
import { classNames } from '../../utils/helperFunctions';
import { handleMultiFormInputBlur } from '../../utils/inputValidator';
import { objectArraySort } from '../../utils/formHelperFunctions';

export default function MultiComboBoxInput({ 
  items, label, setFilters, handleFilterChange, filterKey,
  altLabel, selectedValues, singleSelect, handleSingleSelectChange, customMaxLength,
  currentFormState, currentFormErrorFunction, currentFormErrors, selectedValue,
  hideOwnValue = true,
  localUpdate,
}) {
        const [query, setQuery] = useState('');
        const [otherValue, setOtherValue] = useState('');
        const [selectedItems, setSelectedItems] = useState(selectedValues || []);
        const [selectedItem, setSelectedItem] = useState(selectedValue || "");
        const [showOptions, setShowOptions] = useState(false);
        const [localItems, setLocalItems] = useState([]);
        const containerRef = useRef(null);
      
        useEffect(() => {
          const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
              setShowOptions(false);
            }
          };
      
          document.addEventListener('mousedown', handleClickOutside);
          return () => document.removeEventListener('mousedown', handleClickOutside);
        }, []);

        useEffect(() => {
          // console.log(selectedValues,selectedItems)
          if (!selectedValues) {
            setSelectedItems([]);
          }
          if(!singleSelect && selectedValues && selectedValues.length !== selectedItems.length) {

          setSelectedItems(selectedValues) 
        } 
        // else if(Array.isArray(selectedItems) && Array.isArray([0])) {
        //   setSelectedItems(selectedValues[0]) 
        // }
        // console.log(selectedValues,selectedItems)
        }, [selectedValues]);
      
        const queryLower = query.toLowerCase();

        // console.log(items,label)
        let combinedItems = Array.isArray(items) ? [...localItems, ...items] : [...localItems, ...objectArraySort(items)]
        // console.log(combinedItems,'CI')
        const filteredItems = query === '' ? combinedItems : combinedItems.filter((item) => {
          if (typeof item === 'object') {
            if (filterKey) {
              return item[filterKey].toLowerCase().includes(queryLower);
            } else {
              for (let key in item) {
                if (key.toLowerCase().includes('name')) {
                  return item[key].toLowerCase().includes(queryLower);
                }
              }
            }
            return false;
          } else {
            let itemLower = typeof item.toLowerCase === 'function' ? item.toLowerCase() : item;
            return typeof itemLower.includes === 'function' && itemLower.includes(queryLower);
          }
        });
        // console.log(filteredItems,'FI')
        // filteredItems.sort((a, b) => {
        //   if (selectedItems.includes(a) && !selectedItems.includes(b)) {
        //     return -1;
        //   }
        //   if (!selectedItems.includes(a) && selectedItems.includes(b)) {
        //     return 1;
        //   }
        //   return 0;
        // });
        filteredItems.sort((a, b) => {
          if (typeof a === 'object' && typeof b === 'object' && filterKey) {
            const aValue = a[filterKey] ? a[filterKey] : '';
            const bValue = b[filterKey] ? b[filterKey] : '';
            if (selectedItems.some(i => i[filterKey] === aValue) && !selectedItems.some(i => i[filterKey] === bValue)) {
              return -1;
            }
            if (!selectedItems.some(i => i[filterKey] === aValue) && selectedItems.some(i => i[filterKey] === bValue)) {
              return 1;
            }
          } else {
            if (selectedItems.includes(a) && !selectedItems.includes(b)) {
              return -1;
            }
            if (!selectedItems.includes(a) && selectedItems.includes(b)) {
              return 1;
            }
          }
          return 0;
        });
      
        const handleSelect = (item) => {
          if (filterKey) {

            if (selectedItems.some(i => i[filterKey] === item[filterKey])) {
              setSelectedItems(selectedItems.filter(i => i[filterKey] !== item[filterKey]));
            } else {
              setSelectedItems([...selectedItems, item]);
            }
            // setFilters(label, item);
          } else {
            if (selectedItems.includes(item)) {
              setSelectedItems(selectedItems.filter((i) => i !== item));
            } else {
              setSelectedItems([...selectedItems, item]);
            }
          }
          if(handleFilterChange){
            handleFilterChange(label, item, setFilters);
          }
        };

        const handleSingleSelect = (label, value) => {
          setQuery(value.companyName);
          setSelectedItem(value.companyName);
          handleSingleSelectChange(label, value);
        };
      
        const isItemSelected = (item) => {
          if (filterKey) {
            return selectedItems.some(i => i[filterKey] === item[filterKey]);
          } else {
            return selectedItems.includes(item);
          }
        };
        const handleOtherChange = (e) => {
          setOtherValue(e.target.value);
        };

        const handleOtherSubmit = () => {
          // handleSelect(otherValue);
          setLocalItems(prevItems => [...prevItems, otherValue]);
          setOtherValue('');
        };
      
        // const displayValue = selectedItems.map((i) => i.name).join(', ');
        const formattedLabel = altLabel ? altLabel : label.charAt(0).toUpperCase() + label.slice(1);
        const otherFieldLength = customMaxLength ? customMaxLength : 25;
      
        return (
          <>
          {singleSelect && (
            <Combobox as="div" value={selectedItem} onChange={setSelectedItem}>
              <Combobox.Label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">{label}</Combobox.Label>
              <div className="tw-relative tw-mt-2">
                <Combobox.Input
                  className="tw-w-full tw-rounded-md tw-border-0 tw-bg-white tw-py-1.5 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                  onChange={(event) => {
                    setQuery(event.target.value);
                    localUpdate(event.target.value);
                  }}
                  displayValue={(item) => item?.companyName}
                />
                <Combobox.Button className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-rounded-r-md tw-px-2 tw-focus:tw-outline-none">
                  <ChevronUpDownIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                </Combobox.Button>

                {filteredItems.length > 0 && (
                  <Combobox.Options className="tw-absolute tw-z-10 tw-mt-1 tw-max-h-60 tw-w-full tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1 tw-text-base tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-focus:tw-outline-none sm:tw-text-sm">
                    {filteredItems.map((item, index) => (
                      <Combobox.Option
                        key={item.id}
                        value={item}
                        onClick={() => handleSingleSelect(label, item)}
                        className={({ active }) =>
                          classNames(
                            'tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-3 tw-pr-9',
                            active ? 'tw-bg-indigo-600 tw-text-white' : 'tw-text-gray-900'
                          )
                        }
                      >
                        {({ active, selected }) => (
                          <>
                            <span className={classNames('tw-block tw-truncate', selected && 'tw-font-semibold')}>{item.companyName}</span>

                            {selected && (
                              <span
                                className={classNames(
                                  'tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-4',
                                  active ? 'tw-text-white' : 'tw-text-indigo-600'
                                )}
                              >
                                <CheckIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                              </span>
                            )}
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
              </div>
            </Combobox>
          )}
          {!singleSelect && (
            <div ref={containerRef} className="tw-relative tw-mt-2">
              <div className="tw-flex tw-items-center tw-rounded-md tw-border-0 tw-bg-white tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-indigo-600">
                <input
                  type="text"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder={formattedLabel}
                  className="tw-flex-1 tw-py-1.5 tw-pl-3 tw-pr-0 tw-text-gray-900 sm:tw-text-sm sm:tw-leading-6"
                  onClick={() => setShowOptions(true)}
                />
                <button className="tw-px-2 tw-focus:outline-none" 
                onClick={(e) => {e.preventDefault(); setShowOptions(!showOptions);}}
                >
                  <ChevronUpDownIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                </button>
              </div>
              {showOptions && (
                <ul className="tw-absolute tw-z-10 tw-mt-1 tw-max-h-60 tw-w-full tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1 tw-text-base tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-focus:outline-none sm:tw-text-sm">
                  {filteredItems.length > 0 ? (
                    filteredItems.map((item, index) => (
                      <li
                        key={`${filterKey ? item[filterKey] : item}-${index}`}
                        className={`tw-flex tw-items-center tw-cursor-default tw-select-none tw-py-2 tw-pl-8 tw-pr-4 ${isItemSelected(item) ? 'tw-bg-indigo-600 tw-text-white' : 'tw-text-gray-900'}`}
                        onClick={() => handleSelect(item)}
                      >
                        <input type="checkbox" checked={isItemSelected(item)} readOnly className="tw-mr-2" />
                        <span className={`tw-block tw-truncate tw-flex-1`}>{filterKey ? item[filterKey] : item}</span>
                      </li>
                    ))
                  ) : 
                  (!hideOwnValue && (
                    <li className="">
                      <input
                        type="text"
                        value={otherValue}
                        onChange={handleOtherChange}
                        placeholder="Enter your own value"
                        className="tw-flex-1 tw-py-1.5 tw-pl-3 tw-pr-0 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                        maxLength={otherFieldLength}
                        onBlur={() => handleMultiFormInputBlur(label, otherValue, currentFormErrorFunction)}
                      />
                      <button className="tw-px-2 tw-bg-indigo-600 tw-text-white tw-rounded focus:tw-outline-none tw-ml-5" 
                        onClick={(event) => {
                          event.preventDefault();
                          handleOtherSubmit();
                        }}
                        disabled={currentFormErrors && currentFormErrors[label] ? currentFormErrors[label] !== "" : false}
                      >
                        Add
                      </button>
                      {currentFormErrors && currentFormErrors[label] && (
                        <p className="tw-text-red-500 tw-text-xs tw-pt-2">{currentFormErrors[label]}</p>
                      )}
                    </li>
                  ))}
                 
                </ul>
              )}
             
            </div>
          )}
</>
        );
      }