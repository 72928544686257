export const memberInClubs = (clubs = [], userId) => {
  const memberInClubs = clubs && clubs.length > 0 ? clubs.filter(club => club.memberIds && club.memberIds.includes(userId))
    .map(club => ({
      shortName: club.shortName || '',
      id: club.id || ''
    }))
    .sort((a, b) => (a.shortName || '').localeCompare(b.shortName || '')) : [];

  return memberInClubs;
};
