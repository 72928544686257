import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './tailwind.css';
import './theme.js';
import ReactGA from 'react-ga4';

ReactGA.initialize('GA4_MEASUREMENT_ID');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// function sendToAnalytics(metric) {
//   const { id, name, value } = metric;
//   ReactGA.event({
//       category: "Web Vitals",
//       action: name,
//       label: id,
//       value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
//       nonInteraction: true, // indicates that the event was not initiated by a user action
//   });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
