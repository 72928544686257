import { useUser } from "../contexts/UserContext";


export const useChaiEditSubmit = () => {
    const { 
      userCompanies, 
      userContacts, 
      createUserContact, 
      myChaiTab, 
      setChaiFormLoading,
      setChaiPopUpOpen,
      updateUserCompany,
      updateUserContact
    } = useUser();
  
    const handleChaiSubmit = async (type, event, formState, id, filters) => {
        event.preventDefault();
        setChaiFormLoading(true);
        const formData = new FormData();
        if(type === "Companies") {
          console.log(formState)
            Object.keys(formState).forEach(key => {
                if (typeof formState[key] === 'object' && formState[key] !== null && !(formState[key] instanceof File)) {
                  // Stringify object fields
                  formData.append(key, JSON.stringify(formState[key]));
                } else {
                    // Append non-object fields as is
                  formData.append(key, formState[key] || "");
                }
              });
              Object.keys(filters).forEach(key => {
                if (typeof filters[key] === 'object' && filters[key] !== null && !(filters[key] instanceof File)) {
                  // Stringify object fields
                  formData.append(key, JSON.stringify(filters[key]));
                } else {
                    // Append non-object fields as is
                  formData.append(key, filters[key] || "");
                }
              });
            if(!formState["companyName"]) {
                setChaiFormLoading(false);
                return alert("Name is required");
            }
            try {
                const newCompany = await updateUserCompany(formData, id);
                setChaiPopUpOpen(false);
                setChaiFormLoading(false);
                return newCompany;
            } catch (error) {
                // Handle errors
                console.error('Error occurred while creating company:', error);
                setChaiFormLoading(false);
            }
        } else if(type === "Contacts") {
            if(formState["Company"] && !formState["company"]) {
                const company = {
                    companyName: formState["Company"],
                    id: "",
                    notes: ""
                };
                formData.append("company", JSON.stringify(company));
                formData.append("createNewCompany", true);
            }
            Object.keys(formState).forEach(key => {
                if (typeof formState[key] === 'object' && formState[key] !== null && !(formState[key] instanceof File)) {
                  // Stringify object fields
                  formData.append(key, JSON.stringify(formState[key]));
                } else {
                    // Append non-object fields as is
                  formData.append(key, formState[key] || "");
                }
              });
              Object.keys(filters).forEach(key => {
                if (typeof filters[key] === 'object' && filters[key] !== null && !(filters[key] instanceof File)) {
                  // Stringify object fields
                  formData.append(key, JSON.stringify(filters[key]));
                } else {
                    // Append non-object fields as is
                  formData.append(key, filters[key] || "");
                }
              });
            if(!formState["contactName"]) {
                setChaiFormLoading(false);
                return alert("Name is required");
            }
            try {
                const newContact = await updateUserContact(formData, id);
                setChaiPopUpOpen(false);
                setChaiFormLoading(false);
                return newContact;
            } catch (error) {
                console.error('Error occurred while creating contact:', error);
                setChaiFormLoading(false);
            }
        } 
    };
  
    return handleChaiSubmit;
  };