import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../../firebase/firebase';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useSchoolContext = () => {
  const [clubs, setClubs] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [schoolCompetitionData, setSchoolCompetitionData] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [schoolLoaded, setSchoolLoaded] = useState(false);
  const [schoolTab, setSchoolTab] = useState('Clubs');
  const [schoolSearchTerm, setSchoolSearchTerm] = useState('');
  const handleSchoolTabClick = tabName => setSchoolTab(tabName);
  const [competitionFilters, setCompetitionFilters] = useState({
    // "professionalEventsAttended": [] 
  });
  const [competitionSearchTexts, setCompetitionSearchTexts] = React.useState({
    // 'schoolFiveYearGoals': '',
    // 'interestsHobbies': '',
    // 'recruitingFor': '',
    // 'whoIWantToMeet': '',
    // 'whyIAmAttending': ''
  });
  const [competitionSearchTerm, setCompetitionSearchTerm] = useState('');


  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, async (incomingUser) => {
      if (!incomingUser) return; // Exit early if user is not logged in
      
      try {
        const userId = incomingUser.uid;
        const idToken = await incomingUser.getIdToken(true);
        Promise.all([
          axios.get(`${API_BASE_URL}/school-data`, {
            headers: { 'Authorization': `Bearer ${idToken}` },
            params: { uid: userId }
          })
        ]).then(([schoolDataResponse]) => {
          const { clubs, organizations } = schoolDataResponse.data;
          setClubs(clubs);
          setOrganizations(organizations);
        }).catch(error => {
          console.error("Error fetching data:", error);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
    return unsubscribe; // Simplify cleanup
  }, []);

  const clearCompetitionFilters = () => {
    setCompetitionFilters({
    });
  };

  const getPendingMembers = async (clubId) => {
    const auth = getAuth(app);
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.get(`${API_BASE_URL}/get-pending-members`, {
        params: { clubId },
        headers: { 'Authorization': `Bearer ${idToken}` }
      });
      const { pendingMembers } = response.data;
      // Handle success response
      // Update the pending members for the club
      setClubs(prevClubs => {
        return prevClubs.map(club => 
          club.id === clubId ? {...club, pendingMembers: pendingMembers} : club
        );
      });
      return pendingMembers;
    } catch (error) {
      // Handle errors
      console.error('Error fetching pending members:', error);
      // throw error;
    }
  };

  const approveMember = async (clubId, userId) => {
    const auth = getAuth(app);
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.put(`${API_BASE_URL}/approve-member`, {
        clubId,
        userId
      }, {
        headers: { 
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      });
      const updatedClub = response.data;
      // Handle success response
      // Update the club with the updatedClub object
      setClubs(prevClubs => {
        return prevClubs.map(club => 
          club.id === clubId ? updatedClub : club
        );
      });
      return updatedClub;
    } catch (error) {
      // Handle errors
      console.error('Error approving member:', error);
      // throw error;
    }
  };

  const rejectMember = async (clubId, userId) => {
    const auth = getAuth(app);
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.put(`${API_BASE_URL}/reject-member`, {
        clubId,
        userId
      }, {
        headers: { 
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      });
      const { data } = response.data;
      // Handle success response
      // Update the pending members for the club
      setClubs(prevClubs => {
        return prevClubs.map(club => 
          club.id === clubId ? {...club, pendingMembers: club.pendingMembers.filter(member => member.id !== userId)} : club
        );
      });
      return data;
    } catch (error) {
      // Handle errors
      console.error('Error rejecting member:', error);
      // throw error;
    }
  };

  const promoteMember = async (clubId, userId) => {
    const auth = getAuth(app);
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.put(`${API_BASE_URL}/promote-member`, {
        clubId,
        userId
      }, {
        headers: { 
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      });
      const updatedClub = response.data;
      // Handle success response
      // Update the club with the updatedClub object, promoting the member to a leadership position
      setClubs(prevClubs => {
        const updatedClubs = prevClubs.map(club => 
          club.id === clubId ? updatedClub : club
        );
        return updatedClubs;
      });
      return updatedClub;
    } catch (error) {
      // Handle errors
      console.error('Error promoting member:', error);
      // throw error;
    }
  };



  return {
    schoolLoaded, 
    setSchoolLoaded,
    schoolTab, 
    setSchoolTab,
    handleSchoolTabClick,
    schoolSearchTerm, 
    setSchoolSearchTerm,
    clubs,
    setClubs,
    getPendingMembers,
    approveMember,
    rejectMember,
    promoteMember,
    organizations,
    setOrganizations,
    competitions, 
    setCompetitions,
    schoolCompetitionData, 
    setSchoolCompetitionData,
    competitionFilters, 
    setCompetitionFilters,
    competitionSearchTerm, 
    setCompetitionSearchTerm,
    clearCompetitionFilters
  };
};
