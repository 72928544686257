import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import Loading from '../../components/Loading';
import { colorArray, formatDate } from '../../utils/helperFunctions';

export default function CompetitionFreelancer() {
  const { events, directory, user, organizations, competitions, 
    userInfo,
  } = useUser();
  const [competitionFreelancerData, setCompetitionFreelancerData] = useState({});
  const [activeDetailTab, setActiveDetailTab] = "info"
//   const [popUpOpen, setPopUpOpen] = useState(false);
//   const [attendeeFormLoading, setAttendeeFormLoading] = useState(false)
//   const [formState, setFormState] = React.useState({});
  let { competitionId, freelancerId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (competitions) {
      const competition = competitions.find(comp => comp.id === competitionId);
      if (competition) {
        const freelancer = competition.freelancerMap[freelancerId];
        setCompetitionFreelancerData(freelancer);
      }
    }
  }, [competitions, competitionId, freelancerId]);

  if (!competitionFreelancerData || !user) {
    return ;
    // <Loading />;
  }

//   const handleChange = (event) => {
//     const { name, value, type, checked } = event.target;

//     if (type === 'checkbox') {
//       setFormState(prevState => {
//         const previousValue = prevState[name];
//         const newValue = checked ? [...previousValue, value] : previousValue.filter(v => v !== value);

//         return {
//           ...prevState,
//           [name]: newValue
//         };
//       });
//     } else {
//       setFormState(prevState => ({
//         ...prevState,
//         [name]: value
//       }));
//     }
//   };

//   const handleAttendProfessionalEvent = async (event) => {
//     event.preventDefault();
//     setAttendeeFormLoading(true);
//     const formData = new FormData();
//     Object.keys(formState).forEach(key => {
//       if (typeof formState[key] === 'object' && formState[key] !== null && !(formState[key] instanceof File)) {
//         // Stringify object fields
//         formData.append(key, JSON.stringify(formState[key]));
//       } else {
//           // Append non-object fields as is
//         formData.append(key, formState[key]);
//       }
//     });
//     formData.append('userId', user.uid);
//     formData.append('eventId', id);
//     let userName = '';
//     if (userInfo && userInfo.name) {
//       userName = userInfo.name;
//       formData.append('userName', userName);
//     }
//     let userProfilePhoto = '';
//     if (userInfo && userInfo.profilePhoto) {
//       userProfilePhoto = userInfo.profilePhoto;
//       formData.append('userProfilePhoto', userProfilePhoto);
//     }
   
   
   
//         try {
//             const attendeeInfo = await attendProfessionalEvent(formData, id, user.uid);
//             setPopUpOpen(false);
//             setFormState({})
//             setAttendeeFormLoading(false);
//         } catch (error) {
//             // Handle errors
//             console.error('Error occurred while attending event:', error);
//             setAttendeeFormLoading(false);
//             // alert('Failed to update profile.');
//         }
//   };

//   const handleJoinEvent = () => {
//     joinEvent(user.uid, id);
//   };

//   const handleLeaveEvent = () => {
//     leaveEvent(user.uid, id);
//   };

  const {
    background,
    directoryId,
    email,
    freelancerName,
    gradYear,
    interestSectors,
    freelancerSkills,
    linkedIn,
    school,
    hasJoined
  } = competitionFreelancerData;


  const numberOfSectors = interestSectors ? Object.keys(interestSectors).length : 0;
  

  return (
    <>
        <button className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-rounded tw-px-4 tw-py-2" onClick={() => navigate(-1)}>Back</button>
      <div className="tw-px-4 sm:tw-px-0">
        <h3 className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">{freelancerName}</h3>
        <p className="tw-mt-4">{background}</p>
      </div>
      <div className="tw-mt-6 tw-pb-6">
        <dl className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-4">
          <div className={`tw-grid tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-4 lg:tw-grid-cols-5 xl:tw-grid-cols-6 2xl:tw-grid-cols-7 tw-gap-4 tw-col-span-full tw-mt-6`}>
            <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Interest Sectors</dt>
                <dd className="tw-text-sm tw-leading-6 tw-text-gray-700">
                {interestSectors && Object.keys(interestSectors).map((sectorKey, index) =>  {
                    const selectedColor = colorArray[index % 7];
                    const sector = interestSectors[sectorKey];
                    return (
                    <div key={`${sector}-${index}`} className={`tw-flex tw-flex-col tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2`}>
                        {sector}
                    </div>
                    )
                })}
                </dd>
          </div>
          <div className={`tw-grid tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-4 lg:tw-grid-cols-5 xl:tw-grid-cols-6 2xl:tw-grid-cols-7 tw-gap-4 tw-col-span-full tw-mt-6`}>
            <div
            className="tw-rounded-md 
            tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-gray-900 tw-ring-1 
            tw-ring-inset tw-ring-gray-200 tw-col-span-full tw-cursor-pointer">
            <dd className="tw-text-sm tw-leading-6 tw-text-gray-700 tw-col-span-full">
              <div
                className="tw-text-sm tw-leading-6 tw-text-gray-700 tw-col-span-full"
                // onClick={() => navigate(`/directory/${attendee.id}/${id}`)}
              >
              </div>
            </dd>
            {email && 
              <div className="tw-text-sm tw-leading-6 tw-text-gray-700 tw-col-span-full">
                <div><strong>Email:</strong> <a href={`mailto:${email}`} className="tw-text-blue-500 hover:tw-text-blue-700">{email}</a></div>
              </div>
            }
            {school && 
              <div className="tw-text-sm tw-leading-6 tw-text-gray-700 tw-col-span-full">
                <div><strong>School:</strong> {school}</div>
              </div>
            }
            {gradYear && 
              <div className="tw-text-sm tw-leading-6 tw-text-gray-700 tw-col-span-full">
                <div><strong>Grad Year:</strong> {gradYear}</div>
              </div>
            }
            {linkedIn && 
              <div className="tw-text-sm tw-leading-6 tw-text-gray-700 tw-col-span-full">
                <div><strong>LinkedIn:</strong> <a href={linkedIn} target="_blank" rel="noopener noreferrer" className="tw-text-blue-500 hover:tw-text-blue-700">{linkedIn}</a></div>
              </div>
            }
            </div>
          </div>
          <div className={`tw-grid tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-4 lg:tw-grid-cols-5 xl:tw-grid-cols-6 2xl:tw-grid-cols-7 tw-gap-4 tw-col-span-full tw-mt-6`}>
            <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Skills</dt>
                <dd className="tw-text-sm tw-leading-6 tw-text-gray-700">
                {freelancerSkills && Object.keys(freelancerSkills).map((freelancerSkill, index) =>  {
                    const selectedColor = colorArray[(numberOfSectors + index) % 7];
                    const skill = freelancerSkills[freelancerSkill];
                    return (
                    <div key={`${skill}-${index}`} className={`tw-flex tw-flex-col tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2`}>
                        {skill}
                    </div>
                    )
                })}
                </dd>
          </div>
          {hasJoined && directoryId && 
            <div className="tw-col-span-full tw-text-left tw-mt-4">
              <button
                className="tw-px-4 tw-py-2 tw-bg-green-500 tw-text-white tw-font-bold tw-rounded hover:tw-bg-blue-700 tw-transition-colors"
                onClick={() => navigate(`/directory/${directoryId}`)}
              >
                More Info
              </button>
            </div>
}
        </dl>
      </div>
    </>
  );
}
