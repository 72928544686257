import React, { useState, useEffect, useRef } from 'react';
import { useUser } from '../../../contexts/UserContext';
import FilterPopup from '../../FilterPopup';
import { getUniqueValues, uniqueFilterValues, useNavigateToDirectory, handleFilterChange } from '../../../utils/helperFunctions';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import MultiComboBoxInput from '../MultiComboBoxInput';
import BrewWorksFilterPopup from './BrewWorksFilterPopup';
import { industryValues, roleValues } from '../../../utils/helperValues';
import { memberInClubs } from '../../../utils/clubHelperFunctions';

export default function BrewWorksFilters({ users }) {
  const { 
    brewWorksFilters: filters,
    setBrewWorksFilters: setFilters,
    clearBrewWorksFilters: clearFilters,
    brewWorksPageSearchTerm,
    setBrewWorksPageSearchTerm,
    brewWorksTab,
    clubs,
    userInfo
  } = useUser();

  const [brewWorksFilterPopupOpen, setBrewWorksFilterPopupOpen] = useState(false);

  const placeHolderText = brewWorksTab === "Jobs" ? "Search by company name, location, and role" : "Search by name, 'sponsors', notes, relationship, networking opp" 
  const handleFilterOpen = () => setBrewWorksFilterPopupOpen(!brewWorksFilterPopupOpen);
  const userId = userInfo?.id;
  const clubMember = [...memberInClubs(clubs, userId), {shortName: "Mine, None, Self", id:""}]
    .sort((a, b) => (a.shortName || '').localeCompare(b.shortName || ''));

  return (
    <>
                <div className="tw-grid tw-grid-cols-4 tw-gap-4 tw-w-full">
                  <input
                    id="search-field"
                    className="tw-block tw-w-full tw-h-9 tw-border tw-border-gray-300 tw-rounded-md  md:tw-pt-0 tw-pb-0 
                    tw-pl-0 tw-pr-0 tw-text-gray-900 tw-placeholder:text-gray-400 
                    sm:tw-text-sm dark:tw-text-white dark:tw-bg-black tw-col-span-3 "
                    placeholder={placeHolderText}
                    value={brewWorksPageSearchTerm}
                    onChange={e => setBrewWorksPageSearchTerm(e.target.value)}  
                    type="search"
                    name="search"
                  />
                  <div className="tw-col-span-1 tw-flex tw-justify-center tw-items-center">
                    <button
                      type="button"
                      className="tw-rounded-full tw-bg-indigo-600 tw-px-4 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
                      onClick={handleFilterOpen}
                    >
                      Filter
                    </button>
                  </div>
                </div>
                <BrewWorksFilterPopup
                 open={brewWorksFilterPopupOpen} 
                 setOpen={setBrewWorksFilterPopupOpen} 
                 filters={filters} 
                 setFilters={setFilters}
                 clearFilters={clearFilters} 
                 handleFilterChange={handleFilterChange}
                 industryItems={industryValues} 
                 roleItems={roleValues}
                 clubMember={clubMember}
                 /> 
      </>
  );
}

