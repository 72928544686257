import React, { useState, useEffect, useRef } from 'react';
import { useUser } from '../contexts/UserContext';
import UserDetailModal from '../components/UserDetailModal';
import FilterPopup from '../components/FilterPopup';
import DirectoryCard from '../components/DirectoryCard';
import { useNavigateToDirectory } from '../utils/helperFunctions';
import Loading from '../components/Loading';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

export default function Directory({ users }) {
  const { 
    directory, 
    getUserDetailsById, 
    directoryFilters: filters, 
    setDirectoryFilters: setFilters, 
    directorySearchTexts: searchTexts,
    setDirectorySearchTexts: setSearchTexts,
    directorySearchTerm,
    setDirectorySearchTerm,
    directoryFilterTerm, 
    setDirectoryFilterTerm,
  } = useUser();

  const dropdownRefs = useRef([]);
//   const dropdownVisibilityRef = useRef(dropdownVisibility);

  const directorySlice = directory ? directory.slice(0,400) : [];

 
  const [dropdownVisibility, setDropdownVisibility] = useState({
    'country-dropdown': false,
    'program-dropdown': false
  }); // New state for dropdown visibility
  const [dropdownStyle, setDropdownStyle] = useState({});
  const [activeDropdown, setActiveDropdown] = useState(null);
  

  const dropdownVisibilityRef = useRef(dropdownVisibility);
  useEffect(() => {
    // Update the ref value whenever dropdownVisibility state changes
    dropdownVisibilityRef.current = dropdownVisibility;
  }, [dropdownVisibility]);
  
  const toggleDropdownVisibility = (id, event) => {
    const rect = event.target.getBoundingClientRect();
    const dropdownWidth = 250; 
    let translateX = rect.left - .2 * rect.left;

    if (rect.left + dropdownWidth > window.innerWidth) {
        translateX = window.innerWidth - dropdownWidth - 20;  // 10 is a small margin
    }

    const translateY = rect.height + .25 * rect.height;  // position dropdown below the button
  
    if (window.innerWidth <= 768) { // Check if the device width is less than or equal to 768px (mobile devices)
        setDropdownStyle({
            transform: `translate3d(${translateX}px, -5px, 0)`, // On mobile devices, position the dropdown at the top of the screen
        });
    } else {
        setDropdownStyle({
            transform: `translate3d(${translateX}px, -25px, 0)`, // On larger screens, position the dropdown as before
        });
    }
  
    setDropdownVisibility(prevState => {
      // Access the current value from the ref instead of the prevState argument
      const wasPreviouslyVisible = dropdownVisibilityRef.current[id];
  
      return {
        [id]: !wasPreviouslyVisible,
      };
    });
  };
  
  useEffect(() => {
    function handleClickOutside(event) {
        const dropdownKeys = Object.keys(dropdownRefs.current);
        const clickedOutsideAllDropdowns = dropdownKeys.every(
            key => !dropdownRefs.current[key].contains(event.target)
        );
        if (clickedOutsideAllDropdowns && activeDropdown) {
            setDropdownVisibility(prevState => ({
                ...prevState,
                [activeDropdown]: false
            }));
            setActiveDropdown(null);
        }
    }
    function handleScroll() {
        const dropdownKeys = Object.keys(dropdownRefs.current);
        dropdownKeys.forEach(key => {
            const dropdownElement = dropdownRefs.current[key];
            if (dropdownElement) {
                const triggerButton = document.querySelector(`[aria-controls="${dropdownElement.id}"]`);
                if (triggerButton) {
                    const rect = triggerButton.getBoundingClientRect();
                    const dropdownWidth = dropdownElement.offsetWidth;
                    let translateX = rect.left - 0.2 * rect.left;
                    if (rect.left + dropdownWidth > window.innerWidth) {
                        translateX = window.innerWidth - dropdownWidth - 20; // Adjust for margin
                    }
                    const translateY = rect.bottom + window.pageYOffset; // Adjust for vertical scroll
                    dropdownElement.style.transform = `translate3d(${translateX}px, ${translateY}px, 0)`;
                }
            }
        });
    }

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScroll, { passive: true });

    // Cleanup function
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        window.removeEventListener('scroll', handleScroll);
    };
}, [activeDropdown, setDropdownVisibility, setActiveDropdown]);
  

  const handleSearchTextChange = (filterKey, newSearchText) => {
    setSearchTexts(prevTexts => ({
      ...prevTexts,
      [filterKey]: newSearchText
    }));
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => {
      const previousValue = prevFilters[filterName];
      const isArray = Array.isArray(previousValue);
      const includesValue = isArray && previousValue.includes(value);
  
      let newValue;
      if (includesValue) {
        newValue = previousValue.filter(v => v !== value);
      } else if (isArray) {
        newValue = [...previousValue, value];
      } else {
        newValue = [value];
      }
  
      // If the new value is not empty, remove the empty string from the array
      if (value !== '') {
        newValue = newValue.filter(v => v !== '');
      }
  
      // If the new value array is empty, add the empty string back to the array
      if (newValue.length === 0) {
        newValue = [''];
      }
  
      return {
        ...prevFilters,
        [filterName]: newValue
      };
    });
  };

  const filteredDirectory = React.useMemo(() => {
    return directorySlice.filter(user => {
        const searchTermLower = directoryFilterTerm.toLowerCase();
        // Define the fields you want to search through
        const searchableFields = ['name', 'howYouCanHelp', 'program', 'currentGoals','schoolFiveYearGoals', 'interestsHobbies','recruitingFor', 'country'];

         // Check if any of the fields include the search term
        const fieldMatches = searchableFields.some(field => {
          if (Array.isArray(user[field])) {
            return user[field].some(item => item.toLowerCase().includes(searchTermLower));
          } else if (typeof user[field] === 'string') {
            return user[field].toLowerCase().includes(searchTermLower);
          }
          return false;
        });

    //   // Check if the user's name includes the search term
    //   const nameMatches = user.name.toLowerCase().includes(searchTerm.toLowerCase());
  
      // Check if the user matches the filters
      const filtersMatch = Object.keys(filters).every(key => {
        const filterValues = filters[key];
        if (!filterValues || filterValues.length === 0 || filterValues[0] === '') return true;
       
        if(key === 'country') {
          return user['countryMap'] && Object.values(user['countryMap']).some(value => filters[key].includes(value));
        } else if (key === 'interestsHobbies') {
          return user['interestsMap'] && Object.values(user['interestsMap']).some(value => filters[key].includes(value));
        } 
        // else if (key === 'program') {
        //   return 0;
        // } 
        else if (key === 'recruitingFor') {
          return user['industryMap'] && Object.values(user['industryMap']).some(value => filters[key].includes(value));
        } else if (Array.isArray(filterValues)) {
          return filterValues.some(filterValue => user[key] && user[key].includes(filterValue));
        }
        // if (key === 'recruitingFor' || key === 'interestsHobbies') {
        //   return user[key] && user[key].includes(filters[key]);
        // }
        return user[key] === filters[key];
      });
  
      // Return true if the user matches both the search term and the filters
      return fieldMatches && filtersMatch && user.display !== false;
    });
  }, [directorySlice, filters, directoryFilterTerm]).sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const uniqueFilterValues = (filterKey) => {
    let values = directorySlice.flatMap((user, index) => user[filterKey] ? user[filterKey] : []);
    values = [...new Set(values)]; // remove duplicates
    if (searchTexts[filterKey] && searchTexts[filterKey].trim() !== '') {
      values = values.filter(value => 
          value && 
          typeof value === 'string' && 
          typeof searchTexts[filterKey] === 'string' && 
          value.toLowerCase().includes(searchTexts[filterKey].toLowerCase())
        ); // filter based on search text only if search text is not empty
    }
    values.sort((a, b) => { // sort alphabetically, considering emojis
      const aEmojiRemoved = a.replace(/^[^\p{L}\p{N}]+/u, '');
      const bEmojiRemoved = b.replace(/^[^\p{L}\p{N}]+/u, '');
      return aEmojiRemoved.localeCompare(bEmojiRemoved);
    });
    return values;
  };

  const navigateToDirectory = useNavigateToDirectory();

  let header = "Filter your results above"
  // let header = "Filter your results aboveYour Comprehensive Directory of Students, Faculty, Staff, and Alumni"
  if(directory.length < 2){
    header = "Click the 'Search' Button to begin browsing the community"
  }


  return (
  <>
  <h1 className="tw-text-2xl tw-font-semibold tw-mb-4">{header}</h1>

    <div className="directory">
      {/* <div className="directory__filter-container">
        <button
          className="directory__filter-button"
          onClick={(event) => toggleDropdownVisibility('search-dropdown', event)}
          aria-haspopup="true"
          aria-expanded={dropdownVisibility['search-dropdown']}
        >
          <i className="fas fa-search"></i>
        </button>
        <button
          className="directory__filter-button"
          onClick={(event) => toggleDropdownVisibility('program-dropdown', event)}
          aria-haspopup="true"
          aria-expanded={dropdownVisibility['program-dropdown']}
        >
          Program
        </button>
        <button
          className="directory__filter-button"
          onClick={(event) => toggleDropdownVisibility('professional-dropdown', event)}
          aria-haspopup="true"
          aria-expanded={dropdownVisibility['professional-dropdown']}
        >
          Professional Events Attended
        </button>
        <button
          className="directory__filter-button"
          onClick={(event) => toggleDropdownVisibility('interests-dropdown', event)}
          aria-haspopup="true"
          aria-expanded={dropdownVisibility['interests-dropdown']}
        >
          Interests & Hobbies
        </button>
        <button
          className="directory__filter-button"
          onClick={(event) => toggleDropdownVisibility('recruiting-dropdown', event)}
          aria-haspopup="true"
          aria-expanded={dropdownVisibility['recruiting-dropdown']}
        >
          Recruiting For / Working In
        </button>
        <button
          className="directory__filter-button"
          onClick={(event) => toggleDropdownVisibility('country-dropdown', event)}
          aria-haspopup="true"
          aria-expanded={dropdownVisibility['country-dropdown']}
        >
          Country
        </button>
      </div> */}
      <div
          className={`directory__filter-list directory__filter-list--top directory__filter-list--search-filter ${dropdownVisibility['search-dropdown'] ? 'visible' : 'hidden'}`}
          style={dropdownStyle}
        >
          <input 
            type="text" 
            placeholder="Search by name and more" 
            value={directorySearchTerm} 
            onChange={e => setDirectorySearchTerm(e.target.value)} 
            className="directory__filter-search"
          />
        </div>
      <div
          className={`directory__filter-list directory__filter-list--top ${dropdownVisibility['program-dropdown'] ? 'visible' : 'hidden'}`}
          style={dropdownStyle}
        >
          <FilterPopup  
                filterKey='program'
                values={uniqueFilterValues('program')}
                handleFilterChange={handleFilterChange}
                selectedValues={filters.program}
                searchText={searchTexts.program}
                onSearchTextChange={handleSearchTextChange}
                ref={element => dropdownRefs.current['program-dropdown'] = element}
            />
        </div>
        <div
          className={`directory__filter-list directory__filter-list--top ${dropdownVisibility['country-dropdown'] ? 'visible' : 'hidden'}`}
          style={dropdownStyle}
        >
          <FilterPopup  
                filterKey='country'
                values={uniqueFilterValues('country')}
                handleFilterChange={handleFilterChange}
                selectedValues={filters.country}
                searchText={searchTexts.country}
                onSearchTextChange={handleSearchTextChange}
                ref={element => dropdownRefs.current['country-dropdown'] = element}
            />
        </div>
        <div
          className={`directory__filter-list directory__filter-list--top ${dropdownVisibility['recruiting-dropdown'] ? 'visible' : 'hidden'}`}
          style={dropdownStyle}
        >
          <FilterPopup  
                filterKey='recruitingFor'
                values={uniqueFilterValues('recruitingFor')}
                handleFilterChange={handleFilterChange}
                selectedValues={filters['recruitingFor']}
                searchText={searchTexts.Recruiting}
                onSearchTextChange={handleSearchTextChange}
                ref={element => dropdownRefs.current['recruiting-dropdown'] = element}
            />
        </div>
        <div
          className={`directory__filter-list directory__filter-list--top ${dropdownVisibility['interests-dropdown'] ? 'visible' : 'hidden'}`}
          style={dropdownStyle}
        >
          <FilterPopup  
                filterKey='interestsHobbies'
                values={uniqueFilterValues('interestsHobbies')}
                handleFilterChange={handleFilterChange}
                selectedValues={filters['interestsHobbies']}
                searchText={searchTexts.Recruiting}
                onSearchTextChange={handleSearchTextChange}
                ref={element => dropdownRefs.current['interests-dropdown'] = element}
            />
        </div>
        <div
          className={`directory__filter-list directory__filter-list--top ${dropdownVisibility['professional-dropdown'] ? 'visible' : 'hidden'}`}
          style={dropdownStyle}
        >
          <FilterPopup  
                filterKey='professionalEventsAttended'
                values={uniqueFilterValues('professionalEventsAttended')}
                handleFilterChange={handleFilterChange}
                selectedValues={filters['professionalEventsAttended']}
                searchText={searchTexts.Recruiting}
                onSearchTextChange={handleSearchTextChange}
                ref={element => dropdownRefs.current['professional-dropdown'] = element}
            />
        </div>




        {/* <ul role="list"> */}
        <ul role="list" className="tw-grid tw-gap-6 tw-grid-cols-2 lg:tw-grid-cols-4">
        {/* {filteredDirectory.map((user) => (
          <DirectoryCard visible={filteredDirectory.includes(user)} user={user} index={user.id} key={user.id} />
        ))} */}
        {/* <TransitionGroup className="tw-grid tw-grid-cols-1 tw-gap-6 sm:tw-grid-cols-2 lg:tw-grid-cols-3"> */}
          {filteredDirectory.map((user) => (
            // <CSSTransition key={user.id} timeout={400} classNames="item">
              <DirectoryCard visible={filteredDirectory.includes(user)} user={user} index={user.id} key={user.id} />
            // </CSSTransition>
          ))}
        {/* </TransitionGroup> */}
      </ul>
    </div>
    </>
  );
}
