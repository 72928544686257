import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import MultiComboBoxInput from '../../filters/MultiComboBoxInput'


export default function AddRecruitingTeam({ 
    handleChange,
    // handleSingleChange, 
    formState, recruitingTeams = [],
    popUpOpen, setPopUpOpen,
    handleSubmit, loading, 
    addRecruitingType, setRecruitingType, setShareContactWith, shareContactWith,
    leaderInClubs, addRecruitingTeam, setAddRecruitingTeam, clubMembers = [], 
    handleSelectChange, handleMemberChange,
    activeClubTeams = [], activeClubAllTeams = [],
}) {
    //Add Recruiting Team or Member
    //Add Recruiting Type {Fulltime, Internships}
    const activeClubMembers = clubMembers.find(member => member.clubId === shareContactWith)?.members
    .filter(member => 
        !activeClubAllTeams.some(team => team.people?.some(person => person.id === member.id))
    ) || [];
    // console.log(activeClubTeams)
    // console.log(activeClubMembers,'acm')
  return (
    <Transition.Root show={popUpOpen} as={Fragment}>
      <Dialog as="div" className="tw-relative tw-z-10" onClose={setPopUpOpen}>
        <Transition.Child
          as={Fragment}
          enter="tw-ease-out tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in tw-duration-200"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
        </Transition.Child>

        <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
          <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
            <Transition.Child
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
              enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            >
              <Dialog.Panel className="tw-relative tw-transform tw-overflow-auto tw-rounded-lg 
              tw-bg-white tw-px-4 tw-pb-4 tw-pt-10 tw-text-left tw-shadow-xl tw-transition-all sm:tw-mb-8 sm:tw-w-full 
              sm:tw-max-w-sm sm:tw-p-6 tw-mt-20">
                <form>
                    {/* <div className="tw-mb-4">
                    <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="shareJob">
                      Add to Club: <span className="required-star">*</span>
                    </label>
                    <select 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                      value={shareContactWith}
                      name="shareContactWith" 
                      onChange={e => setShareContactWith(e.target.value)} 
                      required>
                        <option value={""}>{""}</option>
                      {leaderInClubs.map(club => (
                        <option key={club.id} value={club.id}>{club.shortName}</option>
                      ))}
                    </select>
                  </div> */}
                    <div className="tw-mb-4">
                    <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2">
                      Recruiting Type
                    </label>
                    <select 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                      value={addRecruitingType} 
                      onChange={(e) => setRecruitingType(e.target.value)} 
                      name="recruitingType">
                      <option value="Fulltime">Fulltime</option>
                      <option value="Internship">Internship</option>
                    </select>
                  </div>
                  <div className="tw-mb-4">
                    <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2">
                      What to add?
                    </label>
                    <select 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                      value={addRecruitingTeam} 
                      onChange={(e) => setAddRecruitingTeam(e.target.value)} 
                      name="addType">
                      <option value="Team">Add a Team</option>
                      <option value="Member">Add a Member</option>
                      {/* {activeClubMembers.length > 0 && (
                        <option value="Member">Add a Member</option>
                      )} */}
                    </select>
                  </div>
                  {addRecruitingTeam === "Team" && (
                    <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2">
                        Team Name
                      </label>
                      <input 
                        className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                        name="teamName" 
                        type="text" 
                        value={formState.teamName || ''} 
                        onChange={handleChange} 
                        required 
                      />
                    </div>
                  )}
                  {addRecruitingTeam === "Member" && (
                    <>
                    <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2">
                        Select Team
                      </label>
                      {/* Make this is filtered properly */}
                      <select 
                        className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                        name="teamSelection" 
                        value={formState.teamSelection || ''} 
                        onChange={handleChange} 
                        required
                      >
                        <option value="">Select a Team</option>
                        {activeClubTeams.map((team) => (
                          <option key={team.teamId} value={team.teamId}>{team.name}</option>
                        ))}
                      </select>
                    </div>
                    {activeClubMembers.length > 0 && (
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2">
                          Select Member
                        </label>
                        {console.log(formState)}
                        <select 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                          name="teamMemberFull" 
                          value={formState.teamMemberFull || ''} 
                          onChange={handleMemberChange} 
                          required
                        >
                          <option value="">Select a Member</option>
                          {activeClubMembers.map((member) => (
                            <option key={member.id} value={`${member.id},${member.name}`}>{member.name}</option>
                          ))}
                        </select>
                      </div>
                    )}
                    </>
                  )}
                  
                  {addRecruitingType === "Team" && (
                     <>
                     <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="teamName">
                        Team Name
                      </label>
                      <input 
                        className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                        name="teamName" type="text" 
                        value={formState.teamName || ''}
                        placeholder="Enter Team Name" onChange={handleChange} required />
                    </div>
                    <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="teamType">
                        Team Type
                      </label>
                      <input 
                        className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                        name="teamType" type="text" 
                        value={formState.teamType || ''}
                        placeholder="Enter Team Type" onChange={handleChange} required />
                    </div>
                     </>
                  )}
                  <div className="tw-mb-4">
                    <button 
                      className="tw-inline-flex tw-w-full tw-justify-center tw-bg-blue-500 tw-hover:bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
                      disabled={loading || shareContactWith === "" || shareContactWith === "myself" ||
                      (addRecruitingTeam === "Team" && formState.teamName === "") ||
                      (addRecruitingTeam === "Member" && (formState.teamSelection === "" || formState.teamMemberName === ""))} 
                      onClick={handleSubmit}>
                      Submit
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}









