import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import Loading from '../components/Loading';
import { colorArray, formatDate } from '../utils/helperFunctions';
import { HeartIcon } from '@heroicons/react/20/solid'
import AttendEvent from '../components/formPopups/events/AttendEventForm';
import AttendEventForm from '../components/formPopups/events/AttendEventForm';

export default function EventDetail() {
  const { events, directory, joinEvent, user, organizations, leaveEvent, 
    activeDetailTab, userInfo, attendProfessionalEvent, clubs,
    setActiveDetailTab, eventData, setEventData,
    eventDetailFilters: filters, 
    eventDetailSearchTerm: searchTerm,
  } = useUser();

  let { id } = useParams();
  const navigate = useNavigate();

  const [popUpOpen, setPopUpOpen] = useState(false);
  const [attendeeFormLoading, setAttendeeFormLoading] = useState(false)

  useEffect(() => {
    if (events) {
      const event = events.find(event => event.id === id);
      setEventData(event);
    }
  }, [events, id]);

  const {
    eventName: Name = '',
    image: eventImage = '',
    neighborhood: Neighborhood = '',
    address = '',
    eventType = '',
    eventLink = '',
    eventDescription = '',
    startTime = '',
    startDate = '',
    organizers = [],
    isOrganization = false,
    attendees = [],
    recommendedAttendees = []
  } = eventData || {};

  // Check if the user is an attendee
  const isUserAttendee = user && user.uid 
    ? (eventType === 'Professional' 
        ? attendees?.[user?.uid] 
        : attendees?.includes(user?.uid))
    : false;

  const initialFormState = useMemo(() => {
    if (eventType === 'Professional' && isUserAttendee && user && user.uid) {
      return {
        whyIAmAttending: attendees[user.uid]?.whyIAmAttending || "",
        whoIWantToMeet: attendees[user.uid]?.whoIWantToMeet || "",
      };
    }
    return {
      whyIAmAttending: "",
      whoIWantToMeet: "",
    };
  }, [eventType, isUserAttendee, attendees, user?.uid]);

  const [formState, setFormState] = useState(initialFormState);

  const attendeeSlice = useMemo(() => {
    if (!eventData || !eventData.attendees) return [];

    return Object.values(eventData.attendees).map(attendee => {
      const directoryUser = directory.find(user => user.id === attendee.userId);
      if (directoryUser) return { ...attendee, ...directoryUser };
      else return {
        name: attendee.userName,
        id: attendee.userId,
        profilePhoto: attendee.userProfilePhoto,
        industries: attendee.industries,
        interests: attendee.interests
      }
      
    });
  }, [eventData, directory]);

  const filteredAttendees = useMemo(() => {
    if (!attendeeSlice) return [];

    return attendeeSlice.filter(user => {
      const searchTermLower = searchTerm.toLowerCase();
      // Define the fields you want to search through
      const searchableFields = ['name', 'howYouCanHelp', 'whoIWantToMeet', 'currentGoals','schoolFiveYearGoals', 'whyIAmAttending','recruitingFor', 'interestsHobbies'];
       // Check if any of the fields include the search term
      const fieldMatches = searchableFields.some(field => {
        if (Array.isArray(user[field])) {
          return user[field].some(item => item.toLowerCase().includes(searchTermLower));
        } else if (typeof user[field] === 'string') {
          return user[field].toLowerCase().includes(searchTermLower);
        }
        return false;
      });

      // Check if the user matches the filters
      const filtersMatch = Object.keys(filters).every(key => {
          const filterValues = filters[key];
          if (!filterValues || filterValues.length === 0 || filterValues[0] === '') return true;
          if (Array.isArray(filterValues)) {
            return filterValues.some(filterValue => user[key] && Object.values(user[key]).includes(filterValue));
          }
          return user[key] === filters[key];
        });

      // Return true if the user matches both the search term and the filters
      return fieldMatches && filtersMatch;
  });
}, [attendeeSlice, filters, searchTerm]); 

if (!eventData || !user) {
  return <Loading />;
}

  const downloadToCalendar = () => {
    // Placeholder for download to calendar logic
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === 'checkbox') {
      setFormState(prevState => {
        const previousValue = prevState[name];
        const newValue = checked ? [...previousValue, value] : previousValue.filter(v => v !== value);

        return {
          ...prevState,
          [name]: newValue
        };
      });
    } else {
      setFormState(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleAttendProfessionalEvent = async (event) => {
    event.preventDefault();
    setAttendeeFormLoading(true);
    const formData = new FormData();
    Object.keys(formState).forEach(key => {
      if (typeof formState[key] === 'object' && formState[key] !== null && !(formState[key] instanceof File)) {
        // Stringify object fields
        formData.append(key, JSON.stringify(formState[key]));
      } else {
          // Append non-object fields as is
        formData.append(key, formState[key]);
      }
    });
    formData.append('userId', user.uid);
    formData.append('eventId', id);
    let userName = '';
    if (userInfo && userInfo.name) {
      userName = userInfo.name;
      formData.append('userName', userName);
    }
    let userProfilePhoto = '';
    if (userInfo && userInfo.profilePhoto) {
      userProfilePhoto = userInfo.profilePhoto;
      formData.append('userProfilePhoto', userProfilePhoto);
    }
    if (userInfo && userInfo.industryMap) {
      if (typeof userInfo.industryMap === 'object' && !Array.isArray(userInfo.industryMap)) {
        formData.append('industries', JSON.stringify(userInfo.industryMap));
      } else {
        console.error('Industries format is not supported');
      }
    }
    if (userInfo && userInfo.interestsMap) {
      if (typeof userInfo.interestsMap === 'object' && !Array.isArray(userInfo.interestsMap)) {
        formData.append('interests', JSON.stringify(userInfo.interestsMap));
      } else {
        console.error('Interests format is not supported');
      }
    }

   
   
   
        try {
            const attendeeInfo = await attendProfessionalEvent(formData, id, user.uid);
            setPopUpOpen(false);
            setFormState({})
            setAttendeeFormLoading(false);
        } catch (error) {
            // Handle errors
            console.error('Error occurred while attending event:', error);
            setAttendeeFormLoading(false);
            // alert('Failed to update profile.');
        }
  };

  const handleJoinEvent = () => {
    joinEvent(user.uid, id);
  };

  const handleLeaveEvent = () => {
    leaveEvent(user.uid, id);
  };



  let favoriteAttendees = [];
  let attendeeDetails = Array.isArray(filteredAttendees) ? filteredAttendees
    .map(attendee => {
      // const user = directory.find(user => user.id === userId);
      return {
        name: attendee.name,
        profilePhoto: attendee.profilePhoto,
        id: attendee.id
      }
    })
    .sort((a, b) => {
      if (!a.name) return 1;
      if (!b.name) return -1;
      return a.name.localeCompare(b.name);
    })
    : typeof filteredAttendees === 'object' ? Object.values(filteredAttendees)
    .map(attendee => {
      return {
        name: attendee.userName,
        profilePhoto: attendee.userProfilePhoto,
        id: attendee.userId
      };
    })
    .sort((a, b) => {
      if (!a.name) return 1;
      if (!b.name) return -1;
      return a.name.localeCompare(b.name);
    })
    : [];

    if (eventData.favorites && eventData.favorites[user.uid]) {
      favoriteAttendees = attendeeDetails.filter(attendee => eventData.favorites[user.uid][attendee.id]);
      attendeeDetails = attendeeDetails.filter(attendee => !eventData.favorites[user.uid][attendee.id]);
    }

    let recommendedAttendeeDetails = [];
    if (recommendedAttendees && Array.isArray(recommendedAttendees[user.uid])) {
      try {
        recommendedAttendeeDetails = recommendedAttendees[user.uid]
          .map(userId => {
            let user = directory.find(user => user.id === userId);

            //NOTE:  If it doesn't find the user in the directory, it will simply user the information from the attendee slice
            if (!user) {
              let foundAttendee = attendeeSlice.find(attendee => attendee.id === userId);
              return {
                name: foundAttendee.name,
                id: foundAttendee.id,
                profilePhoto: foundAttendee.profilePhoto
              }
              // throw new Error(`User with ID ${userId} not found in directory.`);
            }
            return {
              name: user.name,
              profilePhoto: user.profilePhoto,
              id: userId
            };
          }).sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        console.error('Failed to process recommended attendees:', error);
      }
    }

      /*
          : typeof recommendedAttendees === 'object' ? Object.values(recommendedAttendees)
        .map(attendee => {
          return {
            name: attendee.userName,
            profilePhoto: attendee.userProfilePhoto,
            id: attendee.userId
          };
        })
        .sort((a, b) => {
          if (!a.name) return 1;
          if (!b.name) return -1;
          return a.name.localeCompare(b.name);
      */
  

  let sortedOrganizers = [];
  if (Boolean(isOrganization)){
    if(organizations.length > 1) {
      sortedOrganizers = organizers.map(organizer => organizations.find(org => org.id === organizer.id))
    } else {
      sortedOrganizers = organizers
    }
    sortedOrganizers.sort((a, b) => {
      if (!a.shortName) return 1;
      if (!b.shortName) return -1;
      return a.name.localeCompare(b.shortName);
    });
  } else {
    sortedOrganizers = organizers.map(organizer => directory.find(user => user.id === organizer.id));
    if (organizers.length > 1) {
      sortedOrganizers.sort((a, b) => {
        if (!a.name) return 1;
        if (!b.name) return -1;
        return a.name.localeCompare(b.name);
      });
    }
  }
  const clubOrganizationNavigation = (organizer) => {
    if (organizer.clubName) {
      navigate(`/school/club/${organizer.id}`);
    } else {
      navigate(`/school/organization/${organizer.id}`);
    }
    return null;
  }
  // const sortedOrganizers = isOrganization
  //   ? 
  //   : 

  const renderEventActionButton = () => {
    // Check if the current user is an organizer
    const isUserOrganizer = isOrganization 
      ? organizers?.some(organizer => organizer.leadership && Object.keys(organizer.leadership).includes(user.uid))
      : organizers?.some(organizer => organizer.id === user.uid);
    
  
    let buttons = [];
    if (isUserOrganizer) {
      buttons.push(
        <button key="edit-event" className="tw-bg-yellow-500 hover:tw-bg-yellow-700 tw-text-white tw-rounded tw-px-4 tw-py-2 tw-mr-2" onClick={() => navigate(`/edit-event/${id}`)}>
          Edit Event
        </button>
      );
    }
    if (isUserAttendee) {
      buttons.push(
        <button key="leave" className="tw-bg-red-500 hover:tw-bg-red-700 tw-text-white tw-rounded tw-px-4 tw-py-2" onClick={handleLeaveEvent}>
          Leave Event
        </button>
      );
      if (eventType === 'Professional') {
        buttons.push(
          <button key="edit-details" className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-rounded tw-px-4 tw-py-2" onClick={() => setPopUpOpen(true)}>
            Edit Details
          </button>
        );
      }
    } else {
      buttons.push(
        <button key="join" className="tw-bg-green-500 hover:tw-bg-green-700 tw-text-white tw-rounded tw-px-4 tw-py-2" onClick={eventType === 'Professional' ? () => setPopUpOpen(true) : handleJoinEvent}>
          Join Event
        </button>
      );
    }
    return <>{buttons}</>;
  }  

  let gmtDate = '';
  if (startDate && typeof startDate === 'object' && startDate.hasOwnProperty('_seconds')) {
    gmtDate = startDate;
  } else {
    try {
      gmtDate = new Date(startDate).toISOString().split('T')[0];
    } catch (e) {
      console.error('Invalid time value', e);
    }
  }

  return (
    <>
      <AttendEventForm 
      handleChange={handleChange}
      formState={formState}
      popUpOpen={popUpOpen} setPopUpOpen={setPopUpOpen}
      handleSubmit={handleAttendProfessionalEvent} 
      loading={attendeeFormLoading}
      eventName={Name}
      />
      <div className="tw-flex tw-justify-between tw-items-center tw-py-4">
        <button className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-rounded tw-px-4 tw-py-2" onClick={() => navigate('/events')}>Back</button>
        <div className="tw-space-x-4">
        {renderEventActionButton()}
        </div>


      </div>
    <div className="tw-px-4 sm:tw-px-0">
      <h3 className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">{Name}</h3>
      <img className="tw-h-64 tw-w-full md:tw-w-64 tw-object-fill md:tw-object-cover tw-mt-4" src={eventImage} alt="Event" />
    </div>
    <div className="tw-mt-6 tw-pb-6">
      <dl className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-4">
        <div className="event-detail__tabs tw-mb-5">
          <div className={`event-detail__tab ${activeDetailTab === 'info' ? 'active' : ''}`}
               onClick={() => setActiveDetailTab('info')}>
            Info
          </div>
          <div className={`event-detail__tab ${activeDetailTab === 'favorites' ? 'active' : ''} tw-whitespace-nowrap`}
               onClick={() => setActiveDetailTab('favorites')}>
            Favorites
          </div>
          <div className={`event-detail__tab ${activeDetailTab === 'attending' ? 'active' : ''}`}
               onClick={() => setActiveDetailTab('attending')}>
            Attendees
          </div>
          {recommendedAttendeeDetails.length > 0 && eventType === 'Professional' && (
            <div className={`event-detail__tab ${activeDetailTab === 'recommended' ? 'active' : ''}`}
                 onClick={() => setActiveDetailTab('recommended')}>
              People You Should Meet
            </div>
          )}
        </div>
        {/* <div className={`event-detail__attendees ${activeTab !== 'attending' ? 'hidden' : ''}`}> */}
        <div className={
          `tw-grid tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-4 
        lg:tw-grid-cols-5 xl:tw-grid-cols-6 2xl:tw-grid-cols-7 
        tw-gap-4 tw-col-span-full ${activeDetailTab !== 'info' ? 'tw-hidden' : ''}`}>
          <div className="tw-border-gray-100 tw-px-4 tw-py-6 tw-col-span-2 sm:tw-col-span-6 sm:tw-px-0 lg:tw-col-span-full">
            <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Description</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-mt-3">
              {eventDescription.split(/\\n/).map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < eventDescription.split(/\\n/).length - 1 && <br />}
                </React.Fragment>
              ))}
            </dd>
          </div>
            <div className="tw-border-t tw-border-gray-100 tw-px-4 tw-py-6 sm:tw-border-t-0 sm:tw-col-span-1 sm:tw-px-0">
          <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Event Address</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-mt-2">{address}</dd>
        </div>
        <div className="tw-border-t tw-border-gray-100 tw-px-4 tw-py-6 sm:tw-border-t-0 sm:tw-col-span-1 sm:tw-px-0">
          <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Date</dt>
          {/* <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-mt-2">{formatDate(startDate)}</dd> */}
          <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-mt-2">{formatDate(gmtDate)}</dd>
        </div>
        <div className="tw-border-t tw-border-gray-100 tw-px-4 tw-py-6 sm:tw-border-t-0 sm:tw-col-span-1 sm:tw-px-0">
          <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Time</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-mt-2">{startTime}</dd>
        </div>
        <div className="tw-border-t tw-border-gray-100 tw-px-4 tw-py-6 sm:tw-border-t-0 sm:tw-col-span-1 sm:tw-px-0">
          <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Event Type</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-mt-2">
            <span className="tw-inline-flex tw-items-center tw-rounded-full tw-bg-green-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-green-600 tw-ring-1 tw-ring-inset tw-ring-green-500/10 tw-mr-2 tw-mb-2">
              {eventType}
            </span>
            </dd>
        </div>

        <div className="tw-border-t tw-border-gray-100 tw-px-4 tw-py-6 tw-col-span-full sm:tw-px-0">
          <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Organizers</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-mt-2">
            {sortedOrganizers.map((organizer, index) => {
              const selectedColor = colorArray[index % 7];
              let organizerName = ""
              if (Boolean(isOrganization)) {
                organizerName = organizer.shortName
              } else {
                organizerName = organizer ? organizer.name : ""
              }
              // const organizerName = isOrganization ? organizer.shortName : organizer ? organizer.name : "";
              const onClickHandler = isOrganization ? () => clubOrganizationNavigation(organizer) : () => navigate(`/directory/${organizer.id}/${id}`);
              return (
                <span 
                  key={index} 
                  className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2 hover:tw-cursor-pointer hover:tw-bg-${selectedColor}-100`}
                  onClick={onClickHandler}
                >
                  {organizerName}
                </span>
              );
            })}
          </dd>
        </div>
        </div>
        <div className={
          `tw-grid tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-4 
        lg:tw-grid-cols-5 xl:tw-grid-cols-6 2xl:tw-grid-cols-7 
        tw-gap-4 tw-col-span-full ${activeDetailTab !== 'attending' ? 'tw-hidden' : ''}`}>
            {attendeeDetails.map((attendee, index) => (

                <div
                key={index}
                className="
                tw-inline-flex tw-items-center tw-gap-x-1.5 tw-rounded-md
                tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-gray-900 tw-ring-1
                tw-ring-inset tw-ring-gray-200 sm:tw-col-span-1 tw-cursor-pointer"
                onClick={() => navigate(`/directory/${attendee.id}/${id}`)}
                >
                {attendee.profilePhoto && (
                  <img className="tw-h-10 tw-w-10 tw-rounded-full" src={attendee.profilePhoto} alt={attendee.name} />
                  // <img className="tw-inline-block tw-h-14 tw-w-14 tw-rounded-md" src={attendee.profilePhoto} alt={attendee.name} />
                )}
                {attendee.name}
                </div>
            //   <div 
            //   className="
            //   tw-flex tw-flex-col tw-gap-y-1.5 tw-rounded-md 
            //   tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-ring-1
            //   tw-ring-inset tw-ring-gray-200 tw-col-span-full md:tw-col-span-2 tw-cursor-pointer"
            //   onClick={() => navigate(`/directory/${attendee.id}`)}
            //   key={index}>
             
            //   <div
            //     className="tw-flex tw-items-center tw-justify-between tw-rounded-md tw-cursor-pointer"
            //   >
            //     <img className="tw-h-10 tw-w-10 tw-rounded-full lg:tw-h-14 lg:tw-w-14" src={attendee.profilePhoto} alt={attendee.name} />
               
            //     <div className="tw-flex tw-flex-grow tw-justify-center tw-items-center">
            //       <span className="tw-text-lg lg:tw-text-xl">{attendee.name}</span>
            //     </div>
            //     <HeartIcon className="tw-h-8 tw-w-8 tw-text-gray-500 hover:tw-text-red-500" />
            //   </div>
             
             
             
            //   {eventType === "Professional" && (
              
            //      <div className="tw-mt-2 tw-mb-2 tw-text-sm tw-text-gray-700 tw-pl-2">
            //        <p className="tw-mb-4">
            //          <strong>Why I'm attending:</strong> 
            //          {"Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, ea. Quis quibusdam ratione reiciendis iusto iste atque reprehenderit, minima omnis culpa? Eos consequuntur quam, sint tempore ratione magnam exercitationem rerum."}
            //        </p>
            //        <p className="tw-mb-4">
            //          <strong>Who I want to meet:</strong> 
            //          {"Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, ea. Quis quibusdam ratione reiciendis iusto iste atque reprehenderit, minima omnis culpa? Eos consequuntur quam, sint tempore ratione magnam exercitationem rerum."}
            //        </p>
            //      </div>
            //    )}
            //   {eventType === "Professional" && (
            //    (attendee.why || attendee.whoMeet) && (
            //      <div className="tw-mt-2 tw-mb-2 tw-text-sm tw-text-gray-700 tw-pl-2">
            //        <p className="tw-mb-4">
            //          <strong>Why I'm attending:</strong> 
            //          {attendee.why}
            //        </p>
            //        <p className="tw-mb-4">
            //          <strong>Who I want to meet:</strong> 
            //          {attendee.whoMeet}
            //        </p>
            //      </div>
            //    ))}
            
              
               

            // </div>
           
            ))}
        </div>
        <div className={
          `tw-grid tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-4 
        lg:tw-grid-cols-5 xl:tw-grid-cols-6 2xl:tw-grid-cols-7 
        tw-gap-4 tw-col-span-full ${activeDetailTab !== 'favorites' ? 'tw-hidden' : ''}`}>
            {favoriteAttendees.map((attendee, index) => (

                <div
                key={index}
                className="
                tw-inline-flex tw-items-center tw-gap-x-1.5 tw-rounded-md
                tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-gray-900 tw-ring-1
                tw-ring-inset tw-ring-gray-200 sm:tw-col-span-1 tw-cursor-pointer"
                onClick={() => navigate(`/directory/${attendee.id}/${id}`)}
                >
                <img className="tw-h-10 tw-w-10 tw-rounded-full" src={attendee.profilePhoto} alt={attendee.name} />
                {attendee.name}
                </div>           
            ))}
        </div>
        <div className={
          `tw-grid tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-4 
        lg:tw-grid-cols-5 xl:tw-grid-cols-6 2xl:tw-grid-cols-7 
        tw-gap-4 tw-col-span-full ${activeDetailTab !== 'recommended' ? 'tw-hidden' : ''}`}>
            {recommendedAttendeeDetails.map((attendee, index) => (
              
              <div
                key={index}
                className="
                tw-inline-flex tw-items-center tw-gap-x-1.5 tw-rounded-md
                tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-gray-900 tw-ring-1
                tw-ring-inset tw-ring-gray-200 sm:tw-col-span-1 tw-cursor-pointer"
                onClick={() => navigate(`/directory/${attendee.id}/${id}`)}
              >
                <img className="tw-h-10 tw-w-10 tw-rounded-full" src={attendee.profilePhoto} alt={attendee.name} />
                {/* <img className="tw-inline-block tw-h-14 tw-w-14 tw-rounded-md" src={attendee.profilePhoto} alt={attendee.name} /> */}
                {attendee.name}
              </div>
             
              // <div key={index}>
              //   <div
              //     className="
              //     tw-inline-flex tw-items-center tw-gap-x-1.5 tw-rounded-md 
              //     tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-gray-900 tw-ring-1 
              //     tw-ring-inset tw-ring-gray-200 sm:tw-col-span-1 tw-cursor-pointer"
              //     onClick={() => navigate(`/directory/${attendee.id}`)}
              //   >
              //     <img className="tw-h-10 tw-w-10 tw-rounded-full" src={attendee.profilePhoto} alt={attendee.name} />
              //     <span className="tw-mr-2">{attendee.name}</span>
              //     <HeartIcon className="tw-h-5 tw-w-5 tw-text-red-500" />
              //   </div>
              //   {eventType === "Professional" && (
              //     <div className="tw-mt-2 tw-text-sm tw-text-gray-700">
              //       <p><strong>Why I'm attending:</strong> {attendee.why}</p>
              //       <p><strong>Who I want to meet:</strong> {attendee.whoMeet}</p>
              //     </div>
              //   )}
              // </div>
             
            ))}
           
        </div>

      </dl>
    </div>

        {/* <div className="event-detail__section--organizers">
          
        </div>

        <div className="event-detail__tabs">
          <div className={`event-detail__tab ${activeTab === 'attending' ? 'active' : ''}`}
               onClick={() => setActiveTab('attending')}>
            Attending
          </div>
          <div className={`event-detail__tab ${activeTab === 'recommended' ? 'active' : ''}`}
               onClick={() => setActiveTab('recommended')}>
            Recommended
          </div>
        </div>
        <div className={`event-detail__attendees ${activeTab !== 'attending' ? 'hidden' : ''}`}>
          {attendees && attendeeDetails.map((attendee, index) => (
            <div key={index} className="event-detail__attendee" onClick={() => navigate(`/directory/${attendee.id}`)}>
              <img src={attendee.profilePhoto} alt={attendee.name} className="event-detail__attendee-image" />
              <div className="event-detail__attendee-name">{attendee.name}</div>
            </div>
          ))}
        </div>

        {/* Download to Calendar */}
        {/* <button className="event-detail__calendar-button" onClick={downloadToCalendar}>
          Add to Calendar
        </button> */}
    {/* </div> */}
    {/* </div> */}
    </>
  );
}
