import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Navigate  } from 'react-router-dom';
import { app } from '../firebase/firebase';
// import { useNavigate } from 'react-router-dom';

const NavigateHome = () => {
    const [user, setUser] = useState(null);
    const [checkingAuth, setCheckingAuth] = useState(true); // State to track auth checking
  
    useEffect(() => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
        setCheckingAuth(false); // Auth check completed
      });
  
      // Cleanup subscription on unmount
      return () => unsubscribe();
    }, []);
  
    // While checking authentication, you can return null or a loading indicator
    if (checkingAuth) {
      return null; // or <LoadingIndicator /> if you have a loading component
    }
  
    // After checking, navigate based on the user's authentication state
    if (user) {
      return <Navigate to="/start" replace />;
    } else {
      return <Navigate to="/login" replace />;
    }
  };
  
  export default NavigateHome;