import React from 'react';
import { useNavigate } from 'react-router-dom';
import { colorArray } from '../../utils/helperFunctions';

export default function CompetitionTeamCard({ team, competitionId, teamId }) {
    const navigate = useNavigate();

    const navigateToTeam = () => {
      navigate(`/school/competition/${competitionId}/team/${teamId}`);
    };

    return (
      <div className="event-card" onClick={navigateToTeam}>
        {/* <div className="event-card__image">
          {event.image && <img src={event.image} alt={event.eventName} />}
        </div> */}
        <div className="event-card__content__visible">
          <p className="event-card__name">{team.teamName}</p>
          
          <p className="tw-text-sm tw-font-bold tw-mt-4 tw-mb-2">Sectors:</p>
          {team.competitionSectors && Object.keys(team.competitionSectors).map((sectorKey, index) =>  {
            const selectedColor = colorArray[index % 7];
            const sector = team.competitionSectors[sectorKey];
            return (
              <div key={`${sector}-${index}`} className={`tw-flex tw-flex-col tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2`}>
                {sector}
              </div>
            )
          })}
        </div>
      </div>
    );
  }
  