import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'

export default function DirectoryCard({ user, index, visible }) {
  const navigate = useNavigate();

  const navigateToDirectory = () => {
    navigate(`/directory/${index}`);
  };
  const { 
    'Profile Photo': profilePhotoLegacy, 
    profilePhoto } = user;
  const profileImageUrl = profilePhoto || (profilePhotoLegacy ? profilePhotoLegacy[0].url : '');

  return (
    <>
<li onClick={navigateToDirectory} key={user.email} className={`tw-col-span-1 tw-grid tw-grid-rows-3 tw-divide-y tw-divide-gray-200 tw-rounded-lg tw-bg-white tw-text-center tw-shadow smooth-trans ${visible ? 'visible' : 'hidden'}`}>
  <div className="tw-row-span-2 tw-relative tw-h-48"> {/* Fixed height for image container */}
    <img className="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-object-center tw-rounded-t-lg" src={profileImageUrl} alt="" />
  </div>
  <div className="tw-row-span-1 tw-flex tw-flex-col tw-justify-center tw-p-4">
    <h3 className="tw-text-lg tw-font-medium tw-text-gray-900">{user.name}</h3>
    <p className="tw-text-md tw-text-gray-500">{user.program}</p>
  </div>
</li>



    </>
    // <div className="directory__card" onClick={navigateToDirectory}>
    //   <div className="directory__card__image">
    //           {user['Profile Photo'] ? <img src={profileImageUrl} className="directory__card__image__pic" /> : ""}
    //         </div>
    //         <div className="directory__card-content">
    //           <h2 className="directory__card-content__name">{user.name}</h2>
    //           <div className="directory__card-content__program-info">
    //             <strong>Program:</strong>
    //             <p>{user.program}</p>
    //           </div>
    //           <div className="directory__card-content__interests">
    //             <strong>Interests / Hobbies:</strong>
    //             <p>{Array.isArray(user.interestsHobbies) ? user.interestsHobbies.slice(0, 6).join(', ') : user.interestsHobbies}</p>
    //           </div>
    //         </div>
    // </div>
  );
}
