import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import MultiComboBoxInput from '../../filters/MultiComboBoxInput'
import { DateTime } from "luxon";


export default function EditChaiContact({ 
    type, handleChange, handleSingleChange, handleMultiComboChangeHelper,
    formState, setFormState, setFormErrors,
    popUpOpen, setPopUpOpen,
    handleWebsiteBlur,formErrors, userCompanies, industryValues,
    setCompanyFilters, handleFilterChange, companyFilters, companyValue = {},
    handleSubmit, loading  }) {
  return (
    <Transition.Root show={popUpOpen} as={Fragment}>
      <Dialog as="div" className="tw-relative tw-z-10" onClose={setPopUpOpen}>
        <Transition.Child
          as={Fragment}
          enter="tw-ease-out tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in tw-duration-200"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
        </Transition.Child>

        <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
          <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
            <Transition.Child
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
              enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            >
              <Dialog.Panel className="tw-relative tw-transform tw-overflow-auto tw-rounded-lg tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-max-w-sm sm:tw-p-6">
                {type === "Companies" && (
                  <form>
                    <h1 className="tw-text-center tw-text-2xl tw-font-bold tw-text-black-600 tw-mb-4">Edit Company</h1>
                    <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="name">
                        Name <span className="required-star">*</span>
                      </label>
                      <input 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                      name="companyName" type="text" 
                      value={formState.companyName || ''}
                      placeholder="Name" 
                      onChange={(event) => handleChange(event, setFormState)}
                      required />
                    </div>
                    <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="notes">
                        Notes
                      </label>
                      <textarea 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                      name="notes" placeholder="Notes" 
                      value={formState.notes || ''}
                      rows={'5'}
                      onChange={(event) => handleChange(event, setFormState)}></textarea>
                    </div>
                    <div className="tw-mb-4">
                      {/* <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="industry">
                        Industry
                      </label> */}
                      {/* <select 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                      name="industry" 
                      value={formState.industry || ''}
                      onChange={(event) => handleChange(event, setFormState)}
                      >
                        <option value="">Select Industry</option>
                        {industryValues.map((industry) => (
                          <option key={industry} value={industry}>
                            {industry}
                          </option>
                        ))}
                      </select> */}
                      {companyFilters && companyFilters.industries && 
                        <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                          Selected: {Array.isArray(companyFilters.industries) ? companyFilters.industries.join(', ') : companyFilters.industries}
                        </div>
                      }
                      <MultiComboBoxInput 
                      items={industryValues}
                      label="industries"
                      singleSelect={false}
                      setFilters={setCompanyFilters}
                      handleFilterChange={handleFilterChange}
                      selectedValues={companyFilters.industries || []}
                      // handleSingleSelectChange={handleSingleChange}
                      // localUpdate={(value) => handleMultiComboChangeHelper("Company",value, setFormState)}
                      />
                    </div>
                    <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="website">
                        Website
                      </label>
                      <input 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                      name="website" 
                      type="text" 
                      placeholder="Website" 
                      value={formState.website || ''}
                      onChange={(event) => handleChange(event, setFormState)}
                      onBlur={() => handleWebsiteBlur(formState, setFormErrors)} />
                      {formErrors.website && <small className="error-text">{formErrors.website}</small>}
                    </div>
                    <div className="tw-mb-4">
                      <button 
                      className="tw-inline-flex tw-w-full tw-justify-center tw-bg-blue-500 tw-hover:bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
                      disabled={loading} 
                      onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                )}
                {type === "Contacts" && (
                  <form>
                    <h1 className="tw-text-center tw-text-2xl tw-font-bold tw-text-black-600 tw-mb-4">Edit Contact</h1>
                    <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="name">
                        Name <span className="required-star">*</span>
                      </label>
                      <input 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                      name="contactName" type="text" 
                      value={formState.contactName || ''}
                      placeholder="Name" 
                      onChange={(event) => handleChange(event, setFormState)} 
                      required />
                    </div>
                    <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="role">
                        Role
                      </label>
                      <input 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                      name="role" type="text" 
                      value={formState.role || ''}
                      placeholder="Role" 
                      onChange={(event) => handleChange(event, setFormState)} 
                      required />
                    </div>
                    <div className="tw-mb-4">
                      <MultiComboBoxInput 
                      items={userCompanies}
                      label="Company"
                      singleSelect={true}
                      handleSingleSelectChange={handleSingleChange}
                      localUpdate={(value) => handleMultiComboChangeHelper("Company",value, setFormState)}
                      selectedValue={companyValue}
                      />
                    </div>
                    <div className="tw-mb-4">
                    <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="industries">
                        Industry Background
                      </label>
                      {companyFilters && companyFilters.industries && 
                        <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                          Selected: {Array.isArray(companyFilters.industries) ? companyFilters.industries.join(', ') : companyFilters.industries}
                        </div>
                      }
                      <MultiComboBoxInput 
                      items={industryValues}
                      label="industries"
                      singleSelect={false}
                      setFilters={setCompanyFilters}
                      handleFilterChange={handleFilterChange}
                      selectedValues={companyFilters.industries || []}
                      />
                    </div>
                    {formState.Company && !formState.company && (
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="email">
                          New Company Name - If no existing company is selected above, a new company will be created
                        </label>
                        <p className="tw-text-gray-700 tw-text-sm">{formState.Company}</p>
                      </div>
                    )}
                    <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="email">
                        Email
                      </label>
                      <input 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                      name="email" type="email" 
                      value={formState.email || ''}
                      placeholder="Email" 
                      onChange={(event) => handleChange(event, setFormState)}
                      required />
                    </div>
                    <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="phone">
                        Phone
                      </label>
                      <input 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                      name="phone" type="tel" 
                      value={formState.phone || ''}
                      placeholder="Phone" 
                      onChange={(event) => handleChange(event, setFormState)} 
                      required />
                    </div>
                    <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="lastContactDate">
                        Last Contact Date
                      </label>
                      <input 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                      name="lastContactDate" type="date" 
                      value={formState.lastContactDate}
                      placeholder="Last Contact Date" 
                      onChange={(event) => {
                        const formattedDate = event.target.value ? DateTime.fromISO(event.target.value).toFormat('yyyy-MM-dd') : '';
                        handleChange({ target: { name: 'lastContactDate', value: formattedDate } }, setFormState);
                      }} 
                      required />
                    </div>
                    {/* <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="linkedin">
                        LinkedIn
                      </label>
                      <input 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                      name="linkedIn" type="text" 
                      value={formState.linkedIn || ''}
                      placeholder="LinkedIn" onChange={handleChange} required />
                    </div> */}
                    <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="notes">
                        Notes
                      </label>
                      <textarea 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                      name="notes" placeholder="Notes" 
                      value={formState.notes || ''}
                      onChange={(event) => handleChange(event, setFormState)} 
                      ></textarea>
                    </div>
                    <div className="tw-mb-4">
                      <button 
                      className="tw-inline-flex tw-w-full tw-justify-center tw-bg-blue-500 tw-hover:bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" 
                      disabled={loading} 
                      onClick={handleSubmit}>
                        Submit
                      </button>
                    </div>
                  </form>
                )}
                {type === "Complete" && (
                    <>
                <div>
                  <div className="tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-bg-green-100">
                    <CheckIcon className="tw-h-6 tw-w-6 tw-text-green-600" aria-hidden="true" />
                  </div>
                  <div className="tw-mt-3 tw-text-center sm:tw-mt-5">
                    <Dialog.Title as="h3" className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
                      Payment successful
                    </Dialog.Title>
                    <div className="tw-mt-2">
                      <p className="tw-text-sm tw-text-gray-500">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tw-mt-5 sm:tw-mt-6">
                  <button
                    type="button"
                    className="tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-indigo-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm tw-hover:bg-indigo-500 tw-focus-visible:outline tw-focus-visible:outline-2 tw-focus-visible:outline-offset-2 tw-focus-visible:outline-indigo-600"
                    onClick={() => setPopUpOpen(false)}
                  >
                    Go back to dashboard
                  </button>
                </div>
                </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
