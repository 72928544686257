import Filter from 'bad-words';

const filter = new Filter();

export const sanitizeInput = (input) => {
    return input.replace(/[<>{}]/g, '');
  };
  const filterProfanity = text => {
    return filter.clean(text);
  };
  const isInputAppropriate = (input) => {
    return !filter.isProfane(input);
  };

  const isInputFormattedCorrectly = (input) => {
    // This regex allows letters, numbers, spaces, hyphens, apostrophes, certain accented characters and emojis
    return /^[\p{L}\p{N}\p{Z}\p{Pd}'\p{M}]+$/u.test(input);
  };

export const handleUsernameBlur = (formState, setFormErrors) => {
    const disallowedNames = ['admin', 'moderator', 'user'];
    const isUsernameAppropriate = (username) => {
      return !filter.isProfane(username);
    };
    const isNameFormattedCorrectly = (name) => {
      // This regex allows letters, spaces, hyphens, apostrophes, and certain accented characters
      return /^[a-zA-ZÀ-ÖØ-öø-ÿ\s'-]+$/.test(name);
    };
    if (formState.name) {
      if (disallowedNames.includes(formState.name.toLowerCase())) {
        setFormErrors(prevErrors => ({ ...prevErrors, name: 'Username is not allowed.' }));
      } else if (!isUsernameAppropriate(formState.name)) {
        setFormErrors(prevErrors => ({ ...prevErrors, name: 'Name contains inappropriate words.' }));
      } else if (!isNameFormattedCorrectly(formState.name)) {
        setFormErrors(prevErrors => ({ ...prevErrors, name: 'Name is not formatted correctly.' }));
      } else {
        setFormErrors(prevErrors => ({ ...prevErrors, name: '' }));
      }
    }
  };

export const handleTextInputBlur = (inputName, formState, setFormErrors) => {
 
    if (formState[inputName]) {
      if (!isInputAppropriate(formState[inputName])) {
        setFormErrors(prevErrors => ({ ...prevErrors, [inputName]: 'Input contains inappropriate words.' }));
      } else if (!isInputFormattedCorrectly(formState[inputName])) {
        setFormErrors(prevErrors => ({ ...prevErrors, [inputName]: 'Input is not formatted correctly.' }));
      } else {
        setFormErrors(prevErrors => ({ ...prevErrors, [inputName]: '' }));
      }
    } else {
        setFormErrors(prevErrors => ({ ...prevErrors, [inputName]: '' }));
      }
  };

  export const handleMultiFormInputBlur = (inputName, value, setFormErrors) => {
 
    if (!isInputAppropriate(value)) {
      setFormErrors(prevErrors => ({ ...prevErrors, [inputName]: 'Input contains inappropriate words.' }));
    } else if (!isInputFormattedCorrectly(value)) {
      setFormErrors(prevErrors => ({ ...prevErrors, [inputName]: 'Input is not formatted correctly.' }));
    } else {
      setFormErrors(prevErrors => ({ ...prevErrors, [inputName]: '' }));
    }
  };

  export const hasFormErrors = (formErrors) => {
      for (let key in formErrors) {
          if (formErrors[key] !== '') {
              return true;
          }
      }
      return false;
  };

  