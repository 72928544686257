export const handleCreateNewTag = async (customTagName, addTagsToUser,handleAddTag) => {
    addTagsToUser(customTagName).then((newTags) => {
      const existingTag = newTags.find(tag => tag.value === customTagName);
      if (existingTag) {
        handleAddTag(existingTag.id);
      }
    });
    //Call Function to Add Tag to User Profile
    // done
}

export const sortTags = (tags, chaiTagsArray) => {
  return tags
    .map(tagId => chaiTagsArray?.find(tag => tag.id === tagId))
    .filter(tagObj => tagObj !== undefined)
    .sort((a, b) => a.value.localeCompare(b.value));
};

export const filterAndSortTags = (chaiTagsArray, existingTagIds) => {
  return chaiTagsArray
    .filter(tag => !existingTagIds?.includes(tag.id))
    .sort((a, b) => {
      if (!a.value || !b.value) {
        console.log('Undefined value encountered:', a);
        return 0;
      }
      return a.value.localeCompare(b.value);
    });
};
