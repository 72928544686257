import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import Loading from '../../components/Loading';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../../firebase/firebase';
import { useContactClickHandler } from '../../utils/handleClicks';
import { PaperClipIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'

export default function SchoolOrganization() {
  const { organizations, user } = useUser();
  const [schoolOrganizationData, setSchoolOrganizationData] = useState(null);
  let { id } = useParams();
  const navigate = useNavigate();
  const handleContactClick = useContactClickHandler();

  useEffect(() => {
    if (organizations) {
      const schoolOrganization = organizations.find(schoolOrganization => schoolOrganization.id === id);
      if (schoolOrganization) {
        setSchoolOrganizationData({ ...schoolOrganization });
      }
    }
  }, [organizations, id]);
  

  if (!schoolOrganizationData || !user) {
    return <Loading />;
  }

  return (
    <>
        <div className="tw-flex tw-justify-between tw-pb-5">
          <button className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => navigate(-1)}>Back</button>
        </div>
    <div>
      <div className="tw-px-4 sm:tw-px-0 tw-flex tw-items-center">
        <div className="">
          <h3 className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">{schoolOrganizationData.organizationName}</h3>
          <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-6 tw-text-gray-500">{schoolOrganizationData.notes}</p>
          <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-6 tw-text-gray-500">Website: <a href={schoolOrganizationData.website} target="_blank" rel="noopener noreferrer" className="tw-text-blue-500 hover:tw-text-blue-700">{schoolOrganizationData.website}</a></p>
        </div>
      </div>
      <div>
      <hr className="tw-my-4" />
        <div className="tw-mt-4">
          <h2 className="tw-text-xl tw-font-semibold tw-leading-7 tw-text-gray-900">Organization Description</h2>
          <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-6 tw-text-gray-500">{schoolOrganizationData.description}</p>
        </div>
      </div>
      {schoolOrganizationData.contacts && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
        <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Organization Contacts</dt>
        <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
          <div className="">
            <div className="tw-mt-8 tw-flow-root">
              <div className="tw--mx-4 tw--my-2 tw-overflow-x-auto sm:tw--mx-6 lg:tw--mx-8">
                <div className="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle">
                  <table className="tw-min-w-full tw-divide-y tw-divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-0">Name</th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">Role</th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">Email</th>
                        {/* <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">Phone</th> */}
                      </tr>
                    </thead>
                    <tbody className="tw-divide-y tw-divide-gray-200">
                      {schoolOrganizationData.contacts?.map((contact, index) => (
                        <tr key={index} className="tw-cursor-pointer" onClick={() => handleContactClick(contact.id)}>
                          <td className="tw-whitespace-nowrap tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-pl-0">{contact.contactName}</td>
                          <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{contact.role}</td>
                          <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                            {contact.email && 
                              <a href={`mailto:${contact.email}`} className="tw-text-grey-600 hover:tw-text-blue-500">
                                <EnvelopeIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-grey-600 hover:tw-text-indigo-600" aria-hidden="true" />
                              </a>
                            }
                          </td>
                          {/* <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                            {contact.phone && 
                              <a href={`tel:${contact.phone}`} className="tw-text-grey-600 hover:tw-text-blue-500">
                                <PhoneIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-grey-600 hover:tw-text-blue-500" aria-hidden="true" />
                              </a>
                            }
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </dd>
      </div>}
      </div>
      </>
  )
}
