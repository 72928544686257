import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'

const SchoolCompetitionCard = ({ competition, handleCompetitionClick, statuses, classNames }) => {
  const nestedClickHandler = (event) => {
    event.stopPropagation();
    handleCompetitionClick(competition.id)
  };
  return (
    <li 
      key={competition.competitionName} 
      className="tw-overflow-hidden tw-rounded-xl tw-border tw-border-gray-200"
      onClick={nestedClickHandler}
    >
      <div className="tw-flex tw-items-center tw-gap-x-4 tw-border-b tw-border-gray-900/5 tw-bg-gray-50 tw-p-6">
        {/* {competition.companyWebsite && (
          <img
            src={`https://logo.clearbit.com/${competition.companyWebsite}`}
            alt={competition.companyName}
            className="tw-h-12 tw-w-12 tw-flex-none tw-rounded-lg tw-bg-white tw-object-cover tw-ring-1 tw-ring-gray-900/10"
          />
        )} */}
        <div 
          className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
        >{competition.competitionName}</div>
      </div>
      <dl 
        className="tw--my-3 tw-divide-y tw-divide-gray-100 tw-px-6 tw-py-4 tw-text-sm tw-leading-6"
      >
        <div className="tw-flex tw-justify-between tw-gap-x-4 tw-py-3">
          <dt className="tw-text-gray-500">Description:</dt>
          <dd className="tw-text-gray-700">
            {competition.description}
          </dd>
        </div>
      </dl>
    </li>
  );
};

export default SchoolCompetitionCard;
