import React, { useEffect, useState } from 'react';
import { useUser } from '../contexts/UserContext';
import Loading from '../components/Loading';
import { useNavigate } from 'react-router-dom';

export default function Teahouse() {
    const { directory, user, selectedUser, setSelectedUser, events } = useUser();
    const navigate = useNavigate();
    // const navigate = useNavigate();
  
    useEffect(() => {
      if (user) {
        const directoryUser = directory.find(directoryUser => directoryUser.id === user.uid);
        if (directory && directoryUser) {
          setSelectedUser(directoryUser);
        }
      }
    }, [directory, user]);
    if (!selectedUser || !user.matches) {
      return <Loading />;
    }

    const matchedUsers = user.matches.map(match => {
        const matchedUser = directory.find(directoryUser => directoryUser.id === match.matchedUserId);
        const { 
            'Profile Photo': profilePhotoLegacy, 
            profilePhoto } = matchedUser;
        const profileImageUrl = profilePhoto || (profilePhotoLegacy ? profilePhotoLegacy[0].url : '');
        return { ...match, profileImageUrl, name: matchedUser.name };
    });

    // const recommendedPeople = [
    //     {id: '2kl342', reasoning: 'seems nice' },
    //     {id: '3kl453', reasoning: 'helpful' },
    //     {id: '4kl564', reasoning: 'friendly' },
    //     {id: '5kl675', reasoning: 'knowledgeable' },
    //     {id: '6kl786', reasoning: 'experienced' },
    //     {id: '7kl897', reasoning: 'enthusiastic' }
    // ].map(person => {
    //     // const directoryPerson = directory.find(directoryUser => directoryUser.id === person.id);
    //     // return { ...person, ...directoryPerson };
    //     const randomIndex = Math.floor(Math.random() * directory.length);
    //     const randomPerson = directory[randomIndex];
    //     return { ...person, ...randomPerson };
    // });

    // const recommendedEvents = [
    //     {id: 'ev123', reasoning: 'interesting topic' },
    //     {id: 'ev234', reasoning: 'great speaker' },
    //     {id: 'ev345', reasoning: 'relevant to your interests' },
    //     {id: 'ev456', reasoning: 'popular event' },
    //     {id: 'ev567', reasoning: 'highly recommended' },
    //     {id: 'ev678', reasoning: 'educational' }
    // ].map(event => {
    //     const randomIndex = Math.floor(Math.random() * events.length);
    //     const randomEvent = events[randomIndex];
    //     return { ...event, ...randomEvent };
    // });
    const navigateTo = (location,id) => {
        navigate(`/${location}/${id}`);
      };
    
      


    return (
        <div className="teahouse">
            <h1 className="teahouse__welcome">Welcome, {selectedUser?.name?.split(" ")[0]}</h1>
            <div className="teahouse__section">
                <h2 className="teahouse__section-title">Contacts</h2>
                <div className="teahouse__container">
                {matchedUsers.map(person => { 
                    return (
                    <div className="teahouse__person" key={person.matchedUserId} onClick={() => navigateTo("directory",person.matchedUserId)}>
                        {/* <div className="teahouse__person-photo" style={{backgroundImage: `url(${person.photoUrl})`}}></div> */}
                        <div>
                        <img className="teahouse__person-photo"  src={person.profileImageUrl} />
                        </div>
                        <p className="teahouse__person-name">{person.name}</p>
                        {/* <p className="teahouse__person-reasoning">{person.reasoning}</p> */}
                    </div>
                )}
                )}
                </div>
            </div>
            <div className="teahouse__section">
                <h2 className="teahouse__section-title">Recommended People</h2>
                <div className="teahouse__container">
                {matchedUsers.map(person => { 
                    return (
                    <div className="teahouse__person" key={person.matchedUserId} onClick={() => navigateTo("directory",person.matchedUserId)}>
                        {/* <div className="teahouse__person-photo" style={{backgroundImage: `url(${person.photoUrl})`}}></div> */}
                        <div>
                        <img className="teahouse__person-photo"  src={person.profileImageUrl} />
                        </div>
                        <p className="teahouse__person-name">{person.name}</p>
                        {/* <p className="teahouse__person-reasoning">{person.reasoning}</p> */}
                    </div>
                )}
                )}
                </div>
            </div>
            {/* <div className="teahouse__events">
                <h2 className="teahouse__events-title">Recommended Events</h2>
                <div className="teahouse__container">

                {recommendedEvents.map(event => {


               return (
                    <div className="teahouse__person" key={event.id} onClick={() => navigateTo("events",event.id)}>
                        <img className="teahouse__person-photo"  src={event.image} />
                        <p className="teahouse__person-name">{event.eventName}</p>
                        <p className="teahouse__person-reasoning">{event.reasoning}</p>
                    </div>
                )}
                )}
                </div>
            </div> */}
        </div>
    )
};
