const posts = [
    // {
    //   id: 1,
    //   title: 'Boost your conversion rate',
    //   href: '#',
    // //   description:
    // //     'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    //   imageUrl:
    //     'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    // //   date: 'Mar 16, 2020',
    // //   datetime: '2020-03-16',
    // //   author: {
    // //     name: 'Michael Foster',
    // //     imageUrl:
    // //       'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    // //   },
    // },
    {
        id: 1,
        title: 'AI',
        href: 'https://guide.teahouseai.com/',
      //   description:
      //     'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        imageUrl:
          'https://images.unsplash.com/photo-1677756119517-756a188d2d94?q=80&w=1750&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      {
        id: 2,
        title: 'Solana',
        href: 'https://guide.teahouseai.com/solana',
      //   description:
      //     'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        imageUrl:
          'https://images.unsplash.com/photo-1660062993887-4938423dce59?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      
  ]
  
  export default function Guides() {
    return (
      <div className="tw-bg-white tw-py-24 sm:tw-py-32">
        <div className="tw-mx-auto tw-max-w-7xl tw-px-6 lg:tw-px-8">
          <div className="tw-mx-auto tw-max-w-2xl tw-text-center">
            <h2 className="tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900 sm:tw-text-4xl">Our Guides</h2>
            <p className="tw-mt-2 tw-text-lg tw-leading-8 tw-text-gray-600">
              Level Up your skills.
            </p>
          </div>
          <div className="tw-mx-auto tw-mt-16 tw-grid tw-max-w-2xl tw-auto-rows-fr tw-grid-cols-1 tw-gap-8 sm:tw-mt-20 lg:tw-mx-0 lg:tw-max-w-none lg:tw-grid-cols-3">
            {posts.map((post) => (
              <article
                key={post.id}
                className="tw-relative tw-isolate tw-flex tw-flex-col tw-justify-end tw-overflow-hidden tw-rounded-2xl tw-bg-gray-900 tw-px-8 tw-pb-8 tw-pt-80 sm:tw-pt-48 lg:tw-pt-80"
              >
                <img src={post.imageUrl} alt="" className="tw-absolute tw-inset-0 tw--z-10 tw-h-full tw-w-full tw-object-cover" />
                <div className="tw-absolute tw-inset-0 tw--z-10 tw-bg-gradient-to-t tw-from-gray-900 tw-via-gray-900/40" />
                <div className="tw-absolute tw-inset-0 tw--z-10 tw-rounded-2xl tw-ring-1 tw-ring-inset tw-ring-gray-900/10" />
  
                <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-y-1 tw-overflow-hidden tw-text-sm tw-leading-6 tw-text-gray-300">
                  {/* <time dateTime={post.datetime} className="tw-mr-8">
                    {post.date}
                  </time> */}
                  {/* <div className="tw--ml-4 tw-flex tw-items-center tw-gap-x-4">
                    <svg viewBox="0 0 2 2" className="tw--ml-0.5 tw-h-0.5 tw-w-0.5 tw-flex-none tw-fill-white/50">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <div className="tw-flex tw-gap-x-2.5">
                      <img src={post.author.imageUrl} alt="" className="tw-h-6 tw-w-6 tw-flex-none tw-rounded-full tw-bg-white/10" />
                      {post.author.name}
                    </div>
                  </div> */}
                </div>
                <h3 className="tw-mt-3 tw-text-lg tw-font-semibold tw-leading-6 tw-text-white">
                  <a href={post.href} target="_blank" rel="noopener noreferrer">
                    <span className="tw-absolute tw-inset-0 hover:tw-text-gray-500" />
                    {post.title}
                  </a>
                </h3>
              </article>
            ))}
          </div>
        </div>
      </div>
    )
  }
  