import React from 'react';
import FormSelect from './FormSelect';
import FormObjectCheckboxes from './FormObjectCheckboxes';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import MultiComboBoxInput from './filters/MultiComboBoxInput';
import { handleFilterChange } from '../utils/helperFunctions';
import { Switch } from '@headlessui/react';
import { colorArray } from '../utils/helperFunctions';


const NewEventForm = ({ 
    formState, handleChange, handleSubmit, directory,
    handleSearchTextChange, handleCheckboxChange, handleFileChange, handleRadioChange,
    searchTexts, users, userInfo, otherUpdateState, eventId, handleMultiChange, classNames,
    isOrganization, handleSwitchChange, clubsAndOrganizations, navigateToOrganization,
    errors, setErrors
   }) => {
  const eventTypeValues = ['Professional','Social']
  const organizers = Array.isArray(formState.organizers) && formState.organizers.length >= 1 
    ? formState.organizers.map(id => {
        const user = users.find(user => user.id === id);
        return user ? user.title : '';
      })
    : formState.organizers;

    // Determine the names of the organizers based on whether the event is organized by an organization or an individual
    const organizerNames = formState.organizers 
      ? formState.organizers.map(organizer => {
          // If the event is organized by an organization, find the organizer in the clubsAndOrganizations array
          // Otherwise, find the organizer in the directory array
          const user = isOrganization 
            ? clubsAndOrganizations.find(user => user.id === organizer.id)
            : directory.find(user => user.id === organizer.id);
          // If the user is found, return the shortName if the event is organized by an organization, or the name if it's organized by an individual
          // If the user is not found, return an empty string
          return user ? (isOrganization ? user.shortName : user.name) : '';
        })
      : [];

      let submissionDisabled = false;
      if (eventId) {
        submissionDisabled = true;
        formState.organizers.forEach(organizer => {
          if (isOrganization) {
            const organization = clubsAndOrganizations.find(org => org.id === organizer.id);
            if (organization && organization.leadership && organization.leadership[userInfo.id]) {
              submissionDisabled = false;
              return;
            }
          } else {
            if (organizer.id === userInfo.id) {
              submissionDisabled = false;
              return;
            }
          }
        });
      }

  return (
    <>
    {Object.keys(errors).map((errorKey) => (
      <p className="tw-text-red-500">{errors[errorKey]}</p>
    ))}
    <form onSubmit={handleSubmit} className="tw-space-y-12">
      <div className="tw-border-b tw-border-gray-900/10 tw-pb-12">
        <div className="tw-mt-10 tw-grid tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6">
          <div className="sm:tw-col-span-4">
            <label htmlFor="eventName" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
              Event Name <span className="tw-text-red-500">*</span>
            </label>
            <div className="tw-mt-2">
              <div className="tw-flex tw-rounded-md tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-600 sm:tw-max-w-md">
                <input
                  type="text"
                  name="eventName"
                  id="eventName"
                  autoComplete="eventName"
                  className="tw-block tw-flex-1 tw-border-0 tw-bg-transparent tw-py-1.5 tw-pl-1 tw-text-gray-900 tw-placeholder:text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6"
                  placeholder="Enter event name"
                  value={formState.eventName}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="tw-col-span-full">
            <label htmlFor="eventDescription" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
              Event Description
            </label>
              <p className="tw-mt-3 tw-text-sm tw-leading-6 tw-text-gray-600">Write a few sentences about the event.</p>
            <div className="tw-mt-2">
              <textarea
                id="eventDescription"
                name="eventDescription"
                rows={3}
                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                defaultValue={formState.eventDescription}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="tw-col-span-full">
            {formState.image ? (
              <label htmlFor="image" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Update Event Image
              </label>
            ) : (
              <label htmlFor="image" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Event Image <span className="tw-text-red-500">*</span>
              </label>
            )}
            <div className="tw-mt-2 tw-flex tw-items-center tw-gap-x-3">
              <UserCircleIcon className="tw-h-12 tw-w-12 tw-text-gray-300" aria-hidden="true" />
              <input
                type="file"
                name="image"
                id="image"
                accept=".png, .jpg, .jpeg, .heic"
                onChange={handleFileChange}
                className="tw-rounded-md tw-bg-white tw-px-2.5 tw-py-1.5 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50"
              />
            </div>
          </div>

          <div className="sm:tw-col-span-3">
            <label htmlFor="eventType" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
              Event Type
            </label>
            <div className="tw-mt-2">
              <select
                id="eventType"
                name="eventType"
                autoComplete="eventType"
                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-max-w-xs sm:tw-text-sm sm:tw-leading-6"
                value={formState.eventType}
                onChange={handleChange}
              >
                {eventTypeValues.map((value, index) => (
                  <option key={index} value={value}>{value}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="tw-col-span-full">
            <label htmlFor="address" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
              Event Address
            </label>
            <div className="tw-mt-2">
              <input
                type="text"
                name="address"
                id="address"
                autoComplete="address"
                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                placeholder="Enter event address"
                value={formState.address}
                onChange={handleChange}
              />
            </div>
          </div>


          <div className="tw-col-span-full">
            <label htmlFor="eventLink" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
              External Event Link
            </label>
            <div className="tw-mt-2">
              <input
                type="text"
                name="eventLink"
                id="eventLink"
                autoComplete="eventLink"
                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                placeholder="Enter external event link"
                value={formState.eventLink}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="sm:tw-col-span-2">
            <label htmlFor="startDate" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
              Start Date <span className="tw-text-red-500">*</span>
            </label>
            <div className="tw-mt-2">
              <input
                type="date"
                name="startDate"
                id="startDate"
                autoComplete="startDate"
                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                value={formState.startDate}
                onChange={handleChange}
                required
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
          </div>

          <div className="sm:tw-col-span-2">
            <label htmlFor="startTime" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
              Start Time <span className="tw-text-red-500">*</span>
            </label>
            <div className="tw-mt-2">
              <input
                type="text"
                name="startTime"
                id="startTime"
                autoComplete="startTime"
                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                placeholder="Enter start time"
                value={formState.startTime}
                onChange={handleChange}
              />
            </div>
          </div>

        

          <div className="tw-col-span-full">
          {clubsAndOrganizations.length > 0 && (
            <Switch.Group as="div" className="tw-flex tw-items-center">
              <Switch
                checked={isOrganization}
                onChange={handleSwitchChange}
                className={classNames(
                  isOrganization ? 'tw-bg-indigo-600' : 'tw-bg-gray-200',
                  'tw-relative tw-inline-flex tw-h-6 tw-w-11 tw-flex-shrink-0 tw-cursor-pointer tw-rounded-full tw-border-2 tw-border-transparent tw-transition-colors tw-duration-200 tw-ease-in-out focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-600 focus:tw-ring-offset-2'
                )}
              >
                {/* <span className="sr-only">Use setting</span> */}
                <span
                  className={classNames(
                    isOrganization ? 'tw-translate-x-5' : 'tw-translate-x-0',
                    'tw-pointer-events-none tw-relative tw-inline-block tw-h-5 tw-w-5 tw-transform tw-rounded-full tw-bg-white tw-shadow tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out'
                  )}
                >
                  <span
                    className={classNames(
                      isOrganization ? 'tw-opacity-0 tw-duration-100 tw-ease-out' : 'tw-opacity-100 tw-duration-200 tw-ease-in',
                      'tw-absolute tw-inset-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-transition-opacity'
                    )}
                    aria-hidden="true"
                  >
                    <svg className="tw-h-3 tw-w-3 tw-text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path
                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span
                    className={classNames(
                      isOrganization ? 'tw-opacity-100 tw-duration-200 tw-ease-in' : 'tw-opacity-0 tw-duration-100 tw-ease-out',
                      'tw-absolute tw-inset-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-transition-opacity'
                    )}
                    aria-hidden="true"
                  >
                    <svg className="tw-h-3 tw-w-3 tw-text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
              <Switch.Label as="span" className="tw-ml-3 tw-text-sm">
                <span className="tw-font-medium tw-text-gray-900">Club or Organization event?</span>{' '}
              </Switch.Label>
            </Switch.Group>
          )}
          {isOrganization &&  <label htmlFor="organizers" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900 tw-pt-5">
              Organizers
            </label>}
            {formState.organizers && (
              isOrganization ? (
                <div className="tw-flex tw-flex-wrap">
                  {formState.organizers.map((organizer, index) => {
                    const selectedColor = colorArray[index % 7];
                    return (
                      <span 
                        className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2 tw-cursor-pointer tw-hover:tw-bg-${selectedColor}-100`}
                      >
                        {organizer.shortName}
                      </span>
                    );
                  })}
                </div>
              ) : (
                // <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                //   Selected: {Array.isArray(organizerNames) ? organizerNames.join(', ') : formState.organizers}
                // </div>
                <></>
              )
            )}
            {isOrganization ? (
              <MultiComboBoxInput
                items={clubsAndOrganizations}
                label="organizers"
                selectedValues={formState.organizers}
                setFilters={handleMultiChange}
                singleSelect={false}
                filterKey={"shortName"}
                hideOwnValue={true}
              />
            ) : (
              // <MultiComboBoxInput
              //   items={users}
              //   label="organizers"
              //   selectedValues={formState.organizers}
              //   setFilters={handleMultiChange}
              //   singleSelect={false}
              //   filterKey={"title"}
              // />
              <></>
            )}
          </div>
        </div>
        {Object.keys(errors).map((errorKey) => (
      <p className="tw-text-red-500">{errors[errorKey]}</p>
    ))}
      <div className="tw-mt-6 tw-flex tw-items-center tw-justify-end tw-gap-x-6">
        {/* <button type="button" className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">
          Cancel
        </button> */}
        <button
          type="submit"
          className="tw-rounded-md tw-bg-indigo-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm tw-hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
          disabled={submissionDisabled}
        >
          {eventId ? 'Edit Event' : 'Create Event'}
        </button>
      </div>
      </div>

    </form>
    </>
  );
};

export default NewEventForm;

