import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import Loading from '../components/Loading';
import NewEventForm from '../components/NewEventForm';
import heic2any from 'heic2any';
import { useNavigate } from 'react-router-dom';
import { classNames, sortValuesAlphabetically, toLocalDate } from '../utils/helperFunctions';
import { useParams } from 'react-router-dom';


const NewEvent = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [isOrganization, setIsOrganization] = useState(false)
    // const { createEvent, getEventData, eventData } = useUser();
    const { createEvent, updateEvent, events, directory, userInfo, clubs, organizations, user } = useUser();
    const event = events.find(event => event.id === id);
    const userId = user ? user.uid : "";
    const users = directory.map(user => ({
      id: user.id,
      title: `${user.name} / ${user.program}`
    }));
    const currentUser = users.find(user => user.id === userId);
    const [formState, setFormState] = useState({
        eventName: event ? event.eventName : '',
        image: event ? event.image : '',
        neighborhood: event ? event.neighborhood : '',
        address: event ? event.address : '',
        eventType: event ? event.eventType : 'Professional',
        eventLink: event ? event.eventLink : '',
        eventDescription: event ? event.eventDescription : '',
        startTime: event ? event.startTime : '',
        startDate: event ? (typeof event.startDate === 'object' && event.startDate.hasOwnProperty('_seconds') ? new Date(event.startDate._seconds * 1000).toISOString().split('T')[0] : new Date(event.startDate).toISOString().split('T')[0]) : new Date(new Date().setHours(0,0,0,0) + 24*60*60*1000).toISOString().split('T')[0],       
        // startDate: event ? new Date(event.startDate).toISOString().split('T')[0] : new Date(new Date().setHours(0,0,0,0) + 24*60*60*1000), 
        organizers: event && event.organizers.length > 0 ? event.organizers : currentUser ? [currentUser] : [],
        attendees: event ? event.attendees : [],
        isOrganization: event ? event.isOrganization : false,
      });
      // console.log(event.startDate);
      // console.log(new Date(event.startDate).toISOString().split('T')[0])
      // if(event && event.startDate && event.startDate._seconds) {
      //   console.log(new Date(event.startDate._seconds * 1000));
      // }
      // .toISOString().split('T')[0]
      const clubsAndOrganizations = [
        ...clubs.filter(club => club.leadership && Array.isArray(club.leadership) && club.leadership.some(leader => leader.id === user.uid)),
        ...organizations.filter(org => org.members && org.members[user.uid])
      ].sort((a, b) => a.fullName.localeCompare(b.fullName));
      const [searchTexts, setSearchTexts] = React.useState({
        'organizers': '',
      });
      // {name: userInfo.name, 
      //   id: userInfo.id}
      
    //   useEffect(() => {
        
    //     const fetchData = async () => {
    //       await getSignUpData();
    //     };
    //     fetchData();
    //   }, []);

    useEffect(() => {
        if(event) {
            setIsOrganization(event.isOrganization);
            setFormState(
              {
                eventName: event ? event.eventName : '',
                image: event ? event.image : '',
                neighborhood: event ? event.neighborhood : '',
                address: event ? event.address : '',
                eventType: event ? event.eventType : 'Professional',
                eventLink: event ? event.eventLink : '',
                eventDescription: event ? event.eventDescription : '',
                startTime: event ? event.startTime : '',
                startDate: event ? (typeof event.startDate === 'object' && event.startDate.hasOwnProperty('_seconds') ? new Date(event.startDate._seconds * 1000).toISOString().split('T')[0] : new Date(event.startDate).toISOString().split('T')[0]) : new Date(new Date().setHours(0,0,0,0) + 24*60*60*1000).toISOString().split('T')[0],       
                // startDate: event ? new Date(event.startDate).toISOString().split('T')[0] : new Date(new Date().setHours(0,0,0,0) + 24*60*60*1000), 
                organizers: event && event.organizers.length > 0 ? event.organizers : currentUser ? [currentUser] : [],
                attendees: event ? event.attendees : [],
                isOrganization: event ? event.isOrganization : false,
              }
            )
        }
    }, [event]);
    
      const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
     
    
        if (type === 'checkbox') {
          setFormState(prevState => {
            const previousValue = prevState[name];
            const newValue = checked ? [...previousValue, value] : previousValue.filter(v => v !== value);
    
            return {
              ...prevState,
              [name]: newValue
            };
          });
        } else {
          setFormState(prevState => ({
            ...prevState,
            [name]: value
          }));
        }
      };

      const otherUpdateState = (key,value) => {
        setFormState(prevState => ({
            ...prevState,
            [key]: value
          }));
      }

      const handleRadioChange = (event) => {
        if(event.target) {
            const {name, value } = event.target;
          
            setFormState(prevState => ({
              ...prevState,
              [name]: value
            }));
        }
      };

      const handleSwitchChange = (event) => {
        setIsOrganization(!isOrganization);
        setFormState(prevState => ({
          ...prevState,
          isOrganization: !prevState.isOrganization,
          organizers: []
        }));
      };

      const validateEventForm = () => {
        let errors = {};
        if (!formState.eventName) errors.eventName = "Event name is required";
        if (!id && !formState.eventPhoto) errors.eventImage = "Event image is required";
        if (!formState.startTime) errors.startTime = "Start time is required";
        return errors;
      };

      const handleSubmit = async (event) => {
        event.preventDefault();
        if (!formState.organizers || formState.organizers.length === 0) {
          formState.organizers = [{ name: userInfo.name, id: userInfo.id }];
        }
        const validationErrors = validateEventForm();
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length > 0) {
          // There are errors, don't submit the form
          return;
        }
        const formData = new FormData();
        Object.keys(formState).forEach(key => {
          if (key === 'organizers') {
            // Serialize the organizers array
            if (formState[key].length === 0) {
              formState[key] = [{ id: user.uid }];
            }
            formData.append(key, JSON.stringify(formState[key]));
          } else {
            formData.append(key, formState[key]);
          }
        });
        // formData.append('isOrganization', isOrganization)


        try {
            if(id) {
                formData.append('id', id);
                await updateEvent(formData);
                navigate(`/events/${id}`)
            } else {
                setLoading(true);
                try {
                    await createEvent(formData);
                    navigate(`/events`);
                } catch (error) {
                    console.error('Error creating event:', error);
                } finally {
                    setLoading(false);
                }
            }
            // navigate('/events');
        } catch (error) {
            console.error('Error creating event:', error);
        }
      };
      const handleFileChange = async (event) => {
        const file = event.target.files[0];
    
        if (file.type === 'image/heic') {
            const convertedFile = await heic2any({
              blob: file,
              toType: 'image/jpeg', // or 'image/png'
              quality: 0.8 // optional, default is 0.8
            });
            file = new File([convertedFile], file.name, { type: 'image/jpeg' }); // or 'image/png'
          }
        
        setFormState(prevState => ({
          ...prevState,
          eventPhoto: file
        }));
      };
      const handleSearchTextChange = (filterKey, newSearchText) => {
        setSearchTexts(prevTexts => ({
          ...prevTexts,
          [filterKey]: newSearchText
        }));
      };

      const handleMultiChange = (key, item) => {
        setFormState(prevState => {
          const previousValues = prevState[key];
          let newValues;
          if (previousValues.some(i => i.id === item.id)) {
            newValues = previousValues.filter(i => i.id !== item.id);
          } else {
            newValues = [...previousValues, item];
          }
          return {
            ...prevState,
            [key]: newValues
          };
        });
      };

      const handleCheckboxChange = (key) => (event) => {
        const { value, checked } = event.target;
        setFormState(prevState => {
          const previousValues = prevState[key];
          let newValues;
          if (checked) {
            if (previousValues.includes('')) {
              const index = previousValues.indexOf('');
              if (index > -1) {
                previousValues.splice(index, 1);
              }
            }
            newValues = [...previousValues, value];
          } else {
            newValues = Array.isArray(previousValues) ? previousValues.filter(v => v !== value) : [];
          }
          newValues = sortValuesAlphabetically(newValues);
          return {
            ...prevState,
            [key]: newValues
          };
        });
      };

      const navigateToOrganization = (id) => {
        navigate(`/organizations/${id}`);
      };

    //   if(!signUpData || signUpData.length === 0 || (!Array.isArray(signUpData) && !signUpData.program)){
    
    // }

    if (loading || (id && !event)) {
        return(<Loading />)
    }

    return (
        <div className="tw-mx-auto tw-px-4 tw-bg-white tw-rounded-xl tw-shadow-md tw-overflow-auto tw-mb-8">
            <div className="tw-px-4 tw-py-5 tw-sm:p-6">
                <h1 className="tw-text-center tw-text-2xl tw-font-bold tw-text-gray-900">{id ? 'Edit Event' : 'New Event'}</h1>
                {!id && 
                <h3 className="tw-text-left tw-text-gray-500">Note: All Events are currently public on the platform</h3>}
            </div>
            <div className="tw-flex tw-justify-between">
          <button 
          className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" 
          onClick={() => navigate(-1)}>Back</button>
        </div>
            <NewEventForm 
            formState={formState} 
            handleChange={handleChange} 
            handleSubmit={handleSubmit}
            handleFileChange={handleFileChange}
            handleSearchTextChange={handleSearchTextChange}
            handleCheckboxChange={handleCheckboxChange}
            handleMultiChange={handleMultiChange}
            searchTexts={searchTexts}
            users={users}
            directory={directory}
            handleRadioChange={handleRadioChange}
            otherUpdateState={otherUpdateState}
            eventId={id}
            isOrganization={isOrganization} 
            handleSwitchChange={handleSwitchChange}
            clubsAndOrganizations={clubsAndOrganizations}
            classNames={classNames}
            navigateToOrganization={navigateToOrganization}
            errors={errors}
            setErrors={setErrors}
            userInfo={userInfo}
            />
        </div>
    );
};

export default NewEvent;

