import { DateTime } from 'luxon';

export const handleChangeHelper = (event, setFunction) => {
  const { name, value, type, checked } = event.target;

  if (type === 'checkbox') {
    setFunction(prevState => {
      const previousValue = prevState[name];
      const updatedValue = checked ? [...previousValue, value] : previousValue.filter(v => v !== value);

      return {
        ...prevState,
        [name]: updatedValue
      };
    });
  } else if (type === 'date') {
    const formattedDate = value ? DateTime.fromISO(value).toFormat('yyyy-MM-dd') : '';
      setFunction(prevState => ({
        ...prevState,
        [name]: formattedDate
      }));
  } else {
    setFunction(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
};

export const handleMultiComboChangeHelper = (name, value, setFunction) => {
      setFunction(prevState => ({
        ...prevState,
        [name]: value
      }));
  };

export const handleSingleChangeHelper = (name, item, setFunction) => {
  const lowerName = name.toLowerCase();
  setFunction(prevState => ({
    ...prevState,
    [lowerName]: item
  }));
}

export const handleWebsiteBlurHelper = (formState, setFormErrors) => {
  if (formState.website && !validateWebsite(formState.website)) {
      setFormErrors(prevErrors => ({ ...prevErrors, website: 'Website is not valid.' }));
  } else {
      setFormErrors(prevErrors => ({ ...prevErrors, website: '' }));
  }
};

export const validateWebsite = (website) => {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name and extension
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?'+ // port
        '(\\/[-a-z\\d%_.~+]*)*'+ // path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return pattern.test(website);
  };

export const objectArraySort = (obj) => {
  const keyValueArray = Object.entries(obj);

// Step 2: Sort the array based on the key name
keyValueArray.sort((a, b) => a[0].localeCompare(b[0]));

// Step 3: Map the sorted array back to an array of values
const sortedValues = keyValueArray.map(([key, value]) => value);
return sortedValues
}
