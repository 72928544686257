import { useState, useEffect } from 'react';
import axios from 'axios';
import { app, fireStoreDB } from '../../firebase/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const useRecruitingContext = () => {
//   const [brewWorksPageFilters, setBrewWorksPageFilters] = useState({
//   });
  const [openJobs, setOpenJobs] = useState([]);
  const [openJobsLoading, setOpenJobsLoading] = useState(false);
  const [openJobsPageSearchTerm, setOpenJobsPageSearchTerm] = useState('');
  const [savedUserJobs, setSavedUserJobs] = useState([]);
  const [savedIgnoredJobs, setSavedIgnoredJobs] = useState([]);
  const [clubRecruitingTeams, setClubRecruitingTeams] = useState([]);
  const [jobStatus, setJobStatus] = useState('Saved');

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, async (incomingUser) => {
      if (incomingUser) { // Fetch data only if user is logged in
        try {
          //yi xiaos
          const idToken = await incomingUser.getIdToken(true);
        //   const [brewDataResponse] = await Promise.all([
        //     axios.get(`${API_BASE_URL}/brew`, {
        //       headers: { 'Authorization': `Bearer ${idToken}` }
        //     }),
        //   ]);  
        //   const brewData = brewDataResponse.data;
        //   setBrewWorksLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        setOpenJobsLoading(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);


  const getOpenJobs = async (ids) => {
    const auth = getAuth(app);
    try {
        
      const idToken = await auth.currentUser.getIdToken(true);
      ids = ids ? ids: [auth.currentUser.uid];
      const response = await axios.get(`${API_BASE_URL}/open-jobs`, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        params: { ids },
      });
      setOpenJobs(prevJobs => {
        const updatedJobs = prevJobs.map(prevJob => {
          const foundJob = response.data.find(job => job.id === prevJob.id);
          return foundJob || prevJob;
        });
        const newJobs = response.data.filter(job => !prevJobs.some(prevJob => prevJob.id === job.id));
        return [...updatedJobs, ...newJobs];
      });
    } catch (error) {
      console.error('Error fetching open jobs:', error);
    }
  };

  const getUserJobs = async () => {
    const auth = getAuth(app);
    try {
        
      const idToken = await auth.currentUser.getIdToken(true);
      let id = auth.currentUser.uid;
      const response = await axios.get(`${API_BASE_URL}/user-jobs`, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        params: { id },
      });
      const savedJobsArray = Object.values(response.data.savedJobs);
      setSavedUserJobs(savedJobsArray);
    } catch (error) {
      console.error('Error fetching open jobs:', error);
    }
  };

  const addJobs = async (formData) => {
    const auth = getAuth(app);
    if (!formData) {
      formData = new FormData();
    }
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const userId = auth.currentUser.uid;
      const response = await axios.post(`${API_BASE_URL}/create-new-jobs`, formData, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        'Content-Type': 'multipart/form-data'
      });
      setOpenJobs(prevJobs => {
        const updatedJobs = prevJobs.map(prevJob => {
          const foundJob = response.data.find(job => job.id === prevJob.id);
          return foundJob || prevJob;
        });
        const newJobs = response.data.filter(job => !prevJobs.some(prevJob => prevJob.id === job.id));
        return [...updatedJobs, ...newJobs];
      });
    } catch (error) {
      console.error('Error adding new job:', error);
    }
  };

  const updateJob = async (formData) => {
    const auth = getAuth(app);
    if (!formData) {
      formData = new FormData();
    }
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.put(`${API_BASE_URL}/update-job`, formData, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        'Content-Type': 'multipart/form-data'
      });
      console.log(response.data,'rad')
      setOpenJobs(prevJobs => {
        const updatedJobs = prevJobs.map(prevJob => {
          if (prevJob.id === response.data.id) {
            return response.data;
          }
          return prevJob;
        });
        return updatedJobs;
      });
    } catch (error) {
      console.error('Error updating job:', error);
    }
  };

  const ignoreJob = async (jobId,ownerId) => {
    const auth = getAuth(app);
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const userId = auth.currentUser.uid;
      // Assuming ownerId needs to be fetched or is known beforehand. Placeholder value used here.
      const jobOwnerId = ownerId || userId;
      const response = await axios.put(`${API_BASE_URL}/ignore-job`, {}, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        params: { userId, jobId, jobOwnerId },
      });
      setOpenJobs(prevJobs => {
        const updatedJobs = prevJobs.map(job => {
          if (job?.id === response?.data?.jobToUpdate?.id) {
            return { ...job, ...(response?.data?.jobToUpdate || {}) };
          }
          return job;
        });
        return updatedJobs;
      });
      if (response?.data?.updatedUserDoc?.savedJobs) {
        const savedJobsArray = Object.values(response.data.updatedUserDoc.savedJobs);
        setSavedUserJobs(savedJobsArray);
      }
      if (response?.data?.updatedUserDoc?.savedIgnoredJobs) {
        const ignoredJobsArray = Object.values(response.data.updatedUserDoc.savedIgnoredJobs);
        setSavedIgnoredJobs(ignoredJobsArray);
      }
    } catch (error) {
      console.error('Error ignoring job:', error);
    }
  }

  const saveUserJob = async (jobId,ownerId) => {
    const auth = getAuth(app);
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const userId = auth.currentUser.uid;
      // Assuming ownerId needs to be fetched or is known beforehand. Placeholder value used here.
      const jobOwnerId = ownerId || userId;
      const response = await axios.put(`${API_BASE_URL}/save-user-job`, {}, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        params: { userId, jobId, jobOwnerId },
      });
      setOpenJobs(prevJobs => {
        const updatedJobs = prevJobs.map(job => {
          if (job?.id === response?.data?.jobToUpdate?.id) {
            return { ...job, ...(response?.data?.jobToUpdate || {}) };
          }
          return job;
        });
        return updatedJobs;
      });
      if (response?.data?.updatedUserDoc?.savedJobs) {
        const savedJobsArray = Object.values(response.data.updatedUserDoc.savedJobs);
        setSavedUserJobs(savedJobsArray);
      }
      if (response?.data?.updatedUserDoc?.savedIgnoredJobs) {
        const ignoredJobsArray = Object.values(response.data.updatedUserDoc.savedIgnoredJobs);
        setSavedIgnoredJobs(ignoredJobsArray);
      }
    } catch (error) {
      console.error('Error saving job:', error);
    }
  }


const getClubRecruitingTeams = async (clubIds) => {
    const auth = getAuth(app);
    const idToken = await auth.currentUser.getIdToken(true);
    const response = await axios.get(`${API_BASE_URL}/club-recruiting-teams`, {
      headers: { 'Authorization': `Bearer ${idToken}` },
      params: { clubIds },
    });
    setClubRecruitingTeams(response.data);
};
const addRecruitingTeam = async (clubId, year, type, teamName, clubName) => {
    const auth = getAuth(app);
    try {
      console.log(clubId, year, type, teamName)
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.post(`${API_BASE_URL}/add-recruiting-team`, {
        clubId, year, type, teamName, clubName
      }, {
        headers: { 'Authorization': `Bearer ${idToken}` },
      });
      console.log(response.data,'ART')
      console.log('Recruiting team added successfully:', response.data);
      setClubRecruitingTeams(prevTeams => {
        const teamExists = prevTeams.some(prevTeam => prevTeam.id === response.data.id);
        if (teamExists) {
          return prevTeams.map(prevTeam => prevTeam.id === response.data.id ? response.data : prevTeam);
        } else {
          return [...prevTeams, response.data];
        }
      });
    } catch (error) {
      console.error('Error adding recruiting team:', error);
    }
  };

  const addRecruitingTeamMember = async (clubId, year, teamId, memberName, memberId, type) => {
    const auth = getAuth(app);
    try {
      console.log(clubId, year, teamId, memberName, memberId);
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.post(`${API_BASE_URL}/add-recruiting-team-member`, {
        clubId, year, teamId, memberName, memberId, type
      }, {
        headers: { 'Authorization': `Bearer ${idToken}` },
      });
      console.log(response.data, 'ARTM');
      console.log('Recruiting team member added successfully:', response.data);
      setClubRecruitingTeams(prevTeams => {
        const updatedTeams = prevTeams.map(prevTeam => {
          if (prevTeam.id === response.data.id) {
            return response.data;
          }
          return prevTeam;
        });
        return [...updatedTeams];
      });
    } catch (error) {
      console.error('Error adding recruiting team member:', error);
    }
  };

  const updateUserJobStatus = async (userId, jobId, newStatus) => {
    const auth = getAuth(app);
    try {
      console.log(`Updating job status for user ${userId} and job ${jobId} to ${newStatus}`);
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.put(`${API_BASE_URL}/update-user-job-status`, {
        userId, jobId, newStatus
      }, {
        headers: { 'Authorization': `Bearer ${idToken}` },
      });
      console.log('Job status updated successfully:', response.data);
      const updatedUserJobsData = response.data.updatedUserJobsData || {};
      const savedJobs = updatedUserJobsData.savedJobs || [];
      const savedJobsArray = Object.values(savedJobs);
      setSavedUserJobs(savedJobsArray);
    } catch (error) {
      console.error('Error updating job status:', error);
    }
  };


  return {
    openJobs,
    savedUserJobs,
    savedIgnoredJobs,
    openJobsPageSearchTerm, 
    setOpenJobsPageSearchTerm,
    addJobs,
    updateJob,
    getOpenJobs,
    getUserJobs,
    saveUserJob,
    ignoreJob,
    clubRecruitingTeams,
    getClubRecruitingTeams,
    addRecruitingTeam,
    addRecruitingTeamMember,
    jobStatus,
    setJobStatus,
    updateUserJobStatus
  };
};
