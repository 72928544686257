import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../../firebase/firebase';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useEventContext = () => {
  const [events, setEvents] = useState([]);
  const [eventData, setEventData] = useState(null);
  const [myEventsTab, setMyEventsTab] = useState('Attending');
  const [activeTab, setActiveTab] = useState('Teahouse');
  const [activeDetailTab, setActiveDetailTab] = useState('info');
  const [selectedEvent, setSelectedEvent] = useState({});
  const [eventDetailFilters, setEventDetailFilters] = useState({
    "recruitingFor": [], 
    "interestsHobbies": [],
    // "professionalEventsAttended": [] 
  });
  const [eventDetailSearchTexts, setEventDetailSearchTexts] = React.useState({
    'schoolFiveYearGoals': '',
    'interestsHobbies': '',
    'recruitingFor': '',
    'whoIWantToMeet': '',
    'whyIAmAttending': ''
  });
  const [eventDetailSearchTerm, setEventDetailSearchTerm] = useState('');


  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, async (incomingUser) => {
      if (incomingUser) { // Fetch data only if user is logged in
        try {
          const userId = incomingUser.uid;
          const idToken = await incomingUser.getIdToken(true);
         //Placeholder
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const clearEventFilters = () => {
    setEventDetailFilters({
      "recruitingFor": [], 
      "interestsHobbies": [],
    });
    setEventDetailSearchTexts({
      'schoolFiveYearGoals': '',
      'interestsHobbies': '',
      'recruitingFor': '',
      'whoIWantToMeet': '',
      'whyIAmAttending': ''
    });
  };

  const createEvent = async (formData) => {
    const auth = getAuth(app);
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      formData.append('idToken', idToken);
      const response = await axios.post(`${API_BASE_URL}/create-event`, formData, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        'Content-Type': 'multipart/form-data'
      });
      const { data } = response.data;
      setEvents(prevEvents => {
        const updatedEvents = [...prevEvents, data];
        updatedEvents.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
        return updatedEvents;
      });
      return
      // // Handle success response
      // return data;
    } catch (error) {
      // Handle errors
      console.error('Error creating event:', error);
      // throw error;
    }
  };

  const joinEvent = async (userId, eventId) => {
    const auth = getAuth(app);
    const formData = new FormData();
    formData.append('userId', userId);
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.put(`${API_BASE_URL}/attend-event`, {
        userId: userId,
        eventId: eventId
      }, {
        headers: { 
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      });
      const { data } = response.data;
      // Handle success response
      // Update the event in the directory
      setEvents(prevEvents => {
        return prevEvents.map(event => 
          event.id === eventId ? {...event, attendees: event.attendees ? (event.attendees.includes(userId) ? event.attendees : [...event.attendees, userId]) : [userId]} : event
        );
      });
      return data;
    } catch (error) {
      // Handle errors
      console.error('Error joining event:', error);
      // throw error;
    }
  };

  const attendProfessionalEvent = async (formData, eventId, userId) => {
    const auth = getAuth(app);
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.put(`${API_BASE_URL}/attend-professional-event`, formData, {
        headers: { 
          'Authorization': `Bearer ${idToken}`
        }
      });
      const { data } = response.data;
      // Handle success response
      // Update the event in the directory
      setEvents(prevEvents => {
        return prevEvents.map(event => {
          if (event.id === eventId) {
            return {
              ...event,
              attendees: data
            };
          }
          return event;
        });
      });
      return data;
    } catch (error) {
      // Handle errors
      console.error('Error joining event:', error);
      // throw error;
    }
  };

  const favoriteAttendee = async (userId, favoriteId, eventId) => {
    const auth = getAuth(app);
    try {
      const idToken = await auth.currentUser.getIdToken();
      axios.put(`${API_BASE_URL}/favorite-attendee`, {
        userId: userId,
        favoriteId: favoriteId,
        eventId: eventId
      }, {
        headers: { 
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      }).catch(error => {
        console.error('Error favoriting attendee:', error);
      });
      let isFavorited;
      setEvents(prevEvents => {
        return prevEvents.map(event => {
          if (event.id === eventId) {
            let favorites = event.favorites || {};
            if (typeof favorites !== 'object' || Array.isArray(favorites)) {
              favorites = {}; // Reset to an empty object if it's not an object or if it's an array
            }
            if (favorites[userId]) {
              if (favorites[userId][favoriteId]) {
                // Remove favoriteId from the user's favorites
                delete favorites[userId][favoriteId];
                isFavorited = false;
              } else {
                // Add favoriteId to the user's favorites
                favorites[userId][favoriteId] = true;
                isFavorited = true;
              }
            } else {
              // Create a new map for the user with favoriteId
              favorites[userId] = {};
              favorites[userId][favoriteId] = true;
            }
            return {...event, favorites: favorites};
          }
          return event;
        });
      });
      isFavorited ? setActiveDetailTab('favorites') : setActiveDetailTab('attending')
      // Assume success response and update the event in the directory
    } catch (error) {
      // Handle errors
      console.error('Error favoriting attendee:', error);
      // throw error;
    }
  };


 
  const leaveEvent = async (userId, eventId) => {
    const auth = getAuth(app);
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.put(`${API_BASE_URL}/leave-event`, {
        userId: userId,
        eventId: eventId
      }, {
        headers: { 
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      });
      const { data } = response.data;
      // Handle success response
      // Update the event in the directory
      setEvents(prevEvents => {
        return prevEvents.map(event => {
          if (event.id === eventId) {
            let updatedAttendees = { ...event.attendees };
            if (updatedAttendees[userId]) {
              delete updatedAttendees[userId];
            }
            return { ...event, attendees: updatedAttendees };
          } else {
            return event;
          }
        });
      });
      return data;
    } catch (error) {
      // Handle errors
      console.error('Error leaving event:', error);
      // throw error;
    }
  };

  const updateEvent = async (formData) => {
    const auth = getAuth(app);
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      formData.append('idToken', idToken);
      const response = await axios.put(`${API_BASE_URL}/update-event`, formData, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        'Content-Type': 'multipart/form-data'
      });
      setEvents(response.data);
      return
      // // Handle success response
      // return data;
    } catch (error) {
      // Handle errors
      console.error('Error updating event:', error);
      // throw error;
    }
  };



  return {
    events,
    setEvents,
    eventData, 
    setEventData,
    selectedEvent, 
    setSelectedEvent,
    myEventsTab, 
    setMyEventsTab,
    activeTab, 
    setActiveTab,
    activeDetailTab, 
    setActiveDetailTab,
    eventDetailFilters, 
    setEventDetailFilters,
    eventDetailSearchTexts, 
    setEventDetailSearchTexts,
    eventDetailSearchTerm, 
    setEventDetailSearchTerm,
    // Functions
    createEvent,
    joinEvent,
    attendProfessionalEvent,
    favoriteAttendee,
    leaveEvent,
    updateEvent,
    clearEventFilters
  };
};
