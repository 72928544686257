import React from 'react';

const FormCheckboxes = ({ values, searchText, onSearchTextChange, searchKey, selectedValues, onChange }) => {
    const filteredValues = React.useMemo(() => {
        if (!searchText) return values;
        return values.filter(value =>
            value.toLowerCase().includes(searchText.toLowerCase())
        );
    }, [values, searchText]);
    return (
        <div>
                <input
                type="text"
                value={searchText}
                onChange={e => onSearchTextChange(searchKey, e.target.value)}
                placeholder={`Search...`}
                className="profile-form__checkbox-search"
            />
            <div className={`profile-form__checkbox-list`}>
                {filteredValues.map((value, index) => (
                    <div className="profile-form__checkbox-list-item" key={index}>
                        <input
                            type="checkbox"
                            id={`checkbox-${index}-${value}`}
                            checked={selectedValues.includes(value)}
                            onChange={onChange}
                            value={value}
                        />
                        <label htmlFor={`checkbox-${index}`}>{value}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FormCheckboxes;
