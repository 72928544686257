// src/pages/Profile.js
import React, { useState, useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import Loading from '../components/Loading';
import ProfileForm from '../components/ProfileForm';
import SuccessfulNoti from '../components/notifications/SuccessfulNoti';
import { handleFilterChange } from '../utils/helperFunctions';

const Profile = () => {
    // const [formState, setFormState] = useState({
    //     name: user.name || '',
    //     email: user.email || '',
    //     // ... add the rest of your fields here
    //   });
    
    //   const handleChange = (event) => {
    //     setFormState({
    //       ...formState,
    //       [event.target.name]: event.target.value,
    //     });
    //   };
    const { userInfo, user, 
        directory, updateUserProfile, signUpData,
        updateDirectory,
        directoryFilters: filters, 
    setDirectoryFilters: setFilters, getSignUpData } = useUser();
    const [resetMessage, setResetMessage] = useState('Profile Updated');
    const [showSuccessNoti, setShowSuccessNoti] = useState(false);
    const [profileFilters, setProfileFilters] = useState({
        "program": userInfo && userInfo.program || [], 
        "country": userInfo && userInfo.country || [], 
        "recruitingFor": userInfo && userInfo.recruitingFor || [], 
        "interestsHobbies": userInfo && userInfo.interestsHobbies || [],
        // "professionalEventsAttended": [] 
      });
    //   console.log(profileFilters)
    //   console.log(userInfo)
    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     onSubmit(formState);
    //   };
    useEffect(() => {
        const fetchSignUpData = async () => {
          if(!signUpData || !signUpData.interests) {
            getSignUpData()
          }
        };
        fetchSignUpData();
      }, [signUpData]);
    useEffect(() => {
        if (userInfo) {
            setProfileFilters({
                "program": userInfo.program || [], 
                "country": userInfo.country || [], 
                "recruitingFor": userInfo.recruitingFor || [], 
                "interestsHobbies": userInfo.interestsHobbies || [],
            });
        }
    }, [userInfo]);
    if (!userInfo || !user) {
        return <Loading />;
    }
    return (
        <>{showSuccessNoti && <SuccessfulNoti show={showSuccessNoti} setShow={setShowSuccessNoti} message={resetMessage} />}
        <ProfileForm userInfo={userInfo} user={user}
        directory={directory} updateUserProfile={updateUserProfile} 
        updateDirectory={updateDirectory}
        signUpData={signUpData}
        setResetMessage={setResetMessage} setShowSuccessNoti={setShowSuccessNoti}
        filters={profileFilters}
        setFilters={setProfileFilters}
        handleFilterChange={handleFilterChange} />
        </>
      );
};

export default Profile;
