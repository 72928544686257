export const industryValues = 
[
    
  "Apparel / Textiles",
  "Automotive",
  "Commercial Real Estate",
  "Consumer Packaged Goods (CPG)",
  "Construction",
  "Consulting",
  "Defense",
  "E-Commerce",
  "Education",
  "Energy / Utilities / Alt. Energy",
  "Entertainment",
  "Financial Services",
  "Gaming",
  "Government",
  "Healthcare - General / Providers and Services",
  "Healthcare - Tech / Other",
  "Insurance",
  "Investment Banking",
  "Investment Management",
  "Manufacturing",
  "Non-Commercial Real Estate",
  "Non-Profit",
  "Other",
  "Pharmaceuticals",
  "Private Equity",
  "Real Estate",
  "SAAS",
  "Sports",
  "Technology",
  "Venture Capital"
]

export const roleValues = 
[
  "Brand Manager",
  "Business Analyst",
  "Business Development",
  "Corporate Strategy",
  "Corporate Finance",
  "Data Analyst",
  "Data Scientist",
  "Entrepreneur",
  "Executive",
  "Human Resources",
  "Investment Banker",
  "Management Consultant",
  "Marketing",
  "Manager",
  "Operations",
  "Product Manager",
  "Project Manager",
  "Program Manager",
  "Sales",
  "Supply Chain",
  "Venture Capital / Private Equity",
]

