import { PhoneIcon, EnvelopeIcon } from '@heroicons/react/20/solid'
import React, { useEffect, useState } from 'react';
import { classNames } from '../../utils/helperFunctions';
import { DateTime } from "luxon";


export default function ActionsTimeline({ timeline, updateForm }) {
  return (
    <div className="tw-flow-root">
      <ul role="list" className="tw-mb-8">
        {timeline.map((event, eventIdx) => (
          <li 
          className="hover:tw-cursor-pointer" 
          key={event.actionId} 
          onClick={() => updateForm(event)}>
            <div className="tw-relative tw-pb-8">
              {eventIdx !== timeline.length - 1 ? (
                <span className="tw-absolute tw-left-4 tw-top-4 tw-ml-px tw-h-full tw-w-0.5 tw-bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="tw-relative tw-flex tw-space-x-3">    
                <div>
                  <span
                    className={`${event.actionType === "emailed" ? 'tw-bg-blue-500' : 'tw-bg-green-500'} tw-h-8 tw-w-8 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-ring-8 tw-ring-white`}
                  >
                    {event.actionType === "emailed" ? (
                      <EnvelopeIcon className="tw-h-5 tw-w-5 tw-text-white" aria-hidden="true" />
                    ) : (
                      <PhoneIcon className="tw-h-5 tw-w-5 tw-text-white" aria-hidden="true" />
                    )}
                  </span>
                </div>
                <div className="tw-grid tw-grid-cols-5 tw-gap-6 tw-pt-1.5">
                  <div className="tw-col-span-3">
                    <p className="tw-text-sm tw-text-gray-500">
                      <time dateTime={event.actionDate}>{DateTime.fromISO(event.actionDate).toLocaleString({ month: 'short', day: 'numeric' })}:  </time>
                      {event.notes ? event.notes + "" : event.actionType === "emailed" ? "Emailed" : "Called"}
                    </p>
                  </div>
                  <div className="tw-text-sm tw-text-gray-500">
                    
                  </div>
                  <div> {/* This div intentionally left blank for spacing */} </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
