import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import Loading from '../../components/Loading';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../../firebase/firebase';
import { useContactClickHandler } from '../../utils/handleClicks';
import { PaperClipIcon, EnvelopeIcon, PhoneIcon, GlobeAltIcon } from '@heroicons/react/20/solid'
import { classNames } from '../../utils/helperFunctions';
import ReactGA from 'react-ga4';

export default function SchoolClub() {
  const { clubs, user, userInfo, joinClub, 
    getPendingMembers, approveMember, rejectMember, promoteMember} = useUser();
  const [schoolClubData, setSchoolClubData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const handleContactClick = useContactClickHandler();
  const [pendingMembersAdded, setPendingMembersAdded] = useState(false);
  const [clubTab, setClubTab] = useState('Members');

  useEffect(() => {
    if (clubs) {
      const schoolClub = clubs.find(schoolClub => schoolClub.id === id);
      if (schoolClub) {
        setSchoolClubData({ ...schoolClub });
        if (schoolClubData && schoolClubData.leadership.some(leader => leader.id === userInfo.id) && !pendingMembersAdded) {
          getPendingMembers(id);
          setPendingMembersAdded(true);
        }
      }
    }
  }, [clubs, id]);

  
  const joinSchoolClub = () => {
    const clubId = id;
    const userObj = {
      profilePhoto: userInfo.profilePhoto,
      name: userInfo.name,
      id: userInfo.id
    }
    joinClub(clubId,userObj)

    ReactGA.event({
      category: 'Club',
      action: 'Join Club',
      label: `User ${userInfo.id} Joined Club ${schoolClubData.clubName} - ${clubId}`,
    });
  }

  const handleApproveMember = (memberId) => {
    approveMember(id, memberId)
    ReactGA.event({
      category: 'Club',
      action: 'Approve Member',
      label: `User ${memberId} approved ${id} for ${schoolClubData.clubName}`,
    });
  }
  
  const handleRejectMember = (memberId) => {
    rejectMember(id, memberId);
    ReactGA.event({
      category: 'Club',
      action: 'Approve Member',
      label: `User ${memberId} rejected ${id} for ${schoolClubData.clubName}`,
    });
  }

  const handlePromoteMember = (memberId) => {
    promoteMember(id, memberId);
    ReactGA.event({
      category: 'Club',
      action: 'Promote Member',
      label: `User ${memberId} promoted in ${schoolClubData.clubName}`,
    });
  }

  if (!schoolClubData || !user) {
    return <Loading />;
  }

  const pendingMembers = schoolClubData.pendingMembers ? schoolClubData.pendingMembers.filter(member => !member.isDenied ) : [];
  const appliedToClub = userInfo.pendingClubMemberships && userInfo.pendingClubMemberships.includes(id) || schoolClubData.leadership.some(leader => leader.id === userInfo.id);
  const clubTabs = ['Members', 
  ...(pendingMembers.length > 0 && schoolClubData.leadership.some(leader => leader.id === userInfo.id) ? ['Pending'] : [])
  , ...(schoolClubData.leadership.length > 0 ? ['Leaders'] : [])
  , ...(schoolClubData.leadership.some(leader => leader.id === userInfo.id) ? ['Manage Members'] : [])
];
  

  let sortedMembers = [];
  if (schoolClubData.members && Array.isArray(schoolClubData.members)) {
    sortedMembers = [...schoolClubData.members].sort((a, b) => a.name.localeCompare(b.name));
  }

  let sortedLeadership = [];
  if (schoolClubData.leadership && Array.isArray(schoolClubData.leadership)) {
    sortedLeadership = [...schoolClubData.leadership].sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <>
        <div className="tw-flex tw-justify-between tw-pb-5">
          <button className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => navigate(-1)}>Back</button>
          {!appliedToClub && <button className="tw-bg-green-500 hover:tw-bg-green-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={joinSchoolClub}>Join</button>}
        </div>
    <div>
      <div className="tw-px-4 sm:tw-px-0 tw-flex tw-items-center">
        <div className="">
          <h1 className="tw-text-2xl tw-font-semibold tw-leading-7 tw-text-gray-900">{schoolClubData.clubName}</h1>
          {schoolClubData.shortName && <h3 className="tw-text-xl tw-font-semibold tw-leading-7 tw-text-gray-900">{`"${schoolClubData.shortName}"`}</h3>}
          <a href={schoolClubData.website} target="_blank" rel="noopener noreferrer" className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-6 tw-text-blue-500 hover:tw-text-blue-700">
            {"Club Website"}
            </a>
          {schoolClubData.contactEmail && 
            <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-6 tw-text-gray-500">Contact Email: {schoolClubData.contactEmail}</p>
          }
        </div>
      </div>
      <div>
      <hr className="tw-my-4" />
        <div className="tw-mt-4">
          <h2 className="tw-text-xl tw-font-semibold tw-leading-7 tw-text-gray-900">Club Description</h2>
          <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-6 tw-text-gray-500">{schoolClubData.description}</p>
        </div>
      </div>
      <div className="tw-block  tw-pb-5 lg:tw-pb-10">
        <div className="tw-border-b tw-border-gray-200">
          <nav className="tw-mb-px tw-flex tw-justify-between" aria-label="Tabs">
            <div className="tw-flex tw-space-x-8">
              {clubTabs.map((tabName) => (
                <div
                  key={tabName}
                  onClick={(event) => {
                    event.preventDefault();
                    setClubTab(tabName);
                  }}
                  className={classNames(
                      clubTab === tabName
                      ? 'tw-border-indigo-500 tw-text-indigo-600 tw-cursor-pointer'
                      : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700 tw-cursor-pointer',
                    'tw-whitespace-nowrap tw-border-b-2 tw-py-4 tw-px-1 tw-text-sm tw-font-medium'
                  )}
                  aria-current={clubTab === tabName ? 'page' : undefined}
                >
                  {tabName}
                </div>
              ))}
            </div>
            {/* <div>
              <button 
                className="tw-py-2 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-indigo-600 hover:tw-bg-indigo-700"
                onClick={() => setPopUpOpen(true)}
              >
                {buttonText}
                </button>
            </div> */}
          </nav>
        </div>
      </div>
      {clubTab === "Members" && (
      <ul role="list" className="tw-grid tw-grid-cols-1 tw-gap-6 sm:tw-grid-cols-2 lg:tw-grid-cols-3">
        {sortedMembers.map((member) => (
          <li 
          key={member.name} 
          className="tw-col-span-1 tw-divide-y tw-divide-gray-200 tw-rounded-lg tw-bg-white tw-shadow hover:tw-cursor-pointer"
          onClick={() => navigate(`/directory/${member.id}`)}>
            <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-space-x-6 tw-p-6">
              <img className="tw-h-10 tw-w-10 tw-flex-shrink-0 tw-rounded-full tw-bg-gray-300" src={member.profilePhoto} alt="" />
              <div className="tw-flex-1 tw-truncate">
                <div className="tw-flex tw-items-center tw-space-x-3">
                  <h3 className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-900">{member.name}</h3>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      )}
      {clubTab === "Leaders" && (
      <ul role="list" className="tw-grid tw-grid-cols-1 tw-gap-6 sm:tw-grid-cols-2 lg:tw-grid-cols-3">
        {sortedLeadership.map((member) => (
          <li 
          key={member.name} 
          className="tw-col-span-1 tw-divide-y tw-divide-gray-200 tw-rounded-lg tw-bg-white tw-shadow hover:tw-cursor-pointer"
          onClick={() => navigate(`/directory/${member.id}`)}>
            <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-space-x-6 tw-p-6">
              <img className="tw-h-10 tw-w-10 tw-flex-shrink-0 tw-rounded-full tw-bg-gray-300" src={member.profilePhoto} alt="" />
              <div className="tw-flex-1 tw-truncate">
                <div className="tw-flex tw-items-center tw-space-x-3">
                  <h3 className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-900">{member.name}</h3>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      )}
      {clubTab === "Pending" && pendingMembers.length > 0 && (
        <div className="tw-mt-8">
          <div className="sm:tw-flex sm:tw-items-center">
            <div className="sm:tw-flex-auto">
              <h1 className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Pending Members</h1>
              {/* <p className="tw-mt-2 tw-text-sm tw-text-gray-700">
                A list of all the pending members including their name and actions to approve or reject their membership.
              </p> */}
            </div>
          </div>
          <div className="tw-flow-root">
            <div className="tw-my-2 tw-overflow-x-auto ">
              <div className="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle sm:tw-px-6 lg:tw-px-8">
                <table className="tw-min-w-full tw-divide-y tw-divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-0">
                        Name
                      </th>
                      <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                        
                      </th>
                      <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                        
                      </th>
                    </tr>
                  </thead>
                  <tbody className="tw-divide-y tw-divide-gray-200">
                    {pendingMembers.map((member) => (
                      <tr key={member.id}>
                        <td className="tw-whitespace-nowrap tw-py-5 tw-pl-4 tw-pr-3 tw-text-sm sm:tw-pl-0">
                          <div className="tw-flex tw-items-center">
                            <div className="tw-h-11 tw-w-11 tw-flex-shrink-0">
                              <img className="tw-h-11 tw-w-11 tw-rounded-full" src={member.profilePhoto} alt="" />
                            </div>
                            <div className="tw-ml-4">
                              <div className="tw-font-medium tw-text-gray-900">{member.name}</div>
                            </div>
                          </div>
                        </td>
                        <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                          <button className="tw-bg-green-500 hover:tw-bg-green-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => handleApproveMember(member.id)}>Approve</button>
                        </td>
                        <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                          <button className="tw-bg-red-500 hover:tw-bg-red-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => handleRejectMember(member.id)}>Reject</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {clubTab === "Manage Members" && sortedMembers.length > 0 && (
        <div className="tw-mt-8">
          <div className="sm:tw-flex sm:tw-items-center">
            <div className="sm:tw-flex-auto">
              <h1 className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Manage Club Members</h1>
              {/* <p className="tw-mt-2 tw-text-sm tw-text-gray-700">
                A list of all the pending members including their name and actions to approve or reject their membership.
              </p> */}
            </div>
          </div>
          <div className="tw-flow-root">
            <div className="tw-my-2 tw-overflow-x-auto ">
              <div className="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle sm:tw-px-6 lg:tw-px-8">
                <table className="tw-min-w-full tw-divide-y tw-divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-0">
                        Name
                      </th>
                      <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                        
                      </th>
                      <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                        
                      </th>
                    </tr>
                  </thead>
                  <tbody className="tw-divide-y tw-divide-gray-200">
                    {sortedMembers.map((member) => (
                      <tr key={member.id}>
                        <td className="tw-whitespace-nowrap tw-py-5 tw-pl-4 tw-pr-3 tw-text-sm sm:tw-pl-0">
                          <div className="tw-flex tw-items-center">
                            <div className="tw-h-11 tw-w-11 tw-flex-shrink-0">
                              <img className="tw-h-11 tw-w-11 tw-rounded-full" src={member.profilePhoto} alt="" />
                            </div>
                            <div className="tw-ml-4">
                              <div className="tw-font-medium tw-text-gray-900">{member.name}</div>
                            </div>
                          </div>
                        </td>
                        {!schoolClubData.leaderIds.includes(member.id) && (
                          <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                            <button className="tw-bg-green-500 hover:tw-bg-green-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => handlePromoteMember(member.id)}>Promote to Leadership</button>
                          </td>
                        )}
                        {/* <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                          <button className="tw-bg-red-500 hover:tw-bg-red-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => handleRejectMember(member.id)}>Remove from Club</button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {schoolClubData.contacts && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0">
        <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Club Contacts</dt>
        <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
          <div className="">
            <div className="tw-mt-8 tw-flow-root">
              <div className="tw-mx-4 tw-my-2 tw-overflow-x-auto sm:tw-mx-6 lg:tw-mx-8">
                <div className="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle">
                  <table className="tw-min-w-full tw-divide-y tw-divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-0">Name</th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">Role</th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">Email</th>
                        {/* <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">Phone</th> */}
                      </tr>
                    </thead>
                    <tbody className="tw-divide-y tw-divide-gray-200">
                      {schoolClubData.contacts?.map((contact, index) => (
                        <tr key={index} className="tw-cursor-pointer" onClick={() => handleContactClick(contact.id)}>
                          <td className="tw-whitespace-nowrap tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-pl-0">{contact.contactName}</td>
                          <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{contact.role}</td>
                          <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                            {contact.email && 
                              <a href={`mailto:${contact.email}`} className="tw-text-grey-600 hover:tw-text-blue-500">
                                <EnvelopeIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-grey-600 hover:tw-text-indigo-600" aria-hidden="true" />
                              </a>
                            }
                          </td>
                          {/* <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                            {contact.phone && 
                              <a href={`tel:${contact.phone}`} className="tw-text-grey-600 hover:tw-text-blue-500">
                                <PhoneIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-grey-600 hover:tw-text-blue-500" aria-hidden="true" />
                              </a>
                            }
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </dd>
      </div>}
      </div>
      </>
  )
}
