import { useState, useEffect } from 'react';
import axios from 'axios';
import { app, fireStoreDB } from '../../firebase/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const useBrewWorksPage = () => {
//   const [brewWorksPageFilters, setBrewWorksPageFilters] = useState({
//   });
  const [brewCompanies, setBrewCompanies] = useState([]);
  const [sharedContacts, setSharedContacts] = useState([]);
  const [sharedCompanies, setSharedCompanies] = useState([]);
  const [orgContacts, setOrgContacts] = useState([]);
  const [brewWorksLoading, setBrewWorksLoading] = useState(true);
  const [brewWorksTab, setBrewWorksTab] = useState('Club Job Board');
  const [brewWorksPageSearchTexts, setBrewWorksPageSearchTexts] = useState({
    'type': ''
  });
  const [brewWorksPageSearchTerm, setBrewWorksPageSearchTerm] = useState('');
  const [brewWorksFilters, setBrewWorksFilters] = useState({
    industries: [],
    roles: [],
    clubs: [],
  })

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, async (incomingUser) => {
      if (incomingUser) { // Fetch data only if user is logged in
        try {
          const idToken = await incomingUser.getIdToken(true);
          const userId = auth.currentUser.uid;
          const [brewDataResponse, sharedContactsResponse] = await Promise.all([
            axios.get(`${API_BASE_URL}/brew`, {
              headers: { 'Authorization': `Bearer ${idToken}` }
            }),
            axios.get(`${API_BASE_URL}/shared-contacts`, {
              params: { userId },
              headers: { 'Authorization': `Bearer ${idToken}` }
            }),
          ]);  
          const brewData = brewDataResponse.data;
          const sharedContactsData = sharedContactsResponse.data;
          setBrewWorksLoading(false);
          setBrewCompanies(brewData);
          setSharedCompanies(sharedContactsData.contactsByClubId);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        setBrewWorksLoading(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const addContacts = async (formData) => {
    const auth = getAuth(app);
    if (!formData) {
      formData = new FormData();
    }
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const userId = auth.currentUser.uid;
      const response = await axios.post(`${API_BASE_URL}/create-new-contacts`, formData, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        'Content-Type': 'multipart/form-data'
      });
      // setSharedCompanies(prevCompanies => [...prevCompanies, ...response.data]);
      setSharedCompanies(prevCompanies => {
        const existingCompanies = prevCompanies || [];
        const newCompanies = response.data.filter(newCompany => 
          !existingCompanies.some(prevCompany => 
            prevCompany.companyId === newCompany.companyId && prevCompany.orgId === newCompany.orgId
          )
        );
        return [...existingCompanies, ...newCompanies];
      });
    } catch (error) {
      console.error('Error adding new contact:', error);
    }
  };

  const getContacts = async (ids) => {
    const auth = getAuth(app);
    try {
        
      if (auth && auth.currentUser) {
        const idToken = await auth.currentUser.getIdToken(true);
        ids = ids ? ids : [auth.currentUser.uid];
        const response = await axios.get(`${API_BASE_URL}/shared-contacts`, {
          headers: { 'Authorization': `Bearer ${idToken}` },
          params: { ids },
        });
        setSharedCompanies(prevCompanies => {
          const existingCompanies = prevCompanies || [];
          const newCompanies = response.data.filter(newCompany => 
            !existingCompanies.some(prevCompany => 
              prevCompany.companyId === newCompany.companyId && prevCompany.orgId === newCompany.orgId
            )
          );
          return [...existingCompanies, ...newCompanies];
        });
      }
    } catch (error) {
      console.error('Error fetching open jobs:', error);
    }
  };

  const getOrgContact = async (contactId) => {
    const auth = getAuth(app);
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.get(`${API_BASE_URL}/org-contact`, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        params: { id: contactId },
      });
      setOrgContacts(prevContacts => [...prevContacts, response.data.orgContactData]);
      return;
    } catch (error) {
      console.error('Error fetching org contact:', error);
      return null;
    }
  };


  const updateCompany = async (formData) => {
    const auth = getAuth(app);
    if (!formData) {
      formData = new FormData();
    }
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.put(`${API_BASE_URL}/update-org-company`, formData, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        'Content-Type': 'multipart/form-data'
      });
      setSharedCompanies(prevCompanies => {
        const foundCompany = response.data.updatedCompany;
        if (foundCompany) {
          const updatedCompanies = prevCompanies.map(prevCompany => {
            return prevCompany.id === foundCompany.id ? foundCompany : prevCompany;
          });
          return updatedCompanies;
        } else {
          return prevCompanies;
        }
      });
    } catch (error) {
      console.error('Error updating job:', error);
    }
  };

  const updateSharedContact = async (formData) => {
    const auth = getAuth(app);
    if (!formData) {
      formData = new FormData();
    }
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.put(`${API_BASE_URL}/update-shared-contact`, formData, {
        headers: { 'Authorization': `Bearer ${idToken}` },
        'Content-Type': 'multipart/form-data'
      });
      setSharedCompanies(prevCompanies => {
        const foundCompany = response.data.updatedCompany;
        if (foundCompany && Array.isArray(prevCompanies)) {
          const updatedCompanies = prevCompanies.map(prevCompany => {
            return prevCompany.id === foundCompany.id ? foundCompany : prevCompany;
          });
          return updatedCompanies;
        } else {
          return prevCompanies;
        }
      });

      setOrgContacts(prevContacts => {
        const updatedContactIndex = prevContacts.findIndex(contact => contact.id === response.data.updatedContact.id);
        if (updatedContactIndex !== -1) {
          const updatedContacts = [...prevContacts];
          updatedContacts[updatedContactIndex] = response.data.updatedContact;
          return updatedContacts;
        } else {
          return [...prevContacts, response.data.updatedContact];
        }
      });
    } catch (error) {
      console.error('Error updating shared contact:', error);
    }
  };


  const clearBrewWorksFilters = () => {
    setBrewWorksFilters({
      industries: [], 
      roles: [],
      clubs: [],
    });
  }; 

  return {
    brewWorksFilters, 
    setBrewWorksFilters,
    clearBrewWorksFilters,
    // brewWorksPageSearchTexts,
    // setBrewWorksPageSearchTexts,
    brewWorksLoading,
    setBrewWorksLoading,
    brewWorksPageSearchTerm,
    setBrewWorksPageSearchTerm,
    brewCompanies, 
    setBrewCompanies,
    brewWorksTab, 
    setBrewWorksTab,
    //Contacts
    sharedCompanies,
    updateCompany,
    updateSharedContact,
    addContacts,
    getContacts,
    orgContacts,
    getOrgContact,
    // sharedContacts, 
  };
};
