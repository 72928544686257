import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import MultiComboBoxInput from '../../filters/MultiComboBoxInput'


export default function AddContacts({ 
    handleChange,handleFileChange, 
    // handleSingleChange, 
    formState, 
    // setFormState,
    popUpOpen, setPopUpOpen,
    handleSubmit, loading, 
    // handleMultiComboChangeHelper,
    addContactType, setContactJobType,
    setShareContactWith,
    leaderInClubs  
}) {
  return (
    <Transition.Root show={popUpOpen} as={Fragment}>
      <Dialog as="div" className="tw-relative tw-z-10" onClose={setPopUpOpen}>
        <Transition.Child
          as={Fragment}
          enter="tw-ease-out tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in tw-duration-200"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
        </Transition.Child>

        <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
          <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
            <Transition.Child
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
              enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            >
              <Dialog.Panel className="tw-relative tw-transform tw-overflow-auto tw-rounded-lg 
              tw-bg-white tw-px-4 tw-pb-4 tw-pt-10 tw-text-left tw-shadow-xl tw-transition-all sm:tw-mb-8 sm:tw-w-full 
              sm:tw-max-w-sm sm:tw-p-6 tw-mt-20">
                <form>
                  <div className="tw-mb-4">
                    <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2">
                      What to add?
                    </label>
                    <select 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                      value={addContactType} 
                      onChange={(e) => setContactJobType(e.target.value)} 
                      name="jobType">
                      <option value="Add-Company">Add a Single Company</option>
                      {/* <option value="Single-Long">Single-Long</option> */}
                      <option value="Multiple">Multiple Companies</option>
                    </select>
                  </div>
                  {["Add-Company", "Single-Long"].includes(addContactType) && (
                    <>
                    <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="companyName">
                          Company Name <span className="required-star">*</span>
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="companyName" type="text" 
                          value={formState.companyName || ''}
                          placeholder="Company" onChange={handleChange} required />
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="companyId">
                          Company Id (used to match contacts to the company )<span className="required-star">*</span>
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="companyId" type="text" 
                          value={formState.companyId || ''}
                          placeholder="companyId" onChange={handleChange} required />
                          <p className="tw-mt-1 tw-text-xs tw-text-gray-600">Also known as a slug i.e. disney or morganstanley</p>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="companyWebsite">
                          Company Website <span className="required-star">*</span>
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="companyWebsite" type="url" 
                          value={formState.companyWebsite || ''}
                          placeholder="www.bestcompany.com" onChange={handleChange} required />
                        <p className="tw-mt-1 tw-text-xs tw-text-gray-600">Enter the main website of the company, not the recruiting page.</p>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="sponsorsInternationals">
                          Sponsors Internationals <span className="required-star">*</span>
                        </label>
                        <select 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="sponsorsInternationals" 
                          value={formState.sponsorsInternationals || ''}
                          onChange={handleChange} required>
                          <option value={true} defaultValue>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="officeLocations">
                          Office Locations 
                          {/* <span className="required-star">*</span> */}
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="officeLocations" type="text" 
                          value={formState.officeLocations || ''}
                          placeholder="Office Locations" onChange={handleChange} required />
                          <p className="tw-mt-1 tw-text-xs tw-text-gray-600">Seperate multiple locations with a / between each location</p>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="organizationRelationshipToCompany">
                          Organization Relationship to Company
                           {/* <span className="required-star">*</span> */}
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="organizationRelationshipToCompany" type="text" 
                          value={formState.organizationRelationshipToCompany || ''}
                          placeholder="Organization Relationship to Company" onChange={handleChange} required />
                          <p className="tw-mt-1 tw-text-xs tw-text-gray-600">Alumni work here, recruiting partner, etc...</p>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="networkingOpportunities">
                          Networking Opportunities 
                          {/* <span className="required-star">*</span> */}
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="networkingOpportunities" type="text" 
                          value={formState.networkingOpportunities || ''}
                          placeholder="Networking Opportunities" onChange={handleChange} required />
                          <p className="tw-mt-1 tw-text-xs tw-text-gray-600">Where can members interact with this company?</p>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="companyNotes">
                          Company Notes 
                          {/* <span className="required-star">*</span> */}
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="companyNotes" type="text" 
                          value={formState.companyNotes || ''}
                          placeholder="Company Notes" onChange={handleChange} required />
                          <p className="tw-mt-1 tw-text-xs tw-text-gray-600">Any other company information</p>
                      </div>
                      
                    </>
                  )}
{/* 
                  {addContactType === "Single-Long" && (
                     <>
                     <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="datePosted">
                          Date Posted
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="datePosted" type="date" 
                          value={formState.datePosted || ''}
                          onChange={handleChange} required />
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="deadline">
                          Deadline
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="deadline" type="date" 
                          value={formState.deadline || ''}
                          onChange={handleChange} required />
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="location">
                          Location
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="location" type="text" 
                          value={formState.location || ''}
                          placeholder="Location" onChange={handleChange} required />
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="isAcademic">
                          Academic Internship?
                        </label>
                        <select 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                          name="isAcademic" onChange={handleChange} required>
                          <option value="">Select</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="workSetting">
                          Work Setting
                        </label>
                        <select 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                          name="workSetting" onChange={handleChange} required>
                          <option value="">Select Work Setting</option>
                          <option value="in-office">In-office</option>
                          <option value="remote">Remote</option>
                          <option value="hybrid">Hybrid</option>
                        </select>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="startDate">
                          Start Date
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="startDate" type="date" 
                          value={formState.startDate || ''}
                          onChange={handleChange} required />
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="endDate">
                          End Date
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="endDate" type="date" 
                          value={formState.endDate || ''}
                          onChange={handleChange} required />
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="isCoverLetterRequired">
                          Cover Letter Required?
                        </label>
                        <select 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                          name="isCoverLetterRequired" onChange={handleChange} required>
                          <option value="">Select</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="additionalNotes">
                          Additional Notes
                        </label>
                        <textarea 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="additionalNotes" 
                          value={formState.additionalNotes || ''}
                          placeholder="Additional Notes" onChange={handleChange} />
                      </div>
                     </>
                  )} */}
                  
                  {addContactType === "Multiple" && (
                     <>
                     <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="csvFile">
                        Upload CSV File
                      </label>
                      <input 
                        className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                        name="csvFile" type="file" 
                        accept=".csv"
                        onChange={handleFileChange} />
                    </div>
                     </>
                  )}
                  <div className="tw-mb-4">
                    <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="shareJob">
                      Share Contacts With: <span className="required-star">*</span>
                    </label>
                    <select 
                      className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline"
                      name="shareContactWith" 
                      onChange={e => setShareContactWith(e.target.value)} 
                      required>
                        <option value={""}>{""}</option>
                      {leaderInClubs.map(club => (
                        <option key={club.id} value={club.id}>{club.shortName}</option>
                      ))}
                      {/* Additional options will be added here */}
                    </select>
                  </div>
                  <div className="tw-mb-4">
                    <button 
                      className="tw-inline-flex tw-w-full tw-justify-center tw-bg-blue-500 tw-hover:bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
                      disabled={loading} 
                      onClick={handleSubmit}>
                      Submit
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}









