import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  AcademicCapIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  BeakerIcon,
  CurrencyDollarIcon,
  BookOpenIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { useLocation } from 'react-router-dom';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const Header = ({ page, filters}) => {
  const { user, directory } = useUser();

  return (
    <LoggedInHeader page={page} filters={filters} />
    // <header className="app__header">
    //   {user || directory.length ? (
    //     <LoggedInHeader />
    //   ) : (
    //     // <LoggedOutHeader />
    //     <></>
    //   )}
    // </header>
  );
};

const LoggedInHeader = ({ page, filters }) => {
  const { logout, setHeaderDropdownOpen, headerDropdownOpen, userInfo } = useUser();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const dropdownRef = useRef(null);
  let profilePhoto;
  let fullName;
  if(userInfo) {
    profilePhoto = userInfo.profilePhoto ? userInfo.profilePhoto : (userInfo['Profile Photo'] ? userInfo['Profile Photo'][0].url : null)
    fullName = userInfo.name
  }
  const navigation = [
    { name: 'Chai', href: '/chai', icon: ChartPieIcon, current: location.pathname.includes('/chai') },
    { name: 'BrewWorks', href: '/brew', icon: BeakerIcon, current: location.pathname.includes('/brew') },
    // { name: 'Directory', href: '/directory', icon: UsersIcon, current: location.pathname.includes('/directory') },
    { name: 'School Hub', href: '/school', icon: AcademicCapIcon, current: location.pathname.includes('/school') },
    // { name: 'Earn', href: '/earn', icon: CurrencyDollarIcon, current: location.pathname.includes('/earn') },
    { name: 'Events', href: '/events', icon: CalendarIcon, current: location.pathname.includes('/events') },
    // { name: 'Documents', href: '#', icon: DocumentDuplicateIcon, current: location.pathname === '#' },
  ]
  const teams = [
    // { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
    // { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
    // { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
  ]
  const userNavigation = [
    { name: 'Your profile', href: '/profile' },
    // { name: 'Sign out', href: '#' },
  ]
  const handleLinkClick = () => {
    if (window.innerWidth <= 1200) {
      setSidebarOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.closest('.dropdown__content').contains(event.target)) {
        setTimeout(() => {
          setHeaderDropdownOpen(false);
        }, 200);
      }
    };
  
  
  
    document.addEventListener('mousedown', handleClickOutside);
    // document.addEventListener('click', handleLinkClick);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      // document.removeEventListener('click', handleLinkClick);
    };
  
  }, [dropdownRef, setHeaderDropdownOpen]);
  return (
    <>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="tw-relative tw-z-50 lg:tw-hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="tw-transition-opacity tw-ease-linear tw-duration-300"
              enterFrom="tw-opacity-0"
              enterTo="tw-opacity-100"
              leave="tw-transition-opacity tw-ease-linear tw-duration-300"
              leaveFrom="tw-opacity-100"
              leaveTo="tw-opacity-0"
            >
              <div className="tw-fixed tw-inset-0 tw-bg-gray-900/80" />
            </Transition.Child>

            <div className="tw-fixed tw-inset-0 tw-flex">
              <Transition.Child
                as={Fragment}
                enter="tw-transition tw-ease-in-out tw-duration-300 tw-transform"
                enterFrom="-tw-translate-x-full"
                enterTo="tw-translate-x-0"
                leave="tw-transition tw-ease-in-out tw-duration-300 tw-transform"
                leaveFrom="tw-translate-x-0"
                leaveTo="-tw-translate-x-full"
              >
                <Dialog.Panel className="tw-relative tw-mr-16 tw-flex tw-w-full tw-max-w-xs tw-flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="tw-ease-in-out tw-duration-300"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in-out tw-duration-300"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                  >
                    <div className="tw-absolute tw-left-full tw-top-0 tw-flex tw-w-16 tw-justify-center tw-pt-5">
                      <button type="button" className="-tw-m-2.5 tw-p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="tw-sr-only">Close sidebar</span>
                        <XMarkIcon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="tw-flex tw-grow tw-flex-col tw-gap-y-5 tw-overflow-y-auto tw-bg-white dark:tw-bg-black tw-px-6 tw-pb-4">
                    <div className="tw-flex tw-h-16 tw-shrink-0 tw-items-center">
                      {/* <img
                        className="tw-h-8 tw-w-auto"
                        // src="/on-white-teahouse-STACKED.svg?color=indigo&shade=600"
                        alt="Your Company"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                      /> */}
                           <img
                              // className="tw-h-8 tw-w-auto"
                              className="tw-h-24 tw-w-auto tw-pt-5" 
                              src="/on-white-teahouse-STACKED.svg"
                              alt="Your Company"
                              // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                            />
                    </div>
                    
                    {/* <div className="tw-flex tw-h-16 tw-shrink-0 tw-items-center">
                      <h1 className="tw-h-8 tw-w-auto tw-text-2xl tw-font-bold tw-text-indigo-600 tw-pt-2">
                        CHAI
                      </h1>
                    </div> */}
                    <nav className="tw-flex tw-flex-1 tw-flex-col">
                      <ul role="list" className="tw-flex tw-flex-1 tw-flex-col tw-gap-y-7">
                        <li>
                          <ul role="list" className="-tw-mx-2 tw-space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    item.current
                                      ? 'tw-bg-gray-50 tw-text-indigo-600 dark:tw-bg-gray-800 dark:tw-text-white'
                                      : 'tw-text-gray-700 tw-hover:text-indigo-600 tw-hover:bg-gray-50 dark:tw-text-gray-400 dark:hover:tw-text-white dark:hover:tw-bg-gray-800',
                                    'tw-group tw-flex tw-gap-x-3 tw-rounded-md tw-p-2 tw-text-sm tw-leading-6 tw-font-semibold'
                                  )}
                                  onClick={handleLinkClick}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current ? 'tw-text-indigo-600' : 'tw-text-gray-400 tw-group-hover:text-indigo-600',
                                      'tw-h-6 tw-w-6 tw-shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <div className="tw-text-xs tw-font-semibold tw-leading-6 tw-text-gray-400"></div>
                          <ul role="list" className="-tw-mx-2 tw-mt-2 tw-space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <Link
                                  to={team.href}
                                  className={classNames(
                                    team.current
                                      ? 'tw-bg-gray-50 tw-text-indigo-600 dark:tw-bg-gray-800 dark:tw-text-white'
                                      : 'tw-text-gray-700 tw-hover:text-indigo-600 tw-hover:bg-gray-50',
                                    'tw-group tw-flex tw-gap-x-3 tw-rounded-md tw-p-2 tw-text-sm tw-leading-6 tw-font-semibold'
                                  )}
                                  onClick={handleLinkClick}
                                >
                                  <span
                                    className={classNames(
                                      team.current
                                        ? 'tw-text-indigo-600 tw-border-indigo-600'
                                        : 'tw-text-gray-400 tw-border-gray-200 tw-group-hover:border-indigo-600 tw-group-hover:text-indigo-600',
                                      'tw-flex tw-h-6 tw-w-6 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-lg tw-border tw-text-[0.625rem] tw-font-medium tw-bg-white dark:tw-bg-black'
                                    )}
                                  >
                                    {team.initial}
                                  </span>
                                  <span className="tw-truncate">{team.name}</span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="tw-mt-auto">
                          {/* <a
                            href="#"
                            className="tw-group -tw-mx-2 tw-flex tw-gap-x-3 tw-rounded-md tw-p-2 tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-700 tw-hover:bg-gray-50 tw-hover:text-indigo-600"
                          >
                            <Cog6ToothIcon
                              className="tw-h-6 tw-w-6 tw-shrink-0 tw-text-gray-400 tw-group-hover:text-indigo-600"
                              aria-hidden="true"
                            />
                            Settings
                          </a> */}
                          
                          <Link
                          to={'/guides'}
                          className={classNames(
                            location.pathname.includes('/guides')
                              ? 'tw-bg-gray-50 tw-text-indigo-600 dark:tw-bg-gray-800 dark:tw-text-white'
                              : 'tw-text-gray-700 tw-hover:tw-text-indigo-600 tw-hover:tw-bg-gray-50 dark:tw-text-gray-400 dark:hover:tw-text-white dark:hover:tw-bg-gray-800',
                            'tw-group tw-flex tw-gap-x-3 tw-rounded-md tw-p-2 tw-text-sm tw-leading-6 tw-font-semibold -tw-mx-2'
                          )}
                          onClick={handleLinkClick}
                        >
                          <BookOpenIcon
                            className={classNames(
                              location.pathname.includes('/guides') ? 'tw-text-indigo-600' : 'tw-text-gray-400 tw-group-hover:text-indigo-600',
                              'tw-h-6 tw-w-6 tw-shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {"Guides"}
                      </Link>
                                <Link
                          to={'/start'}
                          className={classNames(
                            location.pathname.includes('/start')
                              ? 'tw-bg-gray-50 tw-text-indigo-600 dark:tw-bg-gray-800 dark:tw-text-white'
                              : 'tw-text-gray-700 tw-hover:tw-text-indigo-600 tw-hover:tw-bg-gray-50 dark:tw-text-gray-400 dark:hover:tw-text-white dark:hover:tw-bg-gray-800',
                            'tw-group tw-flex tw-gap-x-3 tw-rounded-md tw-p-2 tw-text-sm tw-leading-6 tw-font-semibold -tw-mx-2'
                          )}
                          onClick={handleLinkClick}
                        >
                          <QuestionMarkCircleIcon
                            className={classNames(
                              location.pathname.includes('/start') ? 'tw-text-indigo-600' : 'tw-text-gray-400 tw-group-hover:text-indigo-600',
                              'tw-h-6 tw-w-6 tw-shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {"Getting Started"}
                      </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="tw-hidden lg:tw-fixed lg:tw-inset-y-0 lg:tw-z-50 lg:tw-flex lg:tw-w-72 lg:tw-flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="tw-flex tw-grow tw-flex-col tw-gap-y-5 tw-overflow-y-auto tw-border-r tw-border-gray-200 tw-bg-white tw-px-6 tw-pb-4 dark:tw-bg-black">
            <div className="tw-flex tw-h-16 tw-shrink-0 tw-items-center">
              <img
                // className="tw-h-8 tw-w-auto"
                className="tw-h-16 tw-w-auto tw-pt-5" 
                src="/on-white-teahouse-STACKED.svg"
                alt="Your Company"
                // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              />
            </div>
            {/* <div className="tw-flex tw-h-16 tw-shrink-0 tw-items-center">
              <h1 className="tw-h-8 tw-w-auto tw-text-2xl tw-font-bold tw-text-indigo-600 tw-pt-2">
                CHAI
              </h1>
            </div> */}
            <nav className="tw-flex tw-flex-1 tw-flex-col">
              <ul role="list" className="tw-flex tw-flex-1 tw-flex-col tw-gap-y-7">
                <li>
                  <ul role="list" className="-tw-mx-2 tw-space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'tw-bg-gray-50 tw-text-indigo-600 dark:tw-bg-gray-800 dark:tw-text-white'
                              : 'tw-text-gray-700 tw-hover:tw-text-indigo-600 tw-hover:tw-bg-gray-50 dark:tw-text-gray-400 dark:hover:tw-text-white dark:hover:tw-bg-gray-800',
                            'tw-group tw-flex tw-gap-x-3 tw-rounded-md tw-p-2 tw-text-sm tw-leading-6 tw-font-semibold'
                          )}
                          onClick={handleLinkClick}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'tw-text-indigo-600' : 'tw-text-gray-400 tw-group-hover:text-indigo-600',
                              'tw-h-6 tw-w-6 tw-shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className="tw-text-xs tw-font-semibold tw-leading-6 tw-text-gray-400"></div>
                  <ul role="list" className="-tw-mx-2 tw-mt-2 tw-space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? 'tw-bg-gray-50 tw-text-indigo-600'
                              : 'tw-text-gray-700 tw-hover:text-indigo-600 tw-hover:bg-gray-50 dark:tw-text-gray-400 dark:hover:tw-text-white dark:hover:tw-bg-gray-800',
                            'tw-group tw-flex tw-gap-x-3 tw-rounded-md tw-p-2 tw-text-sm tw-leading-6 tw-font-semibold'
                          )}
                          onClick={handleLinkClick}
                        >
                          <span
                            className={classNames(
                              team.current
                                ? 'tw-text-indigo-600 tw-border-indigo-600'
                                : 'tw-text-gray-400 tw-border-gray-200 tw-group-hover:border-indigo-600 tw-group-hover:text-indigo-600',
                              'tw-flex tw-h-6 tw-w-6 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-lg tw-border tw-text-[0.625rem] tw-font-medium tw-bg-white dark:tw-bg-black'
                            )}
                          >
                            {team.initial}
                          </span>
                          <span className="tw-truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="tw-mt-auto">
                  {/* <a
                    href="#"
                    className="tw-group -tw-mx-2 tw-flex tw-gap-x-3 tw-rounded-md tw-p-2 tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-700 tw-hover:bg-gray-50 tw-hover:text-indigo-600"
                  >
                    <Cog6ToothIcon
                      className="tw-h-6 tw-w-6 tw-shrink-0 tw-text-gray-400 tw-group-hover:text-indigo-600"
                      aria-hidden="true"
                    />
                    Settings
                  </a> */}
                      <Link
                          to={'/guides'}
                          className={classNames(
                            location.pathname.includes('/guides')
                              ? 'tw-bg-gray-50 tw-text-indigo-600 dark:tw-bg-gray-800 dark:tw-text-white'
                              : 'tw-text-gray-700 tw-hover:tw-text-indigo-600 tw-hover:tw-bg-gray-50 dark:tw-text-gray-400 dark:hover:tw-text-white dark:hover:tw-bg-gray-800',
                            'tw-group tw-flex tw-gap-x-3 tw-rounded-md tw-p-2 tw-text-sm tw-leading-6 tw-font-semibold -tw-mx-2'
                          )}
                          onClick={handleLinkClick}
                        >
                          <BookOpenIcon
                            className={classNames(
                              location.pathname.includes('/guides') ? 'tw-text-indigo-600' : 'tw-text-gray-400 tw-group-hover:text-indigo-600',
                              'tw-h-6 tw-w-6 tw-shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {"Guides"}
                      </Link>
                  <Link
                          to={'/start'}
                          className={classNames(
                            location.pathname.includes('/start')
                              ? 'tw-bg-gray-50 tw-text-indigo-600 dark:tw-bg-gray-800 dark:tw-text-white'
                              : 'tw-text-gray-700 tw-hover:tw-text-indigo-600 tw-hover:tw-bg-gray-50 dark:tw-text-gray-400 dark:hover:tw-text-white dark:hover:tw-bg-gray-800',
                            'tw-group tw-flex tw-gap-x-3 tw-rounded-md tw-p-2 tw-text-sm tw-leading-6 tw-font-semibold -tw-mx-2'
                          )}
                          onClick={handleLinkClick}
                        >
                          <QuestionMarkCircleIcon
                            className={classNames(
                              location.pathname.includes('/start') ? 'tw-text-indigo-600' : 'tw-text-gray-400 tw-group-hover:text-indigo-600',
                              'tw-h-6 tw-w-6 tw-shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {"Getting Started"}
                      </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
                {/* Mobile */}
        <div className="lg:tw-pl-72">
          <div className={classNames(filters ? 'tw-h-20' : 'tw-h-12', "tw-sticky tw-top-0 tw-z-40 tw-flex tw-shrink-0 tw-items-center tw-gap-x-4 tw-border-b tw-border-gray-200 tw-bg-white tw-px-4 tw-shadow-sm sm:tw-gap-x-6 sm:tw-px-6 lg:tw-px-8 dark:tw-bg-black")}>
            <button type="button" className="-tw-m-2.5 tw-p-2.5 tw-text-gray-700 lg:tw-hidden" onClick={() => setSidebarOpen(true)}>
              <span className="tw-sr-only">Open sidebar</span>
              <Bars3Icon className="tw-h-6 tw-w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div className="tw-h-6 tw-w-px tw-bg-gray-200 lg:tw-hidden" aria-hidden="true" />

            <div className="tw-flex tw-flex-1 tw-gap-x-4 tw-self-stretch lg:tw-gap-x-6">
              <form className="tw-relative tw-flex tw-flex-1 tw-justify-center tw-items-center tw-w-full" action="#" method="GET">
                {/* <label htmlFor="search-field" className="tw-sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-h-full tw-w-5 tw-text-gray-400"
                  aria-hidden="true"
                />
                <input
                  id="search-field"
                  className="tw-block tw-h-full tw-w-full tw-border-0 tw-py-0 tw-pl-8 tw-pr-0 tw-text-gray-900 tw-placeholder:text-gray-400 tw-focus:ring-0 sm:tw-text-sm"
                  placeholder="Search..."
                  type="search"
                  name="search"
                /> */}
                { filters }
              </form>
              <div className="tw-flex tw-items-center tw-gap-x-4 lg:tw-gap-x-6">
                {/* <button type="button" className="-tw-m-2.5 tw-p-2.5 tw-text-gray-400 tw-hover:text-gray-500">
                  <span className="tw-sr-only">View notifications</span>
                  <BellIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                </button> */}

                {/* Separator */}
                <div className="tw-hidden lg:tw-block lg:tw-h-6 lg:tw-w-px lg:tw-bg-gray-200" aria-hidden="true" />

                {/* Profile dropdown */}
                <Menu as="div" className="tw-relative">
                  <Menu.Button className="-tw-m-1.5 tw-flex tw-items-center tw-p-1.5">
                    <span className="tw-sr-only">Open user menu</span>
                    {profilePhoto ? (
                      <img
                        className="tw-h-8 tw-w-8 tw-rounded-full tw-bg-gray-50"
                        src={profilePhoto}
                        alt=""
                      />
                    ) : (
                      <div className="tw-h-8 tw-w-8 tw-rounded-full"></div>
                    )}
                    <span className="tw-hidden lg:tw-flex lg:tw-items-center">
                      <span className="tw-ml-4 tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 dark:tw-text-white dark:hover:tw-bg-gray-800" aria-hidden="true">
                        {fullName}
                      </span>
                      <ChevronDownIcon className="tw-ml-2 tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="tw-transition tw-ease-out tw-duration-100"
                    enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                    enterTo="tw-transform tw-opacity-100 tw-scale-100"
                    leave="tw-transition tw-ease-in tw-duration-75"
                    leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                    leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                  >
                    <Menu.Items className="tw-absolute tw-right-0 tw-z-10 tw-mt-2.5 tw-w-32 tw-origin-top-right tw-rounded-md tw-bg-white tw-py-2 tw-shadow-lg tw-ring-1 tw-ring-gray-900/5 tw-focus:outline-none dark:tw-bg-black">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              to={item.href}
                              className={classNames(
                                active ? 'tw-bg-gray-50 dark:tw-bg-gray-800 dark:tw-text-white' : '',
                                'tw-block tw-px-3 tw-py-1 tw-text-sm tw-leading-6 tw-text-gray-900'
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                      <Menu.Item>
                        <p
                          onClick={logout}
                          className="tw-block tw-px-3 tw-py-1 tw-text-sm tw-leading-6 tw-text-gray-900 tw-cursor-pointer tw-hover:tw-underline"
                        >
                          Sign Out
                        </p>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="tw-mt-4">
            <div className="tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-pb-4">{ page }</div>
          </main>
        </div>
    </>
  )

};



export default Header;
