import React, { useState } from 'react';

const FormSelect = ({ 
    values, selectedValue, onChange, 
    stateKey, otherUpdateState, hideOther,
    newName }) => {
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [otherValue, setOtherValue] = useState('');
    const [searchText, setSearchText] = useState('');

    const handleSelectChange = (e) => {
        const selected = e?.target?.value;
        setIsOtherSelected(selected === 'Other');
        if (selected !== 'Other') {
            setIsOtherSelected(false);
            setOtherValue('');
            otherUpdateState(stateKey, '');
        }
        onChange(selected);
    };

    const handleOtherValueChange = (e) => {
        setOtherValue(e?.target?.value);
        onChange(e?.target?.value);
        otherUpdateState(stateKey,e?.target?.value)

    };

    const filteredValues = React.useMemo(() => {
        if (!searchText) return values;
        return values?.filter(value =>
            value.toLowerCase().includes(searchText.toLowerCase())
        );
    }, [values, searchText]);

    const otherHidden = hideOther || false
    const radioName = newName || 'program'

    return (
        <div>
            <input
                type="text"
                value={searchText}
                onChange={e => setSearchText(e?.target?.value)}
                placeholder={`Search...`}
                className="profile-form__select-search"
            />
            <div className={`profile-form__select-list`}>
                {filteredValues?.map((value, index) => (
                    <div className="profile-form__select-list-item" key={index}>
                        <input
                            type="radio"
                            id={`select-${index}`}
                            checked={selectedValue === value}
                            onChange={event => {
                                handleSelectChange(event);
                                onChange(event);
                            }}
                            name={radioName}
                            value={value}
                        />
                        <label htmlFor={`select-${index}`}>{value}</label>
                    </div>
                ))}
                {!otherHidden && (
                    <div className="profile-form__select-list-item">
                        <input
                            type="radio"
                            id={`select-other`}
                            checked={isOtherSelected}
                            onChange={handleSelectChange}
                            value="Other"
                        />
                        <label htmlFor={`select-other`}>Other</label>
                    </div>
                )}
            </div>
            {!otherHidden && isOtherSelected && (
                <input
                    type="text"
                    value={otherValue}
                    onChange={handleOtherValueChange}
                    placeholder="Enter your major"
                    className="profile-form__select-other"
                />
            )}
        </div>
    );
};

export default FormSelect;
