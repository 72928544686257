import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import EventCard from '../components/EventCard';
import { classNames } from '../utils/helperFunctions';
import { app } from '../firebase/firebase';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function Events({ eventList }) {
    const navigate = useNavigate();
    const { events, user, clubs, organizations, setEvents, setClubs, setOrganizations,
      myEventsTab, setMyEventsTab, activeTab, setActiveTab } = useUser();

      useEffect(() => {
        const auth = getAuth(app);
        const unsubscribe = onAuthStateChanged(auth, async (incomingUser) => {
          if (!incomingUser) return; // Exit early if user is not logged in
          
          try {
            const userId = incomingUser.uid;
            const idToken = await incomingUser.getIdToken(true);
            Promise.all([
              axios.get(`${API_BASE_URL}/events`, {
                headers: { 'Authorization': `Bearer ${idToken}` }
              }),
              axios.get(`${API_BASE_URL}/school-data`, {
                headers: { 'Authorization': `Bearer ${idToken}` },
                params: { uid: userId }
              })
            ]).then(([eventsResponse, schoolDataResponse]) => {
              setEvents(eventsResponse.data);
              console.log(schoolDataResponse,'SDR')
              const { clubs, organizations } = schoolDataResponse.data;
              setClubs(clubs);
              setOrganizations(organizations);
            }).catch(error => {
              console.error("Error fetching data:", error);
            });
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        });
        return unsubscribe; // Simplify cleanup
      }, []);

    const handleMyEventsTabClick = (tabName) => {
      setMyEventsTab(tabName);
    };
  
    const handleNewEvent = () => {
      navigate('/events/new'); // replace with the actual route to create a new event
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const currentEvents = events.filter(event => {
      const eventDate = event.startDate._seconds ? new Date(event.startDate._seconds * 1000) : new Date(event.startDate);
      eventDate.setHours(0, 0, 0, 0);

      return eventDate >= yesterday;
    });

    const pastEvents = events.filter(event => {
      const eventDate = event.startDate._seconds ? new Date(event.startDate._seconds * 1000) : new Date(event.startDate);
      eventDate.setHours(0, 0, 0, 0);
      return eventDate < tomorrow;
    });
    const eventsSlice = currentEvents 
      ? currentEvents
        .filter(event => {
          // Exclude events the user is already attending or hosting
          if (user) {
            const isAttending = Array.isArray(event.attendees) && event.attendees.some(attendee => typeof attendee === 'object' && attendee.id === user.uid);
            const isHosting = Array.isArray(event.organizers) && event.organizers.some(organizer => typeof organizer === 'object' && organizer.id === user.uid);
            return !(isAttending || isHosting);
          }
        })
        .sort((a, b) => {
          const aDate = a.startDate._seconds ? new Date(a.startDate._seconds * 1000) : new Date(a.startDate);
          const bDate = b.startDate._seconds ? new Date(b.startDate._seconds * 1000) : new Date(b.startDate);
          return aDate - bDate;
        })
      : []
    // const filteredEvents = events.filter(event => event.category === activeTab);
    const attendingEvents = user && user.uid 
      ? currentEvents.filter(event => 
          Array.isArray(event.attendees) && event.attendees.some(attendee => 
            typeof attendee === 'object' && attendee.id === user.uid
          )
        ) 
      : [];
    const hostingEvents = user && user.uid 
      ? currentEvents.filter(event => 
          Array.isArray(event.organizers) && event.organizers.some(organizer => {
            return typeof organizer === 'object' && organizer.id === user.uid;
          })
        ) 
      : [];
    const previousEvents = user && user.uid
  ? pastEvents.filter(event => {
    // console.log(event,'prev')
      const isAttendee = Array.isArray(event.attendees) && event.attendees.some(attendee => 
        typeof attendee === 'object' && attendee.userId === user.uid
      );
      const isOrganizer = Array.isArray(event.organizers) && event.organizers.some(organizer => 
        typeof organizer === 'object' && organizer.id === user.uid
      );

      const isClubLeader = event.organizers && Array.isArray(event.organizers) && event.organizers.some(organizer => {
        if (typeof organizer === 'object' && organizer.clubName) {
          const club = clubs.find(club => club.id === organizer.id);
          return club?.leadership?.hasOwnProperty(user.uid);
        }
        return false;
      });

      const isOrgLeader = event.organizers && Array.isArray(event.organizers) && event.organizers.some(organizer => {
        if (typeof organizer === 'object' && organizer.organizationName) {
          const organization = organizations.find(org => org.id === organizer.id);
          return organization?.leadership?.hasOwnProperty(user.uid);
        }
        return false;
      });

      const isOrgMember = event.organizers && Array.isArray(event.organizers) && event.organizers.some(organizer => {
        if (typeof organizer === 'object' && organizer.organizationName) {
          const organization = organizations.find(org => org.id === organizer.id);
          return organization?.members?.hasOwnProperty(user.uid);
        }
        return false;
      });

      return isAttendee || isOrganizer || isClubLeader || isOrgLeader || isOrgMember;
    })
  : [];
  // console.log(hostingEvents, 'hosting')
  // console.log(events, 'events')
  // console.log(previousEvents, 'previous')
  // // console.log(attendingEvents)
  // console.log(currentEvents,'current')
  // console.log(pastEvents,'past')

    return (
      <>
        <button 
            className="tw-py-2 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-indigo-600 hover:tw-bg-indigo-700 tw-float-right" 
            onClick={handleNewEvent}
        >
            New Event
        </button>
          <h1 className="tw-text-2xl tw-font-bold tw-mb-4">My Events</h1>
        <div className="tw-border-gray-200">
          <nav className="tw-mb-px tw-flex tw-justify-between tw-pb-5" aria-label="Tabs">
            <div className="tw-flex tw-space-x-8">
              {['Attending', 'Hosting', 'Previous Events'].map((tabName) => (
                <div
                  key={tabName}
                  onClick={(event) => {
                    event.preventDefault();
                    handleMyEventsTabClick(tabName);
                  }}
                  className={classNames(
                      myEventsTab === tabName
                      ? 'tw-border-indigo-500 tw-text-indigo-600 tw-cursor-pointer'
                      : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700 tw-cursor-pointer',
                    'tw-whitespace-nowrap tw-border-b-2 tw-py-4 tw-px-1 tw-text-sm tw-font-medium'
                  )}
                  aria-current={myEventsTab === tabName ? 'page' : undefined}
                >
                  {tabName}
                </div>
              ))}
            </div>
          </nav>
        <div className="events__grid">
          {myEventsTab === 'Attending' && attendingEvents.map((event, index) => (
            <EventCard event={event} id={event.id} key={index} />
          ))}
          {myEventsTab === 'Hosting' && hostingEvents.map((event, index) => (
            <EventCard event={event} id={event.id} key={index} />
          ))}
          {myEventsTab === 'Previous Events' && previousEvents.map((event, index) => (
            <EventCard event={event} id={event.id} key={index} />
          ))}
        </div>
        <h1 className="tw-text-2xl tw-font-bold tw-mb-4 tw-mt-4">Upcoming Events</h1>
        <div className="events__controls">
          {/* TABS TO ADD */}
          {/* <div className="events__tabs">
            {['Teahouse', 'Professional', 'Social'].map((tabName) => (
              <div 
                key={tabName} 
                className={`events__tab ${activeTab === tabName ? 'events__tab--active' : ''}`} 
                onClick={() => handleTabClick(tabName)}
              >
                {tabName}
              </div>
            ))}
          </div> */}
        </div>
        {/* <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4"> */}
        <div className="events__grid-upcoming">
          {activeTab === 'Teahouse' && eventsSlice.map((event, index) => (
            <EventCard event={event} id={event.id} key={index} />
          ))}
        </div>
      </div>
      </>
    );
  }
  
//       <h1 className="events__title">Upcoming Events</h1>
//       <div className="events__controls">
//         <button className="events__new-event-button" onClick={handleNewEvent}>New Event</button>
//         <div className="events__tabs">
//           {['Teahouse', 'Professional', 'Social'].map((tabName) => (
//             <div 
//               key={tabName} 
//               className={`events__tab ${activeTab === tabName ? 'events__tab--active' : ''}`} 
//               onClick={() => handleTabClick(tabName)}
//             >
//               {tabName}
//             </div>
//           ))}
//         </div>
//       </div>
//       <div className="events__grid">
//       {

// eventsSlice.map((event, index) => {
//     // Find the event index based on the "Name" key
//     // const eventIndex = events.findIndex(e => e.id === event.id);


//     // Use eventIndex instead of index for the key prop if eventIndex is not -1
//     // The key prop should be unique, so ensure that eventIndex is a valid number
//     // If eventIndex is -1, it means the event was not found based on the Name key, and we fallback to the current index
//     return (
//       <EventCard event={event} id={event.id} key={index} />
//     );
//   })}
//       </div>
    // </div>
    // );
  // }
  
