import React from 'react';

const FilterPopup = React.forwardRef(({ filterKey, values, handleFilterChange, selectedValues, searchText, onSearchTextChange }, ref) => {
    const filteredValues = React.useMemo(() => {
        if (!searchText) return values;
        return values.filter(value =>
            value.toLowerCase().includes(searchText.toLowerCase())
        );
    }, [values, searchText]);

    return (
        <div ref={ref}>
            <input
                type="text"
                value={searchText}
                onChange={e => onSearchTextChange(filterKey, e.target.value)}
                placeholder={`Search...`}
                className="directory__filter-search"
            />
            <div className={`directory__filter-list directory__filter-list--inner`}>
                {filteredValues.map((value, index) => (
                    <div className="directory__filter-list-item" key={index}>
                        <input
                            type="checkbox"
                            id={`${filterKey}-${index}`}
                            value={value}
                            checked={selectedValues.includes(value)}
                            onChange={e => handleFilterChange(filterKey, e.target.value)}
                        />
                        <label htmlFor={`${filterKey}-${index}`}>{value}</label>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default FilterPopup;