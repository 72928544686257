import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router-dom';
import { classNames } from '../../utils/helperFunctions';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { useUser } from '../../contexts/UserContext';
import ChaiCompanyCard from '../../components/chai/ChaiCompanyCard';
import AddChaiContact from '../../components/formPopups/chai/AddChaiContact';
import ChaiContactCard from '../../components/chai/ChaiContactCard';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../../firebase/firebase';
import { useCompanyClickHandler, useContactClickHandler } from '../../utils/handleClicks';
import { handleMultiComboChangeHelper } from '../../utils/formHelperFunctions';


export default function ChaiPage() {
    // const { userCompanies, chaiPageSearchTerm, brewCompanyFilters } = useUser();
    // const userCompanies = [{name: 'jim bob'}]
    const { createUserCompany, fetchUserCompanies, fetchUserContacts, 
      userCompanies, userContacts, createUserContact, initialChaiFetch,
      setUserCompanies, setUserContacts, chaiLoaded, setChaiLoaded,
      myChaiTab, setMyChaiTab, chaiPageSearchTerm, 
      chaiFilters: filters,
    } = useUser();
    const navigate = useNavigate();
    const [chaiLoading, setChaiLoading] = useState(false);
    const [popUpOpen, setPopUpOpen] = useState(false);
    const [chaiFormLoading, setChaiFormLoading] = useState(false);
    
    const handleCompanyClick = useCompanyClickHandler();
    const handleContactClick = useContactClickHandler();
    const [formState, setFormState] = React.useState({
      // companyName: "",
      // notes: "",
      // contactName: "",
      // email: "",
      // linkedIn: "",
      // phone: "",
      // role: ""
  });
  const [formErrors, setFormErrors] = useState({});

const handleMyChaiTabClick = tabName => setMyChaiTab(tabName);

const filteredUserCompanies = React.useMemo(() => {
        try {
            return userCompanies.filter(company => {
                const searchTermLower = chaiPageSearchTerm.toLowerCase();
                const searchableFields = ["companyName", "notes", "email"];

                const fieldMatches =  searchableFields.some(field => {
                    const fieldValue = company[field];
                    if (Array.isArray(fieldValue)) {
                        return fieldValue.some(item => item.toLowerCase().includes(searchTermLower));
                    } 
                    return typeof fieldValue === 'string' && fieldValue.toLowerCase().includes(searchTermLower);
                });
        
              const filtersMatch = Object.keys(filters).every(key => {
                const filterValues = filters[key];
                if (!filterValues || filterValues.length === 0 || filterValues[0] === '') return true;
        
                if (key === 'tags') {
                  return company['tags'] && filterValues.some(tag => company['tags'].includes(tag));
                } else if (key === 'industries') {
                  return company['industries'] && filterValues.some(industry => company['industries'].includes(industry));
                } else if (Array.isArray(filterValues)) {
                  return filterValues.some(filterValue => company[key] && company[key].includes(filterValue));
                }
                return company[key] === filters[key];
              });
        
              // Only return the contact if it matches both the search term and the filters
              return fieldMatches && filtersMatch;
            }).sort((a, b) => a.companyName.localeCompare(b.companyName));
        } catch (error) {
            console.error('Error occurred while filtering companies:', error);
            return userCompanies;
        }
    }, [userCompanies, chaiPageSearchTerm, filters]);
const filteredUserContacts = React.useMemo(() => {
  return userContacts.filter(contact => {
      const searchTermLower = chaiPageSearchTerm.toLowerCase();
      const searchableFields = [
        "contactName",
        "notes",
        "email"
      ];

      const fieldMatches = searchableFields.some(field => {
          if (Array.isArray(contact[field])) {
              return contact[field].some(item => typeof item === 'string' && item.toLowerCase().includes(searchTermLower));
          } else if (typeof contact[field] === 'string') {
              return contact[field].toLowerCase().includes(searchTermLower);
          }
          return false;
      });

      const filtersMatch = Object.keys(filters).every(key => {
        const filterValues = filters[key];
        if (!filterValues || filterValues.length === 0 || filterValues[0] === '') return true;

        if (key === 'tags') {
          return contact['tags'] && filterValues.some(tag => contact['tags'].includes(tag));
        } else if (key === 'industries') {
          return true; //UPDATE IF I WANT INDUSTRY TO WORK ON CONTACTs
        }else if (Array.isArray(filterValues)) {
          return filterValues.some(filterValue => contact[key] && contact[key].includes(filterValue));
        }
        return contact[key] === filters[key];
      });

      // Only return the contact if it matches both the search term and the filters
      return fieldMatches && filtersMatch;

  }).sort((a, b) => a.contactName.localeCompare(b.contactName));
}, [userContacts, chaiPageSearchTerm, filters]);


    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
    
        if (type === 'checkbox') {
          setFormState(prevState => {
            const previousValue = prevState[name];
            const newValue = checked ? [...previousValue, value] : previousValue.filter(v => v !== value);
    
            return {
              ...prevState,
              [name]: newValue
            };
          });
        } else {
          setFormState(prevState => ({
            ...prevState,
            [name]: value
          }));
        }
      };

      const handleSingleChange = (name, item) => {
        const lowerName = name.toLowerCase();
        setFormState(prevState => ({
          ...prevState,
          [lowerName]: item
        }));
      }

      const validateWebsite = (website) => {
          const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
              '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name and extension
              '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
              '(\\:\\d+)?'+ // port
              '(\\/[-a-z\\d%_.~+]*)*'+ // path
              '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
              '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
          return pattern.test(website);
      };

      const handleWebsiteBlur = () => {
        if (formState.website && !validateWebsite(formState.website)) {
            setFormErrors(prevErrors => ({ ...prevErrors, website: 'Website is not valid.' }));
        } else {
            setFormErrors(prevErrors => ({ ...prevErrors, website: '' }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setChaiFormLoading(true);
        const formData = new FormData();
        Object.keys(formState).forEach(key => {
          if (typeof formState[key] === 'object' && formState[key] !== null && !(formState[key] instanceof File)) {
            // Stringify object fields
            formData.append(key, JSON.stringify(formState[key]));
          } else {
              // Append non-object fields as is
            formData.append(key, formState[key]);
          }
        });
        if(myChaiTab === "Companies") {
            // current submit code
            if(!formState["companyName"]) {
                return alert("Name is required")
            }
            try {
                // const startTime = performance.now();
                const newCompany = await createUserCompany(formData);
                // const endTime = performance.now();
                // console.log("Time taken to create the company: " + (endTime - startTime) + " milliseconds.");
                // Handle success response
                // setIsUpdateSuccessful(true);
                // setUserCompanies(prevCompanies => [...prevCompanies, newCompany.data]);
                // setTimeout(() => {
                //     // setIsUpdateSuccessful(false);
                //     setPopUpOpen(false);
                //     setFormState({})
                // }, 3000);
                setPopUpOpen(false);
                setFormState({})
                setChaiFormLoading(false);
            } catch (error) {
                // Handle errors
                console.error('Error occurred while creating company:', error);
                setChaiFormLoading(false);
                // alert('Failed to update profile.');
            }
        } else if(myChaiTab === "Contacts") {
          if(formState["Company"] && !formState["company"]) {
            const company = {
                companyName: formState["Company"],
                id: "",
                notes: ""
            };
            formData.append("company", JSON.stringify(company));
            formData.append("createNewCompany", true);
        }
              if(!formState["contactName"]) {
                return alert("Name is required")
            }
            try {
                const newContact = await createUserContact(formData);
                // setUserContacts(prevContacts => [...prevContacts, newContact.data]);
              //   setTimeout(() => {
              //     // setIsUpdateSuccessful(false);
                 
              // }, 3000);
              setPopUpOpen(false);
              setFormState({})
              setChaiFormLoading(false);
                return newContact;
            } catch (error) {
                console.error('Error occurred while creating contact:', error);
                setChaiFormLoading(false);
            }
        }
      };
    
  
    // useEffect(() => {
    //   if (user) {
    //     const directoryUser = directory.find(directoryUser => directoryUser.id === user.uid);
    //     if (directory && directoryUser) {
    //       setSelectedUser(directoryUser);
    //     }
    //   }
    // }, [directory, user]);
    // if (!userCompanies.length > 0) {
    //   return (<Loading />);
    // }

    const statuses = {
        true: 'tw-text-green-700 tw-bg-green-50 tw-ring-green-600/20',
        // Withdraw: 'tw-text-gray-600 tw-bg-gray-50 tw-ring-gray-500/10',
        false: 'tw-text-red-700 tw-bg-red-50 tw-ring-red-600/10',
      }

    const buttonValues = {
        "Companies": "Company",
        "Contacts": "Contact"
    }

    // const userCompanyNames = userCompanies.map(company => company.companyName);

    const buttonText = `Add ${buttonValues[myChaiTab]}`
    // let formType = myChaiTab;
    // console.log(formState,'formy')
    // if (chaiLoading && !chaiLoaded) {
    //     return <Loading />;
    // }
    return (
      <>
      <AddChaiContact 
      type={myChaiTab}
      handleChange={handleChange}
      handleSingleChange={handleSingleChange}
      formState={formState}
      setFormState={setFormState}
      popUpOpen={popUpOpen} setPopUpOpen={setPopUpOpen}
      handleWebsiteBlur={handleWebsiteBlur}
      handleSubmit={handleSubmit} 
      formErrors={formErrors}
      userCompanies={userCompanies}
      loading={chaiFormLoading}
      handleMultiComboChangeHelper={handleMultiComboChangeHelper}
      />
      <h1 className="tw-text-2xl tw-font-semibold tw-mb-4">Chai: Centralize Your Contacts for Personal and Professional Growth</h1>
              <div className="tw-block  tw-pb-5 lg:tw-pb-10">
        <div className="tw-border-b tw-border-gray-200">
          <nav className="tw-mb-px tw-flex tw-justify-between" aria-label="Tabs">
            <div className="tw-flex tw-space-x-8">
              {['Companies', 'Contacts'].map((tabName) => (
                <div
                  key={tabName}
                  onClick={(event) => {
                    event.preventDefault();
                    handleMyChaiTabClick(tabName);
                  }}
                  className={classNames(
                      myChaiTab === tabName
                      ? 'tw-border-indigo-500 tw-text-indigo-600 tw-cursor-pointer'
                      : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700 tw-cursor-pointer',
                    'tw-whitespace-nowrap tw-border-b-2 tw-py-4 tw-px-1 tw-text-sm tw-font-medium'
                  )}
                  aria-current={myChaiTab === tabName ? 'page' : undefined}
                >
                  {tabName}
                </div>
              ))}
            </div>
            <div>
              <button 
                className="tw-py-2 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-indigo-600 hover:tw-bg-indigo-700"
                onClick={() => setPopUpOpen(true)}
              >
                {buttonText}
                </button>
            </div>
          </nav>
        </div>
      </div>
        <ul role="list" className="tw-grid tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 lg:tw-grid-cols-3 xl:tw-gap-x-8">
      {myChaiTab === "Companies" && filteredUserCompanies.map((client, index) => (
        <ChaiCompanyCard 
        key={client.companyName + index} 
        client={client}
        handleCompanyClick={handleCompanyClick}
        classNames={classNames}
        />
      ))}
      {myChaiTab === "Contacts" && filteredUserContacts.map((contact, index) => (
        <ChaiContactCard 
        key={index} 
        handleContactClick={handleContactClick}
        contact={contact}
        classNames={classNames}
        />
      ))}
    </ul>
    {/* {myChaiTab === "Companies" && filteredUserCompanies.length > 0 && <span>Non-User Logos provided by <a href="https://clearbit.com" className="tw-text-blue-500 tw-hover:text-blue-800">Clearbit</a></span>} */}
    </>
    )
}