import { useNavigate } from 'react-router-dom';

export const cleanString = (str) => str.replace(/[\u200B-\u200D\uFEFF]/g, '');

export function useNavigateToDirectory() {
  const navigate = useNavigate();

  function navigateToDirectory(id) {
    navigate(`/directory/${id}`);
  }

  return {
    navigateToDirectory,
  };
}

export function sortValuesAlphabetically(values) {
  values.sort((a, b) => { // sort alphabetically, considering emojis
    const aEmojiRemoved = a.replace(/^[^\p{L}\p{N}]+/u, '');
    const bEmojiRemoved = b.replace(/^[^\p{L}\p{N}]+/u, '');
    return aEmojiRemoved.localeCompare(bEmojiRemoved);
  });
  return values;
}

export function getUniqueValues(key,directory) {
  let values = directory.flatMap((user, index) => user[key] ? user[key] : []);
  values = [...new Set(values)]; // remove duplicates

  return sortValuesAlphabetically(values);
}

export const uniqueFilterValues = (baseValues, filterKey) => {
  let values = baseValues.flatMap((user, index) => {
    if (user[filterKey]) {
      let value = user[filterKey];
      if (typeof value === 'string') {
        value = value.trim();
        // General pattern match and replace for both FTMBA and FEMBA
        value = value.replace(/^(F[ET]MBA\s'\d{2}\s-\s)([A-Z])$/, "$1Section $2");
      }
  
      return value;
    } else {
      return [];
    }
  });
  
  // Remove duplicates and sort alphabetically
  values = [...new Set(values.map(value => typeof value === 'string' ? value.trim() : value))];
  values.sort((a, b) => { // sort alphabetically, considering emojis
    const aEmojiRemoved = a.replace(/^[^\p{L}\p{N}]+/u, '');
    const bEmojiRemoved = b.replace(/^[^\p{L}\p{N}]+/u, '');
    return aEmojiRemoved.localeCompare(bEmojiRemoved);
  });
  return values;
};

export const uniqueFilterMap = (baseValues, filterKey) => {
  const valueMap = new Map();
  baseValues.forEach(user => {
    if (user[filterKey]) {
      Object.values(user[filterKey]).forEach(value => {
        if (!valueMap.has(value)) {
          valueMap.set(value, true);
        }
      });
    }
  });

  const uniqueValues = Array.from(valueMap.keys());
  uniqueValues.sort((a, b) => { // sort alphabetically, considering emojis
    const aEmojiRemoved = a.replace(/^[^\p{L}\p{N}]+/u, '');
    const bEmojiRemoved = b.replace(/^[^\p{L}\p{N}]+/u, '');
    return aEmojiRemoved.localeCompare(bEmojiRemoved);
  });

  return uniqueValues;
}

export const uniqueFilterValuesMultipleKeys = (baseValues, ...filterKeys) => {
  let values = [];
  baseValues.forEach((user) => {
    filterKeys.forEach(filterKey => {
      if (user[filterKey]) {
        let tempValue = user[filterKey];
        if (typeof tempValue === 'string') {
          tempValue = tempValue.trim();
          // General pattern match and replace for both FTMBA and FEMBA
          tempValue = tempValue.replace(/^(F[ET]MBA\s'\d{2}\s-\s)([A-Z])$/, "$1Section $2");
        }
        if (Array.isArray(tempValue)) {
          tempValue.forEach(val => values.push(val));
        } else {
          values.push(tempValue);
        }
      }
    });
  });
  
  // Remove duplicates and sort alphabetically
  values = [...new Set(values.map(value => typeof value === 'string' ? value.trim() : value))];
  let uniqueObjectValues = [];
  if (values.some(value => typeof value === 'object')) {
    values.forEach(value => {
      if (typeof value === 'object') {
        Object.values(value).forEach(val => {
          if (typeof val === 'string') {
            val = val.trim();
            uniqueObjectValues.push(val);
          }
        });
      }
    });
    uniqueObjectValues = [...new Set(uniqueObjectValues)];
  }
  uniqueObjectValues.sort((a, b) => { // sort alphabetically, considering emojis
    const aEmojiRemoved = typeof a === 'string' ? a.replace(/^[^\p{L}\p{N}]+/u, '') : a;
    const bEmojiRemoved = typeof b === 'string' ? b.replace(/^[^\p{L}\p{N}]+/u, '') : b;
    return String(aEmojiRemoved).localeCompare(String(bEmojiRemoved));
  });
  return uniqueObjectValues;
};


export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const colorArray = 
  [
    "gray",
    "red",
    "indigo",
    "green",
    "blue",
    "yellow",
    "purple",
    "pink"
  ];


export const handleFilterChange = (filterName, value, setFunction) => {
  setFunction(prevFilters => {
    const previousValue = prevFilters[filterName];
    const isArray = Array.isArray(previousValue);
    const includesValue = isArray ? (typeof value === 'object' ? previousValue.some(item => JSON.stringify(item) === JSON.stringify(value)) : previousValue.includes(value)) : false;


    let newValue;
    if (includesValue) {
      if (typeof value === 'object') {
        newValue = previousValue.filter(v => JSON.stringify(v) !== JSON.stringify(value));
      } else {
        newValue = previousValue.filter(v => v !== value);
      }
    } else if (isArray) {
      newValue = [...previousValue, value];
    } else {
      newValue = [value];
    }

    // If the new value is not empty, remove the empty string from the array
    if (value !== '') {
      newValue = newValue.filter(v => v !== '');
    }

    // If the new value array is empty, add the empty string back to the array
    if (newValue.length === 0) {
      newValue = [''];
    }

    return {
      ...prevFilters,
      [filterName]: newValue
    };
  });
};


export const getLinkedInURL = (linkedIn) => {
  // Check if linkedIn already includes a full HTTP/HTTPS URL
  if (linkedIn.startsWith('http://') || linkedIn.startsWith('https://')) {
    return linkedIn;
  }
  
  // Check if linkedIn is a partial URL (e.g., 'linkedin.com/in/username')
  if (linkedIn.startsWith('linkedin.com/') || linkedIn.startsWith('www.linkedin.com/')) {
    return `https://${linkedIn}`;
  }

  // If it's just a username, construct the full LinkedIn URL
  return `https://www.linkedin.com/in/${linkedIn}`;
};

export const formatDate = (dateString) => {
  if (dateString === '') {
    return dateString;
  }
  let date;

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  
  // Check if dateString is an object with _seconds and _nanoseconds properties
  if (typeof dateString === 'object' && dateString.hasOwnProperty('_seconds')) {
    // Convert _seconds to milliseconds and create a new Date object
    date = new Date(dateString._seconds * 1000 + new Date().getTimezoneOffset() * 60000);
    return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  } else {
    let dateArray = dateString.split('-');
    return `${monthNames[parseInt(dateArray[1])-1]} ${dateArray[2]}, ${dateArray[0]}`;
  }
  
  
};

export const toLocalDate = (date) => {
  const d = new Date(date);
  // const offset = d.getTimezoneOffset();
  // const newDate = new Date(d.getTime() - offset * 60 * 1000);
  return d.toISOString().split('T')[0];
};

export const formatPhone = (phoneString) => {
  // Remove all non-numeric characters from the phoneString
  const numericPhone = phoneString.replace(/\D/g, '');

  // Check if the phone number has 10 digits, which is the standard length for US numbers
  if (numericPhone.length === 10) {
    // Format the string to match the US phone number format (xxx) xxx-xxxx
    return `(${numericPhone.slice(0, 3)}) ${numericPhone.slice(3, 6)}-${numericPhone.slice(6)}`;
  } else {
    // If the phone number is not 10 digits long, return the original string
    return phoneString;
  }
};

/**
 * Capitalizes the first letter of each word in a string.
 * @param {string} str - The string to capitalize.
 * @returns {string} - The capitalized string.
 */
export const capitalize = (str) => {
  return str.replace(/\b\w/g, char => char.toUpperCase());
};
