import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router-dom';
import { classNames } from '../../utils/helperFunctions';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { useUser } from '../../contexts/UserContext';
import ChaiCompanyCard from '../../components/chai/ChaiCompanyCard';
import AddChaiContact from '../../components/formPopups/chai/AddChaiContact';
import ChaiContactCard from '../../components/chai/ChaiContactCard';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../../firebase/firebase';
import { useClubClickHandler, useCompanyClickHandler, useCompetitionClickHandler, useContactClickHandler, useOrganizationClickHandler } from '../../utils/handleClicks';
import SchoolClubCard from '../../components/school/SchoolClubCard';
import SchoolOrganizationCard from '../../components/school/SchoolOrganizationCard';
import SchoolCompetitionCard from '../../components/school/SchoolCompetitionCard';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export default function SchoolPage() {
    const { 
        schoolLoaded, 
        setSchoolLoaded,
        schoolTab, 
        setSchoolTab,
        schoolSearchTerm, 
        setSchoolSearchTerm,
        handleSchoolTabClick,
        clubs,
        setClubs,
        organizations,
        setOrganizations,
        competitions, 
        setCompetitions
    } = useUser();
    const navigate = useNavigate();
    const handleClubClick = useClubClickHandler();
    const handleOrganizationClick = useOrganizationClickHandler();
    const handleCompetitionClick = useCompetitionClickHandler();

    useEffect(() => {
      const auth = getAuth(app);
      const unsubscribe = onAuthStateChanged(auth, async (incomingUser) => {
        if (incomingUser) { // Fetch data only if user is logged in
          try {
            const userId = incomingUser.uid;
            const idToken = await incomingUser.getIdToken(true);
            const schoolDataResponse = await axios.get(`${API_BASE_URL}/school-data`, {
              headers: { 'Authorization': `Bearer ${idToken}` },
              params: { uid: userId }
            });
            const schoolData = schoolDataResponse.data;
            const {clubs, organizations, competitions } = schoolData;
            setClubs(clubs);
            setOrganizations(organizations);
            setCompetitions(competitions);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      });
      return () => {
        unsubscribe();
      };
    }, []);


const filteredClubs = React.useMemo(() => {
  if(!schoolSearchTerm){
    return clubs.sort((a, b) => a.fullName.localeCompare(b.fullName));
  }
  try {
    return clubs.filter(club => {
      const searchTermLower = schoolSearchTerm.toLowerCase();
      const searchableFields = ["clubName", "description", "notes"];

      return searchableFields.some(field => {
        const fieldValue = club[field];
        if (Array.isArray(fieldValue)) {
          return fieldValue.some(item => item.toLowerCase().includes(searchTermLower));
        } 
        return typeof fieldValue === 'string' && fieldValue.toLowerCase().includes(searchTermLower);
      });
    }).sort((a, b) => a.fullName.localeCompare(b.fullName));
  } catch (error) {
    console.error('Error occurred while filtering clubs:', error);
    return clubs;
  }
}, [clubs, schoolSearchTerm]);


const filteredCompetitions = React.useMemo(() => {
  if (!schoolSearchTerm) {
    return competitions;
  }
  try {
    return competitions.filter(competition => {
      const searchTermLower = schoolSearchTerm.toLowerCase();
      const searchableFields = ["competitionName", "description"];

      return searchableFields.some(field => {
        const fieldValue = competition[field];
        if (Array.isArray(fieldValue)) {
          return fieldValue.some(item => item.toLowerCase().includes(searchTermLower));
        } 
        return typeof fieldValue === 'string' && fieldValue.toLowerCase().includes(searchTermLower);
      });
    });
  } catch (error) {
    console.error('Error occurred while filtering competitions:', error);
    return competitions;
  }
}, [competitions, schoolSearchTerm]);

    const filteredOrganizations = React.useMemo(() => {
      if(!schoolSearchTerm){
        return organizations;
      }
      return organizations.filter(organization => {
          const searchTermLower = schoolSearchTerm.toLowerCase();
          const searchableFields = [
            "organizationName",
            "notes"
          ];

          const fieldMatches = searchableFields.some(field => {
              if (Array.isArray(organization[field])) {
                  return organization[field].some(item => {
                      if (typeof item === 'string') {
                          return item.toLowerCase().includes(searchTermLower);
                      }
                      return false;
                  });
              } else if (typeof organization[field] === 'string') {
                  return organization[field].toLowerCase().includes(searchTermLower);
              } 
              return false;
          });

          return fieldMatches;
      });
  }, [organizations, schoolSearchTerm]);



    const statuses = {
        true: 'tw-text-green-700 tw-bg-green-50 tw-ring-green-600/20',
        // Withdraw: 'tw-text-gray-600 tw-bg-gray-50 tw-ring-gray-500/10',
        false: 'tw-text-red-700 tw-bg-red-50 tw-ring-red-600/10',
      }

    // const userCompanyNames = userCompanies.map(company => company.companyName);

    // let formType = myChaiTab;
    // console.log(formState,'formy')
    // if (chaiLoading && !chaiLoaded) {
    //     return <Loading />;
    // }
    return (
      <>
      <h1 className="tw-text-2xl tw-font-semibold tw-mb-4">School Hub</h1>
              <div className="tw-block  tw-pb-5 lg:tw-pb-10">
        <div className="tw-border-b tw-border-gray-200">
          <nav className="tw-mb-px tw-flex tw-flex-col sm:tw-flex-row tw-justify-between" aria-label="Tabs">
            <div className="tw-flex tw-flex-wrap tw-space-x-8 tw-justify-center sm:tw-justify-start">
              {['Clubs', 'Competitions', 'Organizations'].map((tabName) => (
                <div
                  key={tabName}
                  onClick={(event) => {
                    event.preventDefault();
                    handleSchoolTabClick(tabName);
                  }}
                  className={classNames(
                      schoolTab === tabName
                      ? 'tw-border-indigo-500 tw-text-indigo-600 tw-cursor-pointer'
                      : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700 tw-cursor-pointer',
                    'tw-whitespace-nowrap tw-border-b-2 tw-py-4 tw-px-1 tw-text-sm tw-font-medium'
                  )}
                  aria-current={schoolTab === tabName ? 'page' : undefined}
                >
                  {tabName}
                </div>
              ))}
            </div>
            {/* {schoolTab === 'Clubs' && <div className="tw-mt-4 sm:tw-mt-0 tw-w-full md:tw-w-auto">
              <button 
                className="tw-w-full sm:tw-w-auto tw-py-2 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-indigo-600 hover:tw-bg-indigo-700"
                onClick={() => navigate('/school/club/add')}
              >
                Add Club
                </button>
            </div>} */}
          </nav>
        </div>
      </div>
        <ul role="list" className="tw-grid tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 lg:tw-grid-cols-3 xl:tw-gap-x-8">
      {schoolTab === "Clubs" && filteredClubs.map((club) => (
        <SchoolClubCard 
        key={club.clubName} 
        club={club}
        handleClubClick={handleClubClick}
        classNames={classNames}
        />
      ))}
      {schoolTab === "Competitions" && filteredCompetitions.map((competition) => (
        <SchoolCompetitionCard
        key={competition.competitionName} 
        competition={competition}
        handleCompetitionClick={handleCompetitionClick}
        classNames={classNames}
        />
      ))}
      {schoolTab === "Organizations" && filteredOrganizations.map((organization) => (
        <SchoolOrganizationCard 
        key={organization.organizationName} 
        organization={organization}
        handleOrganizationClick={handleOrganizationClick}
        classNames={classNames}
        />
      ))}
    </ul>
    {/* {myChaiTab === "Companies" && filteredUserCompanies.length > 0 && <span>Non-User Logos provided by <a href="https://clearbit.com" className="tw-text-blue-500 tw-hover:text-blue-800">Clearbit</a></span>} */}
    </>
    )
}