import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useUser } from '../../contexts/UserContext';
import FilterPopup from '../FilterPopup';
import { getUniqueValues, uniqueFilterValues, useNavigateToDirectory, handleFilterChange, uniqueFilterMap } from '../../utils/helperFunctions';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import MultiComboBoxInput from './MultiComboBoxInput';
import EventDetailFilterPopup from './directory/EventDetailFilterPopup';

export default function EventDetailFilters({ users }) {
  const { 
    events,
    directory,
    eventData, 
    setEventData,
    eventDetailFilters: filters, 
    setEventDetailFilters: setFilters, 
    eventDetailSearchTerm: searchTerm,
    setEventDetailSearchTerm: setSearchTerm,
    clearEventFilters
  } = useUser();

  const dropdownRefs = useRef([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const attendeeSlice = useMemo(() => {
    if (!eventData || !eventData.attendees) return [];

    return Object.values(eventData.attendees).map(attendee => {
      const directoryUser = directory.find(user => user.id === attendee.userId);
      return { ...attendee, ...directoryUser };
    });
  }, [eventData, directory]);

  const handleFilterOpen = () => setFilterOpen(!filterOpen);

const uniqueIndustry = uniqueFilterMap(attendeeSlice, 'industries') || []
const uniqueInterests = uniqueFilterMap(attendeeSlice, 'interests') || []
// console.log(attendeeSlice)
// console.log(uniqueIndustry, uniqueInterests)

// });
  return (
    // <div className="directory__filter-container">
    <>
         {/* <label htmlFor="search-field" className="tw-sr-only">
                  Search
                </label> */}
                {/* <MagnifyingGlassIcon
                  className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-h-full tw-w-5 tw-text-gray-400"
                  aria-hidden="true"
                /> */}
              {/* <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-4 tw-w-full tw-pt-4"> */}
                <div className="tw-grid tw-grid-cols-4 tw-gap-4 tw-w-full">
                  <input
                    id="search-field"
                    className="tw-block tw-w-full tw-h-full tw-border tw-border-gray-300 tw-rounded-md  md:tw-pt-0 tw-pb-0 
                    tw-pl-0 tw-pr-0 tw-text-gray-900 tw-placeholder:text-gray-400 
                    sm:tw-text-sm dark:tw-text-white dark:tw-bg-black tw-col-span-3"
                    // placeholder="Search by name, section i.e. '- C', and more" 
                    placeholder="Search by name, reason for attending, and industry" 
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}  
                    type="search"
                    name="search"
                  />
                  <div className="tw-col-span-1 tw-flex tw-justify-center tw-items-center">
                    <button
                      type="button"
                      className="tw-rounded-full tw-bg-indigo-600 tw-px-4 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
                      onClick={handleFilterOpen}
                    >
                      Filters
                    </button>
                  </div>
                </div>
                
                {/* tw-focus:ring-0 */}
                <EventDetailFilterPopup
                   open={filterOpen}
                   filters={filters}
                   industryItems={uniqueIndustry}
                   industrySingleSelect={false}
                   interestItems={uniqueInterests}
                   interestSingleSelect={false}
                   setOpen={setFilterOpen}
                   setFilters={setFilters}
                   handleFilterChange={handleFilterChange}
                   clearEventFilters={clearEventFilters}
                  />
       
      </>
  );
}
