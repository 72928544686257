import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'

export default function ChaiContactCard({contact, handleContactClick }) {
  return (
        <li 
        key={contact.email} 
        className="tw-col-span-1 tw-divide-y tw-divide-gray-200 tw-rounded-lg tw-bg-white tw-shadow"
        onClick={() => handleContactClick(contact.id)}>
          <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-space-x-6 tw-p-6 tw-min-h-[6em]">
            <div className="tw-flex-1 tw-truncate">
              <div className="tw-flex tw-items-center tw-space-x-3">
                <h3 className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-900">{contact.contactName}</h3>
                {/* <span className="tw-inline-flex tw-flex-shrink-0 tw-items-center tw-rounded-full tw-bg-green-50 tw-px-1.5 tw-py-0.5 tw-text-xs tw-font-medium tw-text-green-700 tw-ring-1 tw-ring-inset tw-ring-green-600/20">
                  {contact.role}
                </span> */}
              </div>
              <p className="tw-mt-1 tw-truncate tw-text-sm tw-text-gray-500 tw-flex-grow">
                {contact.role || " "}{contact.company && Object.keys(contact.company).length > 0 && (
            <>
              {" - "}
              <span 
                className="tw-truncate tw-text-sm tw-text-gray-500 tw-flex-grow"
              >
                {contact.company.companyName}
              </span>
            </>
          )} 
                </p>
            </div>
            {contact.imageUrl && <img className="tw-h-10 tw-w-10 tw-flex-shrink-0 tw-rounded-full tw-bg-gray-300" src={contact.imageUrl} alt="" />}
          </div>
          <div>
            <div className="tw--mt-px tw-flex tw-divide-x tw-divide-gray-200">
              <div className="tw-flex tw-w-0 tw-flex-1">
                {contact.email && (
                  <a
                    href={`mailto:${contact.email}`}
                    className="tw-relative tw--mr-px tw-inline-flex tw-w-0 tw-flex-1 tw-items-center tw-justify-center tw-gap-x-3 tw-rounded-bl-lg tw-border tw-border-transparent tw-py-4 tw-text-sm tw-font-semibold tw-text-blue-900 hover:tw-text-blue-600"
                  >
                    <EnvelopeIcon className="tw-h-5 tw-w-5 tw-text-gray-400 hover:tw-text-blue-600" aria-hidden="true" />
                    Email
                  </a>
                )}
              </div>
              <div className="tw--ml-px tw-flex tw-w-0 tw-flex-1">
                {contact.phone && (
                  <a
                    href={`tel:${contact.phone}`}
                    className="tw-relative tw-inline-flex tw-w-0 tw-flex-1 tw-items-center tw-justify-center tw-gap-x-3 tw-rounded-br-lg tw-border tw-border-transparent tw-py-4 tw-text-sm tw-font-semibold tw-text-gray-900"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href=`tel:${contact.phone}`;
                    }}
                  >
                    <PhoneIcon className="tw-h-5 tw-w-5 tw-text-blue-400 hover:tw-text-blue-600" aria-hidden="true" />
                    Call
                  </a>
                )}
              </div>
            </div>
          </div>
        </li>
  )
}
