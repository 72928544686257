import React, { useState } from 'react';
import { getAuth, confirmPasswordReset } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import SuccessfulNoti from '../components/notifications/SuccessfulNoti';

const AuthActions = () => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [resetMessage, setResetMessage] = useState('');
  const [showSuccessNoti, setShowSuccessNoti] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  const auth = getAuth();
  const navigate = useNavigate();

  const getErrorMessage = (error) => {
    switch (error.code) {
      case 'auth/invalid-action-code':
        return "The Reset Link has expired, please request a new one";
      case 'auth/user-disabled':
        return "The user corresponding to the provided code has been disabled.";
      case 'auth/user-not-found':
        return "The user corresponding to the provided code does not exist.";
      case 'auth/weak-password':
        return "The new password is too weak. Please choose a stronger password.";
      case 'auth/operation-not-allowed':
        return "Password reset is not enabled. Please contact support.";
      default:
        return `An error occurred during reset. Please try again. Error: ${error}`;
    }
  }

  const handlePasswordReset = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');
    const code = urlParams.get('oobCode'); // You get this from the URL parameters
    const newPassword = password;

    if (mode === 'resetPassword') {
      try {
        await confirmPasswordReset(auth, code, newPassword);
        setError(null);
        setResetMessage('Password reset successful!');
        setIsResetting(true);
        setShowSuccessNoti(true);
        setTimeout(() => {
          navigate('/login');
          setIsResetting(false);
          setShowSuccessNoti(false);
        }, 5000);
      } catch (error) {
        setError(getErrorMessage(error));
        console.error(error);
      }
    }
  }

  return (
    <>{showSuccessNoti && <SuccessfulNoti show={showSuccessNoti} setShow={setShowSuccessNoti} message={resetMessage} />}
    <div className="tw-relative tw--mt-[5.75rem] tw-overflow-hidden pt-[5.75rem] login-form">
  <div className="tw-relative tw-mx-auto tw-mt-20 tw-w-full tw-max-w-container sm:tw-px-6 lg:tw-px-8">
    <div className="tw-space-y-24 tw-pb-px">
      <div className="tw-grid tw-grid-cols-[1fr,auto] tw-items-center">
        <div className="tw-col-span-2 tw-row-start-2 tw-min-w-0">
          <div className="tw-flex tw-min-h-[840px] tw-flex-col tw-bg-white">
         
             
            <div className="tw-flex tw-min-h-full tw-flex-1 tw-flex-col tw-justify-center tw-px-6 tw-py-12 lg:tw-px-8 lg:tw-w-1/3 lg:tw-mx-auto">
              <div className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm">
                <form className="tw-space-y-6">
                <h2 className="tw-mt-10 tw-mb-10 tw-text-center tw-text-2xl tw-font-bold tw-leading-9 tw-tracking-tight tw-text-gray-900">
                Reset your Chai password
              </h2>
              <div className="tw-mt-2 tw-text-sm tw-font-medium tw-text-gray-600 tw-border tw-border-gray-200 tw-rounded tw-p-4 tw-bg-gray-50">
                <p>Please note:</p>
                <ul className="tw-list-disc tw-list-inside">
                  <li>Firebase requires the password to be at least 6 characters long.</li>
                  <li>The password reset token expires quickly for security reasons. If you need another one, please request another on the login page</li>
                </ul>
              </div>
                  <div>
                    <label htmlFor="password" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                      New Password
                    </label>
                    {error && (
                      <div className="tw-mt-2 tw-text-red-500 tw-text-sm">
                        {error}
                      </div>
                    )}
                    <div className="tw-mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={handlePasswordReset}
                      className="tw-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-indigo-600 tw-px-3 tw-py-1.5 tw-text-sm tw-font-semibold tw-leading-6 tw-text-white tw-shadow-sm tw-hover:bg-indigo-500 tw-focus-visible:outline tw-focus-visible:outline-2 tw-focus-visible:outline-offset-2 tw-focus-visible:outline-indigo-600"
                      disabled={isResetting}
                    >
                      Reset Password
                    </button>
                  </div>
                </form>
                <p className="tw-mt-10 tw-text-center tw-text-sm tw-text-gray-500">
                  <a href="/login" className="tw-font-semibold tw-leading-6 tw-text-indigo-600 tw-hover:text-indigo-500">Sign In</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</>
  );
};

 {/* <div className="tw-text-center">
                    <h2 className="tw-mt-10 tw-text-2xl tw-font-bold tw-leading-9 tw-tracking-tight tw-text-gray-900">
                      Reset your Chai password
                    </h2>
                    <p className="tw-mt-2 tw-text-lg tw-font-medium tw-text-gray-600">
                      Please enter your new password below
                    </p>
                  </div> */}
export default AuthActions;

