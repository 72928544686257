import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="tw-p-8 tw-bg-white tw-rounded-lg tw-shadow-md tw-max-w-2xl tw-mx-auto">
      <h1 className="tw-text-2xl tw-font-bold tw-mb-4">Terms and Conditions of TeahouseAI</h1>
      <p className="tw-mb-2">Last Updated: January 2nd, 2024</p>
      <ul className="tw-list-none tw-list-inside">
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">1. Introduction</h2>
          <p>Welcome to TeahouseAI, headquartered in Dallas, TX. These Terms and Conditions govern your use of our diverse suite of services, including Customer Relationship Management (CRM), event management, directory services, and opportunities to earn money through campus activities ("Services"). By accessing or using our Services, you agree to these Terms.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">2. Service Description and Access</h2>
          <p>TeahouseAI offers a comprehensive CRM platform tailored for educational communities, including features for event management, a directory service, and tools to monetize campus activities. Our Services are dynamic and may evolve, including the addition of new features and tools.</p>
          <h3 className="tw-font-bold">2.1 Service Modifications</h3>
          <p>We may modify or discontinue any part of our Services at any time, with or without notice. We are not liable to you or any third party for any modification, suspension, or discontinuance of our Services.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">3. User Registration and Account Security</h2>
          <p>Users must register and create an account to access certain features of our Services. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
          <h3 className="tw-font-bold">3.1 Account Security</h3>
          <p>You are responsible for maintaining the confidentiality of your account information, including your password. You are also responsible for all activities that occur under your account.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">4. Data Use, Privacy, and Security</h2>
          <p>Your privacy is important to us. Our Privacy Policy, which is incorporated into these Terms by reference, explains how we collect, use, store, and protect your data.</p>
          <h3 className="tw-font-bold">4.1 Data Storage and Analytics</h3>
          <p>We use Firebase for secure data storage and Google Analytics for data analysis. Our third-party payment processors include Fenix, PayPal, and Stripe.</p>
          <h3 className="tw-font-bold">4.2 Data Sharing and Third-Party Services</h3>
          <p>We may share your data with third parties in accordance with our <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. This includes sharing with service providers like ChromaDB and OpenAI API, as required for the provision of our Services.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">5. User Responsibilities and Conduct</h2>
          <p>You agree to use our Services in accordance with all applicable laws and regulations and not to engage in any activity that is harmful to us, our Services, or other users.</p>
          <h3 className="tw-font-bold">5.1 Prohibited Conduct</h3>
          <p>You must not misuse our Services, such as by interfering with their normal operation or attempting to access them using a method other than through the interfaces and instructions provided by us.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">6. Intellectual Property Rights</h2>
          <p>All intellectual property rights in our Services, including software, trademarks, and content provided by us, are owned by TeahouseAI or our licensors. Your use of our Services does not grant you any rights to our intellectual property.</p>
          <h3 className="tw-font-bold">6.1 User Content</h3>
          <p>You retain all rights to any data, information, or material that you submit, post, or display on or through our Services ("User Content"). By submitting User Content, you grant us a worldwide, non-exclusive, royalty-free license to use, copy, modify, distribute, and display such User Content in connection with our Services.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">7. Warranties and Disclaimers</h2>
          <p>TeahouseAI provides the Services on an "as is" and "as available" basis. We do not guarantee that the Services will be uninterrupted or error-free, and we disclaim all warranties, express or implied, including warranties of merchantability and fitness for a particular purpose.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">8. Limitation of Liability</h2>
          <p>To the maximum extent permitted by applicable law, TeahouseAI shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your access to, use of, or inability to access or use our Services; (b) any conduct or content of any third party on our Services, including any defamatory, offensive, or illegal conduct of other users or third parties; or (c) unauthorized access, use, or alteration of your transmissions or content.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">9. Indemnification</h2>
          <p>You agree to indemnify and hold harmless TeahouseAI and its officers, directors, employees, and agents from any and all claims, damages, liabilities, costs, and fees (including reasonable attorneys' fees) arising from or relating to your use of the Services, including but not limited to your violation of these Terms.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">10. Termination</h2>
          <p>We may terminate or suspend your access to our Services immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach these Terms.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">11. Governing Law and Jurisdiction</h2>
          <p>These Terms shall be governed and construed in accordance with the laws of the State of Texas, USA, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">12. Changes to Terms</h2>
          <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">13. Contact Information</h2>
          <p>If you have any questions about these Terms, please contact us at <a href="mailto:team@teahouseai.com">team@teahouseai.com</a>.</p>
        </li>
      </ul>
    </div>
  );
};

export default TermsAndConditions;
