import { useState, useEffect } from 'react';
import axios from 'axios';
import { app, fireStoreDB } from '../../firebase/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const useDirectoryContext = () => {
//   const [brewWorksPageFilters, setBrewWorksPageFilters] = useState({
//   });
const [directory, setDirectory] = useState([]);
const [signUpData, setSignUpData] = useState([]);
const [userInfo, setUserInfo] = useState(null);
const [directorySearchTerm, setDirectorySearchTerm] = useState('');
const [directoryFilterTerm, setDirectoryFilterTerm] = useState('');
const [directoryFilters, setDirectoryFilters] = useState({
    "program": [], 
    "country": [], 
    "recruitingFor": [], 
    "interestsHobbies": [],
    "professionalEventsAttended": [] 
  });

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, async (incomingUser) => {
      if (incomingUser) { // Fetch data only if user is logged in
        try {
          //yi xiaos
          const idToken = await incomingUser.getIdToken(true);
          // const [dirDataResponse, userDataResponse] = await Promise.all([
          //   axios.get(`${API_BASE_URL}/directory-small`, {
          //     headers: { 'Authorization': `Bearer ${idToken}` }
          //   }),
          //   axios.get(`${API_BASE_URL}/fetch-user`, {
          //     params: { id: incomingUser.uid },
          //     headers: { 'Authorization': `Bearer ${idToken}` }
          //   }),
          // ]);  
          // const dirData = dirDataResponse.data.sort((a, b) => a.name.localeCompare(b.name));
        //   const mergedDirectory = [...directory, ...dirData];
        //   const uniqueDirectory = Array.from(new Set(mergedDirectory.map(a => a.id)))
        //     .map(id => {
        //       return mergedDirectory.find(a => a.id === id)
        //     });
        //   setDirectory(uniqueDirectory);
          const [userDataResponse, signUpDataResponse] = await Promise.all([
            axios.get(`${API_BASE_URL}/fetch-user`, {
              params: { id: incomingUser.uid },
              headers: { 'Authorization': `Bearer ${idToken}` }
            }),
            axios.get(`${API_BASE_URL}/signup-data`, {
              headers: { 'Authorization': `Bearer ${idToken}` },
            }),
          ]);  
          const userProfile = userDataResponse ? userDataResponse.data ? userDataResponse.data : {} : {};
          setUserInfo(userProfile);
          setSignUpData(signUpDataResponse.data);
          localStorage.setItem('teaData', JSON.stringify({ 
            userInfo: userProfile ? userProfile : {}, 
          }));
          // Merge new data with existing data and remove duplicates
 
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const getSignUpData = async () => {
      const auth = getAuth(app);
      if (auth.currentUser) {
        const idToken = 'blank'
        try {
          const response = await axios.get(`${API_BASE_URL}/signup-data`, {
            headers: { 'Authorization': `Bearer ${idToken}` },
          });
          setSignUpData(response.data);
        } catch (error) {
          console.error("Error fetching signup data:", error);
        }
      }
    };
    getSignUpData();
  }, []);

  const clearDirectoryFilters = () => {
    setDirectoryFilters({
        "program": [], 
        "country": [], 
        "recruitingFor": [], 
        "interestsHobbies": [],
        "professionalEventsAttended": [] 
    })
  } 

  const directorySearch = async () => {
    const auth = getAuth(app);
    if (auth && auth.currentUser) {
      try {
        const idToken = await auth.currentUser.getIdToken(true);
        const filteredDirectoryFilters = Object.entries(directoryFilters).reduce((acc, [key, value]) => {
          if (Array.isArray(value) && value.length > 0 && !(value.length === 1 && value[0] === '')) {
            acc[key] = value;
          }
          return acc;
        }, {});
        
        const response = await axios.get(`${API_BASE_URL}/directory-search`, {
          params: {
            searchTerm: directorySearchTerm,
            directoryFilters: filteredDirectoryFilters,
            id: auth.currentUser.uid,
          },
          headers: { 'Authorization': `Bearer ${idToken}` }
        });
        if (response.data) {
          setDirectory(prevDirectory => {
            const newEntries = response.data.filter(newEntry => !prevDirectory.some(prevEntry => prevEntry.id === newEntry.id));
            return [...prevDirectory, ...newEntries];
          });
        }
      } catch (error) {
        console.error("Error during directory search:", error);
      }
    }
  };

  const fetchUser = async (userId) => {

    const auth = getAuth(app);
    if (auth && auth.currentUser) {
      try {
        const idToken = await auth.currentUser.getIdToken(true);
        const response = await axios.get(`${API_BASE_URL}/fetch-user`, {
          params: { id: userId },
          headers: { 'Authorization': `Bearer ${idToken}` }
        });
        if (response.data) {
          let found = false;
          setDirectory(prevDirectory => {
            if (prevDirectory.some(item => item.id === response.data.id)) found = true;
            if(found) return prevDirectory
            else return [...prevDirectory, response.data];
          });
        }
      } catch (error) {
        console.error("Error during directory search:", error);
      }
    }
  }

  const addTagsToUser = async (tags) => {
    const auth = getAuth(app);
    if (auth && auth.currentUser) {
      try {
        const idToken = await auth.currentUser.getIdToken(true);
        const userId = auth.currentUser.uid;
        const response = await axios.put(`${API_BASE_URL}/add-tags-to-user`, null, {
          params: {userId, tags: [tags] },
          headers: { 'Authorization': `Bearer ${idToken}` }
        });
        if (response.data) {
          const newTagsArray = response.data?.data?.chaiTagsArray || []
        setUserInfo(prevUserInfo => {
          return { ...prevUserInfo, chaiTagsArray: newTagsArray };
        });
        return newTagsArray;
        } else {
          console.log(response)
        }
      } catch (error) {
        console.error("Error adding tags to user:", error);
      }
    } 
  };

  const editUserTags = async (oldTag,newTag) => {
    const auth = getAuth(app);
    if (auth && auth.currentUser) {
      try {
        const idToken = await auth.currentUser.getIdToken(true);
        const userId = auth.currentUser.uid;
        const response = await axios.put(`${API_BASE_URL}/update-tags-for-user`, null, {
          params: {userId, oldTag, newTag },
          headers: { 'Authorization': `Bearer ${idToken}` }
        });
        if (response.data) {
        setUserInfo(prevUserInfo => {
          const updatedChaiTagsArray = prevUserInfo.chaiTagsArray ? prevUserInfo.chaiTagsArray.map(tag => tag === oldTag ? newTag : tag) : [];
          return { ...prevUserInfo, chaiTagsArray: updatedChaiTagsArray };
        });
        } else {
          console.log(response)
        }
      } catch (error) {
        console.error("Error adding tags to user:", error);
      }
    } 
  };
  return {
    directory, 
    setDirectory,
    userInfo, 
    setUserInfo,
    directoryFilters, 
    setDirectoryFilters,
    signUpData, 
    setSignUpData,
    clearDirectoryFilters,
    directorySearchTerm, 
    setDirectorySearchTerm,
    directoryFilterTerm, 
    setDirectoryFilterTerm,
    directorySearch,
    fetchUser,
    // --- tag Functions
    addTagsToUser,
    editUserTags
  };
};
