import React, {useState, useEffect, useMemo} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CompetitionTeamCard from '../../components/school/CompetitionTeamCard';
import { useUser } from '../../contexts/UserContext';
import CompetitionFreelancerCard from '../../components/school/CompetitionFreelancerCard';

export default function SchoolCompetition({ eventList }) {
    const navigate = useNavigate();
    const { events, user, clubs, directory, organizations, 
        competitions, schoolCompetitionData, setSchoolCompetitionData,
        competitionFilters: filters, 
        competitionSearchTerm: searchTerm, } = useUser();
    let { id } = useParams();
    // const handleContactClick = useContactClickHandler();
  
    // const handleNewEvent = () => {
    //   navigate('/events/new'); // replace with the actual route to create a new event
    // };

    useEffect(() => {
        if (competitions) {
          const schoolCompetition = competitions.find(schoolCompetition => schoolCompetition.id === id);
          if (schoolCompetition) {
            setSchoolCompetitionData({ ...schoolCompetition });
          }
        }
      }, [competitions, id]);

    const competitionName = schoolCompetitionData ? schoolCompetitionData.competitionName : "";
    // const competitionTeams = schoolCompetitionData ? schoolCompetitionData.teamMap : {};
    // const competitionFreelancers = schoolCompetitionData ? schoolCompetitionData.freelancerMap : {};

    const competitionTeams = useMemo(() => {
        if (!schoolCompetitionData || !schoolCompetitionData.teamMap) return {};
    
        return Object.entries(schoolCompetitionData.teamMap).map(([teamId, team]) => {
          return {
            ...team,
            teamId: teamId,
            teamMembers: Object.values(team.teamMembers).map(member => {
              if (member.hasJoined) {
                const directoryUser = directory.find(user => user.id === member.id);
                return { ...member, ...directoryUser };
              }
              return member;
            })
          };
        });
      }, [schoolCompetitionData, directory]);


    const competitionFreelancers = useMemo(() => {
        if (!schoolCompetitionData || !schoolCompetitionData.freelancerMap) return [];
    
        return Object.entries(schoolCompetitionData.freelancerMap).map(([freelancerId, freelancer]) => {
          if (freelancer.hasJoined) {
            const directoryUser = directory.find(user => user.id === freelancer.directoryId);
            return { ...freelancer, ...directoryUser, freelancerId: freelancerId };
          }
          return { ...freelancer, freelancerId: freelancerId };
        });
      }, [schoolCompetitionData, directory]);

    
      const filteredTeams = useMemo(() => {
        if (!competitionTeams || Object.keys(competitionTeams).length === 0) return [];

        return competitionTeams.filter(team => {
            const searchTermLower = searchTerm.toLowerCase();
            const searchableFields = ['teamName', 'description'];
            const fieldMatches = searchableFields.some(field => {
              if (Array.isArray(team[field])) {
                return team[field].some(item => item.toLowerCase().includes(searchTermLower));
              } else if (typeof team[field] === 'string') {
                return team[field].toLowerCase().includes(searchTermLower);
              }
              return false;
            });

            const filtersMatch = Object.keys(filters).every(key => {
                const filterValues = filters[key];
                if (!filterValues || filterValues.length === 0 || filterValues[0] === '') return true;
                if (key === 'memberTypes') {
                  return team['teamNeeds'] && filters[key].some(filter => Object.values(team['teamNeeds']).includes(filter));
                }
                if (key === 'teamTypes') {    
                  return team['competitionSectors'] && filters[key].some(filter => Object.values(team['competitionSectors']).includes(filter));
                }
                return team[key] === filters[key];
              });

            return fieldMatches && filtersMatch;
        }).sort((a, b) => a.teamName.localeCompare(b.teamName));
      }, [competitionTeams, filters, searchTerm]); 

      const filteredFreelancers = useMemo(() => {
        if (!competitionFreelancers || Object.keys(competitionFreelancers).length === 0) return [];

        return competitionFreelancers.filter(freelancer => {
            const searchTermLower = searchTerm.toLowerCase();
            const searchableFields = ['freelancerName', 'school', 'background'];
            const fieldMatches = searchableFields.some(field => {
              if (Array.isArray(freelancer[field])) {
                return freelancer[field].some(item => item.toLowerCase().includes(searchTermLower));
              } else if (typeof freelancer[field] === 'string') {
                return freelancer[field].toLowerCase().includes(searchTermLower);
              }
              return false;
            });
              const filtersMatch = Object.keys(filters).every(key => {
                const filterValues = filters[key];
                if (!filterValues || filterValues.length === 0 || filterValues[0] === '') return true;
                if (key === 'memberTypes') {
                    return freelancer['freelancerSkills'] && filters[key].some(filter => Object.values(freelancer['freelancerSkills']).includes(filter));
                  }
                  if (key === 'teamTypes') {
                    return freelancer['interestSectors'] && filters[key].some(filter => Object.values(freelancer['interestSectors']).includes(filter));
                  }
                return freelancer[key] === filters[key];
              });

            return fieldMatches && filtersMatch;
        }).sort((a, b) => a.freelancerName.localeCompare(b.freelancerName));
      }, [competitionFreelancers, filters, searchTerm]); 

    return (
      <>
        {/* <button 
            className="tw-py-2 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-indigo-600 hover:tw-bg-indigo-700 tw-float-right" 
            onClick={handleNewEvent}
        >
            New Event
        </button> */}
        <button className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-rounded tw-px-4 tw-py-2" onClick={() => navigate(-1)}>Back</button>
        <h1 className="tw-text-2xl tw-font-bold tw-mb-4">{competitionName}</h1>
        <div className="tw-border-gray-200">
            <h2 className="tw-text-lg tw-font-bold tw-mb-4">Teams</h2>
          {/* <nav className="tw-mb-px tw-flex tw-justify-between tw-pb-5" aria-label="Tabs">
            <div className="tw-flex tw-space-x-8">
              {['Attending', 'Hosting', 'Previous Events'].map((tabName) => (
                <div
                  key={tabName}
                  onClick={(event) => {
                    event.preventDefault();
                    handleMyEventsTabClick(tabName);
                  }}
                  className={classNames(
                      myEventsTab === tabName
                      ? 'tw-border-indigo-500 tw-text-indigo-600 tw-cursor-pointer'
                      : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700 tw-cursor-pointer',
                    'tw-whitespace-nowrap tw-border-b-2 tw-py-4 tw-px-1 tw-text-sm tw-font-medium'
                  )}
                  aria-current={myEventsTab === tabName ? 'page' : undefined}
                >
                  {tabName}
                </div>
              ))}
            </div>
          </nav> */}
        <div className="events__grid">
          {filteredTeams && Object.values(filteredTeams).map((team, index) => (
            <CompetitionTeamCard team={team} competitionId={id} teamId={team['teamId']} key={index} />
          ))}
        </div>
        <h2 className="tw-text-lg tw-font-bold tw-mb-4 tw-mt-4">Freelancers</h2>
        <div className="events__grid-upcoming">
          {filteredFreelancers && Object.values(filteredFreelancers).map((freelancer, index) => {
            return <CompetitionFreelancerCard freelancer={freelancer} competitionId={id} freelancerId={freelancer['freelancerId']} key={index} />
          })}
        </div>
      </div>
      </>
    );
  }
  
//       <h1 className="events__title">Upcoming Events</h1>
//       <div className="events__controls">
//         <button className="events__new-event-button" onClick={handleNewEvent}>New Event</button>
//         <div className="events__tabs">
//           {['Teahouse', 'Professional', 'Social'].map((tabName) => (
//             <div 
//               key={tabName} 
//               className={`events__tab ${activeTab === tabName ? 'events__tab--active' : ''}`} 
//               onClick={() => handleTabClick(tabName)}
//             >
//               {tabName}
//             </div>
//           ))}
//         </div>
//       </div>
//       <div className="events__grid">
//       {

// eventsSlice.map((event, index) => {
//     // Find the event index based on the "Name" key
//     // const eventIndex = events.findIndex(e => e.id === event.id);


//     // Use eventIndex instead of index for the key prop if eventIndex is not -1
//     // The key prop should be unique, so ensure that eventIndex is a valid number
//     // If eventIndex is -1, it means the event was not found based on the Name key, and we fallback to the current index
//     return (
//       <EventCard event={event} id={event.id} key={index} />
//     );
//   })}
//       </div>
    // </div>
    // );
  // }
  
