import { UserGroupIcon, BriefcaseIcon, BookOpenIcon, CurrencyDollarIcon, CalendarIcon, UserIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

const features = [
  {
    name: 'Chai - Your Personal CRM',
    description:
      'Effortlessly manage your contacts and interactions. Keep track of your network and strengthen your professional relationships.',
    href: '/chai',
    icon: UserGroupIcon,
  },
  {
    name: 'BrewWorks - Organize your Job Search',
    description:
      'Streamline your job search with organized lists, track applications, and set reminders for follow-ups. Your job search, simplified.',
    href: '/brew',
    icon: BriefcaseIcon,
  },
  // {
  //   name: 'Directory - Discover new contacts',
  //   description:
  //     'Expand your network by discovering new contacts within your community. Connect, engage, and grow together.',
  //   href: '/directory',
  //   icon: BookOpenIcon,
  // },
  // {
  //   name: 'Earn - Ways to earn on Campus',
  //   description:
  //     'Access a curated list of paid studies, part-time jobs, and other earning opportunities on campus. Start earning today.',
  //   href: '/earn',
  //   icon: CurrencyDollarIcon,
  // },
  {
    name: 'Events - Your next great event',
    description:
      'Discover and attend events that match your interests. From professional workshops to social gatherings, find your next great experience.',
    href: '/events',
    icon: CalendarIcon,
  },
{
  name: 'Profile - Manage Your Identity',
  description:
    'Update your profile, manage your skills, and showcase your interests to the network.',
  href: '/profile',
  icon: UserIcon,
},
]

export default function GettingStarted() {
  return (
    <div className="tw-bg-white tw-py-6 sm:tw-py-12">
      <div className="tw-mx-auto tw-max-w-7xl tw-px-6 lg:tw-px-8">
        <div className="tw-mx-auto tw-max-w-2xl lg:tw-text-center">
          <h2 className="tw-text-base tw-font-semibold tw-leading-7 tw-text-indigo-600">Welcome to Chai</h2>
          <p className="tw-mt-2 tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900 sm:tw-text-4xl">
            Where Your Network Flourishes
          </p>
          <p className="tw-mt-6 tw-text-lg tw-leading-8 tw-text-gray-600">
            We're thrilled to guide you through a journey of professional growth and connection. Let's brew something great!
          </p>
        </div>
        <div className="tw-mx-auto tw-mt-16 tw-max-w-2xl sm:tw-mt-20 lg:tw-mt-24 lg:tw-max-w-4xl">
          <dl className="tw-grid tw-max-w-xl tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10 lg:tw-max-w-none lg:tw-grid-cols-2 lg:tw-gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="tw-relative tw-pl-16">
                <dt className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">
                  <Link to={feature.href} className="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-lg tw-bg-indigo-600 hover:tw-bg-indigo-700 hover:tw-cursor-pointer">
                    <feature.icon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
                  </Link>
                  <span className="tw-text-lg">{feature.name}</span>
                </dt>
                <dd className="tw-mt-2 tw-text-base tw-leading-7 tw-text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
