import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import Loading from '../../components/Loading';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../../firebase/firebase';
import { useCompanyClickHandler } from '../../utils/handleClicks';
import EditChaiContact from '../../components/formPopups/chai/EditChaiContact';
import { handleChangeHelper, handleMultiComboChangeHelper, handleWebsiteBlurHelper } from '../../utils/formHelperFunctions';
import { useChaiEditSubmit } from '../../utils/chaiHelperFunctions';
import AddAction from '../../components/formPopups/actions/AddAction';
import ActionsTimeline from '../../components/timeline/ActionTimeline';
import { colorArray, formatDate, handleFilterChange } from '../../utils/helperFunctions';
import { XMarkIcon as XIcon,PlusIcon, PencilIcon } from '@heroicons/react/20/solid';
import { industryValues } from '../../utils/helperValues';
import { DateTime } from "luxon";
import { filterAndSortTags, handleCreateNewTag, sortTags } from '../../components/filters/chai/ChaiHelpers';

export default function ChaiContact() {
  const { userCompanies, userContacts, user, fetchUserCompanies, fetchUserContacts, userInfo, 
    myChaiTab, chaiFormLoading, chaiPopUpOpen, setChaiPopUpOpen, setMyChaiTab, logContactAction, updateContactAction,
    addTagsToUser, editUserTags, addTagToContact, removeTagFromContact } = useUser();
  const [contactData, setContactData] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [actionType, setActionType] = useState('Add');
  const [contactCompanyData, setContactCompanyData] = useState({});
  const [actionPopup, setActionPopup] = useState(false);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [customTagModalOpen,setCustomTagModalOpen] = useState(false);
  const [customTagName, setCustomTagName] = useState('');
  const [editTagModalOpen,setEditTagModalOpen] = useState(false);
  const [editTagName, setEditTagName] = useState('');
  const [companyFilters, setCompanyFilters] = useState({ 
    industries: [] });
  let { id } = useParams();
  let completeCompanyData;
  const navigate = useNavigate();
  const handleCompanyClick = useCompanyClickHandler();

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, async (incomingUser) => {
        if (incomingUser && userCompanies.length === 0) {
            fetchUserCompanies();
            fetchUserContacts();
        }
    });
    return () => {
        unsubscribe();
    };
}, []);

  useEffect(() => {
    if (userContacts) {
      const chaiContact = userContacts.find(contact => contact.id === id);
      if (chaiContact) {
        let companyData = {};
        if (chaiContact.companyId) {
          const chaiCompany = userCompanies.find(company => company.id === chaiContact.companyId);
          if (chaiCompany) {
            companyData = {
              id: chaiCompany.id,
              name: chaiCompany.companyName
            };
            setContactCompanyData({
              id: chaiCompany.id,
              companyName: chaiCompany.companyName
            })
          }
        }
        setContactData({ 
          ...chaiContact, 
          company: companyData
        });
      }
    }
  }, [userCompanies, userContacts, id]);

  useEffect(() => {
    if (userCompanies) {
      const chaiCompany = userCompanies.find(chaiCompany => chaiCompany.id === id);
      if (chaiCompany) {
        let companyContacts = [];
        if (userContacts && userContacts.length > 0) {
          companyContacts = userContacts.filter(contact => contact.companyId === chaiCompany.id);
        }
        setContactData({ ...chaiCompany, contacts: companyContacts });
      }
    }
  }, [userCompanies, userContacts, id]);

  const [formState, setFormState] = useState({
    contactName: "",
    role: "",
    email: "",
    phone: "",
    notes: "",
    linkedIn: "",
    companyName: "",
    website: "",
    lastContactDate: new Date().toLocaleDateString('en-CA'),
    tags: [],
  });

  const [actionState, setActionState] = useState({
    actionType: "emailed",
    notes: "",
    actionDate: DateTime.now().toISODate(),
  });

  useEffect(() => {
    if (contactData) {
      setFormState(prevState => ({
        contactName: contactData.contactName,
        // company: contactData.company ? contactData.company : {},
        role: contactData.role,
        email: contactData.email,
        phone: contactData.phone,
        linkedIn: contactData.linkedIn,
        notes: contactData.notes || '',
        website: contactData.website || '',
        lastContactDate: contactData.lastContactDate || new Date().toLocaleDateString('en-CA'),
        tags: contactData.tags || []
      }));
      setCompanyFilters({
        industries: contactData.industries || []
      })
    }
  }, [contactData]);


  const handleSingleChange = (name, item) => {
    const lowerName = name.toLowerCase();
    setFormState(prevState => ({
      ...prevState,
      [lowerName]: item
    }));
  }

  const handleActionClick = (action, isNew) => {
    setAction(action)
    if (isNew) {
      setActionType('Add');
      setActionState({
        actionType: action,
        notes: "",
        actionDate: DateTime.now().toISODate(),
      });
    }
    setActionPopup(true);
  }

  const handleChaiSubmit = useChaiEditSubmit();

  const handleSubmit = (event) => {
    handleChaiSubmit("Contacts",event, formState,id, companyFilters);
  };
  const handleActionSubmit = async (event) => {
    event.preventDefault();
    if (actionType === "Add") {
      await logContactAction(actionState.actionType, "user_contacts", id, actionState.actionDate, actionState.notes);
    } else {
      await updateContactAction (actionState, "user_contacts", id)
    }
    setActionPopup(false);
    setActionState({
      actionType: "",
      notes: "",
      actionDate: DateTime.now().toISODate(),
    });
  }

  const handleAddTag = async (tagName) => {
    // Update Chai Contact with Tags
    let tagValue =  tagName ? tagName : customTagName;
    addTagToContact(tagValue, id); 
    setCustomTagName('');
    setTagModalOpen(false);
  }

  const openEditModal = (tag) => {
    setEditTagModalOpen(!editTagModalOpen)
    setCustomTagModalOpen(false);
    setEditTagName(tag);
  }
  const openNewTagModel = () => {
    setEditTagModalOpen(false)
    setCustomTagModalOpen(!customTagModalOpen);
  }

  const handleEditTag = async () => {
    editUserTags(editTagName,customTagName)
  }

  const handleRemoveTag = async (tag) => {
    removeTagFromContact(tag, id);
  }

  const setAction = (action) => {
  setActionState(prevActionState => ({
    ...prevActionState,
    actionType: action
  }));
  }

  const triggerUpdateForm = (event) => {
    const { actionId, actionDate ="", actionType, notes } = event;
  setActionState(prevActionState => ({
    ...prevActionState,
    actionId,
    notes,
    actionDate,
    actionType
  }));
  setActionType('Edit');
  setActionPopup(true);
  }

  const goToDirectory = (id) => {
    navigate(`/directory/${id}`);
  };

  if (!contactData || !user || !userInfo) {
    return <Loading />;
  }
  const sortedContactHistory = contactData && contactData.contactHistoryArray ? contactData.contactHistoryArray.sort((a, b) => new Date(a.actionDate) - new Date(b.actionDate)) : [];
  const sortedIndustries = contactData && Array.isArray(contactData.industries) ? contactData.industries.sort((a, b) => a.localeCompare(b)) : [];
  let sortedTags = [];
  let allTags = [];
  if (contactData && Array.isArray(contactData.tags)) {
    try {
      sortedTags = sortTags(contactData.tags, userInfo?.chaiTagsArray);
    } catch (error) {
      console.error('Failed to sort tags:', error);
    }
  }

  if (userInfo && userInfo.chaiTagsArray) {
    allTags = filterAndSortTags(userInfo.chaiTagsArray, contactData.tags);
  }
  
  const lastAction = sortedContactHistory[sortedContactHistory.length - 1];
  const lastActionDate = lastAction ? new Date(lastAction.actionDate) : new Date();
  const localDate = new Date(lastActionDate.getTime() - lastActionDate.getTimezoneOffset() * 60000);
  const formattedActionDate = lastAction ? localDate.toISOString().split('T')[0] : "";
  // const lastActionDate = sortedContactHistory.length > 1 ? new Date(sortedContactHistory[sortedContactHistory.length - 1].actionDate).toISOString().split('T')[0] : "";
  // console.log(formattedActionDate)
  // console.log(contactData.lastContactDate)
  // const allTags = userInfo && userInfo.chaiTagsArray ? contactData.tags.sort((a, b) => a.localeCompare(b)) : [];
  return ( 
  <>
      <EditChaiContact
      type={"Contacts"}
      handleChange={handleChangeHelper}
      handleMultiComboChangeHelper={handleMultiComboChangeHelper}
      handleSingleChange={handleSingleChange}
      industryValues={industryValues}
      companyFilters={companyFilters}
      setCompanyFilters={setCompanyFilters}
      handleFilterChange={handleFilterChange}
      companyValue={contactCompanyData}
      formState={formState}
      setFormErrors={setFormErrors}
      setFormState={setFormState}
      popUpOpen={chaiPopUpOpen} 
      setPopUpOpen={setChaiPopUpOpen}
      handleWebsiteBlur={handleWebsiteBlurHelper}
      handleSubmit={handleSubmit} 
      formErrors={formErrors}
      userCompanies={userCompanies}
      loading={chaiFormLoading}
      />

      <AddAction
      type={actionType}
      handleChange={handleChangeHelper}
      formState={actionState}
      setFormState={setActionState}
      popUpOpen={actionPopup}
      setPopUpOpen={setActionPopup}
      handleSubmit={handleActionSubmit}
      loading={chaiFormLoading}
      setAction={setAction} 
      />

          <div className="tw-flex tw-justify-between tw-pb-5">
          <button className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => navigate(-1)}>Back</button>
          <div className="tw-flex tw-gap-x-2">
            <button className="tw-bg-green-500 hover:tw-bg-green-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => handleActionClick('emailed', true)}>Add Action</button>
            <button className="tw-bg-yellow-500 hover:tw-bg-yellow-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => { setMyChaiTab("Contacts"); setChaiPopUpOpen(true); }}>Edit Contact</button>
          </div>
        </div>
    <div className="tw-overflow-hidden tw-bg-white tw-shadow sm:tw-rounded-lg">
      <div className="tw-px-4 tw-py-5 sm:tw-px-6">
        <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">{contactData.contactName}</h3>
        <p className="tw-mt-1 tw-mb-2 tw-max-w-2xl tw-text-sm tw-leading-5 tw-text-gray-500">
          {contactData.role && contactData.role != "undefined" && contactData.role} 
          {contactData.company && Object.keys(contactData.company).length > 0 && (
            <>
              {" - "}
              <span 
                className="tw-inline-flex tw-items-center tw-rounded-full tw-bg-indigo-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-indigo-600 tw-ring-1 tw-ring-inset tw-ring-indigo-500/10 tw-mr-2 tw-mb-2 tw-cursor-pointer"
                onClick={() => handleCompanyClick(contactData.company.id)}
              >
                {contactData.company.name}
              </span>
            </>
          )} 
        </p>
      </div>
      {/* {contactData.company && Object.keys(contactData.company).length > 0 && (
        <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
          <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Company</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
          <span className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-indigo-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-indigo-600 tw-ring-1 tw-ring-inset tw-ring-indigo-500/10 tw-mr-2 tw-mb-2 tw-cursor-pointer`}
          onClick={() => handleCompanyClick(contactData.company.id)}>
          {contactData.company.name}
    </span>
          
            </dd>
        </div>
      )} */}
      <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
        <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Email</dt>
        <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
          <a href={`mailto:${contactData.email}`} className="tw-text-blue-500 hover:tw-text-blue-800 visited:tw-text-purple-600" onClick={() => handleActionClick('emailed')}>{contactData.email}</a>
        </dd>
      </div>
      <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
        <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Phone</dt>
        <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
          {contactData.phone && contactData.phone != "undefined" && <a href={`tel:${contactData.phone}`} className="tw-text-blue-500 hover:tw-text-blue-800 visited:tw-text-purple-600" onClick={() => handleActionClick('called')}>{contactData.phone}</a>}
        </dd>
      </div>
      {contactData.notes && (
        <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
          <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Notes</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
            <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-5 tw-text-gray-500">{contactData.notes}</p>
          </dd>
        </div>
      )}

    {contactData.industries && (
        <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
          <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Industry Background</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
          {sortedIndustries.map((industry,index) => {
                const selectedColor = colorArray[index % 7 + 1]
                return (
                <div key={`${industry}-${index}`} className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2 tw-relative`}>
                  {industry}
                  {/* <XIcon className="tw-ml-2 tw-h-4 tw-w-4 tw-text-${selectedColor}-600 hover:tw-text-${selectedColor}-800 tw-cursor-pointer" onClick={() => handleRemoveTag(tag)} aria-hidden="true" /> */}
                </div>
              )})}
          </dd>
        </div>
      )}

        <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Tags:</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
            {sortedTags.map((tag,index) => {
                const selectedColor = colorArray[index % 7 + 4]
                return (
                <div key={`${tag.id}-${index}`} className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2 tw-relative`}>
                  {tag.value}
                  <XIcon className="tw-ml-2 tw-h-4 tw-w-4 tw-text-${selectedColor}-600 hover:tw-text-${selectedColor}-800 tw-cursor-pointer" onClick={() => handleRemoveTag(tag.id)} aria-hidden="true" />
                </div>
              )})}
              <div className="tw-mt-2 tw-relative">
                <button
                  type="button"
                  onClick={() => setTagModalOpen(!tagModalOpen)}
                  className="tw-inline-flex tw-items-center tw-gap-x-1.5 tw-rounded-full tw-bg-white tw-px-3 tw-py-1.5 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-hover:bg-gray-50"
                >
                  <PlusIcon className="tw--ml-1 tw--mr-0.5 tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                  Add Tag
                </button>
                <div onMouseLeave={() => {
                  if (tagModalOpen || customTagModalOpen || editTagModalOpen) {
                    setTagModalOpen(false);
                    setCustomTagModalOpen(false);
                    setEditTagModalOpen(false);
                  }
                }}>
                {tagModalOpen && (
                  <div className="tw-relative tw-z-10 tw-mt-2 tw-w-48 tw-bg-white tw-shadow-lg tw-rounded-lg tw-p-4 tw-left-0 tw-pb-5 tw-mb-5"> 
                    <div className="tw-flex tw-flex-col">
                      {allTags.map((tag, index) => (
                        <div key={tag.id} className="tw-flex tw-items-center tw-justify-between tw-py-1 tw-px-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 tw-rounded">
                          <button className="tw-flex-1 tw-text-left" onClick={() => handleAddTag(tag.id)}>
                            {tag.value}
                          </button>
                          {/* <button className="tw-ml-2" onClick={() => openEditModal(tag.value)}>
                            <PencilIcon className="tw-h-5 tw-w-5 tw-text-yellow-500" aria-hidden="true" />
                          </button> */}
                        </div>
                      ))}
                      <hr className="tw-my-2" />
                      <button className="tw-text-left tw-py-1 tw-px-2 tw-text-sm tw-text-blue-600 hover:tw-bg-blue-50 tw-rounded" onClick={() => openNewTagModel()}>
                        + Add New Tag
                      </button>
                    </div>
                  </div>
                )}
                {customTagModalOpen && (
                  <div className="tw-relative tw-z-10 tw-mt-2 tw-w-48 tw-bg-white tw-shadow-lg tw-rounded-lg tw-p-4 tw-left-0">
                    <input type="text" placeholder="Tag Name" className="tw-border tw-p-2 tw-rounded tw-w-full tw-mb-2" value={customTagName} onChange={(e) => setCustomTagName(e.target.value)} />
                    <button className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => handleCreateNewTag(customTagName, addTagsToUser, handleAddTag)}>
                      Add Custom Tag
                    </button>
                  </div>
                )}
              {editTagModalOpen && (
                <div className="tw-relative tw-z-10 tw-mt-2 tw-w-48 tw-bg-white tw-shadow-lg tw-rounded-lg tw-p-4 tw-left-0">
                  <input type="text" placeholder="New Tag Name" className="tw-border tw-p-2 tw-rounded tw-w-full tw-mb-2" value={customTagName} onChange={(e) => setCustomTagName(e.target.value)} />
                  <p>Editing: {editTagName}</p>
                  <button className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => handleEditTag()}>
                    Update Tag
                  </button>
                </div>
              )}
              </div>
              </div>
              </dd>
          </div>
      {(contactData.lastContactDate || lastActionDate) && (
        <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
        <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Last Contacted</dt>
        <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
          <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-5 tw-text-gray-500">{formatDate(contactData.lastContactDate || formattedActionDate)}</p>
        </dd>
      </div>
      )}
      {sortedContactHistory.length > 0 && (
        <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
          <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Contact History</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
            <ActionsTimeline 
            timeline={sortedContactHistory}
            updateForm={triggerUpdateForm} 
            />
          </dd>
        </div>
      )}

      {contactData.directoryId && (
        <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
          <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Directory Contact</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
            <span className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-indigo-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-indigo-600 tw-ring-1 tw-ring-inset tw-ring-indigo-500/10 tw-mr-2 tw-mb-2 tw-cursor-pointer`}
              onClick={() => goToDirectory(contactData.directoryId)}>
              Go to Directory Page</span>
          </dd>
        </div>
      )}
    </div>
  </>
  
  )}