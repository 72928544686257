import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import Loading from '../../components/Loading';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../../firebase/firebase';
import { useContactClickHandler } from '../../utils/handleClicks';
import { PaperClipIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'
import { handleFilterChange, validateWebsite } from '../../utils/helperFunctions';
import AddChaiContact from '../../components/formPopups/chai/AddChaiContact';
import { useChaiEditSubmit } from '../../utils/chaiHelperFunctions';
import EditChaiContact from '../../components/formPopups/chai/EditChaiContact';
import { handleChangeHelper, handleSingleChangeHelper, handleWebsiteBlurHelper } from '../../utils/formHelperFunctions';
import { colorArray } from '../../utils/helperFunctions';
import { XMarkIcon as XIcon,PlusIcon, PencilIcon } from '@heroicons/react/20/solid';
import { industryValues } from '../../utils/helperValues';
import { filterAndSortTags, handleCreateNewTag, sortTags } from '../../components/filters/chai/ChaiHelpers';

export default function ChaiCompany() {
  const { userCompanies, userContacts, user, fetchUserCompanies, userInfo, 
    fetchUserContacts, myChaiTab, chaiFormLoading, chaiPopUpOpen, setChaiPopUpOpen,
    addTagsToUser, editUserTags, addTagToCompany, removeTagFromCompany } = useUser();
  const [chaiCompanyData, setChaiCompanyData] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [customTagModalOpen,setCustomTagModalOpen] = useState(false);
  const [customTagName, setCustomTagName] = useState('');
  const [editTagModalOpen,setEditTagModalOpen] = useState(false);
  const [editTagName, setEditTagName] = useState('');
  const [companyFilters, setCompanyFilters] = useState({ 
    industries: [] });
  let { id } = useParams();
  const navigate = useNavigate();
  const handleContactClick = useContactClickHandler();
  
  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, async (incomingUser) => {
        if (incomingUser && userCompanies.length === 0) {
            fetchUserCompanies();
            fetchUserContacts();
        }
    });
    return () => {
        unsubscribe();
    };
}, []);
  useEffect(() => {
    if (userCompanies) {
      const chaiCompany = userCompanies.find(chaiCompany => chaiCompany.id === id);
      if (chaiCompany) {
        let companyContacts = [];
        if (userContacts && userContacts.length > 0) {
          companyContacts = userContacts.filter(contact => contact.companyId === chaiCompany.id);
        }
        setChaiCompanyData({ ...chaiCompany, contacts: companyContacts });
      }
    }
  }, [userCompanies, userContacts, id]);

  const [formState, setFormState] = useState({
    companyName: "",
    notes: "",
    website: "",
    industries: [],
    tags: [],
  });

  useEffect(() => {
    if (chaiCompanyData) {
      setFormState({
        companyName: chaiCompanyData.companyName,
        notes: chaiCompanyData.notes,
        website: chaiCompanyData.website,
        tags: chaiCompanyData.tags || [],
      });
      setCompanyFilters({
        industries: chaiCompanyData.industries || []
      })
    }
  }, [chaiCompanyData]);

  const handleSingleChange = (name, item) => {
    const lowerName = name.toLowerCase();
    setFormState(prevState => ({
      ...prevState,
      [lowerName]: item
    }));
  }

  const handleChaiSubmit = useChaiEditSubmit();

  const handleSubmit = (event) => {
    handleChaiSubmit("Companies", event, formState,id,companyFilters);
  };

  const handleAddTag = async (tagName) => {
    // Update Chai Contact with Tags
    let tagValue =  tagName ? tagName : customTagName;
    addTagToCompany(tagValue, id); 
    setCustomTagName('');
    setTagModalOpen(false);
  }

  const openEditModal = (tag) => {
    setEditTagModalOpen(!editTagModalOpen)
    setCustomTagModalOpen(false);
    setEditTagName(tag);
  }
  const openNewTagModel = () => {
    setEditTagModalOpen(false)
    setCustomTagModalOpen(!customTagModalOpen);
  }

  const handleEditTag = async () => {
    editUserTags(editTagName,customTagName)
  }
  const handleRemoveTag = async (tag) => {
    removeTagFromCompany(tag, id);
  }


  


  if (!chaiCompanyData || !user) {
    return <Loading />;
  }

  const sortedIndustries = chaiCompanyData && Array.isArray(chaiCompanyData.industries) ? chaiCompanyData.industries.sort((a, b) => a.localeCompare(b)) : [];

  let sortedTags = [];
  let allTags = [];
  if (chaiCompanyData && Array.isArray(chaiCompanyData.tags)) {
    try {
      sortedTags = sortTags(chaiCompanyData.tags, userInfo?.chaiTagsArray);
    } catch (error) {
      console.error('Failed to sort tags:', error);
    }
  }

  if (userInfo && userInfo.chaiTagsArray) {
    allTags = filterAndSortTags(userInfo.chaiTagsArray, chaiCompanyData.tags);
  }
  // const displayIndustries = chaiCompanyData.industries ? chaiCompanyData.industries.join(', ') : 'N/A';

  return (
    <>
      <EditChaiContact
      type={"Companies"}
      handleChange={handleChangeHelper}
      handleSingleChange={handleSingleChange}
      industryValues={industryValues}
      companyFilters={companyFilters}
      setCompanyFilters={setCompanyFilters}
      handleFilterChange={handleFilterChange}
      formState={formState}
      setFormErrors={setFormErrors}
      setFormState={setFormState}
      popUpOpen={chaiPopUpOpen} 
      setPopUpOpen={setChaiPopUpOpen}
      handleWebsiteBlur={handleWebsiteBlurHelper}
      handleSubmit={handleSubmit} 
      formErrors={formErrors}
      userCompanies={userCompanies}
      loading={chaiFormLoading}
      />
        <div className="tw-flex tw-justify-between tw-pb-5">
          <button className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => navigate(-1)}>Back</button>
          <button className="tw-bg-yellow-500 hover:tw-bg-yellow-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => setChaiPopUpOpen(true)}>Edit Company</button>
        </div>
        <div className="tw-overflow-hidden tw-bg-white tw-shadow sm:tw-rounded-lg">
      <div className="tw-px-4 tw-py-5 sm:tw-px-6">
          <h3 className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">{chaiCompanyData.companyName}</h3>
          {/* <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-6 tw-text-gray-500">{chaiCompanyData.notes}</p> */}
          {chaiCompanyData.website && (
            <a href={chaiCompanyData.website} target="_blank" rel="noopener noreferrer" className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-6 tw-text-blue-500 hover:tw-text-blue-700">{chaiCompanyData.website}</a>
          )}
      </div>
      {chaiCompanyData.notes && (
        <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
          <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Notes</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
            <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-5 tw-text-gray-500">{chaiCompanyData.notes}</p>
          </dd>
        </div>
      )}
    {chaiCompanyData.industries && Array.isArray(chaiCompanyData.industries) && (
        <div className="tw-px-4 tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
          <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Industry Background</dt>
          <dd className="tw-mt-1 tw-text-sm tw-leading-5 tw-text-gray-900 sm:tw-mt-0 sm:tw-col-span-2">
          {sortedIndustries.map((industry,index) => {
                const selectedColor = colorArray[index % 7 + 1]
                return (
                <div key={`${industry}-${index}`} className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2 tw-relative`}>
                  {industry}
                  {/* <XIcon className="tw-ml-2 tw-h-4 tw-w-4 tw-text-${selectedColor}-600 hover:tw-text-${selectedColor}-800 tw-cursor-pointer" onClick={() => handleRemoveTag(tag)} aria-hidden="true" /> */}
                </div>
              )})}
          </dd>
        </div>
      )}
      <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-500">Tags:</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
            {sortedTags.map((tag,index) => {
                const selectedColor = colorArray[index % 7 + 2]
                return (
                <div key={`${tag.id}-${index}`} className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2 tw-relative`}>
                  {tag.value}
                  <XIcon className="tw-ml-2 tw-h-4 tw-w-4 tw-text-${selectedColor}-600 hover:tw-text-${selectedColor}-800 tw-cursor-pointer" onClick={() => handleRemoveTag(tag.id)} aria-hidden="true" />
                </div>
              )})}
              <div className="tw-mt-2 tw-relative">
                <button
                  type="button"
                  onClick={() => setTagModalOpen(!tagModalOpen)}
                  className="tw-inline-flex tw-items-center tw-gap-x-1.5 tw-rounded-full tw-bg-white tw-px-3 tw-py-1.5 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-hover:bg-gray-50"
                >
                  <PlusIcon className="tw--ml-1 tw--mr-0.5 tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                  Add Tag
                </button>
                <div onMouseLeave={() => {
                  if (tagModalOpen || customTagModalOpen || editTagModalOpen) {
                    setTagModalOpen(false);
                    setCustomTagModalOpen(false);
                    setEditTagModalOpen(false);
                  }
                }}>
                {tagModalOpen && (
                  <div className="tw-relative tw-z-10 tw-mt-2 tw-w-48 tw-bg-white tw-shadow-lg tw-rounded-lg tw-p-4 tw-left-0 tw-pb-5 tw-mb-5"> 
                    <div className="tw-flex tw-flex-col">
                    {allTags.map((tag, index) => (
                        <div key={tag.id} className="tw-flex tw-items-center tw-justify-between tw-py-1 tw-px-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 tw-rounded">
                          <button className="tw-flex-1 tw-text-left" onClick={() => handleAddTag(tag.id)}>
                            {tag.value}
                          </button>
                          {/* <button className="tw-ml-2" onClick={() => openEditModal(tag.value)}>
                            <PencilIcon className="tw-h-5 tw-w-5 tw-text-yellow-500" aria-hidden="true" />
                          </button> */}
                        </div>
                      ))}
                      <hr className="tw-my-2" />
                      <button className="tw-text-left tw-py-1 tw-px-2 tw-text-sm tw-text-blue-600 hover:tw-bg-blue-50 tw-rounded" onClick={() => openNewTagModel()}>
                        + Add New Tag
                      </button>
                    </div>
                  </div>
                )}
                {customTagModalOpen && (
                  <div className="tw-relative tw-z-10 tw-mt-2 tw-w-48 tw-bg-white tw-shadow-lg tw-rounded-lg tw-p-4 tw-left-0">
                    <input type="text" placeholder="Tag Name" className="tw-border tw-p-2 tw-rounded tw-w-full tw-mb-2" value={customTagName} onChange={(e) => setCustomTagName(e.target.value)} />
                    <button className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => handleCreateNewTag(customTagName, addTagsToUser, handleAddTag)}>
                      Add Custom Tag
                    </button>
                  </div>
                )}
              {editTagModalOpen && (
                <div className="tw-relative tw-z-10 tw-mt-2 tw-w-48 tw-bg-white tw-shadow-lg tw-rounded-lg tw-p-4 tw-left-0">
                  <input type="text" placeholder="New Tag Name" className="tw-border tw-p-2 tw-rounded tw-w-full tw-mb-2" value={customTagName} onChange={(e) => setCustomTagName(e.target.value)} />
                  <p>Editing: {editTagName}</p>
                  <button className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => handleEditTag()}>
                    Update Tag
                  </button>
                </div>
              )}
              </div>
              </div>
              </dd>
          </div>
      {chaiCompanyData.contacts && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-0 sm:tw-pl-6">
        <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Company Contacts</dt>
        <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
          <div className="">
            <div className="tw-mt-8 tw-flow-root">
              <div className="tw--mx-4 tw--my-2 tw-overflow-x-auto sm:tw--mx-6 lg:tw--mx-8">
                <div className="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle">
                  <table className="tw-min-w-full tw-divide-y tw-divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-0">Name</th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">Role</th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">Email</th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">Phone</th>
                      </tr>
                    </thead>
                    <tbody className="tw-divide-y tw-divide-gray-200">
                      {chaiCompanyData.contacts?.map((contact, index) => (
                        <tr key={index} className="tw-cursor-pointer" onClick={() => handleContactClick(contact.id)}>
                          <td className="tw-whitespace-nowrap tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-pl-0">{contact.contactName}</td>
                          <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{contact.role}</td>
                          <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                            {contact.email && 
                              <a href={`mailto:${contact.email}`} className="tw-text-grey-600 hover:tw-text-blue-500">
                                <EnvelopeIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-grey-600 hover:tw-text-indigo-600" aria-hidden="true" />
                              </a>
                            }
                          </td>
                          <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                            {contact.phone && 
                              <a href={`tel:${contact.phone}`} className="tw-text-grey-600 hover:tw-text-blue-500">
                                <PhoneIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-grey-600 hover:tw-text-blue-500" aria-hidden="true" />
                              </a>
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </dd>
      </div>}
      </div>
      </>
  )
}
