import { CheckIcon } from '@heroicons/react/20/solid'
import React, { useEffect, useState } from 'react';
import { useUser } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';

const includedFeatures = [
  'Club job board',
  'Shared club contacts',
]

export default function AddClub() {
    const { 
       userInfo
    } = useUser();
    const navigate = useNavigate();
  const [formState, setFormState] = useState({
    clubName: '',
    shortName: '',
    description: '',
    website: '',
    contactEmail: '',
    type: 'club',
  });
  const hasSubscription = userInfo?.hasSubscription ?? true;
  const clubCreated = userInfo?.clubCreated ?? false;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let finalValues = formState;
    if (!finalValues.school) finalValues.school = combinedSchoolIds[0];
    console.log(finalValues,'fs');
  }

  let combinedSchoolIds = [];
  if (userInfo?.schoolIds) {
    if (Array.isArray(userInfo.schoolIds)) {
      combinedSchoolIds = combinedSchoolIds.concat(userInfo.schoolIds);
    } else if (typeof userInfo.schoolIds === 'object') {
      combinedSchoolIds = combinedSchoolIds.concat(Object.values(userInfo.schoolIds));
    }
  }
  if (userInfo?.professionalSchoolIds && typeof userInfo.professionalSchoolIds === 'object') {
    combinedSchoolIds = combinedSchoolIds.concat(Object.values(userInfo.professionalSchoolIds));
  }

  const formatSchoolName = (schoolName) => {
    if (schoolName.includes('_')) {
      const parts = schoolName.split('_');
      return parts[1].toUpperCase() + ' ' + parts[0].charAt(0).toUpperCase() + parts[0].slice(1);
    } else {
      return schoolName.toUpperCase();
    }
  }
  
  return (
    <div className="tw-bg-white tw-py-2 sm:tw-py-16">
    <button className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-mb-2 tw-px-4 tw-rounded" onClick={() => navigate(-1)}>Back</button>
      <div className="tw-mx-auto tw-max-w-7xl lg:tw-px-8">
        <div className="tw-mx-auto tw-max-w-2xl sm:tw-text-center">
          <h2 className="tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900 sm:tw-text-4xl">Add your Club</h2>
          <p className="tw-mt-6 tw-text-lg tw-leading-8 tw-text-gray-600">
            Add your club to Chai and get access to a job board and shared club contacts.
          </p>
        </div>
        {hasSubscription && !clubCreated && (
          <div className="tw-mx-auto tw-mt-4 tw-max-w-2xl tw-rounded-3xl tw-ring-1 tw-ring-gray-200 sm:tw-mt-8 lg:tw-mx-0 lg:tw-flex lg:tw-max-w-none">
            <div className="tw-p-4 sm:tw-p-10 lg:tw-flex-auto">
              <form>
                <div className="tw-mb-6">
                  <label htmlFor="clubName" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                    Club Name
                  </label>
                  <div className="tw-mt-2">
                    <input 
                      className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6" 
                      name="clubName" type="text" 
                      placeholder="Name of your club" required 
                      value={formState.clubName}
                      onChange={handleChange}/>
                  </div>
                </div>
                <div className="tw-mb-6">
                  <label htmlFor="shortName" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                    Short Name
                  </label>
                  <div className="tw-mt-2">
                    <input 
                      className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6" 
                      name="shortName" type="text" 
                      placeholder="i.e. HBA" required 
                      value={formState.shortName}
                      onChange={handleChange}/>
                  </div>
                </div>
                <div className="tw-mb-6">
                  <label htmlFor="description" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                    Description
                  </label>
                  <div className="tw-mt-2">
                    <textarea 
                      className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6" 
                      name="description" 
                      placeholder="Short description of the club" required 
                      value={formState.description}
                      onChange={handleChange}/>
                  </div>
                </div>
                <div className="tw-mb-6">
                  <label htmlFor="website" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                    Club's Website
                  </label>
                  <div className="tw-mt-2">
                    <input 
                      className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6" 
                      name="website" type="url" 
                      placeholder="Club's website" required 
                      value={formState.website}
                      onChange={handleChange}/>
                  </div>
                </div>
                <div className="tw-mb-6">
                  <label htmlFor="contactEmail" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                    Contact Email
                  </label>
                  <div className="tw-mt-2">
                    <input 
                      className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6" 
                      name="contactEmail" type="email" 
                      placeholder="Club contact email" 
                      value={formState.contactEmail}
                      onChange={handleChange}/>
                  </div>
                </div>
                <div className="tw-mb-6">
                  <label htmlFor="school" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                    Which students can join your club?
                  </label>
                  <div className="tw-mt-2">
                    <select 
                      className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                      name="school" 
                      onChange={handleChange} 
                      required>
                        {combinedSchoolIds.map(school => (
                          <option key={school} value={school}>{formatSchoolName(school)}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="tw-mb-4">
                  <button 
                    className="tw-inline-flex tw-w-full tw-justify-center tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
                    type="button"
                    onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {!hasSubscription && (
          <div className="tw-mx-auto tw-mt-8 tw-max-w-2xl tw-rounded-3xl tw-ring-1 tw-ring-gray-200 sm:tw-mt-20 lg:tw-mx-0 lg:tw-flex lg:tw-max-w-none">
            <div className="tw-p-8 sm:tw-p-10 lg:tw-flex-auto">
              <h3 className="tw-text-2xl tw-font-bold tw-tracking-tight tw-text-gray-900">Annual Subscription</h3>
              <p className="tw-mt-6 tw-text-base tw-leading-7 tw-text-gray-600">
              </p>
              <div className="tw-mt-10 tw-flex tw-items-center tw-gap-x-4">
                <h4 className="tw-flex-none tw-text-sm tw-font-semibold tw-leading-6 tw-text-indigo-600">What’s included</h4>
                <div className="tw-h-px tw-flex-auto tw-bg-gray-100" />
              </div>
              <ul
                role="list"
                className="tw-mt-8 tw-grid tw-grid-cols-1 tw-gap-4 tw-text-sm tw-leading-6 tw-text-gray-600 sm:tw-grid-cols-2 sm:tw-gap-6"
              >
                {includedFeatures.map((feature) => (
                  <li key={feature} className="tw-flex tw-gap-x-3">
                    <CheckIcon className="tw-h-6 tw-w-5 tw-flex-none tw-text-indigo-600" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="-tw-mt-2 tw-p-2 lg:tw-mt-0 lg:tw-w-full lg:tw-max-w-md lg:tw-flex-shrink-0">
              <div className="tw-rounded-2xl tw-bg-gray-50 tw-py-10 tw-text-center tw-ring-1 tw-ring-inset tw-ring-gray-900/5 lg:tw-flex lg:tw-flex-col lg:tw-justify-center lg:tw-py-16">
                <div className="tw-mx-auto tw-max-w-xs tw-px-8">
                  <p className="tw-text-base tw-font-semibold tw-text-gray-600">Upgrade your club for only</p>
                  <p className="tw-mt-6 tw-flex tw-items-baseline tw-justify-center tw-gap-x-2">
                    <span className="tw-text-5xl tw-font-bold tw-tracking-tight tw-text-gray-900">$299</span>
                    <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-tracking-wide tw-text-gray-600">USD</span>
                  </p>
                  <a
                    href="#"
                    className="tw-mt-10 tw-block tw-w-full tw-rounded-md tw-bg-indigo-600 tw-px-3 tw-py-2 tw-text-center tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
                  >
                    Get access
                  </a>
                  <p className="tw-mt-6 tw-text-xs tw-leading-5 tw-text-gray-600">
                    Invoices and receipts available
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
