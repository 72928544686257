import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import MultiComboBoxInput from '../../filters/MultiComboBoxInput'
import { DateTime } from "luxon";



export default function AddContact({ 
    handleChange,handleFileChange, 
    // handleSingleChange, 
    formState, 
    // setFormState,
    popUpOpen, setPopUpOpen,
    handleSubmit, loading, 
    contactFilters, setContactFilters,
    industryValues,roleValues,
    handleFilterChange,
}) {
  return (
    <Transition.Root show={popUpOpen} as={Fragment}>
      <Dialog as="div" className="tw-relative tw-z-10" onClose={setPopUpOpen}>
        <Transition.Child
          as={Fragment}
          enter="tw-ease-out tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in tw-duration-200"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
        </Transition.Child>

        <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
          <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
            <Transition.Child
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
              enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            >
              <Dialog.Panel className="tw-relative tw-transform tw-overflow-auto tw-rounded-lg 
              tw-bg-white tw-px-4 tw-pb-4 tw-pt-10 tw-text-left tw-shadow-xl tw-transition-all sm:tw-mb-8 sm:tw-w-full 
              sm:tw-max-w-sm sm:tw-p-6 tw-mt-20">
                <form>
                    <>
                    <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="contactName">
                          Contact Name <span className="required-star">*</span>
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="contactName" type="text" 
                          value={formState.contactName || ''}
                          placeholder="Contact Name" onChange={handleChange} required />
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="email">
                          Email <span className="required-star">*</span>
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="email" type="email" 
                          value={formState.email || ''}
                          placeholder="Email" onChange={handleChange} required />
                      </div>
                      <div className="tw-mb-4">
                      <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="industries">
                          Industry Background
                        </label>
                      {contactFilters && contactFilters.industries && 
                        <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                          Selected: {Array.isArray(contactFilters.industries) ? contactFilters.industries.join(', ') : contactFilters.industries}
                        </div>
                      }
                      <MultiComboBoxInput 
                      items={industryValues}
                      label="industries"
                      singleSelect={false}
                      setFilters={setContactFilters}
                      handleFilterChange={handleFilterChange}
                      selectedValues={contactFilters.industries || []}
                      />
                    </div>
                    <div className="tw-mb-4">
                    <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="roles">
                          Role Held
                        </label>
                      {contactFilters && contactFilters.roles && 
                        <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                          Selected: {Array.isArray(contactFilters.roles) ? contactFilters.roles.join(', ') : contactFilters.roles}
                        </div>
                      }
                      <MultiComboBoxInput 
                      items={roleValues}
                      label="roles"
                      singleSelect={false}
                      setFilters={setContactFilters}
                      handleFilterChange={handleFilterChange}
                      selectedValues={contactFilters.roles || []}
                      />
                    </div>

                      {/* <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="role">
                          Role <span className="required-star">*</span>
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="role" type="text" 
                          value={formState.role || ''}
                          placeholder="Role" onChange={handleChange} required />
                      </div> */}
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="location">
                          Location
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="location" type="text" 
                          value={formState.location || ''}
                          placeholder="Location" onChange={handleChange} required />
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="linkedIn">
                          LinkedIn
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="linkedIn" type="url" 
                          value={formState.linkedIn || ''}
                          placeholder="LinkedIn" onChange={handleChange} />
                      </div>
                      {/* <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="introducedBy">
                          Introduced By
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="introducedBy" type="text" 
                          value={formState.introducedBy || ''}
                          placeholder="Introduced By" onChange={handleChange} />
                      </div> */}
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="bSchool">
                          Business School Attended
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="bSchool" type="text" 
                          value={formState.bSchool || ''}
                          placeholder="Business School" onChange={handleChange} />
                      </div>
                      {/* <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="focusArea">
                          Focus Area
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="focusArea" type="text" 
                          value={formState.focusArea || ''}
                          placeholder="Focus Area" onChange={handleChange} />
                      </div> */}
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="notes">
                          Notes
                        </label>
                        <textarea 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="notes" 
                          value={formState.notes || ''}
                          placeholder="notes" onChange={handleChange} />
                      </div>
                      <div className="tw-mb-4">
                        <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="dateLastContacted">
                          Date Last Contacted
                        </label>
                        <input 
                          className="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:tw-outline-none tw-focus:tw-shadow-outline" 
                          name="dateLastContacted" type="date" 
                          value={formState.dateLastContacted || ''}
                          onChange={(event) => {
                            const formattedDate = event.target.value ? DateTime.fromISO(event.target.value).toFormat('yyyy-MM-dd') : '';
                            handleChange({ target: { name: 'dateLastContacted', value: formattedDate } });
                          }}  
                          required />
                      </div>
                    </>
                  <div className="tw-mb-4">
                    <button 
                      className="tw-inline-flex tw-w-full tw-justify-center tw-bg-blue-500 tw-hover:bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
                      disabled={loading} 
                      onClick={handleSubmit}>
                      Submit
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}









