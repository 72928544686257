import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { EllipsisHorizontalIcon, UserIcon } from '@heroicons/react/20/solid'

const ChaiCompanyCard = ({ client, handleCompanyClick, statuses, classNames }) => {
  const nestedClickHandler = (event) => {
    event.stopPropagation();
    handleCompanyClick(client.id)
  };
  return (
    <li 
      key={client.companyName} 
      className="tw-overflow-hidden tw-rounded-xl tw-border tw-border-gray-200"
      onClick={nestedClickHandler}
    >
      <div className="tw-flex tw-items-center tw-gap-x-4 tw-border-b tw-border-gray-900/5 tw-bg-gray-50 tw-p-6">
        {client.companyWebsite && (
          <img
            src={`https://logo.clearbit.com/${client.companyWebsite}`}
            alt={client.companyName}
            className="tw-h-12 tw-w-12 tw-flex-none tw-rounded-lg tw-bg-white tw-object-cover tw-ring-1 tw-ring-gray-900/10"
          />
        )}
        <div 
          className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
        >{client.companyName}</div>
        {/* <Menu as="div" className="tw-relative tw-ml-auto">
          <Menu.Button className="tw--m-2.5 tw-block tw-p-2.5 tw-text-gray-400 tw-hover:text-gray-500">
            <span className="tw-sr-only">Open options</span>
            <EllipsisHorizontalIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="tw-absolute tw-right-0 tw-z-10 tw-mt-0.5 tw-w-32 tw-origin-top-right tw-rounded-md tw-bg-white tw-py-2 tw-shadow-lg tw-ring-1 tw-ring-gray-900/5 tw-focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  // <a
                  //   href="#"
                  //   className={classNames(
                  //     active ? 'tw-bg-gray-50' : '',
                  //     'tw-block tw-px-3 tw-py-1 tw-text-sm tw-leading-6 tw-text-gray-900'
                  //   )}
                  // >
                    <span onClick={() => handleCompanyClick(client.id)}>

                    View<span className="tw-sr-only">, {client.companyName}</span>
                    </span>
                  // </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  // <a
                  //   href="#"
                  //   className={classNames(
                  //     active ? 'tw-bg-gray-50' : '',
                  //     'tw-block tw-px-3 tw-py-1 tw-text-sm tw-leading-6 tw-text-gray-900'
                  //   )}
                  // >
                  <>
                    Edit<span className="tw-sr-only">, {client.companyName}</span>
                  </>
                  // </a>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu> */}
      </div>
      <dl 
        className="tw--my-3 tw-divide-y tw-divide-gray-100 tw-px-6 tw-py-4 tw-text-sm tw-leading-6"
      >
        <div className="tw-flex tw-justify-between tw-gap-x-4 tw-py-3">
          <dt className="tw-text-gray-500">Notes:</dt>
          <dd className="tw-text-gray-700">
            {client.notes}
            {/* <time dateTime={client.lastInvoice.dateTime}>{client.lastInvoice.date}</time> */}
          </dd>
        </div>
        {/* <div className="tw-flex tw-justify-between tw-gap-x-4 tw-py-3">
          <dt className="tw-text-gray-500">Networking Opportunities:</dt>
          <dd className="tw-text-gray-700">
            {client.networkingOpportunities}
          </dd>
        </div> */}
        {/* <div className="tw-flex tw-justify-between tw-gap-x-4 tw-py-3">
          <dt className="tw-text-gray-500">Sponsors:</dt>
          <dd className="tw-flex tw-items-start tw-gap-x-2">
            <div className="tw-font-medium tw-text-gray-900">{client.lastInvoice.amount}</div>
            <div
              className={classNames(
                statuses[client.sponsorsInternationals],
                'tw-rounded-md tw-py-1 tw-px-2 tw-text-xs tw-font-medium tw-ring-1 tw-ring-inset'
              )}
            >
              {client.sponsorsInternationals ? "true" : "false"}
            </div>
          </dd>
        </div> */}
      </dl>
    </li>
  );
};

export default ChaiCompanyCard;
