import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import { classNames, colorArray, formatPhone, getLinkedInURL } from '../utils/helperFunctions';
import { HeartIcon } from '@heroicons/react/20/solid'
import { Dialog, Menu, Transition } from '@headlessui/react';
// import { XMarkIcon } from '@heroicons/react/24/outline';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { useChaiEditSubmit } from '../utils/chaiHelperFunctions';


export default function UserDetail() {
  const { directory, selectedUser, setSelectedUser, events, selectedEvent, 
    setSelectedEvent, favoriteAttendee, user, setMyChaiTab, userContacts, createUserContact, fetchUser } = useUser();
  let { id, eventId } = useParams();
  const [loading, setLoading] = useState(false);
  const [chaiSubmitDisabled, setChaiSubmitDisabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const directoryUser = directory.find(user => user.id === id);
    if (directory && directoryUser) {
      setSelectedUser(directoryUser);
      setLoading(false)
    } else {
      setLoading(true)
      fetchUser(id);
    }
  }, [directory, id]);

  useEffect(() => {
    const event = events.find(event => event.id === eventId);
    if (events && event) {
      setSelectedEvent(event);
    }
  }, [events, eventId]);

  // const handleChaiSubmit = useChaiEditSubmit();

  if (!selectedUser || loading) {
    return <Loading />;
  }

  const {
    "Profile Photo": profilePhotoLegacy,
    profilePhoto,
    name,
    email,
    linkedIn,
    instagram: Instagram,
    discord: discordId,
    hometown: Hometown,
    company: previousCompanies,
    currentLastJobTitle: currentJobTitle,
    program,
    recruitingFor,
    interestsHobbies: Interests,
    helpOffered: howYouCanHelp,
    currentGoals,
    interestedInLanguagePartner,
    areasOfInterest,
    availability,
    nativeLanguage,
    talkInClassPreference,
    eventsToAvoid,
    languagePartnerMatched,
    numberOfEventsAttended,
    schoolFiveYearGoals,
    languageLevels,
    birthday,
    interestLanguages,
    favoriteMedia,
    phone,
    slack,
    professionalEventsAttended,
    countryMap,
    industryMap,
    interestsMap,
    school: School
  } = selectedUser;

  const attendees = selectedEvent ? selectedEvent.attendees : undefined;
  const attendeeInfo = attendees ? attendees[id] : undefined; 
  const isFavorited = eventId 
    && selectedEvent?.favorites?.[user.uid]?.[id] 
    ? true 
    : false;
  const whyIAmAttending = attendeeInfo ? attendeeInfo.whyIAmAttending : '';
  const whoIWantToMeet = attendeeInfo ? attendeeInfo.whoIWantToMeet : '';

 
  const profileImageUrl = profilePhoto ? profilePhoto : (profilePhotoLegacy ? profilePhotoLegacy[0].url : '');
  const previousCompaniesList = Array.isArray(previousCompanies) ? previousCompanies.join(', ') : previousCompanies || '';
  // const recruitingForList = Array.isArray(recruitingFor) ? recruitingFor.join(', ') : recruitingFor || '';
  const formattedPhone = phone ? formatPhone(phone) : '';
  let chaiContactId, isChaiContact, chaiContact;
  if (userContacts) {
    chaiContact = userContacts ? userContacts.find(contact => contact.directoryId === id) : null;
    isChaiContact = Boolean(chaiContact);
    if (isChaiContact) {
      chaiContactId = chaiContact.id;
    }
  }

  let fullLinkedInURL;
  if (linkedIn) {
    fullLinkedInURL = getLinkedInURL(linkedIn);
  }

  const handleFavoriteAttendee = async () => {
    setLoading(true);
    await favoriteAttendee(user.uid,id,eventId);
    setLoading(false);
    navigate(-1);
  }
  
  const addToChai = async () => {
    setMyChaiTab("Contacts");
    setChaiSubmitDisabled(true);

    const formState = {
        contactName: name,
        role: "",
        email: email,
        phone: phone || "",
        notes: "",
        linkedIn: linkedIn || "",
        website: '',
        directoryId: id,
    }
    const formData = new FormData();
    Object.keys(formState).forEach(key => {
      if (typeof formState[key] === 'object' && formState[key] !== null && !(formState[key] instanceof File)) {
        // Stringify object fields
        formData.append(key, JSON.stringify(formState[key]));
      } else {
          // Append non-object fields as is
        formData.append(key, formState[key]);
      }
    });
    
    await createUserContact(formData);
    setChaiSubmitDisabled(false);
  };

  const goToChai = () => navigate(`/chai/contact/${chaiContactId}`);
  const homeCountries = countryMap ? Object.entries(countryMap).sort((a, b) => a[0].localeCompare(b[0])).map(entry => entry[1]) : [];
  const countryLabel = homeCountries.length > 1 ? "Countries" : "Country"
  const industryArray =  industryMap ? Object.entries(industryMap).sort((a, b) => a[0].localeCompare(b[0])).map(entry => entry[1]) : [];
  const interestsList = interestsMap ? Object.entries(interestsMap).sort((a, b) => a[0].localeCompare(b[0])).map(entry => entry[1]) : [];

  // const handleAddToChai = async () => {
  //   let { id, eventId } = useParams();
  //   const addToChaiObject = {
  //     directoryId: id,
  //     contactName: name,
  //     email: email || "",
  //     linkedIn: fullLinkedInURL || "",
  //     phone: formattedPhone || "",
  //     role: "",
  //     userId: 
  //   };
  //   if (eventId) {
  //     addToChaiObject.metAt = selectedEvent ? selectedEvent.eventName : "";
  //   }
  //   setMyChaiTab("Contacts");

  // }

  return (
    <>
       <div className="tw-flex tw-justify-between tw-pb-5">
       <button className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded" onClick={() => navigate(-1)}>Back</button>
       {eventId && (
            isFavorited ? (
              <HeartIcon 
              className="tw-m-2 tw-h-6 tw-w-6 tw-text-red-500 tw-cursor-pointer hover:tw-text-gray-500 tw-float-right" 
                onClick={handleFavoriteAttendee}
              />
            ) : (
              <HeartIcon 
                className="tw-m-2 tw-h-6 tw-w-6 tw-text-gray-500 tw-cursor-pointer hover:tw-text-red-700 tw-float-right"  
                onClick={handleFavoriteAttendee}
              />
            )
          )}
       </div>
<div className="tw-flex tw-h-full tw-flex-col tw-overflow-y-scroll tw-bg-white tw-shadow-xl">
  {/* Main */}
  <div className="tw-divide-y tw-divide-gray-200">
    <div className="sm:tw-pb-6">
      <div className="tw-h-24 tw-bg-indigo-700 sm:tw-h-20 lg:tw-h-20 lg:tw-mb-4" />
      <div className="tw--mt-12 tw-flow-root tw-px-4 sm:tw--mt-8 sm:tw-flex sm:tw-items-end sm:tw-px-6 lg:tw--mt-16">
        <div>
          <div className="tw--m-1 tw-flex">
            <div className="tw-inline-flex tw-overflow-hidden tw-rounded-lg tw-border-4 tw-border-white">
              <img
                className="tw-h-36 tw-w-36 tw-object-cover sm:tw-h-40 sm:tw-w-40 md:tw-h-48 md:tw-w-48"
                src={profileImageUrl}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="tw-mt-6 sm:tw-ml-6 sm:tw-flex-1">
          <div>
            <div className="tw-flex tw-items-center">
              <h3 className="tw-text-xl tw-font-bold tw-text-gray-900 sm:tw-text-2xl">{name}</h3>
              {/* <span className="tw-ml-2.5 tw-inline-block tw-h-2 tw-w-2 tw-flex-shrink-0 tw-rounded-full tw-bg-green-400">
                <span className="tw-sr-only">Online</span>
              </span> */}
            </div>
            <p className="tw-text-sm tw-text-gray-500">{program}</p>
          </div>
          <div className="tw-grid tw-grid-cols-5  tw-mb-6 sm:tw-mb-0 tw-gap-3 tw-mt-5 sm:tw-flex sm:tw-flex-wrap sm:tw-space-x-3 sm:tw-space-y-0">
            <button
              type="button"
              className="tw-col-span-2 tw-inline-flex tw-w-full tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-md tw-bg-indigo-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600 sm:tw-flex-1"
              onClick={() => window.location.href = `mailto:${email}`}
            >
              Email
            </button>
            {!isChaiContact ? (
              <button
                type="button"
                className="tw-col-span-2 tw-inline-flex tw-w-full tw-flex-1 tw-items-center tw-justify-center tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50"
                onClick={addToChai}
              >
                Add to Chai
              </button>
            ) : (
              <button
                type="button"
                className="tw-col-span-2 tw-inline-flex tw-w-full tw-flex-1 tw-items-center tw-justify-center tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50"
                onClick={goToChai}
              >
                Go To Contact
              </button>
            )}
            <div className="tw-col-span-1 tw-ml-3 tw-inline-flex sm:tw-ml-0">
              <Menu as="div" className="tw-relative tw-inline-block tw-text-left">
                <Menu.Button className="tw-relative tw-inline-flex tw-items-center tw-rounded-md tw-bg-white tw-p-2 tw-text-gray-400 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50">
                  <span className="tw-absolute tw--inset-1" />
                  <span className="tw-sr-only">Open options menu</span>
                  <EllipsisVerticalIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="tw-transition tw-ease-out tw-duration-100"
                  enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                  enterTo="tw-transform tw-opacity-100 tw-scale-100"
                  leave="tw-transition tw-ease-in tw-duration-75"
                  leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                  leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                >
                  <Menu.Items className="tw-absolute tw-right-0 tw-z-10 tw-mt-2 tw-w-48 tw-origin-top-right tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
                    <div className="tw-py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => navigator.clipboard.writeText(window.location.href)}
                            className={classNames(
                              active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700',
                              'tw-block tw-px-4 tw-py-2 tw-text-sm'
                            )}
                          >
                            Copy profile link
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="tw-px-4 tw-py-2 sm:tw-px-0 sm:tw-py-0">
      <dl className="tw-space-y-0 sm:tw-space-y-1 sm:tw-divide-y sm:tw-divide-gray-200">
      {eventId && 
        <>
        <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Why I'm Attending</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{whyIAmAttending}</dd>
          </div>
          <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Who I Want To Meet</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{whoIWantToMeet}</dd>
          </div>
          </>
          }
          {phone && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Phone</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0"> <a href={`tel:${phone}`} className="tw-text-blue-500 tw-hover:text-blue-800">{formattedPhone}</a></dd>
          </div>}
          {slack && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Slack</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{slack}</dd>
          </div>}
          {linkedIn && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900">LinkedIn</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
              <a href={fullLinkedInURL} target="_blank" rel="noopener noreferrer" className="tw-text-blue-500 tw-hover:text-blue-800">{fullLinkedInURL}</a>
              </dd>
          </div>}
          {Instagram && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Instagram</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{Instagram}</dd>
          </div>}
          {discordId && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Discord</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{discordId}</dd>
          </div>}
          {(Hometown) && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Hometown</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{Hometown}</dd>
          </div>}
          {(homeCountries.length > 0) && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900">{`Home ${countryLabel}`}</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
            {homeCountries.map((country,index) => {
                const selectedColor = colorArray[index % 7 + 2]
                return (
                <span key={`${country}-${index}`} className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2`}>
                  {country}
                </span>
              )})}
              </dd>
          </div>}
          {previousCompaniesList && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Previous Companies</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{previousCompaniesList}</dd>
          </div>}
          {currentJobTitle && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Current / Last Job Title</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{currentJobTitle}</dd>
          </div>}
          {industryArray.length > 0 && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Industry Focus</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
              {industryArray.map((industry,index) => {
                const selectedColor = colorArray[index % 7]
                return (
                <span key={`${industry}-${index}`} className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2`}>
                  {industry}
                </span>
              )})}
              </dd>
          </div>}
          {interestsList.length > 0 && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Interest / Hobbies</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
            {interestsList.map((interest,index) => {
                const selectedColor = colorArray[index % 7 + 4]
                return (
                <span key={`${interest}-${index}`} className={`tw-inline-flex tw-items-center tw-rounded-full tw-bg-${selectedColor}-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-${selectedColor}-600 tw-ring-1 tw-ring-inset tw-ring-${selectedColor}-500/10 tw-mr-2 tw-mb-2`}>
                  {interest}
                </span>
              )})}
              </dd>
          </div>}
          {favoriteMedia && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Favorite Media</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{favoriteMedia}</dd>
          </div>}
          {howYouCanHelp && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900">How you can help</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{howYouCanHelp}</dd>
          </div>}
          {currentGoals && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Current Goals</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{currentGoals}</dd>
          </div>}
          {schoolFiveYearGoals && <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Long Term Goals</dt>
            <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{schoolFiveYearGoals}</dd>
          </div>}
      </dl>
    </div>
  </div>
</div>


        
    <div className="tw-overflow-hidden tw-bg-white tw-shadow sm:tw-rounded-lg">

      <div className="tw-border-t tw-border-gray-100">
        <dl className="tw-divide-y tw-divide-gray-100">
        
        </dl>
      </div>
    </div>
    </>
  );
}
