import React, { useState, useEffect } from 'react';
import { app, fireStoreDB } from '../firebase/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
// import { useHistory } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import Loading from '../components/Loading';
import SignUpForm from '../components/SignUpForm';
import heic2any from 'heic2any';
import { handleFilterChange, sortValuesAlphabetically, uniqueFilterValues } from '../utils/helperFunctions';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { handleTextInputBlur, handleUsernameBlur, sanitizeInput } from '../utils/inputValidator';

const SignUp = () => {

  const { 
    directoryFilters: filters, 
    setDirectoryFilters: setFilters, addUserToRTD 
  } = useUser();
    
    const navigate = useNavigate();// const history = useHistory();
    const prevLocation = useLocation();
    const from = prevLocation.state?.from;

    let { location = "", eventId = "" } = useParams();
    const events = {
      easton: "Knapp & Innovation Challenge"
    }
    const { createAccount,getSignUpData, signUpData, getNewUserData, setUserInfo } = useUser();
    const [formState, setFormState] = useState({
        name: '',
        email: '',
        password: '',
        phone:'',
        professionalEventsAttended: events[location] ? [events[location]] : [],
        school: '',
        schoolIds: [''],
        recruitingFor: [],
        interestsHobbies: [],
        program: [],
        currentGoals: '',
        helpOffered: '',
        schoolFiveYearGoals: '',
        favoriteMedia: '',
        agreement: false
      });
      const [searchTexts, setSearchTexts] = React.useState({
        'recruitingFor': '',
        'program': '',
        'country': '',
        'interestsHobbies': ''
      });
      const [error, setError] = useState(null);
      const [formErrors, setFormErrors] = useState({});
      const [loading, setLoading] = useState(false);
      const [passwordVisible, setPasswordVisible] = useState(false);


      useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
          if (user) {
            if (eventId) {
              navigate(`/events/${eventId}`)
            } else if (from) {
              navigate(from, { replace: true });
            } else {
              navigate('/start');
            }
          }
        });
      }, []);
      
      useEffect(() => {
        
        const fetchData = async () => {
          await getSignUpData();
        };
        fetchData();
      }, []);
      
      const handleEmailBlur = () => {
        if (formState.email && !formState.email.endsWith('.edu')) {
            setFormErrors(prevErrors => ({ ...prevErrors, email: 'Email must be a .edu address.' }));
        } else {
            setFormErrors(prevErrors => ({ ...prevErrors, email: '' }));
        }
    };

    const handlePasswordBlur = () => {
          if (formState.password.length < 8) {
          setFormErrors(prevErrors => ({ ...prevErrors, password: 'Password must be at least 8 characters long.' }));
      } else {
          setFormErrors(prevErrors => ({ ...prevErrors, password: '' }));
      }
  };


    const handleChange = (event) => {
      let { name, value, type, checked } = event.target;
  
      if (type === 'checkbox') {
        setFormState(prevState => {
          const previousValue = prevState[name];
          const newValue = checked ? [...previousValue, value] : previousValue.filter(v => v !== value);
  
          return {
            ...prevState,
            [name]: newValue
          };
        });
      } else {
        value = sanitizeInput(value);
        setFormState(prevState => ({
          ...prevState,
          [name]: value
        }));
      }
    };
    

      const otherUpdateState = (key,value) => {
        setFormState(prevState => ({
            ...prevState,
            [key]: value
          }));
      }

      const handleRadioChange = (event) => {
        if(event.target) {
            const {name, value } = event.target;
          
            setFormState(prevState => ({
              ...prevState,
              [name]: value
            }));
        }
      };

      const handleSubmit = async (event) => {
        event.preventDefault();
        const errors = {};
        if (!formState.name) errors.name = 'Name is required.';
        if (!formState.password || formState.password.length < 8) errors.password = 'Password must be at least 8 characters long.';
        if (!formState.email) errors.email = 'Email is required.';
        if (!formState.profilePhoto && !formState['Profile Photo']) errors.profilePhoto = 'Profile photo is required.';
        if (!formState.currentGoals) errors.currentGoals = 'Current goals are required.';
        if (!formState.helpOffered) errors.helpOffered = 'Please specify the help you can offer.';
        if (formState.agreement !== true) errors.agreement = 'You must agree to the terms and conditions.';

        setFormErrors(errors);

        const hasErrors = Object.keys(errors).length > 0;
        if (hasErrors) {
          return; // Stop the form submission if there are errors
        }
        const formData = new FormData();
        Object.keys(formState).forEach(key => {
          if (key !== 'password') {
            formData.append(key, formState[key]);
          }
        });
        Object.keys(filters).forEach(key => {
          formData.append(key, filters[key]);
        });
        setLoading(true)
        setUserInfo({
          name: formState.name
        }) 
    
        // try {
        //     const response = await createAccount(formState['email'],formState['password'],formData)
        //     if(response && response.status == '200') {
        //         // const updatedUser = await updateUserProfile(formData);
        //         // updateDirectory(updatedUser);
        //         // Handle success response
        //         let formDataObj = {};
        //         for (let [key, value] of formData.entries()) {
        //           formDataObj[key] = value;
        //         }
        //         addUserToRTD(formDataObj).catch(error => {
        //           console.error('Failed to add user to RTD:', error);
        //         });
        //         await getNewUserData();
        //         if (eventId) {
        //           navigate(`/events/${eventId}`)
        //         } else {
        //           navigate('/earn');
        //         }
        //         setLoading(false)
        //     } else {
        //         setError(response);
        //         setLoading(false)
        //         return response
        //     }
        // } 
        try {
          const response = await createAccount(formState['email'],formState['password'],formData)
          // setLoading(false)
          // return;
          if(response && response.status == '200') {
              // const updatedUser = await updateUserProfile(formData);
              // updateDirectory(updatedUser);
              // Handle success response
              let formDataObj = {};
              for (let [key, value] of formData.entries()) {
                formDataObj[key] = value;
              }
              // addUserToRTD(formDataObj).catch(error => {
              //   console.error('Failed to add user to RTD:', error);
              // });
              await getNewUserData();
              if (eventId) {
                navigate(`/events/${eventId}`)
              } else if (from) {
                navigate(from, { replace: true });
              } else {
                navigate('/start');
              }
              setLoading(false)
          } else {
              setError(response);
              setLoading(false)
              return response
          }
      } 
        
        catch (error) {
            // Handle errors
            console.error('Error updating profile:');
            console.log(error)
            setLoading(false)
            setError(error);
        }
      };
      const handleFileChange = async (event) => {
        const file = event.target.files[0];
    
        if (file.type === 'image/heic') {
            const convertedFile = await heic2any({
              blob: file,
              toType: 'image/jpeg', // or 'image/png'
              quality: 0.8 // optional, default is 0.8
            });
            file = new File([convertedFile], file.name, { type: 'image/jpeg' }); // or 'image/png'
          }
        
        setFormState(prevState => ({
          ...prevState,
          'Profile Photo': file
        }));
      };
      const handleSearchTextChange = (filterKey, newSearchText) => {
        setSearchTexts(prevTexts => ({
          ...prevTexts,
          [filterKey]: newSearchText
        }));
      };
      const handleCheckboxChange = (key) => (event) => {
        setFormState(prevState => ({
          ...prevState,
          [key]: !prevState[key]
        }));
      };

      const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
      };
      
      if(!signUpData || signUpData.length === 0 || 
        // (!Array.isArray(signUpData) && !signUpData.program) || 
        loading){
        return(<Loading />)
      }

    return (
      <>
        {/* <h1 style={{textAlign: "center"}}>Begin Your Teahouse Journey</h1> */}
        <SignUpForm 
        formState={formState}
        passwordVisible={passwordVisible} 
        setPasswordVisible={togglePasswordVisibility} 
        setFormErrors={setFormErrors}
        handleChange={handleChange} 
        handleSubmit={handleSubmit}
        handleFileChange={handleFileChange}
        handleSearchTextChange={handleSearchTextChange}
        handleCheckboxChange={handleCheckboxChange}
        searchTexts={searchTexts}
        signUpData={signUpData}
        formErrors={formErrors}
        error={error}
        handleUsernameBlur={handleUsernameBlur}
        handleEmailBlur={handleEmailBlur}
        handlePasswordBlur={handlePasswordBlur}
        handleTextInputBlur={handleTextInputBlur}
        handleRadioChange={handleRadioChange}
        otherUpdateState={otherUpdateState}
        disabled={loading}
        filters={filters}
        setFilters={setFilters}
        handleFilterChange={handleFilterChange}
        />
      </>
      );
};

export default SignUp;
