import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import MultiComboBoxInput from '../MultiComboBoxInput'

export default function BrewWorksFilterPopup({
    open, filters, industryItems, 
    setOpen, setFilters,handleFilterChange,
    clearFilters, roleItems, clubMember,
    // industrySingleSelect, roleSingleSelect
}) {
  return ( <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="tw-relative tw-z-10" onClose={setOpen}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 transition-opacity" />
      </Transition.Child>
      <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
        <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center tw-p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="tw-relative tw-transform tw-overflow-auto tw-rounded-lg tw-bg-white 
            tw-px-4 tw-pb-4 tw-pt-0 tw-text-left tw-shadow-xl tw-transition-all tw-leading-[15rem]
            sm:tw-my-8 sm:tw-w-full sm:tw-max-w-sm">
                 <button
              type="button"
              className="tw-absolute tw-top-0 tw-right-0 tw-p-4"
              onClick={() => setOpen(false)}
            >
              <XMarkIcon className="tw-h-6 tw-w-6 tw-text-gray-600 hover:tw-text-red-500" aria-hidden="true" />
            </button>
        
            <div className="tw-pt-8">
              <label htmlFor="industries" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
              Filter by Industry
              </label>
              {filters && filters.industries && 
                <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                  Selected: {Array.isArray(filters.industries) ? filters.industries.join(', ') : filters.industries}
                </div>
              }
              <MultiComboBoxInput
                items={industryItems}
                label="industries"
                singleSelect={false}
                selectedValues={filters.industries}
                setFilters={setFilters}
                handleFilterChange={handleFilterChange}
              />
            </div>
            <div className="tw-mt-8">
              <label htmlFor="roles" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
              Filter by Role
              </label>
              {filters && filters.roles && 
                <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                  Selected: {Array.isArray(filters.roles) ? filters.roles.join(', ') : filters.roles}
                </div>
              }
              <MultiComboBoxInput
                items={roleItems}
                label="roles"
                singleSelect={false}
                selectedValues={filters.roles}
                setFilters={setFilters}
                handleFilterChange={handleFilterChange}
              />
            </div>
            <div className="tw-mt-8">
                <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="shareJob">
                  Filter by Club / Organization
                </label>
                {filters && filters.clubs && 
                <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                  Selected: {Array.isArray(filters.clubs) ? filters.clubs.map(club => club.shortName).join(', ') : filters.clubs}
                </div>
              }
              <MultiComboBoxInput
                items={clubMember}
                label="clubs"
                altLabel="Club / Organization"
                filterKey="shortName"
                singleSelect={false}
                selectedValues={filters.clubs}
                setFilters={setFilters}
                handleFilterChange={handleFilterChange}
              />
              <small className="tw-block tw-text-sm tw-text-gray-500">Find your own Jobs by searching "Mine"</small>
              </div>
              <div className="tw-flex tw-justify-between tw-mt-28 tw-mb-2">
                <button
                  type="button"
                  className="tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-red-600 tw-text-base tw-font-medium tw-text-white hover:tw-bg-red-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-red-500 tw-w-1/2 tw-mr-2"
                  onClick={clearFilters}
                >
                  Clear Filters
                </button>
                <button
                  type="button"
                  className="tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-indigo-600 tw-text-base tw-font-medium tw-text-white hover:tw-bg-indigo-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500 tw-w-1/2 tw-ml-2"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>)}