import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useEarnPage } from './earn/EarnContext';
import { useBrewWorksPage } from './brew/BrewWorksContext';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword } from 'firebase/auth';
import { app, fireStoreDB } from '../firebase/firebase';
import { cleanString } from '../utils/helperFunctions';
import { useChaiPage } from './chai/ChaiContext';
import { useSchoolContext } from './school/SchoolContext';
import { collection, query, where, getDocs } from "firebase/firestore";
import { ref, get, set, getDatabase, onValue, update, remove } from "firebase/database";
import { useDirectoryContext } from './directory/DirectoryContext';
import { useEventContext } from './events/EventContext';
import { useRecruitingContext } from './recruiting/RecruitingContext';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  
  const [connections, setConnections] = useState(null);
  const [messages, setMessages] = useState({}); // New state for messages, now an object
  const [conversations, setConversations] = useState([]); // New state for conversations
  const [selectedConversation, setSelectedConversation] = useState(null); // New state for selected conversation
  const [selectedMessages, setSelectedMessages] = useState([]); // New state for selected messages
  const [headerDropdownOpen, setHeaderDropdownOpen] = useState(false);

  // CHAI Context
  const { 
    myChaiTab, 
    setMyChaiTab,
    chaiFormLoading, 
    setChaiFormLoading,
    chaiPopUpOpen, 
    setChaiPopUpOpen,
    initialChaiFetch,
    chaiPageSearchTerm,
    setChaiPageSearchTerm,
    userCompanies, 
    setUserCompanies,
    userContacts, 
    setUserContacts,
    createUserCompany,
    updateUserCompany,
    fetchUserCompanies,
    createUserContact,
    updateUserContact,
    fetchUserContacts,
    chaiLoaded,
    setChaiLoaded,
    logContactAction,
    updateContactAction,
    // --- filter functions
    chaiFilters, 
    setChaiFilters,
    clearChaiFilters,
    // --- tag functions
    addTagToContact,
    addTagToCompany,
    removeTagFromContact,
    removeTagFromCompany,
  } = useChaiPage();
 
  //BREW Context
  const { 
    brewWorksFilters, 
    setBrewWorksFilters,
    clearBrewWorksFilters,
    brewWorksLoading,
    setBrewWorksLoading,
    brewWorksPageSearchTerm,
    setBrewWorksPageSearchTerm,
    brewCompanies, 
    setBrewCompanies,
    brewWorksTab, 
    setBrewWorksTab,
    //Shared Contacts
    sharedCompanies,
    updateCompany,
    updateSharedContact,
    addContacts,
    getContacts,
    orgContacts,
    getOrgContact,
  } = useBrewWorksPage();  
  // DIRECTORY Context
  const {
    directory,
    setDirectory,
    userInfo, 
    setUserInfo,
    directoryFilters, 
    setDirectoryFilters,
    clearDirectoryFilters,
    signUpData, setSignUpData,
    directorySearchTerm, 
    setDirectorySearchTerm,
    directoryFilterTerm, 
    setDirectoryFilterTerm,
    directorySearch,
    fetchUser,
    // --- tag Functions
    addTagsToUser,
    editUserTags,
  } = useDirectoryContext();
  
  // EARN Context
  const {
    clinicalStudies,
    setClinicalStudies,
    earnPageFilters, 
    setEarnPageFilters, 
    earnPageSearchTexts, 
    setEarnPageSearchTexts, 
    earnPageSearchTerm, 
    setEarnPageSearchTerm,
    initialEarnLoaded, 
    setInitialEarnLoaded,
    activeStudies, 
    setActiveStudies,
    ignoredStudies, 
    setIgnoredStudies,
    ignoreStudy,
    studyViewed,
    // getClinicalStudiesFS,
    getClinicalStudiesRTD,
    ignoreStudyRTD,
    studyViewedRTD 
  } = useEarnPage();

  const {
    events,
    setEvents,
    eventData, 
    setEventData,
    selectedEvent, 
    setSelectedEvent,
    myEventsTab, 
    setMyEventsTab,
    activeTab, 
    setActiveTab,
    activeDetailTab, 
    setActiveDetailTab,
    eventDetailFilters, 
    setEventDetailFilters,
    eventDetailSearchTexts, 
    setEventDetailSearchTexts,
    eventDetailSearchTerm, 
    setEventDetailSearchTerm,
    clearEventFilters,
    createEvent,
    joinEvent,
    attendProfessionalEvent,
    favoriteAttendee,
    leaveEvent,
    updateEvent
  } = useEventContext();

  //RECRUITING CONTEXT
  const {
    openJobs,
    savedUserJobs,
    savedIgnoredJobs,
    openJobsPageSearchTerm, 
    setOpenJobsPageSearchTerm,
    addJobs,
    updateJob,
    getOpenJobs,
    getUserJobs,
    saveUserJob,
    ignoreJob,
    clubRecruitingTeams,
    getClubRecruitingTeams,
    addRecruitingTeam,
    addRecruitingTeamMember,
    jobStatus,
    setJobStatus,
    updateUserJobStatus
  } = useRecruitingContext();
  
  // SCHOOL Context
  const {
    schoolLoaded, 
    setSchoolLoaded,
    schoolTab, 
    setSchoolTab,
    schoolSearchTerm, 
    setSchoolSearchTerm,
    handleSchoolTabClick,
    clubs,
    setClubs,
    getPendingMembers,
    approveMember,
    rejectMember,
    promoteMember,
    organizations,
    setOrganizations,
    competitions, 
    setCompetitions,
    schoolCompetitionData, 
    setSchoolCompetitionData,
    competitionFilters, 
    setCompetitionFilters,
    competitionSearchTerm, 
    setCompetitionSearchTerm,
    clearCompetitionFilters
  } = useSchoolContext();


  const [directorySearchTexts, setDirectorySearchTexts] = React.useState({
    'Career': '',
    'program': '',
    'country': ''
  });




  const dumbConversations = [
    {
      id: 'conversation1',
      userIds: ['user1', 'user2'],
      created: '2023-10-01T12:00:00Z',
      lastMessage: 'Hey there!',
      lastUpdated: '2023-10-01T12:05:00Z',
      systemMessage: 'You have been connected because...'
    },
    {
      id: 'conversation2',
      userIds: ['user1', 'user3'],
      created: '2023-10-02T13:00:00Z',
      lastMessage: 'Let\'s catch up soon!',
      lastUpdated: '2023-10-02T13:15:00Z',
      systemMessage: 'You have been connected because...'
    },
    {
      id: 'conversation3',
      userIds: ['user1', 'user4'],
      created: '2023-10-03T14:00:00Z',
      lastMessage: 'Are you free this weekend?',
      lastUpdated: '2023-10-03T14:20:00Z',
      systemMessage: 'You have been connected because...'
    }
  ];
  
  const dumbMessages = [
    { conversationId: 'conversation1', sender: 'user1', recipient: 'user2', message: 'Hi!', timestamp: '2023-10-01T12:00:00Z' },
    { conversationId: 'conversation1', sender: 'user2', recipient: 'user1', message: 'Hey there!', timestamp: '2023-10-01T12:05:00Z' },
    { conversationId: 'conversation1', sender: 'user1', recipient: 'user2', message: 'How have you been?', timestamp: '2023-10-01T12:10:00Z' },
    { conversationId: 'conversation1', sender: 'user2', recipient: 'user1', message: 'Good, thanks! And you?', timestamp: '2023-10-01T12:15:00Z' },
    { conversationId: 'conversation1', sender: 'user1', recipient: 'user2', message: 'Not bad!', timestamp: '2023-10-01T12:20:00Z' },
    { conversationId: 'conversation1', sender: 'user2', recipient: 'user1', message: 'Great to hear!', timestamp: '2023-10-01T12:25:00Z' },
    { conversationId: 'conversation1', sender: 'user1', recipient: 'user2', message: 'Catch up soon?', timestamp: '2023-10-01T12:30:00Z' },
    { conversationId: 'conversation1', sender: 'user2', recipient: 'user1', message: 'Absolutely!', timestamp: '2023-10-01T12:35:00Z' },
    { conversationId: 'conversation2', sender: 'user1', recipient: 'user3', message: 'Long time no see!', timestamp: '2023-10-02T13:00:00Z' },
    { conversationId: 'conversation2', sender: 'user3', recipient: 'user1', message: 'Yeah, let\'s catch up soon!', timestamp: '2023-10-02T13:15:00Z' },
    { conversationId: 'conversation2', sender: 'user1', recipient: 'user3', message: 'How about this weekend?', timestamp: '2023-10-02T13:30:00Z' },
    { conversationId: 'conversation2', sender: 'user3', recipient: 'user1', message: 'Sounds good!', timestamp: '2023-10-02T13:45:00Z' },
    { conversationId: 'conversation3', sender: 'user1', recipient: 'user4', message: 'Hey, are you free this weekend?', timestamp: '2023-10-03T14:00:00Z' },
    { conversationId: 'conversation3', sender: 'user4', recipient: 'user1', message: 'Not sure yet, I\'ll let you know.', timestamp: '2023-10-03T14:20:00Z' },
    { conversationId: 'conversation3', sender: 'user1', recipient: 'user4', message: 'Alright, just let me know.', timestamp: '2023-10-03T14:40:00Z' },
    { conversationId: 'conversation3', sender: 'user4', recipient: 'user1', message: 'Will do!', timestamp: '2023-10-03T15:00:00Z' },
    { conversationId: 'conversation3', sender: 'user1', recipient: 'user4', message: 'Catch you later!', timestamp: '2023-10-03T15:20:00Z' },
    { conversationId: 'conversation3', sender: 'user4', recipient: 'user1', message: 'Take care!', timestamp: '2023-10-03T15:40:00Z' },
    { conversationId: 'conversation3', sender: 'user1', recipient: 'user4', message: 'You too!', timestamp: '2023-10-03T16:00:00Z' },
    { conversationId: 'conversation3', sender: 'user4', recipient: 'user1', message: 'Thanks!', timestamp: '2023-10-03T16:20:00Z' },
  ];

  //Pull Cached Data
  useEffect(() => {
    const cachedTeaData = localStorage.getItem('teaData');
    if (cachedTeaData) {
      const { userInfo} = JSON.parse(cachedTeaData);
      setUserInfo(userInfo);
      // setLoading(false);
    }
  }, []);

  // --EVENTS
  useEffect(() => { 
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, async (incomingUser) => {
      setLoading(false);
      setUser(incomingUser);
      if (incomingUser) { // Fetch data only if user is logged in
        try {
          //yi xiaos
          const idToken = await incomingUser.getIdToken(true);
          const url = window.location.href;
          // const [dirDataResponse, eventDataResponse] = await Promise.all([
          //   axios.get(`${API_BASE_URL}/directory`, {
          //     headers: { 'Authorization': `Bearer ${idToken}` }
          //   }),
          //   axios.get(`${API_BASE_URL}/events`, {
          //     headers: { 'Authorization': `Bearer ${idToken}` }
          //   }),
          // ]);  
          // const dirData = dirDataResponse.data;
          // const eventData = eventDataResponse.data;

          // // Merge new data with existing data and remove duplicates
          // const mergedDirectory = [...directory, ...dirData];
          // const uniqueDirectory = Array.from(new Set(mergedDirectory.map(a => a.id)))
          //   .map(id => {
          //     return mergedDirectory.find(a => a.id === id)
          //   });
          // setDirectory(uniqueDirectory);
          const userId = incomingUser.uid;

          const [eventDataResponse] = await Promise.all([
            axios.get(`${API_BASE_URL}/events`, {
              params: { userId },
              headers: { 'Authorization': `Bearer ${idToken}` }
            }),
          ]);  
          const eventData = eventDataResponse.data;
          setEvents(eventData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, async (incomingUser) => {
      setLoading(false);
      setUser(incomingUser);
      if (incomingUser && (!openJobs || openJobs.length === 0)) { // Fetch data only if user is logged in and openJobs does not exist or is empty
        try {
          //yi xiaos
          const idToken = await incomingUser.getIdToken(true);
          getOpenJobs();
          getUserJobs();
          // const [eventDataResponse] = await Promise.all([
          //   axios.get(`${API_BASE_URL}/events`, {
          //     headers: { 'Authorization': `Bearer ${idToken}` }
          //   }),
          // ]);  
          // const eventData = eventDataResponse.data;
          // setEvents(eventData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
      }
    });
    return () => {
      unsubscribe();
    };
  }, [userInfo]);

  const getNewUserData = async () => {
    const auth = getAuth(app);
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const uid = auth.currentUser.uid;
      const [schoolResponse, userProfileResponse] = await Promise.all([
        axios.get(`${API_BASE_URL}/school-data`, {
          headers: { 'Authorization': `Bearer ${idToken}` },
          params: { uid: uid }
        }),
        axios.get(`${API_BASE_URL}/fetch-user`, {
          params: { id: uid },
          headers: { 'Authorization': `Bearer ${idToken}` }
        }),
      ]);
      
      const schoolData = schoolResponse.data;
      const userProfile = userProfileResponse.data ? userProfileResponse.data : {};
      const { clubs, organizations } = schoolData;
      setClubs(clubs);
      setOrganizations(organizations);
      setUserInfo(userProfile);
      return { schoolData };
    } catch (error) {
      console.error("Error fetching new user data:", error);

      // axios.get(`${API_BASE_URL}/directory`, {
      //   headers: { 'Authorization': `Bearer ${idToken}` }
      // }),
      // const directoryData = directoryResponse.data;
      // setDirectory(directoryData);
    }
  };

  const getUserDetailsById = (userEmail) => {
    const userDetails = directory.find(user => user.email === userEmail);
    setSelectedUser(userDetails);
  };

  const login = async (email, password) => {
    const auth = getAuth(app);
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error("Error in login:", error);
    }
  };
  
  const logout = async () => {
    const auth = getAuth(app);
    try {
      await signOut(auth);
      localStorage.removeItem('teaData');
      setDirectory([]);
    } catch (error) {
      console.error("Error in logout:", error);
    }
  };

  const updateUserProfile = async (formData,id) => {
    const auth = getAuth(app);
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.post(`${API_BASE_URL}/update-profile`, formData, {
        headers: { 
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      const { data } = response.data;
      // Handle success response
      setUserInfo(data);
      return data;
    } catch (error) {
      // Handle errors
      console.error('Error updating profile:', error);
      // throw error;
    }
  };

   


  

  const updateDirectory = (updatedUser) => {
    setDirectory(prevDirectory => {
      return prevDirectory.map(user => 
        user.id === updatedUser.id ? updatedUser : user
      );
    });
  };

  const sendMessage = async (conversationId, sender, recipient, message) => {
    const auth = getAuth(app);
    const idToken = await auth.currentUser.getIdToken(true);
    const now = new Date().toISOString();
  
    const newMessage = {
      conversationId,
      sender,
      recipient,
      message,
      timestamp: now,
    };
  
    // This will replace the current messages state with a new array
    // that includes all the old messages plus the new message.
    setMessages(prevMessages => [...prevMessages, newMessage]);
    // await axios.post(`${API_BASE_URL}/send-message`, {
    //   sender,
    //   recipient,
    //   message,
    // }, {
    //   headers: { 'Authorization': `Bearer ${idToken}` }
    // });
  };
  
  const createAccount = async (email, password, formData) => {
    const auth = getAuth(app);
    try {
      // FIX THIS AFTER Updates
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const uuid = userCredential.user.uid;
      const token = userCredential.user.accessToken
      formData.append('id', uuid);
      const response = await axios.post(`${API_BASE_URL}/create-user`, formData, {
        headers: { 'Authorization': `Bearer ${token}` },
        'Content-Type': 'multipart/form-data'
      });
      // const response = await axios.post(`${API_BASE_URL}/create-user`, formData, {
      //   headers: {},
      //   'Content-Type': 'multipart/form-data'
      // });
      return response;
    } catch (error) {
      console.error("Error creating account:", error);
      return error;
    }
  };
  const getSignUpData = async () => {
    // const auth = getAuth(app);
    const idToken = 'blank'
    try {
      const response = await axios.get(`${API_BASE_URL}/signup-data`, {
        headers: { 'Authorization': `Bearer ${idToken}` },
      });
      setSignUpData(response.data);
    } catch (error) {
      console.error("Error fetching signup data:", error);
    }
  };

  const addUserToRTD = async (userObject) => {
    const db = getDatabase(app);
    const newUserRef = ref(db, 'new_users/' + userObject.id);
    try {
      const result = await set(newUserRef, userObject);
    } catch (error) {
      console.error("Error setting user data:", error);
    }
  };

  const joinClub = async (clubId, userObj) => {
    const auth = getAuth(app);
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.put(`${API_BASE_URL}/join-club`, {
        userObj: JSON.stringify(userObj),
        clubId
      }, {
        headers: { 
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      });
      const { data } = response.data;
      // Handle success response
      // Update the club in the directory
      setClubs(prevClubs => {
        return prevClubs.map(club => 
          club.id === clubId ? {...club, pendingMembers: response.data.pendingMembers} : club
        );
      });
      setUserInfo(prevUserInfo => {
        const updatedPendingClubMemberships = prevUserInfo.pendingClubMemberships ? [...prevUserInfo.pendingClubMemberships, clubId] : [clubId];
        return { ...prevUserInfo, pendingClubMemberships: updatedPendingClubMemberships };
      });
      return data;
    } catch (error) {
      // Handle errors
      console.error('Error joining club:', error);
      // throw error;
    }
  };


  // async function getClinicalStudiesByMetroId(fireStoreDB, metroId) {
  //   const clinicalStudiesRef = collection(fireStoreDB, "clinical_studies");
  //   const q = query(clinicalStudiesRef, where("metroId", "==", metroId));
  
  //   try {
  //     const querySnapshot = await getDocs(q);
  //     const studies = [];
  //     querySnapshot.forEach((doc) => {
  //       // doc.data() is never undefined for query doc snapshots
  //       studies.push({ id: doc.id, ...doc.data() });
  //     });
  //     return studies;
  //   } catch (error) {
  //     console.error("Error fetching clinical studies: ", error);
  //     throw error;
  //   }
  // }


  // const getLatestEarnData = async () => {
  //   // const auth = getAuth(app);
  //   // let currentUser = auth.currentUser;
  //   // if (!currentUser) return;
  //   // try {
  //   //   const idToken = await currentUser.getIdToken(true);
  //   //   const schoolDataResponse = await axios.get(`${API_BASE_URL}/earn-data`, {
  //   //     headers: { 'Authorization': `Bearer ${idToken}` },
  //   //     params: { uid: currentUser.uid }
  //   //   });
  //   //   const schoolData = schoolDataResponse.data;
  //   //   setClinicalStudies(schoolData.clinicalStudies);
  //   // } catch (error) {
  //   //   console.error('Error fetching latest school data:', error);
  //   // }
  //   let studies

  //   await getClinicalStudiesByMetroId(fireStoreDB, "losangeles")
  //     .then(clinicalStudies => {
  //       studies = clinicalStudies
  //       setClinicalStudies(clinicalStudies);
  //     })
  //     .catch(error => {
  //       console.error("Error fetching clinical studies:", error);
  //     });
  //     return studies;
  // };



  return (
    <UserContext.Provider value={{ 
      user, 
      addUserToRTD,
      selectedUser,
      loading,
      userInfo,
      connections,
      messages, // Include messages in context
      conversations, // Include conversations in context
      selectedConversation, // Include selected conversation in context
      selectedMessages, // Include selected messages in context
      headerDropdownOpen, 
      signUpData,
      earnPageFilters,
      earnPageSearchTexts,
      earnPageSearchTerm,
      setSelectedUser,
      getUserDetailsById,
      setSelectedConversation,
      setSelectedMessages, // Include setSelectedMessages in context
      setLoading,
      login,
      logout,
      sendMessage,
      setHeaderDropdownOpen,
      updateUserProfile,
      updateDirectory,
      joinEvent,
      createAccount,
      getSignUpData,
      getNewUserData,
      createEvent,
      updateEvent,
      leaveEvent,
      setDirectoryFilters,
      setDirectorySearchTexts,
      setDirectorySearchTerm,
      setEarnPageFilters,
      setEarnPageSearchTexts,
      setEarnPageSearchTerm,
      // getLatestEarnData,
      // -----------
      // Earn Page
      clinicalStudies,
      setClinicalStudies,
      initialEarnLoaded, 
      setInitialEarnLoaded,
      ignoreStudy,
      activeStudies, 
      setActiveStudies,
      ignoredStudies, 
      setIgnoredStudies,
      studyViewed,
      // getClinicalStudiesFS,
      getClinicalStudiesRTD,
      ignoreStudyRTD,
      studyViewedRTD,
      // -----------
      // BrewWorks
      brewWorksFilters, 
      setBrewWorksFilters,
      clearBrewWorksFilters,
      brewWorksLoading,
      setBrewWorksLoading,
      brewWorksPageSearchTerm,
      setBrewWorksPageSearchTerm,
      brewCompanies, 
      setBrewCompanies,
      brewWorksTab, 
      setBrewWorksTab,
      //Shared Contacts
      sharedCompanies,
      updateCompany,
      updateSharedContact,
      addContacts,
      getContacts,
      orgContacts,
      getOrgContact,
      // -----------
      // Chai
      myChaiTab, 
      setMyChaiTab,
      chaiFormLoading, 
      setChaiFormLoading,
      chaiPopUpOpen, 
      setChaiPopUpOpen,
      initialChaiFetch,
      chaiPageSearchTerm,
      setChaiPageSearchTerm,
      userCompanies, 
      setUserCompanies,
      userContacts, 
      setUserContacts,
      chaiLoaded,
      setChaiLoaded,
      createUserCompany,
      updateUserCompany,
      fetchUserCompanies,
      createUserContact,
      updateUserContact,
      fetchUserContacts,
      logContactAction,
      updateContactAction,
      // --- filter functions
      chaiFilters, 
      setChaiFilters,
      clearChaiFilters,
      // --- tag functions
      addTagToContact,
      addTagToCompany,
      removeTagFromContact,
      removeTagFromCompany,
      // ----------
      // Directory Data
      directory,  
      clearDirectoryFilters,
      directorySearch,
      directoryFilters,
      directorySearchTexts,
      directorySearchTerm,
      directoryFilterTerm, 
      setDirectoryFilterTerm,
      fetchUser,
      setUserInfo,
      // --- tag Functions
      addTagsToUser,
      editUserTags,
      // ----------
      // Events Data
      events,
      setEvents,
      selectedEvent, 
      eventData, 
      setEventData,
      setSelectedEvent,
      eventDetailFilters, 
      setEventDetailFilters,
      eventDetailSearchTexts, 
      setEventDetailSearchTexts,
      eventDetailSearchTerm, 
      setEventDetailSearchTerm,
      attendProfessionalEvent,
      favoriteAttendee,
      myEventsTab, 
      setMyEventsTab,
      activeTab, 
      setActiveTab,
      activeDetailTab, 
      setActiveDetailTab,
      clearEventFilters,
      // ----------
      // Recruiting Data
      openJobs,
      savedUserJobs,
      savedIgnoredJobs,
      openJobsPageSearchTerm, 
      setOpenJobsPageSearchTerm,
      addJobs,
      updateJob,
      saveUserJob,
      getOpenJobs,
      ignoreJob,
      clubRecruitingTeams,
      getClubRecruitingTeams,
      addRecruitingTeam,
      addRecruitingTeamMember,
      jobStatus,
      setJobStatus,
      updateUserJobStatus,
      // ----------
      // School Data
      schoolLoaded, 
      setSchoolLoaded,
      schoolTab, 
      setSchoolTab,
      schoolSearchTerm, 
      setSchoolSearchTerm,
      handleSchoolTabClick,
      clubs,
      setClubs,
      joinClub,
      getPendingMembers,
      approveMember,
      rejectMember,
      promoteMember,
      organizations,
      setOrganizations,
      competitions, 
      setCompetitions,
      schoolCompetitionData, 
      setSchoolCompetitionData,
      competitionFilters, 
      setCompetitionFilters,
      competitionSearchTerm, 
      setCompetitionSearchTerm,
      clearCompetitionFilters
    }}>
      {children}
    </UserContext.Provider>
  );
};

