import React from 'react';
import FormSelect from './FormSelect';
import FormCheckboxes from './FormCheckboxes';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import MultiComboBoxInput from './filters/MultiComboBoxInput';
import { hasFormErrors } from '../utils/inputValidator';


const SignUpForm = ({ 
    formState, setFormErrors, handleChange, handleSubmit,
    passwordVisible, setPasswordVisible,
    filters, setFilters, handleFilterChange, 
    handleSearchTextChange, handleCheckboxChange, handleFileChange, handleRadioChange,
    searchTexts, signUpData, otherUpdateState, formErrors, 
    handleUsernameBlur, handleEmailBlur, handlePasswordBlur, handleTextInputBlur, 
    error, disabled }) => {
    let errorText;
    if (typeof error === 'string') {
        if (error.includes('auth/email-already-in-use')) {
            errorText = 'That Email has already been used';
        } else if (error.includes('auth/invalid-email')) {
            errorText = 'The email address is badly formatted.';
        } else if (error.includes('auth/weak-password')) {
            errorText = 'The password is too weak.';
        } else {
            errorText = error;
        }
    } else {
        errorText = 'The Email may already be in use or there is another problem';
    }
    const formValid = hasFormErrors(formErrors)
  return (
    <div className="tw-mx-auto tw-max-w-7xl tw-px-4 tw-py-24 sm:tw-px-6 sm:tw-py-32 lg:tw-px-8">
      <h1 className="tw-text-3xl tw-font-semibold tw-leading-7 tw-text-gray-900 tw-mb-6">Sign Up</h1>
      <p className="tw-text-base tw-leading-6 tw-text-gray-600 tw-mb-4 tw-border-b tw-border-gray-900/10 tw-pb-12">Start your journey with us by creating an account.</p>
      {error && (
        <div className="tw-mt-2 tw-text-red-500 tw-text-md tw-text-center">
          {errorText}
        </div>
      )}
    <form onSubmit={handleSubmit} className="tw-flex tw-justify-center tw-items-center tw-overflow-auto">
      <div className="tw-space-y-12">
        <div className="tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10 tw-border-b tw-border-gray-900/10 tw-pb-12 md:tw-grid-cols-3">
          <div>
            <h2 className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">Personal Information</h2>
            <p className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-600">
              The basics. We are currently only open to UCLA community members or individuals attending a partner event at UCLA
            </p>
          </div>

          <div className="tw-max-w-2xl tw-space-y-10 md:tw-col-span-2">
            <div>
              <label htmlFor="name" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Full Name <span className="required-star">*</span>
              </label>
              <div className="tw-mt-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formState.name}
                  onChange={handleChange}
                  className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                  required
                  maxLength={50}
                  onBlur={() => handleUsernameBlur(formState, setFormErrors)}
                />
                {formErrors.name && (
                  <p className="tw-text-red-500 tw-text-xs tw-pt-2">{formErrors.name}</p>
                )}
              </div>
            </div>
            <div>
              <label htmlFor="email" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Email <span className="required-star">*</span>
              </label>
              <div className="tw-mt-2">
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formState.email}
                  onChange={handleChange}
                  // onBlur={handleEmailBlur}
                  className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                  required
                  maxLength={70}
                />
                {formErrors.email && (
                  <p className="tw-text-red-500 tw-text-xs tw-pt-2">{formErrors.email}</p>
                )}
              </div>
            </div>
            <div>
              <div className="tw-flex tw-items-center">
                <label htmlFor="password" className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                  Password <span className="required-star">*</span>
                </label>
                <button
                  type="button"
                  onClick={setPasswordVisible}
                  aria-label="Toggle password visibility"
                  className="tw-text-sm tw-pl-2"
                >
                  {passwordVisible ? (
                    <EyeSlashIcon className="tw-h-5 tw-w-5 tw-text-gray-700" /> // Icon for hidden password
                  ) : (
                    <EyeIcon className="tw-h-5 tw-w-5 tw-text-gray-700" /> // Icon for visible password
                  )}
                </button>
              </div>
              <div className="tw-mt-2">
                <input
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  id="password"
                  value={formState.password}
                  onChange={handleChange}
                  onBlur={handlePasswordBlur}
                  className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                  required
                  maxLength={30}
                />
               
                <small className="tw-block tw-text-sm tw-text-gray-500">Minimum 8 characters. Max 30</small>
                {formErrors.password && (
                  <p className="tw-text-red-500 tw-text-xs tw-pt-2">{formErrors.password}</p>
                )}
              </div>
            </div>
            <div>
              <label htmlFor="profilePhoto" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Profile Photo <span className="required-star">*</span>
              </label>
              <div className="tw-mt-2">
                <input
                  type="file"
                  name="profilePhoto"
                  id="profilePhoto"
                  accept=".png, .jpg, .jpeg, .heic"
                  onChange={handleFileChange}
                  className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                  required
                />
                <small className="tw-block tw-text-sm tw-text-gray-500">Accepted formats: PNG, JPG, HEIC</small>
              </div>
            </div>
            <div>
              <label htmlFor="phone" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Phone
              </label>
              <div className="tw-mt-2">
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  value={formState.phone}
                  onChange={handleChange}
                  // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  placeholder="123-456-7890"
                  className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                  maxLength={20}
                />
                <small className="tw-block tw-text-sm tw-text-gray-500">Preferred Format: 123-456-7890</small>
                {formErrors.phone && (
                  <p className="tw-text-red-500 tw-text-xs tw-pt-2">{formErrors.phone}</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10 tw-border-b tw-border-gray-900/10 tw-pb-12 md:tw-grid-cols-3">
          <div>
            <h2 className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">Background Information</h2>
            <p className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-600">
              Tailor your journey with us! Select your preferences to get conected to people and events that align with your background and interests.
            </p>
          </div>

          <div className="tw-max-w-2xl md:tw-col-span-2">
            {/* <div className="">
              <label htmlFor="program" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Major or School Program (e.g., MBA)
              </label>
              {filters && filters.program && 
                <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                  Selected: {Array.isArray(filters.program) ? filters.program.join(', ') : filters.program}
                </div>
              }
                <MultiComboBoxInput
                  items={signUpData ? Object.entries(signUpData.programs).sort((a, b) => a[0].localeCompare(b[0])).map(entry => entry[1]) : []}
                  label="program"
                  singleSelect={false}
                  selectedValues={filters.program}
                  setFilters={setFilters}
                  handleFilterChange={handleFilterChange}
                  customMaxLength={70}
                  currentFormState={formState} 
                  currentFormErrors={formErrors}
                  currentFormErrorFunction={setFormErrors}
                  hideOwnValue={false}
                />
                <small>If your program is not listed, use the format: 'Program Name' 'Year' or 'Program Name' 'Year - Section Name'. See above for Examples</small>
                {formErrors.program && (
                  <p className="tw-text-red-500 tw-text-xs tw-pt-2">{formErrors.program}</p>
                )}
            </div> */}

            {/* <div className="tw-pt-10"> */}
            <div className="">
              <label htmlFor="homeCountry" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Home Country / Countries
              </label>
              {filters && filters.country && 
                <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                  Selected: {Array.isArray(filters.country) ? filters.country.join(', ') : filters.country}
                </div>
              }
                <MultiComboBoxInput
                  items={signUpData ? Object.entries(signUpData.countries).sort((a, b) => a[0].localeCompare(b[0])).map(entry => entry[1]) : []}
                  label="country"
                  singleSelect={false}
                  selectedValues={filters.country}
                  setFilters={setFilters}
                  handleFilterChange={handleFilterChange}
                  currentFormState={formState}
                  currentFormErrors={formErrors} 
                  currentFormErrorFunction={setFormErrors}
                  hideOwnValue={false}
                />
                <small>Note: The names of some countries, such as the United States and the United Kingdom, are abbreviated to 'US' and 'UK' respectively.</small>
                {formErrors.country && (
                  <p className="tw-text-red-500 tw-text-xs tw-pt-2">{formErrors.country}</p>
                )}
            </div>

            {/* <div className="tw-pt-10">
              <label htmlFor="interestsHobbies" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Interests / Hobbies
              </label>
              {filters && filters.interestsHobbies && 
                <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                  Selected: {Array.isArray(filters.interestsHobbies) ? filters.interestsHobbies.join(', ') : filters.interestsHobbies}
                </div>
              }
                <MultiComboBoxInput
                  items={signUpData ? Object.entries(signUpData.interests).sort((a, b) => a[0].localeCompare(b[0])).map(entry => entry[1]) : []}
                  altLabel="Interests and Hobbies"
                  label="interestsHobbies"
                  singleSelect={false}
                  selectedValues={filters.interestsHobbies}
                  setFilters={setFilters}
                  handleFilterChange={handleFilterChange}
                  currentFormState={formState}
                  currentFormErrors={formErrors} 
                  currentFormErrorFunction={setFormErrors}
                  hideOwnValue={false}
                />
                {formErrors.interestsHobbies && (
                  <p className="tw-text-red-500 tw-text-xs tw-pt-2">{formErrors.interestsHobbies}</p>
                )}
            </div> */}

            <div className="tw-pt-10">
              <label htmlFor="recruitingFor" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Industry Focus
              </label>
              {filters && filters.recruitingFor && 
                <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                  Selected: {Array.isArray(filters.recruitingFor) ? filters.recruitingFor.join(', ') : filters.recruitingFor}
                </div>
              }
                <MultiComboBoxInput
                  items={signUpData ? Object.entries(signUpData.industries).sort((a, b) => a[0].localeCompare(b[0])).map(entry => entry[1]) : []}
                  label="recruitingFor"
                  altLabel="Industry"
                  singleSelect={false}
                  selectedValues={filters.recruitingFor}
                  setFilters={setFilters}
                  handleFilterChange={handleFilterChange}
                  currentFormState={formState} 
                  currentFormErrors={formErrors}
                  currentFormErrorFunction={setFormErrors}
                  hideOwnValue={false}
                />
                 {formErrors.recruitingFor && (
                  <p className="tw-text-red-500 tw-text-xs tw-pt-2">{formErrors.recruitingFor}</p>
                )}
            </div>
          </div>
        </div>

        <div className="tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10 tw-border-b tw-border-gray-900/10 tw-pb-12 md:tw-grid-cols-3">
          <div>
            <h2 className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">Short Answer Questions</h2>
            <p className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-600">
              We kindly ask you to provide detailed answers to the following questions. This information is crucial for us to find the best matches for you within the application.
            </p>
          </div>

          <div className="tw-max-w-2xl tw-space-y-10 md:tw-col-span-2">
            <div>
              <label htmlFor="currentGoals" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                What do you need help with now? - Professional, Academic, and/or Other <span className="required-star">*</span>
              </label>
              <div className="tw-mt-2">
                <textarea
                  rows={4}
                  name="currentGoals"
                  id="currentGoals"
                  className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                  value={formState.currentGoals}
                  onChange={handleChange}
                  required
                  maxLength={2000}
                  // onBlur={() => handleTextInputBlur("currentGoals",formState, setFormErrors)}
                />
                {formErrors.currentGoals && (
                  <p className="tw-text-red-500 tw-text-xs tw-pt-2">{formErrors.currentGoals}</p>
                )}
              </div>
            </div>
            <div>
              <label htmlFor="helpOffered" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                How can you help someone? - Professionally, Academic, and/or Other <span className="required-star">*</span>
              </label>
              <div className="tw-mt-2">
                <textarea
                  rows={4}
                  name="helpOffered"
                  id="helpOffered"
                  className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                  value={formState.helpOffered}
                  onChange={handleChange}
                  required
                  maxLength={2000}
                  // onBlur={() => handleTextInputBlur("helpOffered",formState, setFormErrors)}
                />
                 {formErrors.helpOffered && (
                  <p className="tw-text-red-500 tw-text-xs tw-pt-2">{formErrors.helpOffered}</p>
                )}
              </div>
            </div>
            {/* <div>
              <label htmlFor="schoolFiveYearGoals" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                What are your goals for the next 5 years?
              </label>
              <div className="tw-mt-2">
                <textarea
                  rows={4}
                  name="schoolFiveYearGoals"
                  id="schoolFiveYearGoals"
                  className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                  value={formState.schoolFiveYearGoals}
                  onChange={handleChange}
                  maxLength={2000}
                  // onBlur={() => handleTextInputBlur("schoolFiveYearGoals",formState, setFormErrors)}
                />
                {formErrors.schoolFiveYearGoals && (
                  <p className="tw-text-red-500 tw-text-xs tw-pt-2">{formErrors.schoolFiveYearGoals}</p>
                )}
              </div>
            </div> */}
            {/* <div>
              <label htmlFor="favoriteMedia" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Favorite Movie / Music / TV Show
              </label>
              <div className="tw-mt-2">
                <textarea
                  rows={4}
                  name="favoriteMedia"
                  id="favoriteMedia"
                  className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                  value={formState.favoriteMedia}
                  onChange={handleChange}
                  maxLength={500}
                  // onBlur={() => handleTextInputBlur("favoriteMedia",formState, setFormErrors)}
                />
                {formErrors.favoriteMedia && (
                  <p className="tw-text-red-500 tw-text-xs tw-pt-2">{formErrors.favoriteMedia}</p>
                )}
              </div>
            </div> */}
            <div>
              <label htmlFor="terms" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Terms and Conditions <span className="required-star">*</span>
              </label>
              <p className="tw-text-sm tw-text-gray-500">Do you agree to our <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" className="tw-text-indigo-600 hover:tw-text-indigo-800">terms and conditions</a>?</p>
              <div className="tw-mt-2">
                <div className="tw-flex tw-items-center">
                  <input
                    id="agree"
                    name="agreement"
                    type="checkbox"
                    className="tw-h-4 tw-w-4 tw-border-gray-300 tw-text-indigo-600 focus:tw-ring-indigo-600"
                    onChange={handleCheckboxChange('agreement')}
                    checked={formState.agreement}
                    required
                  />
                  <label htmlFor="agree" className="tw-ml-3 tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                    I agree
                  </label>
                </div>
              </div>
            </div>
          {Object.keys(formErrors).length > 0 && (
          <div className="tw-flex tw-items-center tw-justify-end tw-mt-0">
            {Object.keys(formErrors).map((key, index) => (
              formErrors[key] && (
                <p key={index} className="tw-text-red-500 tw-text-xs tw-pt-2">{formErrors[key]}</p>
              )
            ))}
          </div>
        )}
          </div>
        </div>


      <div className="tw-mt-6 tw-flex tw-items-center tw-justify-end tw-gap-x-6">
        <button type="button" className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">
          Cancel
        </button>
        <button
          type="submit"
          className="tw-rounded-md tw-bg-indigo-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm tw-hover:bg-indigo-500 tw-focus-visible:outline tw-focus-visible:outline-2 tw-focus-visible:outline-offset-2 tw-focus-visible:outline-indigo-600"
          disabled={disabled || formValid}
        >
          Save
        </button>
      </div>

      </div>

    </form>
    {/* <form onSubmit={handleSubmit} className="profile-form"> */}

                  {/* <label className="profile-form__label">
        <span>Full Name <span className="required-star">*</span></span>
        <input
          type="text"
          name="name"
          value={formState.fullName}
          onChange={handleChange}
          className="profile-form__input"
          required
        />
      </label>
            <label className="profile-form__label">
        <span>Email <span className="required-star">*</span></span>
        <input
          type="email"
          name="email"
          value={formState.email}
          onChange={handleChange}
          onBlur={handleEmailBlur}
          className="profile-form__input"
          required
        />
        {formErrors.email && <small className="error-text">{formErrors.email}</small>}
      </label>
      <label className="profile-form__label">
      <span>Password <span className="required-star">*</span></span>
        <input
          type="password"
          name="password"
          value={formState.password}
          onChange={handleChange}
          onBlur={handlePasswordBlur}
          className="profile-form__input"
          required
        />
      </label>

      <label className="profile-form__label">
      <span>Profile Photo <span className="required-star">*</span></span>
        <input
          type="file"
          name="profilePhoto"
          accept=".png, .jpg, .jpeg, .heic"
          onChange={handleFileChange}
          className="profile-form__input"
          required
        />
      </label>
      <label className="profile-form__label">
        Phone
        <input
          type="tel"
          name="phone"
          value={formState.phone}
          onChange={handleChange}
          className="profile-form__input"
        />
      </label> */}

      {/* <label className="profile-form__label">
        <span>Major or School Program (e.g., MBA) <span className="required-star">*</span></span>
        <div className="profile-form__select">
          <FormSelect
            values={signUpData ? signUpData.program : []}
            selectedValue={formState.program}
            onChange={handleRadioChange}
            otherUpdateState={otherUpdateState}
            required
            stateKey={'program'}
          />
        </div>
      </label>
      <label className="profile-form__label">
        <span>Interests / Hobbies <span className="required-star">*</span></span>
        <p>
                    Selected: {Array.isArray(formState.interestsHobbies) ? formState.interestsHobbies.join(', ') : formState.interestsHobbies}
                  </p>
        <FormCheckboxes
          values={signUpData ? signUpData.interestsHobbies : []}
          selectedValues={formState.interestsHobbies}
          searchText={searchTexts.interestsHobbies}
          searchKey='interestsHobbies'
          onSearchTextChange={handleSearchTextChange}
          onChange={handleCheckboxChange('interestsHobbies')}
          required
        />
      </label>
      <label className="profile-form__label">
        Industry Focus
        <p>
                    Selected: {Array.isArray(formState.recruitingFor) ? formState.recruitingFor.join(', ') : formState.recruitingFor}
                  </p>
        <FormCheckboxes
           values={signUpData ? signUpData.recruitingFor : []}
           selectedValues={formState.recruitingFor}
           searchText={searchTexts.recruitingFor}
           searchKey='recruitingFor'
           onSearchTextChange={handleSearchTextChange}
           onChange={handleCheckboxChange('recruitingFor')}
        />
      </label> */}
      {/* <h3 className="profile-form__helper-text">
        The more detailed your answers, the better the connection.
      </h3>
      <label className="profile-form__label">
      <span>What do you need help with now? - Professional, Academic, and/or Other <span className="required-star">*</span></span>
      <small className="input-helper-text">Examples: 'I need help building my own CPG brand. I am struggling to build a loyality program', 'I need help understanding my strategy class'</small>
        <textarea
          className="profile-form__input--text-area"
          name="currentGoals"
          value={formState.currentGoals}
          onChange={handleChange}
          required
        />
      </label>
      <label className="profile-form__label">
      <span>How can you help someone? - Professionally, Academic, and/or Other <span className="required-star">*</span></span>
      <small className="input-helper-text">Examples: 'I have extensive experience in consumer goods marketing, particularly in brand development', 'I can tutor you in economics, either micro or macro'</small>
        <textarea
          className="profile-form__input--text-area"
          name="helpOffered"
          value={formState.helpOffered}
          onChange={handleChange}
          required
        />
      </label>
      <label className="profile-form__label">
        What are your goals for the next 5 years?
        <textarea
          className="profile-form__input--text-area"
          name="schoolFiveYearGoals"
          value={formState.schoolFiveYearGoals}
          onChange={handleChange}
        />
      </label>
      <label className="profile-form__label">
        Favorite Movie / Music / TV Show
        <input
          type="text"
          name="favoriteMedia"
          value={formState.favoriteMedia}
          onChange={handleChange}
          className="profile-form__input"
        />
      </label> */}
      {/* <button 
      type="submit" 
      disabled={disabled}
      className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
      >Sign Up</button> */}
    {/* </form> */}
    </div>
  );
};

export default SignUpForm;
