import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import MultiComboBoxInput from '../MultiComboBoxInput'

export default function ChaiFilterPopup({
    open, filters, tagItems, tagSingleSelect,
    setOpen, setFilters,handleFilterChange,
    clearFilters, industryItems, industrySingleSelect
}) {
  return ( <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="tw-relative tw-z-10" onClose={setOpen}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 transition-opacity" />
      </Transition.Child>
      <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
        <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center tw-p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="tw-relative tw-transform tw-overflow-auto tw-rounded-lg tw-bg-white 
            tw-px-4 tw-pb-4 tw-pt-0 tw-text-left tw-shadow-xl tw-transition-all tw-leading-[15rem]
            sm:tw-my-8 sm:tw-w-full sm:tw-max-w-sm">
                 <button
              type="button"
              className="tw-absolute tw-top-0 tw-right-0 tw-p-4"
              onClick={() => setOpen(false)}
            >
              <XMarkIcon className="tw-h-6 tw-w-6 tw-text-gray-600 hover:tw-text-red-500" aria-hidden="true" />
            </button>
        
            <div className="tw-pt-8">
              <label htmlFor="tags" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
              Filter by Tag
              </label>
              {filters && filters.tags && 
                <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                  Selected: {Array.isArray(filters.tags) ? filters.tags.join(', ') : filters.tags}
                </div>
              }
              <MultiComboBoxInput
                items={tagItems}
                label="tags"
                singleSelect={tagSingleSelect}
                selectedValues={filters.tags}
                setFilters={setFilters}
                handleFilterChange={handleFilterChange}
              />
            </div>
            <div className="tw-pt-12">
              <label htmlFor="tags" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
              Filter by Industry
              </label>
              {filters && filters.industries && 
                <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                  Selected: {Array.isArray(filters.industries) ? filters.industries.join(', ') : filters.tags}
                </div>
              }
              <MultiComboBoxInput
                items={industryItems}
                label="industries"
                singleSelect={industrySingleSelect}
                selectedValues={filters.industries}
                setFilters={setFilters}
                handleFilterChange={handleFilterChange}
              />
            </div>
              <div className="tw-flex tw-justify-between tw-mt-28 tw-mb-2">
                <button
                  type="button"
                  className="tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-red-600 tw-text-base tw-font-medium tw-text-white hover:tw-bg-red-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-red-500 tw-w-1/2 tw-mr-2"
                  onClick={clearFilters}
                >
                  Clear Filters
                </button>
                <button
                  type="button"
                  className="tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-indigo-600 tw-text-base tw-font-medium tw-text-white hover:tw-bg-indigo-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500 tw-w-1/2 tw-ml-2"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>)}