import React, { useState, useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import FormCheckboxes from './FormCheckboxes';
import { sortValuesAlphabetically } from '../utils/helperFunctions';
import heic2any from 'heic2any';
import MultiComboBoxInput from './filters/MultiComboBoxInput';

const ProfileForm = ({ 
  user, userInfo, directory, updateUserProfile, updateDirectory, signUpData,
  onSubmit, setResetMessage, setShowSuccessNoti,
filters, setFilters, handleFilterChange }) => {
    
const { 
    
  } = useUser();
  const [formState, setFormState] = useState({
    id: user.uid || '',
    areasOfInterest: userInfo.areasOfInterest || '',
    availability: userInfo.availability || '',
    birthday: userInfo.birthday || '',
    company: userInfo.company || [],
    country: typeof userInfo.country === 'string' ? userInfo.country.split(',') : (userInfo.country ||[]),
    currentGoals: userInfo.currentGoals || '',
    currentLastJobTitle: userInfo.currentLastJobTitle || '',
    discord: userInfo.discord || '',
    email: userInfo.email || '',
    eventsToAvoid: userInfo.eventsToAvoid || '',
    favoriteMedia: userInfo.favoriteMedia || '',
    helpOffered: userInfo.helpOffered || '',
    hometown: userInfo.hometown || '',
    instagram: userInfo.instagram || '',
    interestLanguages: typeof userInfo.interestLanguages === 'string' ? userInfo.interestLanguages.split(',') : (userInfo.interestLanguages || []),
    interestedInLanguagePartner: userInfo.interestedInLanguagePartner || '',
    interestsHobbies: typeof userInfo.interestsHobbies === 'string' ? userInfo.interestsHobbies.split(',') : (userInfo.interestsHobbies ||[]),
    languageLevels: userInfo.languageLevels || '',
    languagePartnerMatched: userInfo.languagePartnerMatched || '',
    linkedIn: userInfo.linkedIn || '',
    name: userInfo.name || '',
    nativeLanguage: typeof userInfo.nativeLanguage === 'string' ? userInfo.nativeLanguage.split(',') : (userInfo.nativeLanguage ||[]),
    numberOfEventsAttended: userInfo.numberOfEventsAttended || 0,
    phone: userInfo.phone || '',
    professionalEventsAttended: userInfo.professionalEventsAttended || '',
    program: typeof userInfo.program === 'string' ? userInfo.program.split(',') : (userInfo.program ||[]),
    recruitingFor: typeof userInfo.recruitingFor === 'string' ? userInfo.recruitingFor.split(',') : (userInfo.recruitingFor ||[]),
    school: userInfo.school || '',
    schoolFiveYearGoals: userInfo.schoolFiveYearGoals || '',
    slack: userInfo.slack || '',
    talkInClassPreference: userInfo.talkInClassPreference || '',
    profilePhoto: userInfo.profilePhoto || userInfo['Profile Photo'] || [],
  });
  const [openSections, setOpenSections] = useState({});

  const [searchTerm, setSearchTerm] = useState('');
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);

  const [searchTexts, setSearchTexts] = React.useState({
    'Career': '',
    'program': '',
    'country': ''
  });

  const toggleSection = (sectionKey) => {
    setOpenSections(prevState => ({
      ...prevState,
      [sectionKey]: !prevState[sectionKey]
    }));
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === 'checkbox') {
      setFormState(prevState => {
        const previousValue = prevState[name];
        const newValue = checked ? [...previousValue, value] : previousValue.filter(v => v !== value);

        return {
          ...prevState,
          [name]: newValue
        };
      });
    } else {
      setFormState(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  // const handleSearchTextChange = (filterKey, newSearchText) => {
  //   setSearchTexts(prevTexts => ({
  //     ...prevTexts,
  //     [filterKey]: newSearchText
  //   }));
  // };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file.type === 'image/heic') {
        const convertedFile = await heic2any({
          blob: file,
          toType: 'image/jpeg', // or 'image/png'
          quality: 0.8 // optional, default is 0.8
        });
        file = new File([convertedFile], file.name, { type: 'image/jpeg' }); // or 'image/png'
      }
    
    setFormState(prevState => ({
      ...prevState,
      profilePhoto: file
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitDisabled(true);
    const formData = new FormData();
    Object.keys(formState).forEach(key => {
      formData.append(key, formState[key]);
    });
    Object.keys(filters).forEach(key => {
      formData.append(key, filters[key]);
    });

    try {
        const updatedUser = await updateUserProfile(formData,user.uid);
        updateDirectory(updatedUser);
        // Handle success response
        setIsUpdateSuccessful(true);
        setShowSuccessNoti(true);
        setTimeout(() => {
            setIsUpdateSuccessful(false);
            setShowSuccessNoti(false);
        }, 3000);
        setSubmitDisabled(false);
    } catch (error) {
        // Handle errors
        console.error('Error updating profile:', error);
        // alert('Failed to update profile.');
    }
  };


  const getUniqueCountryValues = () => {
    const countries = directory.map(user => user.country);
    const uniqueCountries = [...new Set(countries)];
    return uniqueCountries.sort();
  };

  const getUniqueInterestLanguages = () => {
    const interestLanguages = directory.flatMap(user => user.interestLanguages);
    const uniqueInterestLanguages = Array.from(new Set(interestLanguages));
    return uniqueInterestLanguages.sort();
  };

  const getUniqueValues = (key) => {
    let values = directory.flatMap((user, index) => user[key] ? user[key] : []);
    values = [...new Set(values)]; // remove duplicates

    return sortValuesAlphabetically(values);

    // const uniqueInterestsHobbies = new Set();
    // directory.flatMap(user => {
    //   if (Array.isArray(user.interestsHobbies)) {
    //     user.interestsHobbies.forEach(interest => {
    //       uniqueInterestsHobbies.add(interest);
    //     });
    //   }
    // });
    // return Array.from(uniqueInterestsHobbies)
    //   .map(interest => interest.replace(/(?<=\p{Emoji})/gu, ''))
    //   .sort((a, b) => a.localeCompare(b));
    // return [];
  };

  // const getUniqueNativeLanguage = () => {
  //   const nativeLanguage = directory.flatMap(user => user.nativeLanguage);
  //   const uniqueNativeLanguage = Array.from(new Set(nativeLanguage));
  //   return uniqueNativeLanguage.sort();
  // };  

  // const getUniqueRecruitingFor = () => {
  //   const recruitingFor = directory.map(user => user.recruitingFor);
  //   const uniqueRecruitingFor = [...new Set(recruitingFor)];
  //   return uniqueRecruitingFor.sort();
  // };

  // const handleFilterChange = () => {
  //   return;
  // }

  // const handleCheckboxChange = (key) => (event) => {
  //   const { value, checked } = event.target;
  //   setFormState(prevState => {
  //     const previousValues = prevState[key];
  //     let newValues;
  //     if (checked) {
  //       if (previousValues.includes('')) {
  //         const index = previousValues.indexOf('');
  //         if (index > -1) {
  //           previousValues.splice(index, 1);
  //         }
  //       }
  //       newValues = [...previousValues, value];
  //     } else {
  //       newValues = Array.isArray(previousValues) ? previousValues.filter(v => v !== value) : [];
  //     }
  //     newValues = sortValuesAlphabetically(newValues);
  //     return {
  //       ...prevState,
  //       [key]: newValues
  //     };
  //   });
  // };

  // console.log(formState.program,'pro 2')
  
  return (
    <form onSubmit={handleSubmit}>
      {isUpdateSuccessful && (
        <div>Your profile has been updated successfully!</div>
      )}
      <div className="tw-space-y-6" >
        <div className="tw-border-b tw-border-gray-900/10 tw-pb-2">
            <h2 className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">Profile</h2>
            {user.profilePhoto && <img src={user.profilePhoto} className="profile-form__photo" />}
            <div className="tw-mt-10 tw-grid tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6">
              <div className="tw-col-span-full">
                <label htmlFor="name" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Name
                </label>
                <div className="tw-mt-2">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="street-address"
                    className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                    value={formState.name}
                    onChange={handleChange}
                  />
                </div>
              </div>


              <div className="tw-col-span-full">
                <label htmlFor="photo" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                  Update Photo
                </label>
                <div className="tw-mt-2 tw-flex tw-items-center tw-gap-x-3">
                  {/* <UserCircleIcon className="tw-h-12 tw-w-12 tw-text-gray-300" aria-hidden="true" /> */}
                  {/* <button
                    type="button"
                    className="tw-rounded-md tw-bg-white tw-px-2.5 tw-py-1.5 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-hover:bg-gray-50"
                  >
                    Change
                  </button> */}
                    <input
                      className=""
                      type="file"
                      name="profilePhoto"
                      accept=".png, .jpg, .jpeg, .heic"
                      onChange={handleFileChange}
                    />
                </div>
              </div>

            </div>
          </div>

      {/* Personal Information Section */}


      {/* Contact Information Section */}
      <div className="tw-border-b tw-border-gray-900/10 tw-pb-2">
        <h2 onClick={() => toggleSection('contactInfo')} className="tw-font-semibold tw-flex tw-items-center tw-cursor-pointer">
          Contact Information
          <span className={`arrow ${openSections['contactInfo'] ? 'down' : 'right'}`} />
        </h2>
        <div className="tw-mt-2 tw-grid tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-6">

        {openSections.contactInfo && (
          <>
            <div className="tw-col-span-full">
                <label htmlFor="phone" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Phone
                </label>
                <div className="tw-mt-2">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="street-address"
                    className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                    value={formState.phone}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="tw-col-span-full">
                <label htmlFor="instagram" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Instagram
                </label>
                <div className="tw-mt-2">
                  <input
                    type="text"
                    name="instagram"
                    id="instagram"
                    autoComplete="street-address"
                    className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                    value={formState.instagram}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="tw-col-span-full">
                <label htmlFor="linkedIn" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                LinkedIn
                </label>
                <div className="tw-mt-2">
                  <input
                    type="text"
                    name="linkedIn"
                    id="linkedIn"
                    autoComplete="street-address"
                    className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                    value={formState.linkedIn}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="tw-col-span-full">
                <label htmlFor="slack" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Slack
                </label>
                <div className="tw-mt-2">
                  <input
                    type="text"
                    name="slack"
                    id="slack"
                    autoComplete="street-address"
                    className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                    value={formState.slack}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="tw-col-span-full">
                <label htmlFor="discord" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Discord
                </label>
                <div className="tw-mt-2">
                  <input
                    type="text"
                    name="discord"
                    id="discord"
                    autoComplete="street-address"
                    className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                    value={formState.discord}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </>
        )}
        </div>
      </div>

      <section className="tw-border-b tw-border-gray-900/10 tw-pb-4">
        <h2 onClick={() => toggleSection('personalPreferences')} 
        className="tw-font-semibold tw-flex tw-items-center tw-cursor-pointer">
          Personal Interests and Background
          <span className={`arrow ${openSections['personalPreferences'] ? 'down' : 'right'}`} />
        </h2>
        {openSections['personalPreferences'] && (
          <>
            <div className="tw-col-span-full tw-mt-2">
              <label htmlFor="program" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
              Major or School Program
              </label>
              {formState.program&& 
                <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                  Selected: {Array.isArray(filters.program) ? filters.program.join(', ') : filters.program}
                </div>
              }
                <MultiComboBoxInput
                  items={signUpData ? signUpData.programs : []}
                  label="program"
                  singleSelect={false}
                  selectedValues={formState.program}
                  setFilters={setFilters}
                  handleFilterChange={handleFilterChange}
                  customMaxLength={70}
                  currentFormState={formState} 
                  hideOwnValue={false}
                />
                <small>If your program is not listed, use the format: 'Program Name' 'Year' or 'Program Name' 'Year - Section Name'. See above for Examples</small>
            </div>
            <div className="tw-col-span-full tw-mt-2">
              <label htmlFor="country" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
              Home Country / Countries
              </label>
              {formState.country&& 
                <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                  Selected: {Array.isArray(filters.country) ? filters.country.join(', ') : filters.country}
                </div>
              }
              <MultiComboBoxInput
                  items={signUpData ? signUpData.countries : []}
                  label="country"
                  singleSelect={false}
                  selectedValues={formState.country}
                  setFilters={setFilters}
                  handleFilterChange={handleFilterChange}
                  currentFormState={formState}
                  hideOwnValue={false}
                />
                <small>Note: The names of some countries, such as the United States and the United Kingdom, are abbreviated to 'US' and 'UK' respectively.</small>
            </div>
            <div className="tw-col-span-full tw-mt-2">
              <label htmlFor="interests" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
              Interests / Hobbies
              </label>
              {formState.interestsHobbies && 
                <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                  Selected: {Array.isArray(filters.interestsHobbies) ? filters.interestsHobbies.join(', ') : filters.interestsHobbies}
                </div>
              }
                <MultiComboBoxInput
                  items={signUpData ? signUpData.interests : []}
                  label="interestsHobbies"
                  altLabel="Interests and Hobbies"
                  singleSelect={false}
                  selectedValues={formState.interestsHobbies}
                  setFilters={setFilters}
                  handleFilterChange={handleFilterChange}
                  currentFormState={formState}
                  hideOwnValue={false}
                />
            </div>
            <div className="tw-col-span-full tw-mt-2">
              <label htmlFor="industry" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
              Industry Focus
              </label>
              {formState.recruitingFor && 
                <div className="tw-text-xs tw-font-light tw-text-gray-500 tw-mb-2">
                  Selected: {Array.isArray(filters.recruitingFor) ? filters.recruitingFor.join(', ') : filters.recruitingFor}
                </div>
              }
                <MultiComboBoxInput
                  items={signUpData ? signUpData.industries : []}
                  label="recruitingFor"
                  altLabel="Industry"
                  singleSelect={false}
                  selectedValues={formState.recruitingFor}
                  setFilters={setFilters}
                  handleFilterChange={handleFilterChange}
                  currentFormState={formState} 
                  hideOwnValue={false}
                />
            </div>
          </>
        )}
      </section>

      <section className="">
        <h2 onClick={() => toggleSection('personalInterestsAndGoals')} 
        className="tw-font-semibold tw-flex tw-items-center tw-cursor-pointer">
          Personal Goals
          <span className={`arrow ${openSections['personalInterestsAndGoals'] ? 'down' : 'right'}`} />
        </h2>
        {openSections['personalInterestsAndGoals'] && (
          <>
          <div className="tw-col-span-full">
                <label htmlFor="helpOffered" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                How you can help someone
                </label>
                <div className="tw-mt-2">
                  <textarea
                    id="helpOffered"
                    name="helpOffered"
                    rows={3}
                    className="tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                    value={formState.helpOffered}
                    onChange={handleChange}
                  />
                </div>
              </div>

          {/* Current Goals Textarea */}
          <div className="tw-col-span-full">
                <label htmlFor="currentGoals" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Current Goals
                </label>
                <div className="tw-mt-2">
                  <textarea
                    id="currentGoals"
                    name="currentGoals"
                    rows={3}
                    className="tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                    value={formState.currentGoals}
                    onChange={handleChange}
                  />
                </div>
              </div>

          {/* Long-Term Goals Textarea */}
          <div className="tw-col-span-full">
                <label htmlFor="schoolFiveYearGoals" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                Long-Term Goals
                </label>
                <div className="tw-mt-2">
                  <textarea
                    id="schoolFiveYearGoals"
                    name="schoolFiveYearGoals"
                    rows={3}
                    className="tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                    value={formState.schoolFiveYearGoals}
              onChange={handleChange}
                  />
                </div>
              </div>        
        <div className="tw-col-span-full">
              <label htmlFor="favoriteMedia" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
              Favorite Movie / Music / TV Show
              </label>
              <div className="tw-mt-2">
                <input
                  type="text"
                  name="favoriteMedia"
                  id="favoriteMedia"
                  autoComplete="street-address"
                  className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                  value={formState.favoriteMedia}
                  onChange={handleChange}
                />
              </div>
            </div>
            {/* <label className="profile-form__label">
              Native Language(s)
              <p>
                    Current: {Array.isArray(formState.nativeLanguage) ? formState.nativeLanguage.join(', ') : formState.nativeLanguage}
                  </p>
              <div
        >
          <FormCheckboxes  
                values={getUniqueValues('nativeLanguage')}
                selectedValues={formState.nativeLanguage}
                searchText={searchTexts.nativeLanguage}
                searchKey='nativeLanguage'
                onSearchTextChange={handleSearchTextChange}
                onChange={handleCheckboxChange('nativeLanguage')}
            />
        </div>
            </label>
            <label className="profile-form__label">
              Interest Languages
              <p>
                    Current: {Array.isArray(formState.interestLanguages) ? formState.interestLanguages.join(', ') : formState.interestLanguages}
                  </p>
                       <div
        >
          <FormCheckboxes  
                values={getUniqueValues('interestLanguages')}
                selectedValues={formState.interestLanguages}
                searchText={searchTexts.interestLanguages}
                searchKey='interestLanguages'
                onSearchTextChange={handleSearchTextChange}
                onChange={handleCheckboxChange('interestLanguages')}
            />
            </div>
              
            </label> */}
          </>
        )}
      </section>
{/* 
      <section className="profile-form__section">
        <h2 onClick={() => toggleSection('professionalInformation')}>
          Professional Information
          <span className={`arrow ${openSections['professionalInformation'] ? 'down' : 'right'}`} />
        </h2>
        {openSections['professionalInformation'] && (
          <>
            <label className="profile-form__label">
              Current Job Title
              <input
                className="profile-form__input"
                type="text"
                name="currentLastJobTitle"
                value={formState.currentLastJobTitle}
                onChange={handleChange}
              />
            </label>
            <label className="profile-form__label">
              Company
              <input
                className="profile-form__input"
                type="text"
                name="company"
                value={formState.company}
                onChange={handleChange}
              />
            </label>
            <label className="profile-form__label">
                  Industry Focus
                  <p>
                    Current: {Array.isArray(formState.recruitingFor) ? formState.recruitingFor.join(', ') : formState.recruitingFor}
                  </p>
                  <div
            >
              <FormCheckboxes  
                    values={getUniqueValues('recruitingFor')}
                    selectedValues={formState.recruitingFor}
                    searchText={searchTexts.recruitingFor}
                    searchKey='recruitingFor'
                    onSearchTextChange={handleSearchTextChange}
                    onChange={handleCheckboxChange('recruitingFor')}
                />
            </div>
                </label>
          </>
        )}
      </section>
 */}
      </div>
      {isUpdateSuccessful && (
        <div>Your profile has been updated successfully!</div>
      )}

      <div className="tw-mt-6 tw-flex tw-items-center tw-justify-end tw-gap-x-6 tw-pb-5">
        <button
          type="submit"
          className="tw-rounded-md tw-bg-indigo-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
          disabled={isSubmitDisabled}
        >
          Update
        </button>
      </div>

      {/* <label className="tw-profile-form__label">
              Email
              <input
                className="tw-profile-form__input"
                type="email"
                name="email"
                value={formState.email}
                onChange={handleChange}
              />
            </label> */}

         {/* Hometown Input */}
        {/* <label className="tw-profile-form__label">
          Hometown
          <input
            className="tw-profile-form__input"
            type="text"
            name="hometown"
            value={formState.hometown}
            onChange={handleChange}
          />
        </label> */}

        {/* Country Select */}
        {/* <label className="tw-profile-form__label">
          Country
          <select
            className="tw-profile-form__input"
            name="country"
            value={formState.country}
            onChange={handleChange}
          >
            {getUniqueCountryValues().map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
            <option value="other">Other...</option>
          </select> */}

          {/* Other Country Input */}
          {/* {formState.country === 'other' && (
            <input
              className="tw-profile-form__input"
              type="text"
              name="newCountry"
              placeholder="Enter new country"
              value={formState.newCountry || ''}
              onChange={handleChange}
            />
          )}
        </label> */}
{/* 
              <div className="tw-col-span-full">
                <label htmlFor="cover-photo" className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                  Cover photo
                </label>
                <div className="tw-mt-2 tw-flex tw-justify-center tw-rounded-lg tw-border tw-border-dashed tw-border-gray-900/25 tw-px-6 tw-py-10">
                  <div className="tw-text-center">
                    <div className="tw-mt-4 tw-flex tw-text-sm tw-leading-6 tw-text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="tw-relative tw-cursor-pointer tw-rounded-md tw-bg-white tw-font-semibold tw-text-indigo-600 tw-focus-within:outline-none tw-focus-within:ring-2 tw-focus-within:ring-indigo-600 tw-focus-within:ring-offset-2 tw-hover:text-indigo-500"
                      >
                        <span>Upload a file</span>
                        <input id="file-upload" name="file-upload" type="file" className="tw-sr-only" />
                      </label>
                      <p className="tw-pl-1">or drag and drop</p>
                    </div>
                    <p className="tw-text-xs tw-leading-5 tw-text-gray-600">PNG, JPG, GIF up to 10MB</p>
                  </div>
                </div>
              </div>
               */}
    </form>
  );
};

export default ProfileForm;



