import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../utils/helperFunctions';

const StandardMenu = ({ buttonText, items, onSelect, selectedText, disabled = false }) => {
  return (
    <Menu as="div" className="tw-relative tw-inline-block tw-text-left">
      <div>
        <Menu.Button className="tw-inline-flex tw-w-full tw-justify-center tw-gap-x-1.5 tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50">
          {selectedText ? selectedText : buttonText}
          <ChevronDownIcon className="tw-mr-1 tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="tw-transition tw-ease-out tw-duration-100"
        enterFrom="tw-transform tw-opacity-0 tw-scale-95"
        enterTo="tw-transform tw-opacity-100 tw-scale-100"
        leave="tw-transition tw-ease-in tw-duration-75"
        leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
        leaveTo="tw-transform tw-opacity-0 tw-scale-95"
      >
        <Menu.Items className={`tw-absolute tw-right-0 tw-z-10 tw-mt-2 tw-w-56 tw-origin-top-right tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-focus:outline-none ${disabled ? 'tw-pointer-events-none tw-opacity-50 ' : ''}`}>
          <div className="tw-py-1">
            {!disabled && items.map((item) => (
              <Menu.Item key={item.value}>
                {({ active }) => (
                  <a
                    onClick={() => onSelect(item.value)}
                    className={classNames(
                      active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700',
                      'tw-block tw-px-4 tw-py-2 tw-text-sm hover:tw-cursor-pointer'
                    )}
                  >
                    {item.name}
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default StandardMenu;
