import React from 'react';
import './styles/main.scss';
// import { getAnalytics, isSupported } from 'firebase/analytics';
import { app } from './firebase/firebase';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider, useUser } from './contexts/UserContext';
import ProtectedRoute from './components/ProtectedRoute';
import NavigateHome from './components/NavigateHome';
// import Home from './pages/Home';
// import Directory from './pages/Directory.js'
import Directory from './pages/Directory.js';
import Events from './pages/Events.js';
import Header from './components/Header';
import Login from './pages/Login';
import AuthActionsPage from './pages/AuthActionsPage';
import Profile from './pages/Profile';
import ConversationsPage from './pages/ConversationsPage';
import UserDetail from './pages/UserDetail';
import EventDetail from './pages/EventDetail';
import Loading from './components/Loading';
import SignUp from './pages/SignUp';
import NewEvent from './pages/NewEvent';
import Teahouse from './pages/TheTeahouse';
import ContactDetail from './pages/crm/ContactDetail';
import EarnPage from './pages/earn/EarnPage';
import StudyDetail from './pages/earn/StudyDetail';
import { useLocation } from 'react-router-dom';
import DirectoryFilters from './components/filters/DirectoryFilters';
import BrewWorks from './pages/brew/BrewWorks.js';
import BrewCompany from './pages/brew/BrewCompany.js';
import BrewWorksFilters from './components/filters/brewWorks/BrewWorksFilters.js';
import ChaiPage from './pages/chai/ChaiPage.js';
import ChaiCompany from './pages/chai/ChaiCompany.js';
import ChaiContact from './pages/chai/ChaiContact.js';
import TermsAndConditions from './components/TermsAndConditions.js';
import Privacy from './components/Privacy.js';
import SchoolPage from './pages/school/SchoolPage.js';
import SchoolClub from './pages/school/SchoolClub.js';
import SchoolOrganization from './pages/school/SchoolOrganization.js';
import SchoolCompetition from './pages/school/SchoolCompetition.js';
import CompetitionTeam from './pages/school/CompetitionTeam.js';
import CompetitionFreelancer from './pages/school/CompetitionFreelancer.js';
import EventDetailFilters from './components/filters/EventDetailFilters.js';
import CompetitionFilters from './components/filters/competitions/CompetitionFilters.js';
import ChaiFilters from './components/filters/chai/ChaiFilters.js';
import JobCompany from './pages/brew/JobCompany.js';
import GettingStarted from './pages/help/GettingStarted.js';
import Guides from './pages/help/Guides.js';
import { usePageTracking } from './utils/googleAnalyticsFunctions.js';
import SharedContact from './pages/brew/SharedContact.js';
import SchoolFilters from './components/filters/school/SchoolFilters.js';
import AddClub from './pages/school/AddClub.js';
import UserJob from './pages/brew/UserJob.js';


// isSupported().then((supported) => {
//   if (supported) {
//     const analytics = getAnalytics(app);
//     // Analytics can be used since it's supported in this environment
//   } else {
//     console.log("Firebase Analytics is not supported in this environment");
//   }
// });

function AuthRoutes() {
  const location = useLocation();
  const authRoutes = ['/login', '/auth/action', '/terms-and-conditions', '/privacy'];
  const isAuthRoute = authRoutes.includes(location.pathname);
  try {
    usePageTracking();
  } catch (error) {
    console.error('Error in usePageTracking:', error);
  }

  return isAuthRoute ? (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/auth/action" element={<AuthActionsPage />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy" element={<Privacy />} />
    </Routes>
  ) : (
    <>
          {/* <Header /> */}
          <Routes>
            <Route path="/" element={<NavigateHome/>} />
            <Route path="/teahouse" 
              element={
                <PageLayout page={<Teahouse />} />
              } 
            />
            <Route path="/brew" 
              element={
                <PageLayout page={<BrewWorks />} filters={<BrewWorksFilters />} />
              } 
            />
            <Route path="/brew/org/:orgId/company/:id" 
              element={
                <PageLayout page={<BrewCompany />} />
              } 
            />
            <Route path="/brew/company/:id" 
              element={
                <PageLayout page={<BrewCompany />} />
              } 
            />
            <Route path="/brew/contact/:id" 
              element={
                <PageLayout page={<SharedContact />} />
              } 
            />
            <Route path="/brew/job/:id" 
              element={
                <PageLayout page={<JobCompany />} />
              } 
            />
            <Route path="/brew/job/:id/user" 
              element={
                <PageLayout page={<UserJob />} />
              } 
            />
            <Route path="/chai" 
              element={
                <PageLayout page={<ChaiPage />} filters={<ChaiFilters />} />
              } 
            />
            <Route path="/chai/company/:id" 
              element={
                <PageLayout page={<ChaiCompany />} />
              } 
            />
            <Route path="/chai/contact/:id" 
              element={
                <PageLayout page={<ChaiContact />} />
              } 
            />
            <Route path="/teahouse/contact" 
              element={
                <PageLayout page={<ContactDetail />} />
              } 
            />
            <Route path="/directory" 
              element={
                  <PageLayout page={<Directory />} filters={<DirectoryFilters />} />
              } 
            />
            <Route path="/directory/:id"
              element={
                <PageLayout page={<UserDetail />} />
              }
            />
            <Route path="/directory/:id/:eventId"
              element={
                <PageLayout page={<UserDetail />} />
              }
            />
             {/* <Route path="/earn"
              element={
                <PageLayout page={<EarnPage />} />
              }
            />
             <Route path="/earn/study/:id"
              element={
                <PageLayout page={<StudyDetail />} />
              }
            /> */}
            <Route path="/guides"
              element={
                <PageLayout page={<Guides />} />
              }
            />

            <Route path="/profile/*"
              element={
                <PageLayout page={<Profile />} />
              } 
            />
            <Route path="/school"
              element={
                <PageLayout page={<SchoolPage />} filters={<SchoolFilters />} />
              }
            />
            <Route path="/school/club/:id" 
              element={
                <PageLayout page={<SchoolClub />} />
              } 
            />
            <Route path="/school/club/add" 
              element={
                <PageLayout page={<AddClub />} />
              } 
            />
            <Route path="/school/competition/:id" 
              element={
                <PageLayout page={<SchoolCompetition />} filters={<CompetitionFilters />}  />
              } 
            />
            <Route path="/school/competition/:competitionId/freelancer/:freelancerId" 
              element={
                <PageLayout page={<CompetitionFreelancer />} />
              } 
            />
            <Route path="/school/competition/:competitionId/team/:teamId" 
              element={
                <PageLayout page={<CompetitionTeam />} />
              } 
            />
            <Route path="/school/organization/:id" 
              element={
                <PageLayout page={<SchoolOrganization />} />
              } 
            />
            <Route path="/start" 
              element={
                <PageLayout page={<GettingStarted />} />
              } 
            />
            <Route path="/events"
              element={
                <PageLayout page={<Events />} />
              } 
            />
            <Route path="/events/:id"
              element={
                <PageLayout page={<EventDetail />} filters={<EventDetailFilters />} />
              }
            />
            <Route path="/edit-event/:id"
              element={
                <PageLayout page={<NewEvent />} />
              }
            />
            <Route path="/events/new"
              element={
                <PageLayout page={<NewEvent />} />
              }
            />
            {/* <Route path="/the-tea"
              element={
                <ProtectedRoute>
                  <ConversationsPage />
                </ProtectedRoute>
              } 
            /> */}
            {/* <Route path="/events" element={<Events />} /> */}
            {/* <Route path="/signup/easton" element={<SignUp />} /> */}
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signup/location/:location?" element={<SignUp />} />
            <Route path="/signup/events/:eventId" element={<SignUp />} />
            <Route path="*" element={<NavigateHome/>} />
          </Routes>
        </>
  );
}

function AppRoutes() {
  // const { loading } = useUser();
  // if (loading ) {
  //   return <Loading />;
  // }
  return (
    <Router>
      <AuthRoutes />
    </Router>
  );
}

const PageLayout = ({ page, filters }) => {
  return (
    <ProtectedRoute>
      <Header page={page} filters={filters ? filters : null} />
    </ProtectedRoute>
  );
};

function App() {

  return (
    <UserProvider>
      <AppRoutes />
    </UserProvider>
  );
}


export default App;
