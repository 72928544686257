import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../utils/helperFunctions';

export default function EventCard({ event, id }) {
    const navigate = useNavigate();

    const navigateToEvent = () => {
      navigate(`/events/${id}`);
    };

    return (
      <div className="event-card" onClick={navigateToEvent}>
        <div className="event-card__image">
          {event.image && <img src={event.image} alt={event.eventName} />}
        </div>
        <div className="event-card__content">
          <p className="event-card__name">{event.eventName}</p>
          <p>{formatDate(event.startDate)}</p>
          {/* Include other event details here */}
        </div>
      </div>
    );
  }
  