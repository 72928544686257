import React from 'react';

const Privacy = () => {
  return (
    <div className="tw-p-8 tw-bg-white tw-rounded-lg tw-shadow-md tw-max-w-2xl tw-mx-auto">
      <h1 className="tw-text-2xl tw-font-bold tw-mb-4">TeahouseAI Privacy Policy</h1>
      <p className="tw-mb-2">Last Updated: January 2nd, 2024</p>
      <ul className="tw-list-none tw-list-inside">
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">1. Introduction</h2>
          <p>This Privacy Policy explains how TeahouseAI, headquartered in Dallas, TX, collects, uses, and shares information about you when you use our CRM, event management, directory services, and other related Services.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">2. Information Collection</h2>
          <h3 className="tw-font-bold">2.1. Types of Data Collected</h3>
          <p>Personal Data: While using our Service, we may ask you to provide us with certain personally identifiable information.<br/>
          Usage Data: We may also collect information on how the Service is accessed and used.<br/>
          Tracking & Cookies Data: We use cookies and similar tracking technologies.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">3. Use of Data</h2>
          <h3 className="tw-font-bold">3.1. TeahouseAI uses the collected data for various purposes, including:</h3>
          <p>To provide and maintain our Service.<br/>
          To notify you about changes to our Service.<br/>
          To provide customer support.<br/>
          To gather analysis or valuable information so that we can improve our Service.<br/>
          To monitor the usage of our Service.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">4. Data Storage and Security</h2>
          <h3 className="tw-font-bold">4.1. Data Storage</h3>
          <p>We use Firebase for secure data storage.</p>
          <h3 className="tw-font-bold">4.2. Data Security</h3>
          <p>We are committed to protecting your data but acknowledge that no method of transmission over the internet or method of electronic storage is 100% secure.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">5. Data Sharing and Disclosure</h2>
          <h3 className="tw-font-bold">5.1. Third-Party Service Providers</h3>
          <p>We may employ third-party companies like ChromaDB and OpenAI API.</p>
          <h3 className="tw-font-bold">5.2. Legal Requirements</h3>
          <p>TeahouseAI may disclose your Personal Data in the belief that such action is necessary to comply with a legal obligation.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">6. Data Protection Rights Under General Data Protection Regulation (GDPR)</h2>
          <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. TeahouseAI aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
        </li>
        <li className="tw-mb-2">
          <h2 className="tw-font-bold">7. California Privacy Rights (California's Shine the Light law)</h2>
          <p>Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an established business relationship with us can request information once a year about sharing their Personal Data with third parties for the third parties' direct marketing purposes.</p>
        </li>
      </ul>
    </div>
  );
};

export default Privacy;
